import { I18nextProvider } from 'react-i18next';
import { UILibraryContextProvider } from '@breeze-ai/ui-library';
import { GlobalToastRegion } from '@breezeai-frontend/cargo-ui';
import i18n from '@breezeai-frontend/i18n-config';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { AuthContextProvider } from './context/auth/AuthContext';
import { PlatformContextProvider } from './context/PlatformContext';
import { QueryClientProvider } from './providers/ReactQueryProvider';
import { AppRouter } from './router/AppRouter';

export default function App(): JSX.Element {
  // TODO - There should be a top level error boundary here
  return (
    <QueryClientProvider>
      <PlatformContextProvider>
        <I18nextProvider i18n={i18n}>
          <UILibraryContextProvider>
            <GlobalToastRegion />
            <AuthContextProvider>
              <AppRouter />
            </AuthContextProvider>
          </UILibraryContextProvider>
        </I18nextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </PlatformContextProvider>
    </QueryClientProvider>
  );
}
