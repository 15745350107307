import { type Key } from 'react';
import { FaShip } from 'react-icons/fa';
import { FaPlaneUp, FaTrain, FaTruck } from 'react-icons/fa6';
import {
  Select,
  SelectItem,
  type SelectProps,
} from '@breezeai-frontend/cargo-ui';

import { type BffGetConfigurationsResponseModeOfTransportOption } from '../../../generated/api-client';

const motNameToIconMap = (
  name: BffGetConfigurationsResponseModeOfTransportOption['name'],
) => {
  switch (name) {
    case 'sea':
      return <FaShip />;
    case 'air':
      return <FaPlaneUp />;
    case 'rail':
      return <FaTrain />;
    case 'road':
      return <FaTruck />;
  }
};

interface ModeOfTransportSelectProps
  extends Omit<
    SelectProps<BffGetConfigurationsResponseModeOfTransportOption>,
    'children' | 'onSelectionChange'
  > {
  onSelectionChange: (
    item: BffGetConfigurationsResponseModeOfTransportOption,
  ) => void;

  /**
   * We filter the transportation options based on the primary (Origin, Destination)
   * or secondary (Place of loading, Place of Discharge) part of the journey.
   */
  type?: 'primary' | 'secondary';
  items: BffGetConfigurationsResponseModeOfTransportOption[];
}

export function ModeOfTransportSelect({
  type = 'primary',
  onSelectionChange,
  items,
  ...props
}: ModeOfTransportSelectProps) {
  const filteredItems =
    type === 'primary'
      ? items
      : items.filter(({ name }) => name === 'road' || name === 'rail');

  const handleSelectionChange = (key: Key) => {
    const item = filteredItems.find((i) =>
      type === 'primary' ? i.display_name === key : i.name === key,
    );

    if (item) {
      onSelectionChange?.(item);
    }
  };
  return (
    <Select
      aria-label="Select transport"
      placeholder={props.placeholder || 'Select transport'}
      className="w-[140px] min-w-0 capitalize"
      onSelectionChange={handleSelectionChange}
      absoluteErrorMessage={true}
      {...props}
    >
      {filteredItems.map(({ name, display_name }) => (
        <SelectItem
          data-testid={`select-option-${name}`}
          key={type === 'primary' ? display_name : name}
          id={type === 'primary' ? display_name : name}
          textValue={type === 'primary' ? display_name : name}
          className="gap-2"
        >
          <span className="text-icons-primary">{motNameToIconMap(name)}</span>
          <span className="capitalize">
            {type === 'primary' ? display_name : name}
          </span>
        </SelectItem>
      ))}
    </Select>
  );
}
