import { splitFileName } from '@breeze-ai/ui-library';

import { type BffCreateClaimPayloadParams } from '../../../generated/api-client';
import { type Claim } from '../../../model/Claim';
import { get, post, put } from '../../AuthRequests';
import { bffServiceUrl } from '../../netconfig';
import {
  type FilterableFields,
  type PaginationBaseQueryParams,
} from '../types';
import { normalizeClaimResponse } from './normalizers';
import {
  type ClaimModel,
  type ClaimsSortableFields,
  type FilePayload,
  type FileResponse,
  type GetClaimsNormalizedResponse,
  type GetClaimsResponse,
} from './types';

export const getClaims = async (
  params?: PaginationBaseQueryParams<ClaimsSortableFields, FilterableFields>,
): Promise<GetClaimsNormalizedResponse> => {
  const { data } = await post<GetClaimsResponse>(
    `${bffServiceUrl}/claims/get`,
    params,
  );

  return {
    ...data,
    claims: data.claims.map(normalizeClaimResponse),
  };
};

export const getClaimById = async (
  claimId: string | number,
): Promise<Claim> => {
  const { data } = await get<ClaimModel>(`${bffServiceUrl}/claims/${claimId}`);

  return normalizeClaimResponse(data);
};

export const createClaim = async (
  payload: BffCreateClaimPayloadParams,
): Promise<Claim> => {
  const { data } = await post<ClaimModel>(`${bffServiceUrl}/claims`, payload);

  return normalizeClaimResponse(data);
};

export const updateClaim = async (
  claimId: number,
  payload: Partial<BffCreateClaimPayloadParams>,
): Promise<Claim> => {
  const { data } = await put<ClaimModel>(
    `${bffServiceUrl}/claims/${claimId}`,
    payload,
  );

  return normalizeClaimResponse(data);
};

const fileContentToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve((reader.result as string).split('base64,')[1]);
    reader.onerror = reject;
  });

export const uploadClaimFile = async (claimId: number, file: File) => {
  const base64Content = await fileContentToBase64(file);
  const payload: FilePayload = {
    name: file.name,
    type: splitFileName(file)[1],
    data_type: file.type,
    size_in_bytes: file.size,
    content: base64Content,
  };

  const { data } = await post(
    `${bffServiceUrl}/claims/${claimId}/files`,
    payload,
  );

  return data;
};

export const getClaimFiles = async (
  claimId: string | number,
): Promise<FileResponse[]> => {
  const { data } = await get<FileResponse[]>(
    `${bffServiceUrl}/claims/${claimId}/files`,
  );

  return data;
};
