import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { FaCopy, FaDownload, FaPen } from 'react-icons/fa6';
import {
  ColumnHeader,
  CurrencyCell,
  DateCell,
  getBaseColumnDefinition,
  type TableColumn,
  TextCell,
  Tooltip,
} from '@breeze-ai/ui-library';

import { useFeatureToggle } from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import { QuoteStatusEnum } from '../../../../network/apis/quotes/types';
import {
  ActionMenu,
  type ActionMenuItem,
} from '../../../components/ActionMenu/ActionMenu';
import { StatusBadge } from '../../../components/StatusBadge/StatusBadge';
import { useTranslationContext } from '../../../hooks/useTranslationContext';
import {
  type QuoteStatusToBadge,
  statusToBadge,
} from '../../../quotes/utils/statusToBadge';
import { type Certificate } from '../../types';

const unknown = {
  label: 'Unknown',
  icon: <FaRegQuestionCircle opacity={0.8} />,
};

const certificateStatusBadgeVariants: QuoteStatusToBadge = {
  ...statusToBadge,
  // The following statuses are not used in the certificates table and will display a question mark icon with text "Unknown"
  ready_for_policy: unknown,
  unknown: unknown,
  expired: unknown,
  cancelled: unknown,
};

export const useCertificatesTableColumns = (): TableColumn<Certificate>[] => {
  const sortingEnabled = useFeatureToggle('enable_column_sorting');
  const { isWtw, platform } = usePlatform();
  const { t } = useTranslation();
  const tContext = useTranslationContext();

  return useMemo(
    () =>
      [
        {
          ...getBaseColumnDefinition(
            'certificate_id',
            t('table.CertificateNumber', {
              ns: 'certificates',
              context: platform,
            }),
          ),
          valueGetter: ({ row }: { row: Certificate }) =>
            row.external_certificate_id,
          renderCell: ({ value }: { value: string }) => (
            <TextCell>{value}</TextCell>
          ),
          minWidth: 190,
          sortable: true,
        },
        {
          ...getBaseColumnDefinition('created_at', 'Created On'),
          type: 'date',
          minWidth: 150,
          valueGetter: ({ row }: { row: Certificate }) => row.created_time,
          renderCell: ({ row }: { row: Certificate }) => (
            <DateCell
              format="SHORT_MONTH_NAME_DATE"
              value={row.created_time}
              createdBy={
                row.created_by_user && {
                  avatarColor: row.created_by_user.avatar_color,
                  name: `${row.created_by_user.first_name} ${row.created_by_user.last_name}`,
                }
              }
            />
          ),
          sortable: sortingEnabled,
        },
        isWtw
          ? {
              ...getBaseColumnDefinition('primary_assured', 'Primary Assured'),
              valueGetter: ({ row }: { row: Certificate }) =>
                row.distributor?.legal_name,
              renderCell: ({ value }: { value: string }) => (
                <Tooltip content={String(value)}>
                  <div className="flex flex-row items-center gap-2 truncate w-full">
                    <TextCell>{value as string}</TextCell>
                  </div>
                </Tooltip>
              ),
              sortable: sortingEnabled,
              minWidth: 180,
            }
          : null,
        {
          ...getBaseColumnDefinition(
            'external_reference',
            t('BookingReference', {
              ns: 'common',
              context: platform,
            }),
          ),
          valueGetter: ({ row }: { row: Certificate }) =>
            row.quote?.external_reference,
          renderCell: ({ value }: { value: string }) => (
            <TextCell>{value}</TextCell>
          ),
          minWidth: 180,
          sortable: sortingEnabled,
        },
        {
          ...getBaseColumnDefinition('customer_name', 'Named Assured'),
          minWidth: 180,
          valueGetter: ({ row }: { row: Certificate }) => row.customer?.name,
          renderCell: ({ value }: { value: string }) => (
            <TextCell>{value}</TextCell>
          ),
          sortable: false,
        },
        {
          ...getBaseColumnDefinition(
            'insured_value',
            t('InsuredValue', {
              ns: 'common',
              context: tContext,
            }),
          ),
          type: 'number',
          valueGetter: ({ row: { quote } }: { row: Certificate }) =>
            Math.round(Number(quote?.value_insured)),
          renderCell: ({ row: { quote } }: { row: Certificate }) => (
            <CurrencyCell
              value={quote?.insured_value}
              currency={quote?.commodity_currency}
            />
          ),
          renderHeader: () => {
            return (
              <ColumnHeader>
                {t('InsuredValue', {
                  ns: 'common',
                  context: tContext,
                })}
              </ColumnHeader>
            );
          },
          minWidth: 180,
          sortable: sortingEnabled,
        },
        {
          ...getBaseColumnDefinition(
            'etd',
            t('Departure', {
              ns: 'common',
              context: platform,
            }),
          ),
          type: 'date',
          valueGetter: ({ row: { quote } }: { row: Certificate }) => quote?.etd,
          renderCell: ({
            row: { quote },
            value,
          }: {
            row: Certificate;
            value: string;
          }) => (
            <DateCell
              value={value as ISODate}
              title={
                quote?.origin_place?.display_name ?? quote?.origin_port_code
              }
            />
          ),
          minWidth: 150,
          sortable: sortingEnabled,
        },
        {
          ...getBaseColumnDefinition(
            'eta',
            t('Arrival', {
              ns: 'common',
              context: platform,
            }),
          ),
          valueGetter: ({ row: { quote } }: { row: Certificate }) => quote?.eta,
          renderCell: ({
            row: { quote },
            value,
          }: {
            row: Certificate;
            value: string;
          }) => (
            <DateCell
              value={value as ISODate}
              title={
                quote?.destination_place?.display_name ??
                quote?.destination_port_code
              }
            />
          ),
          width: 160,
          sortable: sortingEnabled,
        },
        {
          ...getBaseColumnDefinition('status', 'Status'),
          minWidth: 140,
          sortable: sortingEnabled,
          valueGetter: ({ row }: { row: Certificate }) => row.quote?.status,
          renderCell: ({ row }: { row: Certificate }) => {
            const { label, variant, Icon } =
              certificateStatusBadgeVariants[row.quote?.status ?? 'unknown'] ??
              certificateStatusBadgeVariants.unknown;

            return <StatusBadge variant={variant} Icon={Icon} label={label} />;
          },
        },
        {
          ...getBaseColumnDefinition('action', ''),
          minWidth: 40,
          maxWidth: 60,
          renderCell: ({ row }: { row: Certificate }) => {
            // TODO - disabled vs hidden states not clear here. Should we disable at all?
            const isAuthorizedToEditCertificate =
              row?.quote?.status === QuoteStatusEnum.POLICY_ISSUED
                ? Boolean(row?.permissions?.edit_issued)
                : Boolean(row?.permissions?.create);
            const items: ActionMenuItem[] = [
              {
                type: 'link',
                label: 'Duplicate certificate',
                href: `/certificates/duplicate/${row.external_certificate_id}/${row.id}`,
                leftIcon: <FaCopy />,
                isHidden: !row?.permissions?.create,
                isDownload: false,
              },
              {
                type: 'link',
                label: 'Edit details',
                href: `/certificates/update/${row.external_certificate_id}/${row.id}`,
                leftIcon: <FaPen />,
                isHidden: !isAuthorizedToEditCertificate,
                isDisabled: row?.associated_with_open_claim,
                isDownload: false,
              },
              {
                type: 'link',
                label: 'Download certificate',
                href: row.certificate_url,
                leftIcon: <FaDownload />,
                isDisabled: !row.certificate_url,
                isDownload: true,
              },
            ];
            return <ActionMenu items={items} id={row.id} />;
          },
        },
      ].filter(Boolean) as TableColumn<Certificate>[],
    [isWtw, sortingEnabled, t, platform, tContext],
  );
};
