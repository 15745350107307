import React from 'react';
import {
  Button,
  ComboBox as AriaComboBox,
  type ComboBoxProps as AriaComboBoxProps,
  composeRenderProps,
  type ListBoxItemProps,
  type PopoverProps,
} from 'react-aria-components';
import { FaChevronDown } from 'react-icons/fa6';
import { ImSpinner8 } from 'react-icons/im';

import { clsxMerge } from '../common/utils/classNameUtils';
import {
  Description,
  FieldError,
  FieldGroup,
  fieldStyles,
  Input,
  Label,
} from '../Field/Field';
import {
  DropdownItem,
  DropdownSection,
  type DropdownSectionProps,
  ListBox,
} from '../ListBox/ListBox';
import { Popover } from '../Popover/Popover';
import { Typography } from '../Typography/Typography';

export interface ComboBoxProps<T extends object>
  extends Omit<AriaComboBoxProps<T>, 'children'> {
  label?: string;
  description?: string;
  errorMessage?: string;
  isFetchingItems?: boolean;
  emptyCollectionMessage?: string;
  showCustomOptionWhenEmpty?: boolean;
  /**
   * Function to create a custom option when no results are found
   * TODO check if can be replace with handleEmptyState
   * @param inputValue The current input value
   * @returns A custom option object that will be appended to the items list
   */
  createCustomOption?: (inputValue: string) => T;
  inputRef?: React.ForwardedRef<HTMLInputElement>;
  placeholder?: string;
  popoverProps?: Omit<PopoverProps, 'children'>;
  children: React.ReactNode | ((item: T) => React.ReactNode);
}

export function ComboBox<T extends object>({
  label,
  description,
  errorMessage,
  children,
  items,
  isFetchingItems,
  emptyCollectionMessage = 'No options',
  showCustomOptionWhenEmpty = false,
  createCustomOption,
  inputRef,
  placeholder,
  popoverProps,
  ...props
}: ComboBoxProps<T>) {
  // Get the input value from the props
  const inputValue = props.inputValue || '';
  return (
    <AriaComboBox
      {...props}
      validationBehavior="aria"
      className={composeRenderProps(props.className, (className, renderProps) =>
        clsxMerge(fieldStyles({ ...renderProps }), className),
      )}
    >
      <Label isRequired={props.isRequired}>{label}</Label>
      <FieldGroup>
        <Input
          role="input"
          className="w-full border-none overflow-ellipsis"
          placeholder={placeholder}
          ref={inputRef}
        />

        <Button className="p-3">
          {isFetchingItems ? (
            <ImSpinner8 className="animate-spin" aria-description="Loading" />
          ) : (
            <FaChevronDown aria-hidden className="w-3 h-3 fill-gray-700" />
          )}
        </Button>
      </FieldGroup>
      {description && <Description>{description}</Description>}
      <FieldError absolute>{errorMessage}</FieldError>

      <Popover
        {...popoverProps}
        className={clsxMerge(
          'rounded-lg p-2 bg-white min-w-[--trigger-width]',
          popoverProps?.className,
        )}
        shouldFlip={popoverProps?.shouldFlip ?? false}
        placement={popoverProps?.placement ?? 'bottom start'}
        containerPadding={0}
      >
        <div
          className={clsxMerge(
            'overflow-y-auto rounded-lg max-h-96',
            popoverProps?.className,
          )}
        >
          <ListBox
            renderEmptyState={() => (
              <Typography customStyles="p-3">
                {emptyCollectionMessage}
              </Typography>
            )}
            items={
              showCustomOptionWhenEmpty &&
              inputValue &&
              !isFetchingItems &&
              (items === undefined || [...items].length === 0) &&
              createCustomOption
                ? [...(items || []), createCustomOption(inputValue)]
                : items
            }
            className="outline-0 p-0"
          >
            {children}
          </ListBox>
        </div>
      </Popover>
    </AriaComboBox>
  );
}

export function ComboBoxItem(props: ListBoxItemProps) {
  return <DropdownItem {...props} />;
}

export function ComboBoxSection<T extends object>(
  props: DropdownSectionProps<T>,
) {
  return <DropdownSection {...props} />;
}
