import { type FieldMeta } from '@tanstack/react-form';

export const getUpdatedValue = <T extends string | number | object>(
  value: T | null | undefined,
  formMeta: Record<string, FieldMeta>,
  fieldName: string,
): T | null | undefined => {
  // No value, or value was not changed
  if (
    value === undefined ||
    !formMeta[fieldName] ||
    !formMeta[fieldName].isDirty
  ) {
    return undefined;
  }

  // Return the value if it has been updated
  // Always return the value if it's a number, because it could be 0
  if (!!value || typeof value === 'number') {
    return value;
  }

  // If we get here, it means the user has intentionally cleared the field
  return null;
};
