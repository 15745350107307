/**
 * TODO HACK: Temporary solution to use a custom i18n context based on the distributor
 * company name.
 *
 * The custom context is used in the translation JSON files to provide a more specific
 * translation for a given distributor.
 */

import { type CustomContext } from '@breezeai-frontend/i18n-config';

import { useAuthenticatedUser } from '../../context/auth/auth-hooks';
import { usePlatform } from '../../context/PlatformContext';

const companyNameToIntlContext: Record<string, CustomContext | undefined> = {
  compagnie_generale_du_roumois: 'compagnie_generale_du_roumois',
  'Denbigh Fine Art Services Ltd. (Canada) - Shipments and Art Handling':
    'compagnie_generale_du_roumois',
};

export const useTranslationContext = () => {
  const { platform } = usePlatform();
  const user = useAuthenticatedUser();

  const context = companyNameToIntlContext[user.distributor.company_name];

  return context ?? platform;
};
