import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

import { type ErrorResponse } from '../../../../network/apis/types';
import { type NetworkResponse, put } from '../../../../network/apis/utils';
import { bffServiceUrl } from '../../../../network/netconfig';
import { type StorageLocationFileModel } from '../types';

type Payload = {
  locationId: string;
  storageLocationFileId: string;
};

export const useUpdateStorageLocationFile = ({
  options,
}: {
  options?: UseMutationOptions<
    NetworkResponse<StorageLocationFileModel>,
    ErrorResponse,
    Payload
  >;
}) => {
  return useMutation({
    mutationFn: ({ locationId, storageLocationFileId }) =>
      put(
        `${bffServiceUrl}/bff-storage-locations/${locationId}/files/${storageLocationFileId}`,
        { status: 'deleted' },
      ),
    ...options,
  });
};
