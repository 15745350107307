import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

import { useUser } from '../../../../context/auth/auth-hooks';
import { type ErrorResponse } from '../../../../network/apis/types';
import {
  type NetworkResponse,
  post,
  put,
} from '../../../../network/apis/utils';
import { bffServiceUrl } from '../../../../network/netconfig';
import {
  type CreateStorageLocationPayload,
  type StorageLocationModel,
  type UpdateStorageLocationPayload,
} from '../types';

export const useCreateStorageLocation = ({
  options,
}: {
  options?: UseMutationOptions<
    NetworkResponse<StorageLocationModel>,
    ErrorResponse<
      | 'StorageLocationAddressNotAllowed'
      | 'StorageLocationInsuredValueException'
    >,
    CreateStorageLocationPayload | UpdateStorageLocationPayload
  >;
}) => {
  const user = useUser();
  return useMutation({
    mutationFn: (payload) =>
      post(`${bffServiceUrl}/bff-storage-locations`, {
        ...payload,
        // TODO HACK to be removed once the distributor select is in the form
        distributor_id: user?.distributor.id,
      }),
    ...options,
  });
};

export const useUpdateStorageLocation = ({
  locationId,
  options,
}: {
  locationId: string;
  options?: UseMutationOptions<
    NetworkResponse<StorageLocationModel>,
    ErrorResponse<
      | 'StorageLocationAddressNotAllowed'
      | 'StorageLocationInsuredValueException'
    >,
    CreateStorageLocationPayload | UpdateStorageLocationPayload
  >;
}) => {
  return useMutation({
    mutationFn: (payload) =>
      put(`${bffServiceUrl}/bff-storage-locations/${locationId}`, payload),
    ...options,
  });
};
