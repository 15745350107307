import { type Dispatch, type SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@breeze-ai/ui-library';

import { usePlatform } from '../../../../context/PlatformContext';
import { useUsers } from '../../../../network/apis/users/hooks';
import { MultiSelectRadioDropdown } from '../components/MultiSelectRadioDropdown';
import { useTableFilters } from '../hooks/useTableFilters';

// TODO rebuild using tailwind, aria and move to cargo ui
export function CreatedByFilterV2({
  filterName,
  setSearchParams,
  searchParams,
}: {
  filterName: string;
  // TODO link to useTableFilters props
  setSearchParams: Dispatch<SetStateAction<URLSearchParams>>;
  searchParams: URLSearchParams;
}) {
  const { platform } = usePlatform();
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>('');

  const {
    data: users,
    isError,
    isLoading,
  } = useUsers({
    params: {
      paginate: true,
      limit: 100,
      order: 'asc',
      query,
    },
  });

  const { upsertFilter, removeFilter, getAppliedAndNonAppliedOptions } =
    useTableFilters({
      setSearchParams,
    });
  const filterValues = searchParams.get(filterName)?.split('_');

  const onSubmit = () => {
    removeFilter(filterName);
    upsertFilter({
      filterName: filterName,
      values: selectedUsers,
    });
  };

  const onReset = () => removeFilter(filterName);

  const createdByOptions = useMemo(
    () =>
      users?.map(({ first_name, last_name, id, avatar_color }) => ({
        label: `${first_name} ${last_name}`,
        value: String(id),
        prefix: (
          <Avatar
            alt={`${first_name} ${last_name}`}
            size="small"
            type={'initials'}
            name={`${first_name} ${last_name}`}
            data-testid="user-avatar"
            backgroundColor={avatar_color ?? 'blue'}
          />
        ),
      })),
    [users],
  );
  const { appliedOptions, nonAppliedOptions } = useMemo(
    () => getAppliedAndNonAppliedOptions(filterValues, createdByOptions),
    [filterValues, createdByOptions, getAppliedAndNonAppliedOptions],
  );

  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    filterValues ?? [],
  );

  return (
    <MultiSelectRadioDropdown
      appliedOptions={appliedOptions}
      optionsCount={appliedOptions.length}
      selected={selectedUsers}
      setSelected={setSelectedUsers}
      onSearch={setQuery}
      name="createdBy"
      label={t('tables.filters.createdBy.Label', {
        ns: 'common',
        context: platform,
      })}
      dropdownIcon="user"
      nonAppliedOptions={nonAppliedOptions}
      onSubmit={onSubmit}
      onReset={onReset}
      placeholder="Search team member..."
      isError={isError}
      isLoading={isLoading}
      triggerTestId="created-by-filter-trigger"
      contentTestId="created-by-filter-content"
    />
  );
}
