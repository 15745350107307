import { type Key, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  BouncingDots,
  Button,
  Checkbox,
  DatePicker,
  DateRangePicker,
  LinkButton,
  Modal,
  type ModalProps,
  Select,
  SelectItem,
  TextField,
  Typography,
  UnauthorizedError,
} from '@breezeai-frontend/cargo-ui';
import { type DateValue } from '@internationalized/date';
import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { zodValidator } from '@tanstack/zod-form-adapter';

import { useAppConfiguration } from '../../../../context/app-configuration/useAppConfiguration';
import { useUser, useUserSetting } from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import { type SupportedCurrencies } from '../../../../model/CurrencyValue';
import { type PlaceSuggestion } from '../../../../model/Place';
import { type TitledOption } from '../../../../model/TitledOption';
import { CommodityComboBox } from '../../../components/CommodityComboBox/CommodityComboBox';
import { CustomerComboBox } from '../../../components/CustomerComboBox/CustomerComboBox';
import { type CustomerOption } from '../../../components/CustomerComboBox/types';
import { PlacesComboBox } from '../../../components/PlacesComboBox/PlacesComboBox';
import { useTranslationContext } from '../../../hooks/useTranslationContext';
import { storageLocationQueries } from '../../network/queries';
import { type StorageRouteParams } from '../../types';
import { useCreateStorageDeclaration } from '../hooks/useCreateDeclaration';
import { UNITS, useQuantityUnits } from '../hooks/useQuantityUnits';
import { type CreateStorageDeclarationResponse } from '../types';

interface DeclarationFormData {
  location: TitledOption | null;
  dateOfIssuance: string;
  storageDateRange: {
    start: DateValue;
    end: DateValue;
  } | null;
  distributor: TitledOption | null;
  customer?: CustomerOption | null;
  customerAddress?: Partial<PlaceSuggestion | null>;
  commodity?: TitledOption | null;
  description: string;
  insuredValueCurrencyCode: SupportedCurrencies;
  insuredValueAmount: string;
  quantityUnit: (typeof UNITS)[number];
  quantityAmount: string;
  createCertificate?: boolean;
}

function handleComboBoxSelectionChange<T>({
  key,
  handleChange,
  prevStateValue,
}: {
  key: Key | null;
  handleChange: (value: T | null) => void;
  prevStateValue: T | null;
}) {
  if (key) {
    const parsedValue: T = JSON.parse(String(key));
    handleChange(parsedValue);
    return parsedValue;
  }
  if (!key && prevStateValue) {
    handleChange(null);
    return null;
  }
  return prevStateValue;
}

export function DeclarationCreationModal({
  navigateToOnCancel,
  onCreation,
  ...props
}: Omit<ModalProps, 'children'> & {
  navigateToOnCancel: string;
  onCreation: (response: CreateStorageDeclarationResponse) => void;
}) {
  const { t } = useTranslation();
  const tContext = useTranslationContext();
  const { platform } = usePlatform();
  const user = useUser();
  const isAuthorizedToCreateDeclarations =
    user?.permissions.storage_locations?.create_declaration;
  if (!isAuthorizedToCreateDeclarations) throw new UnauthorizedError();
  const { locationId } = useParams<StorageRouteParams>();
  const { data: storageLocation, isLoading } = useQuery(
    storageLocationQueries.details({ locationId }),
  );
  const {
    mutate,
    isError: isCreateDeclarationError,
    isPending: isCreatingDeclaration,
    reset: resetCreateDeclarationError,
  } = useCreateStorageDeclaration({});
  const customerInputRef = useRef<HTMLInputElement | null>(null);

  const { quantityUnits } = useQuantityUnits();

  const { currencies } = useAppConfiguration();
  const defaultCurrency = useUserSetting('default_currency');
  const { data: configurations, isLoading: isLoadingConfigurations } = useQuery(
    storageLocationQueries.configuration({
      distributorId: storageLocation?.distributor.id,
    }),
  );

  const form = useForm<DeclarationFormData, typeof zodValidator>({
    // See https://tanstack.com/form/latest/docs/framework/react/guides/async-initial-values
    defaultValues: {
      location: {
        title: storageLocation?.location_name ?? '',
        id: storageLocation?.id ?? 0,
      },
      dateOfIssuance: '',
      storageDateRange: null,
      distributor: {
        title: storageLocation?.distributor.legal_name ?? '',
        id: storageLocation?.distributor.id ?? 0,
      },
      customer: null,
      customerAddress: null,
      commodity: null,
      description: '',
      insuredValueCurrencyCode: defaultCurrency?.toString() ?? '',
      insuredValueAmount: '',
      quantityUnit: 'mt',
      quantityAmount: '',
      createCertificate: true,
    },
    onSubmit: ({ formApi }) => {
      const customerInputValue = customerInputRef.current?.value.trim();

      const {
        distributor,
        location,
        storageDateRange,
        customer,
        customerAddress,
        commodity,
        quantityAmount,
        quantityUnit,
        createCertificate,
        insuredValueAmount,
        insuredValueCurrencyCode,
        description,
        dateOfIssuance,
      } = formApi.state.values;
      if (
        !distributor ||
        !location ||
        !storageDateRange ||
        !commodity ||
        !customerInputValue ||
        createCertificate === undefined
      ) {
        return;
      }

      // This is to handle the case where the user only types the company name and does not select from the dropdown
      const customerFieldMeta = formApi.getFieldMeta('customer');
      const isCreatingNewCustomer =
        customer?.company_name !== customerInputValue;
      if (isCreatingNewCustomer && customerInputValue && customerFieldMeta) {
        formApi.setFieldValue('customer', {
          company_name: customerInputValue,
        });
        formApi.setFieldMeta('customer', {
          ...customerFieldMeta,
          isDirty: true,
        });
      }
      const newCustomer = formApi.getFieldValue('customer');

      mutate(
        {
          distributor_id: distributor.id.toString(),
          location_id: location.id.toString(),
          start_date: storageDateRange?.start.toString(),
          end_date: storageDateRange.end.toString(),
          // If the customer is not selected from the dropdown, we only send the company name (user free text). Otherwise, we only send the customer id.
          customer_id: customer?.id ? customer.id.toString() : undefined,
          customer_name: customer?.id ? undefined : newCustomer?.company_name,
          customer_address: customerAddress?.place_id
            ? {
                place_id: customerAddress?.place_id,
                session_token: customerAddress?.session_token,
              }
            : undefined,
          commodity_id: commodity.id.toString(),
          create_certificate: createCertificate,
          insured_value_amount: insuredValueAmount,
          insured_value_currency_code: insuredValueCurrencyCode,
          description,
          date_of_issuance: dateOfIssuance.length ? dateOfIssuance : undefined,
          quantity_unit: quantityAmount ? quantityUnit : undefined,
          quantity_amount: quantityAmount.length ? quantityAmount : undefined,
        },
        {
          onSuccess: ({ data }) => {
            onCreation(data);
          },
          onError: () => {
            setTimeout(() => {
              resetCreateDeclarationError();
            }, 2000);
          },
        },
      );
    },
    validatorAdapter: zodValidator,
  });

  return (
    <Modal
      data-testid="storage-declaration-modal"
      aria-labelledby="Declaration Creation Modal"
      heading={
        <div className="flex w-full py-4">
          <Typography level="h4">
            {t('declarations.form.Title', {
              ns: 'storage',
              context: platform,
            })}
          </Typography>
        </div>
      }
      className="max-w-[929px]"
      {...props}
    >
      {isLoading ? (
        <BouncingDots className="h-[456px] flex items-center justify-center w-full" />
      ) : (
        <form
          noValidate
          data-testid="create-storage-declaration-form"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            void form.handleSubmit();
          }}
          className="w-full px-5 pt-4"
        >
          <div className="grid md:grid-cols-2 grid-cols-1 gap-4 w-full pb-6">
            <div className="md:col-start-1 space-y-5 mb-2">
              <form.Field
                name="distributor"
                children={(field) => (
                  <Select
                    isDisabled
                    data-testid="distributor-combobox"
                    label={t('Distributor', {
                      ns: 'common',
                      context: platform,
                    })}
                    placeholder={t('DistributorPlaceholder', {
                      ns: 'common',
                      context: platform,
                    })}
                    selectedKey={field.state.value?.id}
                    id={field.name}
                    name={field.name}
                    onBlur={field.handleBlur}
                    isInvalid={field.state.meta.errors?.length > 0}
                    errorMessage={field.state.meta.errors.join(', ')}
                    aria-label="Assured Name"
                  >
                    <SelectItem
                      key={field.state.value?.id}
                      id={field.state.value?.id}
                    >
                      {field.state.value?.title}
                    </SelectItem>
                  </Select>
                )}
              />
              <form.Field
                name="location"
                children={(field) => (
                  <Select
                    isDisabled
                    data-testid="location-combobox"
                    placeholder={t('declarations.form.location.Placeholder', {
                      ns: 'storage',
                      context: platform,
                    })}
                    label={t('declarations.form.location.Label', {
                      ns: 'storage',
                      context: platform,
                    })}
                    selectedKey={field.state.value?.id}
                    id={field.name}
                    name={field.name}
                    onBlur={field.handleBlur}
                    isInvalid={field.state.meta.errors?.length > 0}
                    errorMessage={field.state.meta.errors.join(', ')}
                    aria-label="Assured Name"
                  >
                    <SelectItem
                      key={field.state.value?.id}
                      id={field.state.value?.id}
                    >
                      {field.state.value?.title}
                    </SelectItem>
                  </Select>
                )}
              />
              <form.Field
                name="storageDateRange"
                validators={{
                  onChange: ({ value }) => {
                    const maxPeriodDays =
                      configurations?.storage_declaration_form_configurations
                        ?.max_storage_days_period ?? 31;
                    if (!value?.start || !value?.end)
                      return 'Please select a date range';
                    if (value.end?.compare(value?.start) > maxPeriodDays)
                      return `Date range must be less than ${maxPeriodDays} days`;
                    return;
                  },
                }}
                children={(field) => {
                  return (
                    <DateRangePicker
                      isRequired
                      id={field.name}
                      label={t('declarations.form.storageDateRange.Label', {
                        ns: 'storage',
                        context: platform,
                      })}
                      aria-label="Storage Date Range"
                      onBlur={field.handleBlur}
                      onChange={field.handleChange}
                      value={field.state.value}
                      shouldCloseOnSelect
                      isInvalid={field.state.meta.errors?.length > 0}
                      errorMessage={field.state.meta.errors.join(', ')}
                    />
                  );
                }}
              />
              <form.Field
                name="description"
                validators={{
                  onSubmit: ({ value }) =>
                    !value.trim().length && 'Please enter a description',
                }}
                children={(field) => (
                  <TextField
                    data-testid="description-input"
                    placeholder={t(
                      'declarations.form.descriptionOfGoods.Placeholder',
                      {
                        ns: 'storage',
                        context: platform,
                      },
                    )}
                    label={t('declarations.form.descriptionOfGoods.Label', {
                      ns: 'storage',
                      context: platform,
                    })}
                    isRequired
                    id={field.name}
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={field.handleChange}
                    isInvalid={field.state.meta.errors?.length > 0}
                    errorMessage={field.state.meta.errors.join(', ')}
                  />
                )}
              />
              <div className="flex">
                <form.Field
                  name="quantityUnit"
                  children={(field) => (
                    <Select<typeof UNITS>
                      aria-label="Quantity Unit"
                      selectedKey={field.state.value}
                      onSelectionChange={(key) => {
                        const typedKey = key as (typeof UNITS)[number];
                        if (key && UNITS.includes(typedKey)) {
                          field.handleChange(typedKey);
                        }
                      }}
                      id={field.name}
                      name={field.name}
                      label={t('declarations.form.quantity.Label', {
                        ns: 'storage',
                        context: platform,
                      })}
                      className="min-w-24 *:rounded-r-none"
                      onBlur={field.handleBlur}
                      isInvalid={field.state.meta.errors?.length > 0}
                      errorMessage={field.state.meta.errors.join(', ')}
                    >
                      {quantityUnits.map(({ label, value }) => (
                        <SelectItem
                          key={value}
                          id={value}
                          data-testid={`select-option-${value}`}
                        >
                          {label}
                        </SelectItem>
                      ))}
                    </Select>
                  )}
                />
                <form.Field
                  name="quantityAmount"
                  validators={{
                    onSubmit: ({ value }) =>
                      value &&
                      Number(value) < 1 &&
                      'Please enter a valid amount',
                  }}
                  children={(field) => (
                    <TextField
                      aria-label="Quantity Amount"
                      type="number"
                      aria-required
                      data-testid="quantity-amount-input"
                      value={field.state.value}
                      name={field.name}
                      id={field.name}
                      onChange={field.handleChange}
                      onBlur={field.handleBlur}
                      placeholder={t('declarations.form.quantity.Placeholder', {
                        ns: 'storage',
                        context: platform,
                      })}
                      customStyles="*:rounded-l-none mt-5"
                      isRequired
                      isInvalid={field.state.meta.errors?.length > 0}
                      errorMessage={field.state.meta.errors.join(', ')}
                    />
                  )}
                />
              </div>
            </div>

            <div className="md:col-start-2 space-y-5">
              <form.Field
                name="customer"
                validators={{
                  onSubmit: () =>
                    !customerInputRef.current?.value.trim() &&
                    'Please select an option or enter a new customer name',
                }}
                children={(field) => {
                  return (
                    <CustomerComboBox
                      isRequired
                      inputRef={customerInputRef}
                      data-testid="customer-selector"
                      defaultInputValue={field.state.value?.company_name}
                      defaultSelectedKey={JSON.stringify(field.state.value)}
                      placeholder={t(
                        'declarations.form.namedAssured.Placeholder',
                        {
                          ns: 'storage',
                          context: platform,
                        },
                      )}
                      label={t('declarations.form.namedAssured.Label', {
                        ns: 'storage',
                        context: tContext,
                      })}
                      menuTrigger="focus"
                      id={field.name}
                      name={field.name}
                      onBlur={field.handleBlur}
                      onSelectionChange={(key) => {
                        const selectedCustomer = handleComboBoxSelectionChange<
                          CustomerOption | undefined
                        >({
                          key,
                          handleChange: field.handleChange,
                          prevStateValue: field.state.value,
                        });
                        field.form.setFieldValue('customerAddress', {
                          description: selectedCustomer?.address?.full_address,
                        });
                      }}
                      isInvalid={field.state.meta.errors?.length > 0}
                      errorMessage={field.state.meta.errors.join(', ')}
                    />
                  );
                }}
              />
              <form.Field
                name="customerAddress"
                children={(field) => {
                  return (
                    <PlacesComboBox
                      label={t('declarations.form.namedAssuredAddress.Label', {
                        ns: 'storage',
                        context: platform,
                      })}
                      placeholder={t(
                        'declarations.form.namedAssuredAddress.Placeholder',
                        {
                          ns: 'storage',
                          context: platform,
                        },
                      )}
                      data-testid="named-assured-address-combobox"
                      id={field.name}
                      name={field.name}
                      menuTrigger="focus"
                      selectedKey={JSON.stringify(field.state.value)}
                      onInputChange={(value) => {
                        field.handleChange({
                          ...field.state.value,
                          place_id: '',
                          description: value,
                        });
                      }}
                      inputValue={field.state.value?.description}
                      onBlur={() => {
                        if (
                          field.state.value?.place_id &&
                          field.state.value.place_id.length === 0
                        ) {
                          field.handleChange({
                            ...field.state.value,
                            place_id: '',
                            description: '',
                          });
                        }
                        field.handleBlur();
                      }}
                      isInvalid={field.state.meta.errors?.length > 0}
                      errorMessage={field.state.meta.errors.join(', ')}
                      onSelectionChange={(option) => {
                        const parsedOption: PlaceSuggestion = JSON.parse(
                          String(option),
                        );
                        const isFieldUpdated =
                          parsedOption?.place_id !==
                          field.prevState.value?.place_id;
                        isFieldUpdated && field.handleChange(parsedOption);
                      }}
                    />
                  );
                }}
              />

              <form.Field
                name="dateOfIssuance"
                children={(field) => {
                  return (
                    <DatePicker
                      data-testid="date-of-issuance-date-picker"
                      label={t('declarations.form.dateOfIssuance.Label', {
                        ns: 'storage',
                        context: platform,
                      })}
                      id={field.name}
                      name={field.name}
                      onBlur={field.handleBlur}
                      onChange={(e) => e && field.handleChange(e.toString())}
                    />
                  );
                }}
              />
              <form.Field
                name="commodity"
                validators={{
                  onSubmit: ({ value }) => !value && 'Please select an option',
                }}
                children={(field) => {
                  return (
                    <CommodityComboBox
                      commodities={configurations?.commodities ?? []}
                      data-testid="commodity-category-selector"
                      isRequired
                      label={t('declarations.form.commodity.Label', {
                        ns: 'storage',
                        context: platform,
                      })}
                      isFetchingItems={isLoadingConfigurations}
                      placeholder={t(
                        'declarations.form.commodity.Placeholder',
                        {
                          ns: 'storage',
                          context: platform,
                        },
                      )}
                      menuTrigger="focus"
                      id={field.name}
                      name={field.name}
                      onBlur={field.handleBlur}
                      defaultInputValue={field.state.value?.title}
                      onSelectionChange={(key) =>
                        handleComboBoxSelectionChange({
                          key,
                          handleChange: field.handleChange,
                          prevStateValue: field.state.value,
                        })
                      }
                      isInvalid={field.state.meta.errors.length > 0}
                      errorMessage={field.state.meta.errors.join(', ')}
                      popoverProps={{
                        placement: 'top start',
                        className: 'max-h-64',
                      }}
                    />
                  );
                }}
              />
              <div className="flex">
                <form.Field
                  name="insuredValueCurrencyCode"
                  children={(field) => {
                    return (
                      <Select
                        isRequired
                        onSelectionChange={(key) => {
                          if (key) {
                            field.handleChange(String(key));
                          }
                        }}
                        selectedKey={field.state.value}
                        id={field.name}
                        name={field.name}
                        label={t('declarations.form.insuredValue.Label', {
                          ns: 'storage',
                          context: tContext,
                        })}
                        className="min-w-24 *:rounded-r-none"
                      >
                        {currencies.map(({ code, symbol }) => (
                          <SelectItem
                            key={`${symbol}-${code}`}
                            id={code}
                            data-testid={`select-option-${code}`}
                          >
                            {`${symbol} ${code}`}
                          </SelectItem>
                        ))}
                      </Select>
                    );
                  }}
                />

                <form.Field
                  name="insuredValueAmount"
                  validators={{
                    onChange: ({ value }) =>
                      (!value || parseInt(value) < 1) &&
                      'Please enter a valid amount',
                  }}
                  // target the input and make rounded left none
                  children={(field) => (
                    <TextField
                      type="number"
                      aria-required
                      aria-label="Insured Value Amount"
                      data-testid="insured-value-amount-input"
                      value={field.state.value}
                      name={field.name}
                      id={field.name}
                      onChange={field.handleChange}
                      onBlur={field.handleBlur}
                      placeholder={t(
                        'declarations.form.insuredValue.Placeholder',
                        {
                          ns: 'storage',
                          context: platform,
                        },
                      )}
                      customStyles="*:rounded-l-none mt-5"
                      isInvalid={field.state.meta.errors?.length > 0}
                      errorMessage={field.state.meta.errors.join(', ')}
                      isRequired
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <form.Field
            name="createCertificate"
            children={(field) => (
              <Checkbox
                aria-label="CreateCertificate"
                isSelected={field.state.value}
                id={field.name}
                name={field.name}
                onChange={field.handleChange}
              >
                <Typography>
                  {t('declarations.form.createCertificate.Label', {
                    ns: 'storage',
                    context: platform,
                  })}
                </Typography>
              </Checkbox>
            )}
          />
          <form.Subscribe
            children={(_state) => {
              return (
                <div className="flex justify-end pt-3 gap-4">
                  <LinkButton
                    variant="ghost"
                    href={navigateToOnCancel}
                    data-testid="create-storage-declaration-cancel"
                    label={t('declarations.form.Cancel', {
                      ns: 'storage',
                      context: platform,
                    })}
                  />
                  <Button
                    type="submit"
                    loadingText="Submitting..."
                    isLoading={isCreatingDeclaration}
                    customStyles="transition-all duration-500 ease-in-out"
                    variant={isCreateDeclarationError ? 'error' : 'primary'}
                    data-testid="create-storage-declaration-submit"
                    label={t(
                      `${isCreateDeclarationError ? 'declarations.form.SubmissionError' : 'declarations.form.Submit'}`,
                      {
                        ns: 'storage',
                        context: platform,
                      },
                    )}
                  />
                </div>
              );
            }}
          />
        </form>
      )}
    </Modal>
  );
}
