import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCopy, FaTable } from 'react-icons/fa';
import {
  useNavigate,
  useParams,
  useRouteError,
  useSearchParams,
} from 'react-router-dom';
import {
  Badge,
  ClientErrors,
  CreateCertificateModal,
  type ErrorMessageProps,
  LinkButton,
  SectionLayout,
  Typography,
} from '@breezeai-frontend/cargo-ui';
import { useQuery } from '@tanstack/react-query';

import { useAuthenticatedUser } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import {
  ErrorBoundaryFallback,
  parseError,
} from '../../../router/PlatformErrorBoundary/PlatformErrorBoundary';
import { useTracking } from '../../../utils/snowplow/hooks';
import { ActiveOpenCoverTooltip } from '../../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import { QuoteCoverageDescriptions } from '../../components/QuoteCoverageDescriptions/QuoteCoverageDescriptions';
import { policiesQueries } from '../network/queries';
import { PolicyAdditionalClauses } from './PolicyAdditionalClauses/PolicyAdditionalClauses';
import { PolicyClaims } from './PolicyClaims/PolicyClaims';
import styles from './PolicyDetailsPage.module.scss';
import { PolicyInsuranceDetails } from './PolicyInsuranceDetails/PolicyInsuranceDetails';
import { PolicyPriceBreakdown } from './PolicyPriceBreakdown/PolicyPriceBreakdown';
import { PolicyShipmentDetails } from './PolicyShipmentDetails/PolicyShipmentDetails';
import { PollingFileList } from './PollingFileList/PollingFileList';

export type PolicyRouteParams = {
  policyId: string;
};

export function PolicyDetailsPageErrorBoundary() {
  const error = useRouteError();
  const navigate = useNavigate();
  const policyNotFoundError: ErrorMessageProps = {
    type: 'PageNotFound',
    title: 'Oops!',
    details: ['Sorry, the policy you are looking for does not exist.'],
    actions: [
      {
        variant: 'secondary',
        label: 'Back to Policies',
        leftIcon: <FaTable />,
        onPress: () => {
          navigate('/policies');
        },
      },
    ],
  };

  // Override the default error message for 404 errors
  if (parseError(error).includes(ClientErrors.PageNotFound)) {
    return <ErrorBoundaryFallback {...policyNotFoundError} />;
  }

  return <ErrorBoundaryFallback />;
}

export const PolicyDetailsPage = () => {
  useTracking();
  const { platform } = usePlatform();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isReady = searchParams.get('ready') === 'true';
  const [isModalOpen, setIsModalOpen] = useState(isReady);

  const user = useAuthenticatedUser();
  const { isWtw } = usePlatform();

  const enableReferPolicyFlow = user?.settings.find(
    (s) => s.name === 'enable_refer_a_policy_flow',
  )?.value;

  const { policyId } = useParams<PolicyRouteParams>();

  const { data: policy, isLoading } = useQuery(
    policiesQueries.details({ policyId }),
  );

  const certificate_id = policy?.certificate_id;
  const { certificate_status, proof_of_cover_status } = policy ?? {};
  const title = t('details.Title', {
    ns: 'policies',
    context: platform,
    // Both wtw and breeze use the certificate_id to determine the title
    id: certificate_id,
  });
  const isAuthorizedToDuplicatePolicy = policy?.quote?.permissions?.create;

  const isAuthorized = Boolean(
    user?.permissions.policies?.view || user?.permissions.certificates?.view,
  );

  return (
    <SectionLayout
      isAuthorized={isAuthorized}
      data-testid="policy-details-page"
      tooltip={
        isWtw
          ? {
              trigger: <ActiveOpenCoverTooltip.Trigger />,
              content: <ActiveOpenCoverTooltip.Content />,
            }
          : undefined
      }
      actions={
        isAuthorizedToDuplicatePolicy && (
          <LinkButton
            data-testid="duplicate-policy-button"
            variant="secondary"
            label={t('Duplicate', {
              ns: 'common',
              context: platform,
            })}
            leftIcon={<FaCopy />}
            width="auto"
            href={`/quotes/duplicate/${policy?.quote?.id}`}
          />
        )
      }
      title={title}
      backLink="/policies"
      statusBadge={
        !isLoading &&
        !certificate_status &&
        !proof_of_cover_status && (
          <Badge variant="default" data-testid="documents-pending-badge">
            <Typography>
              {t('PendingCargoOwnerApproval', {
                ns: 'common',
                context: platform,
              })}
            </Typography>
          </Badge>
        )
      }
    >
      <div className={styles.policiesDetailsPage}>
        <div className={styles.contentWrapper}>
          <div className={styles.wrapper}>
            <div className={styles.detailsLayout}>
              <div className={styles.policyDetails}>
                <Suspense
                  fallback={
                    <div
                      style={{ height: '330px' }}
                      data-testid="skeleton-loader"
                      className={styles.skeletonLoader}
                    />
                  }
                >
                  <PolicyShipmentDetails />
                </Suspense>

                <Suspense
                  fallback={
                    <div
                      style={{
                        height: '230px',
                      }}
                      data-testid="skeleton-loader"
                      className={styles.skeletonLoader}
                    />
                  }
                >
                  <PolicyInsuranceDetails />
                </Suspense>
              </div>
              <Suspense
                fallback={
                  <div
                    style={{
                      flex: 0.2,
                    }}
                    className={styles.skeletonLoader}
                  />
                }
              >
                <PolicyPriceBreakdown />
              </Suspense>
            </div>

            <Suspense
              fallback={
                <div
                  style={{ height: '200px' }}
                  className={styles.skeletonLoader}
                />
              }
            >
              <PolicyAdditionalClauses />
            </Suspense>

            <Suspense
              fallback={
                <div
                  style={{ height: '200px' }}
                  className={styles.skeletonLoader}
                />
              }
            >
              <PollingFileList />
            </Suspense>

            {/* TODO policyId should always be defined - update suspense query accordingly */}
            <Suspense
              fallback={
                <div
                  style={{ height: '100px' }}
                  className={styles.skeletonLoader}
                />
              }
            >
              <PolicyClaims policyId={policyId} />
            </Suspense>

            <Suspense
              fallback={
                <div
                  style={{ height: '100px' }}
                  className={styles.skeletonLoader}
                />
              }
            >
              <QuoteCoverageDescriptions policyId={policyId} />
            </Suspense>
          </div>
        </div>
      </div>
      <CreateCertificateModal
        data-testid="policy-creation-success-dialog"
        title={
          enableReferPolicyFlow ? 'Thank You!' : 'Your certificate is ready!'
        }
        description={
          enableReferPolicyFlow
            ? 'The policy was successfully referred.'
            : 'You can now view your certificate!'
        }
        isOpen={isModalOpen}
        onOpenChange={(value) => {
          setIsModalOpen(value);
          setSearchParams(undefined, {
            replace: true,
          });
        }}
        onExit={() =>
          setSearchParams(undefined, {
            replace: true,
          })
        }
      />
    </SectionLayout>
  );
};
