import { FaTimes } from 'react-icons/fa';

import { BouncingDots } from '../../../../components/LoadingStates/BouncingDots';
import { Typography } from '../../../../components/Typography/Typography';
import { Modal, type ModalProps } from '../../../../layouts/Modal/Modal';

export function CertificateModalLoadingState({
  isError,
  isLoading,
  isUpdate,
  ...props
}: {
  isUpdate?: boolean;
  isError?: boolean;
  isLoading?: boolean;
} & Omit<ModalProps, 'children'>) {
  return (
    <Modal
      data-testid="certificate-modal-loading-state"
      className="max-w-xl"
      heading={<div className="sr-only">{isError ? 'Error' : 'Loading'}</div>}
      {...props}
    >
      <div className="flex flex-col justify-center items-center h-[270px] w-[480px] space-y-2">
        {isError && (
          <>
            <span className="sr-only">Error</span>
            <FaTimes size={42} className="fill-system-red-500" />
            <Typography level="h2" customStyles="text-center">
              {`There was an error ${isUpdate ? 'updating' : 'creating'} your certificate.`}
            </Typography>
            <Typography color="secondary" customStyles="text-center w-[308px]">
              Please try again later
            </Typography>
          </>
        )}
        {isLoading && (
          <>
            <BouncingDots />
            <Typography level="h3" customStyles="text-center">
              Alright! One moment please
            </Typography>
            <Typography color="secondary" customStyles="text-center w-[220px]">
              We’re working on your certificate. Just a few seconds…
            </Typography>
          </>
        )}
      </div>
    </Modal>
  );
}
