import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@breezeai-frontend/cargo-ui';
import { type FormApi } from '@tanstack/react-form';
import { type zodValidator } from '@tanstack/zod-form-adapter';

import { usePlatform } from '../../../../context/PlatformContext';
import { QuoteFormCancelChangesModal } from './components/QuoteFormCancelChangesModal';
import { FormContext } from './FormContext';
import { type QuoteFormData } from './QuoteForm';
import { useQuoteFormLogic } from './useQuoteFormLogic';

export function QuoteFormActions({
  form,
}: {
  form: FormApi<QuoteFormData, typeof zodValidator>;
}) {
  const { resetForm } = useContext(FormContext);

  const { isUpdate, isDuplicate } = useQuoteFormLogic();

  const [isCancelChangesModalOpen, setIsCancelChangesModalOpen] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const { platform } = usePlatform();

  t('form.GetAQuote', {
    ns: 'quotes',
    context: platform,
  });
  return (
    <form.Subscribe
      selector={(state) => [state.isTouched, state.isSubmitting]}
      children={([isTouched, isSubmitting]) => {
        const isUpdatable = isUpdate && isTouched;

        return isUpdate && !isDuplicate ? (
          <>
            <Button
              data-testid="quote-cancel-changes-button"
              variant="ghost"
              size="large"
              width="auto"
              onPress={() => setIsCancelChangesModalOpen(true)}
              isLoading={isSubmitting}
              isDisabled={!isUpdatable}
              label="Cancel Changes"
            />
            <Button
              data-testid="quote-update-button"
              type="submit"
              size="large"
              width="auto"
              isDisabled={!isUpdatable}
              isLoading={isSubmitting}
              loadingText="Updating Quote..."
              label="Save Changes"
            />

            <QuoteFormCancelChangesModal
              visible={isCancelChangesModalOpen}
              onCancel={() => setIsCancelChangesModalOpen(false)}
              onConfirm={() => {
                resetForm();
                setIsCancelChangesModalOpen(false);
              }}
            />
          </>
        ) : (
          <Button
            data-testid="quote-submit-button"
            size="large"
            width="full"
            type="submit"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            loadingText="Creating quote..."
            label={t('form.GetAQuote', {
              ns: 'quotes',
              context: platform,
            })}
          />
        );
      }}
    />
  );
}
