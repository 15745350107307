import { queryOptions } from '@tanstack/react-query';

import { type BffQuoteModelWithPermissions } from '../../../generated/api-client';
import { type AdditionalClause } from '../../../model/AdditionalClause';
import {
  type CurrencyDefinition,
  type CurrencyExchangeRate,
} from '../../../model/CurrencyValue';
import { type Distributor } from '../../../model/Distributor';
import { type OpenCover } from '../../../model/OpenCover';
import { type PlaceModel } from '../../../model/Place';
import { type Port } from '../../../model/Port';
import { type ExclusionReason } from '../../../model/Quote';
import {
  type ContainerModeEnum,
  type ConveyanceType,
  type SpecialConditionOptionType,
} from '../../../model/Shipment';
import { normalizeQuoteResponse } from '../../../network/apis/quotes/normalizers';
import {
  type QuoteCoveragePackage,
  type QuoteModel,
  type QuoteStatusEnum,
  type QuoteSubmissionSource,
} from '../../../network/apis/quotes/types';
import { type AccountUser } from '../../../network/apis/users/types';
import { getApiClient } from '../../../network/AuthRequests';

export const bffQuoteToQuoteModel = (
  quote: BffQuoteModelWithPermissions,
): QuoteModel => ({
  ...quote,
  transport_mode_code_display_name:
    quote.primary_transport_mode_code_display_name,
  primary_transport_mode_code_display_name:
    quote.primary_transport_mode_code_display_name,
  // TODO HACK: id is set as Optional in Backend. Should be adjusted.
  id: quote.id!,
  // TODO HACK: created_time is set as Optional in Backend. Should be adjusted.
  created_time: quote.created_time!,
  // TODO HACK: status should be narrowed down to QuoteStatusEnum
  status: quote.status as QuoteStatusEnum | undefined,
  // TODO HACK: submission_source should be narrowed down to QuoteSubmissionSource
  submission_source: quote.submission_source as
    | QuoteSubmissionSource
    | undefined,
  // TODO HACK: primary_transport_mode_code should be narrowed down to ConveyanceType
  primary_transport_mode_code: quote.primary_transport_mode_code as
    | ConveyanceType
    | undefined,
  // TODO HACK: secondary_transport_mode_code should be narrowed down to ConveyanceType
  secondary_transport_mode_code: quote.secondary_transport_mode_code as
    | ConveyanceType
    | undefined,
  // TODO HACK: coverage_package should be narrowed down to QuoteCoveragePackage
  coverage_package: quote.coverage_package as QuoteCoveragePackage | undefined,
  // TODO HACK: origin_place.provider is Optional in Backend. Should be adjusted.
  origin_place: quote.origin_place as PlaceModel | undefined,
  // TODO HACK: origin_port.functions should be narrowed down to PortType[]
  origin_port: quote.origin_port as Port | undefined,
  // TODO HACK: destination_place.provider is Optional in Backend. Should be adjusted.
  destination_place: quote.destination_place as PlaceModel | undefined,
  // TODO HACK: origin_port.functions should be narrowed down to PortType[]
  destination_port: quote.destination_port as Port | undefined,
  // TODO HACK: freight_cost_currency.code should be narrowed down to SupportedCurrencies
  freight_cost_currency: quote.freight_cost_currency as
    | CurrencyDefinition
    | undefined,
  // TODO HACK: duty_cost_currency.code should be narrowed down to SupportedCurrencies
  duty_cost_currency: quote.duty_cost_currency as
    | CurrencyDefinition
    | undefined,
  // TODO HACK: commodity type and id are Optional in Backend. Should be adjusted.
  commodity: quote.commodity as
    | {
        id: number;
        commodity_type: string;
      }
    | undefined,
  // TODO HACK: commodity_currency.code should be narrowed down to SupportedCurrencies
  commodity_currency: quote.commodity_currency as
    | CurrencyDefinition
    | undefined,
  // TODO HACK: customer_premium_currency.code should be narrowed down to SupportedCurrencies
  customer_premium_currency: quote.customer_premium_currency as
    | CurrencyDefinition
    | undefined,
  // TODO HACK: currency_exchange_rate.code should be narrowed down to SupportedCurrencies
  currency_exchange_rate: quote.currency_exchange_rate as
    | CurrencyExchangeRate
    | undefined,
  // TODO HACK: insurer_premium_currency.code should be narrowed down to SupportedCurrencies
  insurer_premium_currency: quote.insurer_premium_currency as
    | CurrencyDefinition
    | undefined,
  // TODO HACK: tax_currency.code should be narrowed down to SupportedCurrencies
  tax_currency: quote.tax_currency as CurrencyDefinition | undefined,
  // TODO HACK: container_mode should be narrowed down to ContainerModeEnum
  container_mode: quote.container_mode as ContainerModeEnum | undefined,
  // TODO HACK: special_conditions attributes are Optional in Backend. Should be adjusted.
  special_conditions: quote.special_conditions as
    | SpecialConditionOptionType[]
    | undefined,
  // TODO HACK: created_by_user attributes are Optional in Backend. Should be adjusted.
  created_by_user: quote.created_by_user as AccountUser | undefined,
  // TODO HACK: exclusion_reasons is very loosly typed in Backend. Should be adjusted.
  exclusion_reasons: quote.exclusion_reasons as ExclusionReason[] | undefined,
  // TODO HACK: distributor attributes are Optional in Backend. Should be adjusted.
  distributor: quote.distributor as Distributor | undefined,
  // TODO HACK: loading_place.provider is Optional in Backend. Should be adjusted.
  loading_place: quote.loading_place as PlaceModel | undefined,
  // TODO HACK: delivery_place.provider is Optional in Backend. Should be adjusted.
  delivery_place: quote.delivery_place as PlaceModel | undefined,
  // TODO HACK: original_quote_id is number in Backend. Should be adjusted.
  original_quote_id: quote.original_quote_id
    ? String(quote.original_quote_id)
    : undefined,
  // TODO HACK: additional_clauses is not an array in Backend. Should be adjusted.
  additional_clauses: quote.additional_clauses
    ? [quote.additional_clauses]
    : undefined,
  // TODO HACK: quote_additional_clauses attributes should be narrowed down to AdditionalClause[]
  quote_additional_clauses: quote.quote_additional_clauses as
    | AdditionalClause[]
    | undefined,
  // TODO HACK: open_cover attributes are Optional in Backend. Should be adjusted.
  open_cover: quote.open_cover as OpenCover | undefined,
  // @ts-expect-error permissions TODO generate client when permissions have been implemented
  permissions: quote.permissions,
  // TODO HACK: align types throughout UI with the generated definition
  distributor_net_cost_currency: quote.distributor_net_cost_currency as
    | CurrencyDefinition
    | undefined,
});

export const quotesQueries = {
  details: ({ quoteId }: { quoteId?: string }) => {
    return queryOptions({
      queryKey: ['quotes', quoteId],
      enabled: !!quoteId,
      // TODO: components using this set of options for suspense need to be refactored
      // as ternary/null pattern is not good
      // Reference: https://github.com/TanStack/query/discussions/6206
      queryFn: async () => {
        if (!quoteId) return null;
        const apiClient = await getApiClient();
        const { data } = await apiClient.getQuoteDetails({
          quoteId,
        });
        return data;
      },
      select: (data) =>
        data && normalizeQuoteResponse(bffQuoteToQuoteModel(data)),
    });
  },
};
