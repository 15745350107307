import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@breeze-ai/ui-library';
import { Badge, clsxMerge } from '@breezeai-frontend/cargo-ui';
import classnames from 'classnames';
import invariant from 'tiny-invariant';

import { useUser } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import { isPlace } from '../../../model/Place';
import { type CoveragePackageType, type Quote } from '../../../model/Quote';
import { trackPageView } from '../../../utils/snowplow/utils';
import { COVERAGE_PACKAGES } from '../../constants';
import { FlowSteps } from '../../policy-flow/constants';
import { usePolicyFlowContext } from '../../policy-flow/hooks/context-hooks';
import {
  getPlaceDisplayValue,
  getPlaceModelDisplayValue,
} from '../../utils/places';
import { getPortShortDisplayValue } from '../../utils/ports';
import styles from './PolicySummary.module.scss';
import { usePolicySummaryItems } from './use-policy-summary-items';

type PolicySummaryProps = {
  quote: Quote;
  coverageOptions: CoveragePackageType[];
};

export const PolicySummary = ({
  quote,
  quote: {
    loading_place,
    primary_transport_mode_code,
    origin_place,
    origin_port,
    destination_place,
    destination_port,
    delivery_place,
    coverage_package,
  },
  coverageOptions,
}: PolicySummaryProps) => {
  const { isWtw, platform } = usePlatform();
  const { t } = useTranslation();

  invariant(
    primary_transport_mode_code,
    'primary_transport_mode_code is required',
  );

  const origin = origin_port || origin_place;

  const destination = destination_port || destination_place;

  const { mainItems, footerItems } = usePolicySummaryItems(quote);
  const user = useUser();
  const { step } = usePolicyFlowContext();

  useEffect(() => {
    step === FlowSteps.SHIPMENT_INFORMATION &&
      trackPageView(
        {
          eventFeature: 'policy',
          eventSubfeature: 'form',
          eventAction: 'page_view',
          eventTrigger: 'issue_a_policy',
          quoteId: quote?.id,
          customerId: quote?.customer?.id,
        },
        user,
      );
  }, [user, step, quote]);

  if (!origin || !destination) {
    return null;
  }

  return (
    <div
      className={classnames(styles.wrapper, 'bg-cards-summary-details-bg')}
      data-testid="policy-summary"
    >
      {mainItems.map((list, i) => (
        <div key={i} className={styles.itemsList}>
          {list.map(({ title, value, hidden, testId }, j) => {
            if (hidden) return null;
            return (
              <div key={j} className={styles.item} data-testid={testId}>
                {title}
                {value}
              </div>
            );
          })}
        </div>
      ))}
      {coverageOptions.length > 1 && coverage_package && (
        <Badge
          outlined
          className="my-5 text-system-grey-white border-white w-fit"
          data-testid="coverage-type-badge"
        >
          {COVERAGE_PACKAGES[coverage_package].badgeLabel}
        </Badge>
      )}
      <div className="grid grid-cols-[7rem_1fr] gap-y-5 my-3.5 mb-5">
        {loading_place && (
          <>
            <span className="text-sm text-system-grey-200">
              {t('routeDetails.PlaceOfLoading', {
                ns: 'common',
                context: platform,
              })}
            </span>
            <span className="justify-self-end text-base">
              {getPlaceModelDisplayValue(loading_place)}
            </span>
          </>
        )}
        <span className="text-sm text-system-grey-200">
          {t('routeDetails.Origin', {
            ns: 'common',
            context: platform,
          })}
        </span>
        <span className="justify-self-end text-base">
          {isPlace(origin)
            ? getPlaceDisplayValue(origin)
            : getPortShortDisplayValue(origin)}
        </span>
        <span className="text-sm text-system-grey-200">
          {t('routeDetails.Destination', {
            ns: 'common',
            context: platform,
          })}
        </span>
        <span className="justify-self-end text-base">
          {isPlace(destination)
            ? getPlaceDisplayValue(destination)
            : getPortShortDisplayValue(destination)}
        </span>
        {delivery_place && (
          <>
            <span className="text-sm text-system-grey-200">
              {t('routeDetails.PlaceOfDelivery', {
                ns: 'common',
                context: platform,
              })}
            </span>
            <span className="justify-self-end text-base">
              {getPlaceModelDisplayValue(delivery_place)}
            </span>
          </>
        )}
      </div>
      <div
        className={clsxMerge(
          styles.footerGrid,
          isWtw ? 'grid-cols-2' : 'grid-cols-3',
        )}
      >
        {footerItems.map(({ title, value, hidden, testId }, i) =>
          hidden || !value ? null : (
            <div key={i} className={styles.item} data-testid={testId}>
              <Tooltip content={`${value}`} placement="bottom">
                <Typography
                  level="h3"
                  noBold
                  ellipsis
                  className={styles.value}
                  data-testid="value"
                >
                  {value}
                </Typography>
              </Tooltip>
              <Typography level="subtext" ellipsis data-testid="title">
                {title}
              </Typography>
            </div>
          ),
        )}
      </div>
    </div>
  );
};
