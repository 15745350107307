import { Link, Tooltip, Typography } from '@breeze-ai/ui-library';
import { type ErrorMessage } from '@breezeai-frontend/cargo-ui';

import { DISTRIBUTION_EMAIL } from '../../app-constants';
import { type getTheme } from '../../utils/getTheme';

export enum FlowSteps {
  INIT = 0,
  QUOTE_FORM = 1,
  QUOTE_SUMMARY = 2,
  SHIPMENT_INFORMATION = 3,
  SUCCESS = 4,
  LOADING = 5,
}

export const FLOW_ERROR_MESSAGES = {
  createQuote:
    'The quote could not be created at this time, please try again in a few moments.',
  updateQuote:
    'The quote could not be updated at this time, please try again in a few moments.',
  quoteNotUpdatable: (
    <Typography variant="secondary" block>
      Unable to update this quote because a policy was already created. Please{' '}
      <Tooltip content={DISTRIBUTION_EMAIL}>
        <Link href={`mailto:${DISTRIBUTION_EMAIL}`} underline={false}>
          contact us
        </Link>
      </Tooltip>{' '}
      for further support if needed.
    </Typography>
  ),
  shareQuote:
    'The quote could not be shared at this time, please try again in a few moments.',
  createPolicy:
    'The policy could not be created at this time, please try again in a few moments.',
  excludedCargoOwnerAddress: (
    <Typography variant="secondary" block>
      Please update the cargo owner address, or contact us at{' '}
      <Link href={`mailto:${DISTRIBUTION_EMAIL}`} underline={false}>
        {DISTRIBUTION_EMAIL}
      </Link>{' '}
      for support.
    </Typography>
  ),
};

export const ERROR_MESSAGE_PROPS: Record<
  ReturnType<typeof getTheme>,
  React.ComponentProps<typeof ErrorMessage>
> = {
  breeze: {
    type: 'SomethingWentWrong',
    title: 'We will not be able to offer insurance for this quote request.',
    details: [
      'This can happen if the cargo or journey falls outside of our insurance providers’ risk appetite.',
      'Please feel free to contact us for more information.',
    ],
  },
  wtw: {
    type: 'SomethingWentWrong',
    title: 'Unable to provide cover for this shipment.',
    details: [
      'This can happen if the cargo or journey falls outside of appetite for the insurer.',
      'Please feel free to contact us for more information.',
    ],
  },
};
