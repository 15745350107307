import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@breeze-ai/ui-library';
import { SectionLayout } from '@breezeai-frontend/cargo-ui';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useUser } from '../../context/auth/auth-hooks';
import { usePlatform } from '../../context/PlatformContext';
import { useRouteParams } from '../../router/router-hooks';
import { ActiveOpenCoverTooltip } from '../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import { useScrollToElement } from '../hooks/use-scroll-to-element';
import { policiesQueries } from '../policies/network/queries';
import { quotesQueries } from '../quotes/network/queries';
import { PolicyFlowErrorDialog } from './components/PolicyFlowErrorDialog/PolicyFlowErrorDialog';
import { PolicyFlowLoadingDialog } from './components/PolicyFlowLoadingDialog/PolicyFlowLoadingDialog';
import { PolicyFlowStep } from './components/PolicyFlowStep/PolicyFlowStep';
import { FlowSteps } from './constants';
import { PolicyFlowContextProvider } from './context/PolicyFlowContext';
import {
  type PolicyFlowUrlParams,
  type PolicyFlowUrlSearchParams,
} from './context/types';
import { usePolicyFlowContext } from './hooks/context-hooks';
import { usePolicyFlowInitialization } from './hooks/use-policy-flow-initialization';
import { useSubmitPolicyCreationRequest } from './hooks/use-submit-shipment-information-form';
import styles from './PolicyFlow.module.scss';
import { FormContext } from './steps/QuoteForm/FormContext';
import { QuoteForm } from './steps/QuoteForm/QuoteForm';
import { useQuoteFormLogic } from './steps/QuoteForm/useQuoteFormLogic';
import { QuoteSummary } from './steps/QuoteSummary/QuoteSummary';
import { ShipmentInformationForm } from './steps/ShipmentInformationForm/ShipmentInformationForm';

const loader = (
  <div className={styles.loader}>
    <Loader
      type="progress-bar"
      thickness={5}
      color="var(--loader-loader-fill)"
    />
  </div>
);

type StepsMap = { [K in FlowSteps]?: HTMLDivElement | null };

export const PolicyFlowContent = () => {
  const {
    params: { quoteId },
  } = useRouteParams<PolicyFlowUrlParams>();
  const { data: quote } = useSuspenseQuery(quotesQueries.details({ quoteId }));

  usePolicyFlowInitialization();
  const scrollToElement = useScrollToElement();
  const { data: configuration } = useSuspenseQuery(
    policiesQueries.policyFormConfiguration(),
  );
  const submitPolicyCreationRequest =
    useSubmitPolicyCreationRequest(configuration);
  const { step } = usePolicyFlowContext();
  const stepsMap = useRef<StepsMap>({});

  const setStepRef = (step: FlowSteps, element: HTMLDivElement | null) => {
    stepsMap.current[step] = element;
  };
  const { isUpdate } = useQuoteFormLogic();

  const [quoteFormKey, setQuoteFormKey] = useState(0);

  useEffect(() => {
    scrollToElement(stepsMap.current[step] ?? null);
  }, [scrollToElement, step, quote]);

  if (step === FlowSteps.INIT) {
    return loader;
  }

  // TODO once we can use the edit permission, change the below to quote?.permissions?.edit
  const isAuthorizedToUpdateQuote = isUpdate && quote?.permissions?.create;
  const displayQuoteFormStep = !isUpdate || isAuthorizedToUpdateQuote;

  return (
    <div className="w-full" data-testid="policy-flow">
      {displayQuoteFormStep && (
        <PolicyFlowStep
          scrollMargin={false}
          ref={(el) => setStepRef(FlowSteps.QUOTE_FORM, el)}
          className="h-full"
        >
          <FormContext.Provider
            value={{ resetForm: () => setQuoteFormKey(quoteFormKey + 1) }}
          >
            <QuoteForm key={quoteFormKey} />
          </FormContext.Provider>
        </PolicyFlowStep>
      )}
      <PolicyFlowStep
        visible={step >= FlowSteps.QUOTE_SUMMARY}
        ref={(el) => setStepRef(FlowSteps.QUOTE_SUMMARY, el)}
        className="min-h-full flex flex-col"
      >
        {quote && (
          <QuoteSummary
            quote={quote}
            onUpdateDetails={() =>
              scrollToElement(stepsMap.current[FlowSteps.QUOTE_FORM] ?? null)
            }
          />
        )}
      </PolicyFlowStep>
      <PolicyFlowStep
        visible={step >= FlowSteps.SHIPMENT_INFORMATION}
        ref={(el) => setStepRef(FlowSteps.SHIPMENT_INFORMATION, el)}
      >
        {quote && (
          <ShipmentInformationForm
            quote={quote}
            onSubmit={submitPolicyCreationRequest}
          />
        )}
      </PolicyFlowStep>
      <PolicyFlowLoadingDialog visible={step === FlowSteps.LOADING} />
      <PolicyFlowErrorDialog />
    </div>
  );
};

export const PolicyFlow = () => {
  const {
    params: { quoteId },
  } = useRouteParams<PolicyFlowUrlParams>();
  const {
    searchParams: { shipment_id },
  } = useRouteParams<never, PolicyFlowUrlSearchParams>();

  const { data: quote } = useSuspenseQuery(quotesQueries.details({ quoteId }));
  const { isDuplicate } = useQuoteFormLogic();
  const { isWtw, platform } = usePlatform();
  const { t } = useTranslation();

  let title = t('form.GetAQuote', {
    ns: 'quotes',
    context: platform,
  });
  if (quote && !isDuplicate) {
    title = t('form.QuoteTitle', {
      ns: 'quotes',
      context: platform,
      id: quote.id,
    });
  }
  if (shipment_id) {
    title = title = t('form.ShipmentTitle', {
      ns: 'quotes',
      context: platform,
      id: shipment_id,
    });
  }

  const user = useUser();
  const isAuthorized = Boolean(user?.permissions.quotes?.create);

  return (
    <PolicyFlowContextProvider>
      <SectionLayout
        isAuthorized={isAuthorized}
        title={title}
        backLink="/quotes"
        tooltip={
          isWtw
            ? {
                trigger: <ActiveOpenCoverTooltip.Trigger />,
                content: <ActiveOpenCoverTooltip.Content />,
              }
            : undefined
        }
      >
        <PolicyFlowContent />
      </SectionLayout>
    </PolicyFlowContextProvider>
  );
};
