import { type FocusEvent } from 'react';
import { useParams } from 'react-router-dom';
import { getLocalTimeZone, today } from '@internationalized/date';
import { type FieldApi, type FormApi } from '@tanstack/react-form';
import { type zodValidator } from '@tanstack/zod-form-adapter';
import moment from 'moment';
import invariant from 'tiny-invariant';

import {
  type BffCreateCertificateOnlyBody,
  type BffGetConfigurationsResponseContainerModeDisplayModel,
  type BffGetConfigurationsResponseCoveragePackage,
  type BffUpdateCertificateOnlyRequest,
} from '../../../generated/api-client';
import { type ConveyanceType } from '../../../model/Shipment';
import { type Location } from '../../../network/apis/locations/types';
import {
  getJourneyUpdatePayload,
  isLocation,
} from '../../components/RouteDetails/utils';
import {
  getVesselsUpdatePayload,
  VESSEL_NOT_FOUND_ID,
} from '../../components/VesselFields/utils';
import { getUpdatedValue } from '../../utils/forms';
import { getPortSearchDisplayValue } from '../../utils/ports';
import {
  type CargoOwner,
  type CertificateModel,
  type CertificatesRouteParams,
  type MapsApiPayload,
} from '../types';
import { type CertificateFormProps } from './CertificateFlowForm/types';
import { getDefaultPortOrPlace } from './utils';
import { isPlaceSuggestion } from './utils';

export const getDefaultFormData = ({
  certificate,
  defaultPrimaryMot,
  defaultUserCurrency,
  defaultCoveragePackage,
  defaultContainerMode,
  defaultCommodity,
  defaultDistributorId,
}: {
  certificate: CertificateModel | null;
  defaultPrimaryMot: {
    name: ConveyanceType;
    displayName: string;
  };
  defaultUserCurrency: string;
  defaultCommodity?:
    | {
        id: number;
        commodity_type: string;
      }
    | undefined;
  defaultCoveragePackage?: BffGetConfigurationsResponseCoveragePackage;
  defaultContainerMode?: BffGetConfigurationsResponseContainerModeDisplayModel['title'];
  defaultDistributorId?: number;
}) => {
  return {
    etd: certificate?.quote?.etd || '',
    eta: certificate?.quote?.eta || '',
    vesselName: certificate?.quote?.vessel_name || '',
    marksAndNumbers: certificate?.quote?.goods_marks || '',
    bookingReference: certificate?.quote?.external_reference || '',

    distributorId: defaultDistributorId,

    assuredName: {
      company_name: certificate?.customer?.name || '',
      id: certificate?.customer?.id || undefined,
    },

    origin: certificate?.quote
      ? getDefaultPortOrPlace(certificate?.quote, 'origin')
      : undefined,
    destination: certificate?.quote
      ? getDefaultPortOrPlace(certificate?.quote, 'destination')
      : undefined,
    primaryMot:
      certificate?.quote?.primary_transport_mode_code &&
      certificate?.quote?.primary_transport_mode_code_display_name
        ? {
            name: certificate?.quote?.primary_transport_mode_code,
            displayName:
              certificate?.quote?.primary_transport_mode_code_display_name,
          }
        : defaultPrimaryMot,

    placeOfLoading:
      certificate?.quote && certificate?.quote.loading_place
        ? {
            type: 'place',
            place: {
              place_id: certificate?.quote.loading_place.provider_place_uuid,
              description: certificate?.quote.loading_place.full_address,
            },
            port: null,
          }
        : undefined,
    placeOfLoadingMot:
      certificate?.quote?.loading_transport_mode_code || 'road',

    placeOfDelivery:
      certificate?.quote && certificate?.quote.delivery_place
        ? {
            type: 'place',
            place: {
              place_id: certificate?.quote.delivery_place.provider_place_uuid,
              description: certificate?.quote.delivery_place.full_address,
            },
            port: null,
          }
        : undefined,
    placeOfDeliveryMot:
      certificate?.quote?.delivery_transport_mode_code || 'road',

    cargoDescription: certificate?.quote?.commodity_external_description || '',
    cargoCurrency:
      certificate?.quote?.commodity_currency?.code ?? defaultUserCurrency,
    cargoValue: certificate?.quote?.commodity_value,
    assuredAddress: {
      description: certificate?.customer_address?.full_address || '',
      place_id: certificate?.customer_address?.provider_place_uuid || '',
    },
    letterOfCredit: certificate?.quote?.letter_of_credit || '',
    issueDate: certificate?.issue_date ?? today(getLocalTimeZone()).toString(),
    vessel: certificate?.quote?.vessel
      ? {
          name: certificate.quote.vessel.name,
          external_id: certificate.quote.vessel.external_id,
        }
      : {
          name: certificate?.quote?.vessel_name,
          external_id: undefined,
        },
    commodity: {
      id: defaultCommodity?.id,
      title: defaultCommodity?.commodity_type,
    },
    containerMode: defaultContainerMode?.toString(),
    coveragePackage: defaultCoveragePackage,

    freightCost: certificate?.quote?.freight_cost,
    freightCostCurrency:
      certificate?.quote?.freight_cost_currency?.code ?? defaultUserCurrency,

    dutyCost: certificate?.quote?.duty_cost,
    dutyCostCurrency:
      certificate?.quote?.duty_cost_currency?.code ?? defaultUserCurrency,
  } satisfies CertificateFormProps;
};

export const useFormLogic = () => {
  const { policyId, certificateId } = useParams<CertificatesRouteParams>();

  const isUpdate = window.location.pathname.includes('update');
  const isDuplicate = window.location.pathname.includes('duplicate');

  type LocationKeys =
    | 'origin'
    | 'destination'
    | 'placeOfLoading'
    | 'placeOfDelivery'
    | 'assuredAddress';

  // TODO: remove nested ifs - feels like this can be simplified
  // TODO: should be tested in useFormLogic.spec.ts
  const locationHandleBlur = <TName extends LocationKeys>(
    e: FocusEvent<HTMLInputElement, Element>,
    field: FieldApi<
      CertificateFormProps,
      TName,
      undefined,
      typeof zodValidator
    >,
    setter?: (value: string) => void,
  ) => {
    field.handleBlur();

    const value = field.state.value;
    const prevValue = field.prevState.value;

    if (!value || !prevValue) return;

    if (isPlaceSuggestion(value) && isPlaceSuggestion(prevValue)) {
      if (!e.target.value || value.place_id === prevValue.place_id) {
        field.setValue(prevValue);
        setter && setter(prevValue.description || '');
      }
    }

    if (isLocation(value) && isLocation(prevValue)) {
      if (value.type === 'port' && prevValue.type === 'port') {
        if (!e.target.value || value.port.code === prevValue.port.code) {
          field.setValue(prevValue);
          setter && setter(getPortSearchDisplayValue(prevValue.port) || '');
        }
      }

      if (value.type === 'place' && prevValue.type === 'place') {
        if (
          !e.target.value ||
          value.place.place_id === prevValue.place.place_id
        ) {
          field.setValue(prevValue);
          setter && setter(prevValue.place.description || '');
        }
      }
    }
  };

  // TODO: should be tested in useFormLogic.spec.ts
  const getPlacePayload = (
    location?: Location | null,
  ): MapsApiPayload | undefined | null => {
    if (location === undefined) {
      return;
    }

    if (location === null) {
      return null;
    }
    if (location.type === 'place') {
      return {
        place_id: location.place.place_id,
        session_token: location.place.session_token,
      };
    }

    return;
  };

  const getAssuredDetails = (
    form: FormApi<CertificateFormProps, typeof zodValidator>,
  ): CargoOwner | undefined | null => {
    const data = form.state.values;
    // If an existing customer has been selected from the combobox, we need to send just the id
    if (data.assuredName?.id) {
      let provider_place_details = undefined;
      if (form.getFieldMeta('assuredAddress')?.isDirty) {
        // If the address has been updated and has been given a value, we need to send the new address for the existing customer, otherwise we send null (when the address has been removed or not updated) or undefined (when the address has not been updated)
        provider_place_details = data.assuredAddress?.description
          ? data.assuredAddress
          : null;
      }

      return {
        id: String(data.assuredName?.id),
        name: undefined,
        provider_place_details,
      };
    }

    // If a new customer has been entered, we need to send the name and address (no id)
    if (data.assuredName?.company_name && data.assuredAddress?.description) {
      return {
        name: data.assuredName?.company_name,
        id: undefined,
        provider_place_details: data.assuredAddress,
      };
    }

    // If a new customer has been entered without an address, we need to send the name (no id)
    if (data.assuredName?.company_name) {
      return {
        id: undefined,
        provider_place_details: undefined,
        name: data.assuredName?.company_name,
      };
    }

    // If assuredName or assuredAddress has not been updated, we send nothing
    if (
      !form.getFieldMeta('assuredName')?.isDirty &&
      !form.getFieldMeta('assuredAddress')?.isDirty
    ) {
      return undefined;
    }

    return isUpdate ? null : undefined;
  };

  const getBasePayload = (
    form: FormApi<CertificateFormProps, typeof zodValidator>,
  ) => {
    // Create and update payloads share the same base data - this function extracts it from the form
    // and normalizes it for the API
    const data = form.state.values;
    const loadingPlace = getPlacePayload(data.placeOfLoading);
    const originPlace = getPlacePayload(data.origin);
    const destinationPlace = getPlacePayload(data.destination);
    const deliveryPlace = getPlacePayload(data.placeOfDelivery);
    const originPortCode =
      data.origin?.type === 'port' ? data.origin.port.code : undefined;
    const destinationPortCode =
      data.destination?.type === 'port'
        ? data.destination.port.code
        : undefined;

    if (
      !data.etd ||
      !data.cargoValue ||
      !data.cargoCurrency ||
      !data.cargoDescription ||
      !data.primaryMot ||
      !data?.distributorId
    ) {
      throw new Error(
        `Please fill in all required fields ${JSON.stringify({
          etd: data.etd,
          cargoValue: data.cargoValue,
          cargoCurrency: data.cargoCurrency,
          primaryMot: data.primaryMot,
          distributorId: data?.distributorId,
          cargoDescription: data.cargoDescription,
        })}`,
      );
    }

    return {
      distributor_id: data.distributorId,
      etd: moment(data.etd).locale('en').format('YYYY-MM-DD'),
      eta: data.eta
        ? moment(data.eta).locale('en').format('YYYY-MM-DD')
        : undefined,
      commodity_value: data.cargoValue,
      commodity_currency_code: data.cargoCurrency,
      transport_mode_code: data.primaryMot.name,
      transport_mode_code_display_name: data.primaryMot.displayName,
      origin_port_code: originPortCode,
      destination_port_code: destinationPortCode,
      origin_place_provider_details: originPlace,
      destination_place_provider_details: destinationPlace,
      loading_place_provider_details: loadingPlace,

      delivery_place_provider_details: deliveryPlace,
      delivery_transport_mode_code: data.placeOfDelivery
        ? data.placeOfDeliveryMot
        : undefined,
      loadingPlace,
      deliveryPlace,
      cargoValue: data.cargoValue,
      cargoCurrency: data.cargoCurrency,
      cargoDescription: data.cargoDescription,
      data,
    };
  };

  const getUpdatePayload = (
    form: FormApi<CertificateFormProps, typeof zodValidator>,
  ): BffUpdateCertificateOnlyRequest => {
    const formMeta = form.state.fieldMeta;
    const { data, ...basePayload } = getBasePayload(form);
    const updatedCommodityValue =
      getUpdatedValue(data.cargoValue, formMeta, 'cargoValue') ?? undefined;

    invariant(policyId, 'policyId is required for update');

    return {
      ...basePayload,
      ...getJourneyUpdatePayload(form),
      ...getVesselsUpdatePayload(data, formMeta),
      policy_id: parseInt(policyId),
      etd: getUpdatedValue(data.etd, formMeta, 'etd') ?? undefined,
      eta: getUpdatedValue(data.eta, formMeta, 'eta') ?? undefined,
      commodity_value: updatedCommodityValue,
      commodity_currency_code:
        form.options?.defaultValues?.cargoCurrency !== data.cargoCurrency
          ? data.cargoCurrency
          : undefined,
      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrtade to pydantic v2
      vessel_name: getUpdatedValue(data.vesselName, formMeta, 'vesselName'),
      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrtade to pydantic v2
      external_reference: getUpdatedValue(
        data.bookingReference,
        formMeta,
        'bookingReference',
      ),
      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrtade to pydantic v2
      commodity_external_description: getUpdatedValue(
        data.cargoDescription,
        formMeta,
        'cargoDescription',
      ),
      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrtade to pydantic v2
      goods_marks: getUpdatedValue(
        data.marksAndNumbers,
        formMeta,
        'marksAndNumbers',
      ),
      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrtade to pydantic v2
      cargo_owner: getAssuredDetails(form),
      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrtade to pydantic v2
      letter_of_credit: getUpdatedValue(
        data.letterOfCredit,
        formMeta,
        'letterOfCredit',
      ),
      issue_date:
        getUpdatedValue(data.issueDate, formMeta, 'issueDate') ??
        moment(new Date()).locale('en').format('YYYY-MM-DD'),
      commodity_id: data.commodity?.id,
      commodity_type: data.commodity?.title,
      containerMode: data.containerMode,
      coverage_package: data.coveragePackage,
      freight_cost: getUpdatedValue(data.freightCost, formMeta, 'freightCost'),
      freight_cost_currency: getUpdatedValue(
        data.freightCostCurrency,
        formMeta,
        'freightCostCurrency',
      ),
      duty_cost: getUpdatedValue(data.dutyCost, formMeta, 'dutyCost'),
      duty_cost_currency: getUpdatedValue(
        data.dutyCostCurrency,
        formMeta,
        'dutyCostCurrency',
      ),
    };
  };

  const getCreationPayload = (
    form: FormApi<CertificateFormProps, typeof zodValidator>,
  ): BffCreateCertificateOnlyBody => {
    const { data, ...basePayload } = getBasePayload(form);
    return {
      ...basePayload,
      etd: moment(data.etd).locale('en').format('YYYY-MM-DD'),
      eta: data.eta
        ? moment(data.eta).locale('en').format('YYYY-MM-DD')
        : undefined,
      commodity_value: basePayload.cargoValue,
      commodity_currency_code: basePayload.cargoCurrency,
      external_reference: data.bookingReference || undefined,
      commodity_external_description: basePayload.cargoDescription.trim(),
      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrtade to pydantic v2
      delivery_place_provider_details:
        basePayload.delivery_place_provider_details,
      loading_transport_mode_code: data.placeOfLoading
        ? (data.placeOfLoadingMot as ConveyanceType)
        : undefined,
      goods_marks: data.marksAndNumbers?.trim() || undefined,
      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrtade to pydantic v2
      cargo_owner: getAssuredDetails(form),
      letter_of_credit: data.letterOfCredit,
      issue_date:
        data.issueDate ?? moment(new Date()).locale('en').format('YYYY-MM-DD'),
      vessel_name:
        data.vessel?.name && !data.vessel.external_id
          ? data.vessel.name
          : undefined,
      vessel_external_id:
        data.vessel?.external_id &&
        data.vessel.external_id !== VESSEL_NOT_FOUND_ID
          ? data.vessel.external_id
          : undefined,
      commodity_id: data.commodity?.id,
      commodity_type: data.commodity?.title,
      container_mode: data.containerMode,
      coverage_package: data.coveragePackage,
      freight_cost: data.freightCost,
      freight_cost_currency: data.freightCostCurrency,
      duty_cost: data.dutyCost,
      duty_cost_currency: data.dutyCostCurrency,
    };
  };

  return {
    getCreationPayload,
    getUpdatePayload,
    certificateId,
    policyId,
    locationHandleBlur,
    isUpdate,
    isDuplicate,
  };
};
