import { useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {
  CancelChangesModal,
  CertificateModalLoadingState,
  ErrorMessage,
  UpdateCertificateModal,
} from '@breezeai-frontend/cargo-ui';
import { type FormApi, useForm } from '@tanstack/react-form';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { zodValidator } from '@tanstack/zod-form-adapter';

import { useAppConfiguration } from '../../../../context/app-configuration/useAppConfiguration';
import {
  useAuthenticatedUser,
  useUserSetting,
} from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import { type BffCertificateModelWithPermissions } from '../../../../generated/api-client';
import { type SupportedCurrencies } from '../../../../model/CurrencyValue';
import { distributorQueries } from '../../../../network/apis/distributors/distributors';
import { useSuspenseDistributors } from '../../../../network/apis/distributors/use-distributors';
import { QuoteStatusEnum } from '../../../../network/apis/quotes/types';
import { queryClient } from '../../../../providers/ReactQueryProvider';
import { NoActiveOpenCoverModal } from '../../../components/NoActiveOpenCoverModal/NoActiveOpenCoverModal';
import { RouteDetails } from '../../../components/RouteDetails/RouteDetails';
import { RouteDetailsInputsProvider } from '../../../components/RouteDetails/RouteDetailsInputProvider';
import { getDefaultInputValue } from '../../../components/RouteDetails/utils';
import { useDefaultPrimaryMot } from '../../../hooks/useDefaultPrimaryMot';
import {
  useCreateCertificate,
  useUpdateCertificate,
} from '../../certificateApiHooks';
import { certificateQueries } from '../../network/queries';
import { type CertificateModel } from '../../types';
import { CertificateReviewCard } from '../CertificateReviewCard/CertificateReviewCard';
import { CertificateStatusCard } from '../CertificateStatusCard/CertificateStatusCard';
import { getDefaultFormData, useFormLogic } from '../useFormLogic';
import { useModalStore } from '../useModalStore';
import { ShipmentDetailsForm } from './ShipmentDetailsForm';
import { SubmissionButtons } from './SubmissionButtons';
import { type CertificateFormProps } from './types';

export function FormContent({
  form,
  isSubmitting,
  isUnderReview,
  certificate,
}: {
  form: FormApi<CertificateFormProps, typeof zodValidator>;
  isSubmitting: boolean;
  isUnderReview: boolean;
  certificate: CertificateModel | undefined;
}) {
  const { isUpdate, isDuplicate } = useFormLogic();
  return (
    <form
      className="flex flex-1 flex-col md:flex-row p-3 h-full bg-white w-full gap-3 rounded-3xl min-h-fit"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        void form.handleSubmit();
      }}
    >
      <div className="flex h-full bg-surfaces-secondary rounded-2xl p-5 basis-96">
        <RouteDetails
          form={form}
          isDuplicate={isDuplicate}
          isUpdate={isUpdate}
        />
      </div>

      <div className="flex flex-col w-full">
        <ShipmentDetailsForm
          form={form}
          isUpdate={isUpdate}
          certificate={certificate}
        />
        <div className="flex flex-row gap-2 self-end mt-auto justify-end">
          <SubmissionButtons
            form={form}
            isUnderReview={isUnderReview}
            isSubmitting={isSubmitting}
          />
        </div>
      </div>
    </form>
  );
}

// TODO tidy component to make more readable
export function CertificateFlowForm({
  remountForm,
}: {
  remountForm: () => void;
}) {
  const { commodities, available_coverage_package, container_modes } =
    useAppConfiguration();
  const { getCreationPayload, getUpdatePayload, isUpdate, policyId } =
    useFormLogic();

  const { isWtw } = usePlatform();

  const defaultPrimaryMot = useDefaultPrimaryMot();
  const defaultUserCurrency =
    useUserSetting<SupportedCurrencies>('default_currency');

  const navigate = useNavigate();

  const { data } = useSuspenseQuery(certificateQueries.details({ policyId }));

  const user = useAuthenticatedUser();
  const { data: openCover } = useQuery(
    distributorQueries.activeOpenCover({ distributorId: user.distributor.id }),
  );

  const [status, setStatus] = useState(data?.quote?.status);
  const [isOpenCoverModalOpen, setIsOpenCoverModalOpen] = useState(false);

  const {
    isCancelChangesModalOpen,
    setIsCancelChangesModalOpen,
    isConfirmChangesModalOpen,
    setIsConfirmChangesModalOpen,
  } = useModalStore();

  const defaultCommodity =
    commodities.length === 1
      ? {
          id: commodities[0].id,
          commodity_type: commodities[0].title,
        }
      : data?.quote?.commodity;

  const defaultCoveragePackage =
    available_coverage_package.length > 0 &&
    available_coverage_package.length < 4
      ? available_coverage_package[0]
      : data?.quote?.coverage_package;

  const defaultContainerMode =
    container_modes.any.length === 1 && isWtw
      ? container_modes.any[0].title
      : data?.quote?.container_mode;

  // TODO: replace below with queryoptions object once created following TS Query v5 pattern
  const { data: unfilteredDistributors } = useSuspenseDistributors({
    params: {
      paginate: false,
      include_archived: isUpdate,
      context: 'quotes_creation',
    },
  });
  const distributors = unfilteredDistributors.filter(
    (distributor) => distributor.can_issue_policy,
  );
  const defaultDistributorId =
    distributors.length === 1
      ? distributors[0].id
      : data?.quote?.distributor_id;

  const form = useForm<CertificateFormProps, typeof zodValidator>({
    // Required fields need to have default values
    defaultValues: getDefaultFormData({
      certificate: data,
      defaultPrimaryMot,
      defaultUserCurrency,
      defaultCommodity,
      defaultCoveragePackage,
      defaultContainerMode,
      defaultDistributorId,
    }),
    onSubmit: ({ formApi }) => {
      if (!openCover) {
        setIsOpenCoverModalOpen(true);
        return;
      }

      if (isUpdate) {
        const payload = getUpdatePayload(formApi);
        return certificateUpdateMutate({
          bffUpdateCertificateOnlyRequest: payload,
        });
      }
      const payload = getCreationPayload(formApi);
      return certificateCreationMutate({
        bffCreateCertificateOnlyBody: payload,
      });
    },

    validatorAdapter: zodValidator,
  });

  const onMutationSuccess = (
    certificate: BffCertificateModelWithPermissions,
  ) => {
    queryClient.invalidateQueries(certificateQueries.details({ policyId }));
    const isUnderReview =
      certificate.quote.status === 'pending_for_breeze_manual_review';

    navigate(
      {
        pathname: `/certificates/${isUnderReview ? 'update' : 'details'}/${
          certificate.certificate_id
        }/${certificate.id}`,
        search:
          isUnderReview || isUpdate
            ? ''
            : `?${createSearchParams({ ready: 'true' })}`,
      },
      {
        replace: true,
      },
    );

    // TODO HACK: backend should return a valid status enum
    setStatus(certificate.quote?.status as QuoteStatusEnum);

    // Reset the form state
    remountForm();
  };

  const {
    isPending: isCertificateCreationMutating,
    isError: isCertificateCreationMutatingError,
    mutate: certificateCreationMutate,
    reset: certificateCreationReset,
  } = useCreateCertificate({
    options: {
      onSuccess: onMutationSuccess,
    },
  });

  const {
    isPending: isCertificateUpdateMutating,
    isError: isCertificateUpdateMutatingError,
    mutate: certificateUpdateMutate,
    reset: certificateUpdateReset,
  } = useUpdateCertificate({
    options: {
      onSuccess: onMutationSuccess,
    },
  });

  return (
    <RouteDetailsInputsProvider
      // These are default values for the route details inputs
      originInputValue={getDefaultInputValue(form.getFieldValue('origin'))}
      destinationInputValue={getDefaultInputValue(
        form.getFieldValue('destination'),
      )}
      placeOfLoadingInputValue={
        form.getFieldValue('placeOfLoading')?.place?.description
      }
      placeOfDeliveryInputValue={
        form.getFieldValue('placeOfDelivery')?.place?.description
      }
    >
      <div className="flex flex-col w-full" data-testid="certificate-flow-form">
        <FormContent
          form={form}
          isUnderReview={
            status === QuoteStatusEnum.PENDING_FOR_BREEZE_MANUAL_REVIEW
          }
          isSubmitting={
            isCertificateCreationMutating || isCertificateUpdateMutating
          }
          certificate={data ?? undefined}
        />
        {status === QuoteStatusEnum.PENDING_FOR_BREEZE_MANUAL_REVIEW && (
          <CertificateStatusCard>
            <CertificateReviewCard
              exclusionReasons={data?.quote?.exclusion_reasons}
            />
          </CertificateStatusCard>
        )}
        {status === QuoteStatusEnum.DECLINED && (
          <CertificateStatusCard>
            <ErrorMessage
              type="SomethingWentWrong"
              title="Unable to provide cover for this shipment."
              details={[
                'This can happen if the cargo or journey falls outside of appetite for the insurer.',
                'Please feel free to contact us for more information.',
              ]}
            />
          </CertificateStatusCard>
        )}
      </div>

      <CertificateModalLoadingState
        isOpen={
          isCertificateCreationMutating ||
          isCertificateCreationMutatingError ||
          isCertificateUpdateMutating ||
          isCertificateUpdateMutatingError
        }
        isError={
          isCertificateCreationMutatingError || isCertificateUpdateMutatingError
        }
        isLoading={isCertificateCreationMutating || isCertificateUpdateMutating}
        showExitButton={
          isCertificateCreationMutatingError || isCertificateUpdateMutatingError
        }
        onExit={() => {
          if (isCertificateCreationMutatingError) {
            certificateCreationReset();
          }
          if (isCertificateUpdateMutatingError) {
            certificateUpdateReset();
          }
        }}
        isUpdate={isUpdate}
      />
      <CancelChangesModal
        isOpen={isCancelChangesModalOpen}
        onOpenChange={setIsCancelChangesModalOpen}
        onDiscardChanges={() => void remountForm()}
        title="Are you sure you want to cancel changes?"
        subtitle="Your changes will be lost."
      />
      <UpdateCertificateModal
        isOpen={isConfirmChangesModalOpen}
        onOpenChange={setIsConfirmChangesModalOpen}
        onConfirmChanges={() => void form.handleSubmit()}
      />
      <NoActiveOpenCoverModal
        isOpen={isOpenCoverModalOpen}
        onOpenChange={setIsOpenCoverModalOpen}
      />
    </RouteDetailsInputsProvider>
  );
}
