import { initReactI18next } from 'react-i18next';
import i18n, { type TOptionsBase } from 'i18next';

import enCertificates from './locales/en/certificates.json';
import enCommon from './locales/en/common.json';
import enInsights from './locales/en/insights.json';
import enPolicies from './locales/en/policies.json';
import enQuotes from './locales/en/quotes.json';
import enSidebar from './locales/en/sidebar.json';
import enStorage from './locales/en/storage.json';
import fallback from './locales/fallback.json';
import ptCertificates from './locales/pt/certificates.json';
import ptCommon from './locales/pt/common.json';
import ptInsights from './locales/pt/insights.json';
import ptPolicies from './locales/pt/policies.json';
import ptQuotes from './locales/pt/quotes.json';
import ptSidebar from './locales/pt/sidebar.json';
import ptStorage from './locales/pt/storage.json';

// TODO this can be a list
export const validLocales = {
  en: 'en',
  pt: 'pt',
} as const;

// TODO this can be a list
export const validNameSpaces = {
  fallback: 'fallback',
  sidebar: 'sidebar',
  insights: 'insights',
  quotes: 'quotes',
  common: 'common',
  certificates: 'certificates',
  policies: 'policies',
  storage: 'storage',
} as const;

export type LocalesType = (typeof validLocales)[keyof typeof validLocales];

export type NameSpacesType =
  (typeof validNameSpaces)[keyof typeof validNameSpaces];

export type NameSpaceKeysType<
  K extends NameSpacesType,
  T extends LocalesType = typeof validLocales.en,
> = (typeof resources)[T][K];

// The below enforces type safety for the json files - any keys in the en files must be present in all other files
// See https://github.com/amannn/next-intl/discussions/157
type Messages = {
  [k in LocalesType]: {
    common: typeof enCommon;
    fallback: typeof fallback;
    sidebar: typeof enSidebar;
    insights: typeof enInsights;
    quotes: typeof enQuotes;
    certificates: typeof enCertificates;
    policies: typeof enPolicies;
    storage: typeof enStorage;
  };
};

export const resources: Messages = {
  en: {
    fallback,
    common: enCommon,
    sidebar: enSidebar,
    insights: enInsights,
    quotes: enQuotes,
    certificates: enCertificates,
    policies: enPolicies,
    storage: enStorage,
  },
  pt: {
    fallback,
    common: ptCommon,
    sidebar: ptSidebar,
    insights: ptInsights,
    quotes: ptQuotes,
    certificates: ptCertificates,
    policies: ptPolicies,
    storage: ptStorage,
  },
} as const;

// This is the default and fallback namespace - it should not be used unless absolutely necessary. TS will enforce an explicit namespace for all translations.
export const defaultNS = validNameSpaces.fallback;

// contextSeparator is used to separate the context from the key in the translation files
export const contextSeparator = '::';

i18n.use(initReactI18next).init({
  resources,
  defaultNS,
  ns: Object.values(validNameSpaces),
  lng: validLocales.en,
  // if a translation is not found in the current language, try to fall back to 'en'
  fallbackLng: validLocales.en,
  interpolation: { escapeValue: false },
  contextSeparator,
});

// TODO HACK: Temporary solution to use a custom i18n context based on the distributor company name.
export type CustomContext = 'compagnie_generale_du_roumois';

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: typeof defaultNS;
    resources: (typeof resources)['en'];
    strictKeyChecks: true;
    contextSeparator: typeof contextSeparator;
  }

  type NamespaceKeys = keyof CustomTypeOptions['resources'];

  // Support for nested keys in translation files
  type NestedKeyOf<ObjectType extends object> = {
    [Key in keyof ObjectType &
      (string | number)]: ObjectType[Key] extends object
      ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
      : `${Key}`;
  }[keyof ObjectType & (string | number)];

  // Remove keys with context from the list of keys
  type TranslationKeys<N extends NamespaceKeys> = Exclude<
    NestedKeyOf<CustomTypeOptions['resources'][N]>,
    `${string}${typeof contextSeparator}${string}`
  >;

  interface TFunction {
    <N extends NamespaceKeys, K extends TranslationKeys<N>>(
      key: K,
      options: {
        ns: N;
        context: 'wtw' | 'breeze' | CustomContext;
        // TODO this is to support interpolation arguments, but it's not typed
        [key: string]: unknown;
      } & TOptionsBase,
    ): string;
  }
}

export default i18n;
