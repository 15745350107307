import { useEffect, useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { defaults } from 'lodash';
import moment from 'moment';

import { useAppConfiguration } from '../../../context/app-configuration/useAppConfiguration';
import { useUser, useUserSetting } from '../../../context/auth/auth-hooks';
import { type SupportedCurrencies } from '../../../model/CurrencyValue';
import { type Quote } from '../../../model/Quote';
import { type ConveyanceType, type Shipment } from '../../../model/Shipment';
import { type TitledOption } from '../../../model/TitledOption';
import { useRouteParams } from '../../../router/router-hooks';
import { getTheme } from '../../../utils/getTheme';
import { quotesQueries } from '../../quotes/network/queries';
import { FlowSteps } from '../constants';
import {
  type PolicyFlowFormsData,
  type PolicyFlowUrlParams,
} from '../context/types';
import { useQuoteFormLogic } from '../steps/QuoteForm/useQuoteFormLogic';
import {
  useInitFormData,
  usePolicyFlowContext,
  useSetFlowStep,
} from './context-hooks';
import { useFetchExistingShipmentData } from './use-fetch-forms-existing-data';

type NormalizerPayload = {
  containerModes: Record<ConveyanceType, TitledOption[]>;
  defaultCurrency: SupportedCurrencies;
};

const isWtw = getTheme() === 'wtw';

/**
 * Maps an existing Shipment data into the Quote form inputs
 * for pre-filling the form.
 */
const shipmentToFormsData = (
  shipment: Shipment,
  { containerModes, defaultCurrency }: NormalizerPayload,
): PolicyFlowFormsData => ({
  coverageType: 'all_risks',
  transportMethod: {
    primary: shipment.primary_transport_mode_code,
    primaryDisplayName:
      shipment.transport_mode_code_display_name ||
      shipment.primary_transport_mode_code_display_name,
    secondary: shipment?.secondary_mode_of_transport,
  },
  origin: {
    port: shipment.origin_port,
  },
  destination: {
    port: shipment.destination_port,
  },
  currency: shipment.commodity_currency ?? defaultCurrency,
  customer: shipment.customer,
  commodity: {
    value: shipment.commodity_value,
    category: shipment.commodity_type,
    description: shipment.commodity_description,
  },
  eta: moment(shipment.eta),
  etd: moment(shipment.etd),
  containerModeId: containerModes[shipment.primary_transport_mode_code].find(
    ({ title }) => title === shipment.container_mode,
  )?.id,
  specialConditions: shipment.special_conditions ?? [],
  containerIds: shipment.container_ids?.join(','),
  freightCost: shipment.freight_cost,
  freightCostCurrency: shipment.freight_cost_currency,
  dutyCost: shipment.duty_cost,
  dutyCostCurrency: shipment.duty_cost_currency,
  // The initial external reference value is derived from the external shipment id
  externalReference: shipment.external_shipment_id,
  externalShipmentId: shipment.external_shipment_id,
  incoterm: shipment.incoterm_code,
  vehicleName: undefined,
});

const getEtd = (quote?: Quote) => {
  if (quote?.etd) {
    return moment(quote.etd);
  }

  if (!isWtw) {
    return moment();
  }

  return undefined;
};

/**
 * Maps an existing Quote data into the Quote form inputs
 * for pre-filling the form.
 */
const quoteToFormsData = (
  quote: Quote,
  { containerModes, defaultCurrency }: NormalizerPayload,
): PolicyFlowFormsData => ({
  coverageType: quote.coverage_package ?? 'all_risks',
  transportMethod: {
    primary: quote.primary_transport_mode_code,
    primaryDisplayName:
      quote.primary_transport_mode_code_display_name ??
      quote.transport_mode_display_name ??
      '',
    secondary: quote.secondary_transport_mode_code,
  },
  distributorId: quote.distributor?.id,
  origin: {
    place: quote.origin_place,
    port: quote.origin_port,
  },
  destination: {
    place: quote.destination_place,
    port: quote.destination_port,
  },
  currency: quote.premium_currency ?? defaultCurrency,
  customer: quote.customer,
  commodity: {
    currency: quote.commodity_currency,
    value: quote.commodity_value,
    category: quote.commodity_category,
    categoryId: Number(quote.commodity_category_id),
    description: quote.commodity_external_description,
  },
  freightCost: quote.freight_cost,
  freightCostCurrency: quote.freight_cost_currency,
  dutyCost: quote.duty_cost,
  dutyCostCurrency: quote.duty_cost_currency,
  specialConditions: quote.special_conditions ?? [],
  containerModeId:
    quote.primary_transport_mode_code &&
    containerModes[quote.primary_transport_mode_code].find(
      ({ title }) => title === quote.container_mode,
    )?.id,
  externalReference: quote.external_reference,
  eta: quote.eta ? moment(quote.eta) : undefined,
  etd: getEtd(quote),
  incoterm: quote.incoterm_code,
  vehicleName: quote.vessel_name,
  containerIds: quote.container_ids?.join(','),
});

/**
 * Calculates the default form values
 */
const useFormDefaultValues = () => {
  const user = useUser();
  const defaultCurrency =
    useUserSetting<SupportedCurrencies>('default_currency');

  return useMemo(
    (): PolicyFlowFormsData => ({
      coverageType: 'all_risks',
      transportMethod: {
        primary: 'sea',
        primaryDisplayName: 'sea',
      },
      containerModeId: 1,
      currency: defaultCurrency,
      dutyCostCurrency: 'USD',
      freightCostCurrency: defaultCurrency,
      distributorId: user?.distributor.id,
      etd: getEtd(),
      eta: undefined,
    }),
    [defaultCurrency, user?.distributor.id],
  );
};

/**
 * Orchestrates the initialization of the Policy Flow.
 * - Fetches the flow configuration.
 * - Fetches the existing Shipment or Quote data, if needed (based on the route params).
 * - Populates the initial input values accordingly (existing data or default values).
 * - Progress the flow step indication accordingly.
 */
export const usePolicyFlowInitialization = () => {
  const {
    params: { quoteId },
  } = useRouteParams<PolicyFlowUrlParams>();

  const { data: quote } = useSuspenseQuery(quotesQueries.details({ quoteId }));

  const appConfiguration = useAppConfiguration();
  const shipmentId = useFetchExistingShipmentData();
  const defaultCurrency =
    useUserSetting<SupportedCurrencies>('default_currency');

  const { step, shipment } = usePolicyFlowContext();
  const formDefaultValues = useFormDefaultValues();
  const initFormData = useInitFormData();
  const setFlowStep = useSetFlowStep();
  const { isDuplicate } = useQuoteFormLogic();

  useEffect(() => {
    const normalizerPayload = {
      defaultCurrency,
      containerModes: appConfiguration.container_modes,
    };

    if (step === FlowSteps.INIT) {
      if ((!shipmentId && !quoteId) || isDuplicate) {
        initFormData(formDefaultValues);
        setFlowStep(FlowSteps.QUOTE_FORM);
        return;
      }

      if (shipment) {
        const data = shipmentToFormsData(shipment, normalizerPayload);
        defaults(data, formDefaultValues);
        initFormData(data);
        setFlowStep(FlowSteps.QUOTE_FORM);
        return;
      }
      if (quote) {
        const data = quoteToFormsData(quote, normalizerPayload);
        defaults(data, formDefaultValues);
        initFormData(data);
        setFlowStep(FlowSteps.QUOTE_SUMMARY);
        return;
      }
    }
  }, [
    formDefaultValues,
    quote,
    quoteId,
    shipment,
    shipmentId,
    initFormData,
    setFlowStep,
    appConfiguration,
    step,
    defaultCurrency,
    isDuplicate,
  ]);
};
