import { useTranslation } from 'react-i18next';
import {
  Button,
  clsxMerge,
  DateRangePicker,
  type DateRangePickerProps,
  Typography,
} from '@breezeai-frontend/cargo-ui';
import { type IconName } from '@fortawesome/fontawesome-svg-core';
import { type DateValue } from '@internationalized/date';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classnames from 'classnames';

import { usePlatform } from '../../../../../context/PlatformContext';
import { TriggerButton } from '../TriggerButton/TriggerButton';
import styles from './RadioDropdown.module.scss';

type Option = {
  label: string;
  value: string;
};

// TODO rebuild using tailwind, aria and move to cargo ui
export const RadioDropdownV2 = ({
  options,
  onSelect,
  selected,
  setSelected,
  name,
  label,
  dropdownIcon,
  customDateRange,
  triggerTestId,
  contentTestId,
  onReset,
  triggerButtonCount,
  isCustomDateRangeDisplayed,
}: {
  options: Option[];
  onSelect: (option: string) => void;
  selected: string | undefined;
  setSelected: (selected: string) => void;
  name: string;
  label: string;
  dropdownIcon?: IconName;
  customDateRange?: DateRangePickerProps<DateValue>;
  triggerTestId?: string;
  contentTestId?: string;
  onReset: () => void;
  triggerButtonCount?: number;
  isCustomDateRangeDisplayed?: boolean;
}) => {
  const { platform } = usePlatform();
  const { t } = useTranslation();
  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild data-testid={triggerTestId}>
        <TriggerButton
          selectedCount={triggerButtonCount}
          name={name}
          label={label}
          prefixIcon={dropdownIcon}
          suffixIcon="chevron-down"
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          data-testid={contentTestId}
          sideOffset={5}
          align="start"
          className={styles.dropdownMenuContent}
        >
          <DropdownMenu.RadioGroup
            value={selected}
            onValueChange={setSelected}
            className={styles.radioGroupRoot}
            aria-label="Select Option"
            data-testid="radio-group"
          >
            {options.map(({ value, label }) => {
              return (
                <button
                  key={value}
                  className={classnames(
                    styles.radioGroupItemContainer,
                    'hover:bg-dropdown-row-bg-hover',
                    {
                      ['bg-dropdown-row-bg-selected hover:bg-dropdown-row-bg-selected']:
                        selected === value,
                    },
                  )}
                  onClick={() => {
                    setSelected(value);
                    onSelect(value);
                  }}
                >
                  <DropdownMenu.RadioItem
                    onSelect={(e) => {
                      e.preventDefault();
                      onSelect(value);
                    }}
                    className={styles.RadioGroupItem}
                    value={value}
                    id={value}
                  >
                    <DropdownMenu.ItemIndicator
                      className={clsxMerge(
                        styles.radioGroupIndicator,
                        'after:bg-buttons-primary-bg-default',
                      )}
                    />
                  </DropdownMenu.RadioItem>
                  <label className={styles.label} htmlFor={value}>
                    <Typography>{label}</Typography>
                  </label>
                </button>
              );
            })}
          </DropdownMenu.RadioGroup>
          {/* TODO This prop should no be on the generic radio dropdown component. It should be in its implementation when needed. */}
          {isCustomDateRangeDisplayed && customDateRange && (
            <div
              className={styles.customDateRangeContainer}
              data-testid="custom-date-range-container"
            >
              <DateRangePicker
                defaultOpen
                data-testid="custom-date-range-picker"
                aria-label="Custom Date Range"
                shouldCloseOnSelect
                {...customDateRange}
              />
            </div>
          )}

          <div className="flex justify-end w-full border-t-[1px] border-t-gray-200 py-1 mt-1">
            <Button
              data-testid="reset-radio-button"
              variant="ghost"
              width="auto"
              label={t('tables.filters.Reset', {
                ns: 'common',
                context: platform,
              })}
              onPress={onReset}
            />
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
