import { useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import {
  type BaseQueryParams,
  type PageState,
  ServerSideTableV2,
} from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';
import { type GridRowParams } from '@mui/x-data-grid-pro';

import { useFeatureToggle } from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import { type Quote } from '../../../../model/Quote';
import { getQuotes } from '../../../../network/apis/quotes/quotes';
import { type QuotesSortableFields } from '../../../../network/apis/quotes/types';
import { useAppNavigation } from '../../../../router/use-app-navigation';
import { reportException } from '../../../../utils/error-reporting/error-reporting';
import {
  StatusFilter,
  StatusFilterTypeEnum,
} from '../../../certificates/CertificatesPage/CertificatesTable/CertificateStatusFilter/CertificateStatusFilter';
import { useSectionError } from '../../../components/Section/SectionContext';
import {
  CargoOwnersFilter,
  CreatedByFilter,
  CreatedOnFilter,
  useFilterStore,
} from '../../../components/TableFilters';
import { PrimaryAssuredFilter } from '../../../components/TableFilters/PrimaryAssuredFilter/PrimaryAssuredFilter';
import styles from './QuotesTable.module.scss';
import { useQuotesTableColumns } from './use-quotes-table-columns';

const DEFAULT_QUERY_PARAMS = {
  limit: 10,
};

export const QuotesTable = () => {
  const { isWtw, platform } = usePlatform();
  const { t } = useTranslation();
  const enableFilters = useFeatureToggle('enable_column_filters');
  const { navigateToQuote } = useAppNavigation();
  const { filters, clearFilters } = useFilterStore((state) => ({
    filters: state.filters,
    clearFilters: state.clearFilters,
  }));
  const { setError } = useSectionError();
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [queryParams, setQueryParams] =
    useState<BaseQueryParams>(DEFAULT_QUERY_PARAMS);
  const columns = useQuotesTableColumns();
  // TODO once we can use the edit permission, change the below to quote?.permissions?.edit
  const onRowClick = useCallback(
    ({ row }: GridRowParams<Quote>) =>
      row.permissions?.create
        ? navigateToQuote(`${row.id}`)
        : navigateToQuote(`details/${row.id}`),
    [navigateToQuote],
  );

  // clear filter when navigating away from page
  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, [clearFilters]);

  const fetchQuotesPage = useCallback(
    async (params: BaseQueryParams): Promise<PageState<Quote>> => {
      return getQuotes({
        limit: params?.limit,
        query: params?.query,
        order: params?.order,
        sort_by: params?.sortBy as QuotesSortableFields,
        page_pointer: params?.pagePointer,
        filters,
        paginate: true,
      })
        .then((page) => {
          return {
            records: page.quotes,
            nextPagePointer: page.next_page_pointer,
            prevPagePointer: page.prev_page_pointer,
            totalRows: page.total_num_records,
          };
        })
        .catch((e) => {
          reportException(e);
          setError(e);
          return { records: [] };
        });
    },
    [filters, setError],
  );

  return (
    <div className="h-full w-full">
      <ServerSideTableV2<Quote>
        testId="quotes-table"
        pinnedColumns={{ right: ['action'] }}
        sx={{
          '.MuiDataGrid-pinnedColumns--right': {
            boxShadow: 'none',
          },
          '.MuiDataGrid-pinnedColumnHeaders--right': {
            display: 'none',
          },
        }}
        fetchPage={fetchQuotesPage}
        columns={columns}
        getRowId={(row) => row.id}
        getRowClassName={() => styles.quoteRow}
        onRowClick={onRowClick}
        actions={{ search: true, pageSize: true, filters: true }}
        searchProps={{
          placeholder: t('table.SearchQuoteOrShipmentID', {
            ns: 'quotes',
            context: platform,
          }),
        }}
        setCurrentPageNumber={setCurrentPageNumber}
        currentPageNumber={currentPageNumber}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        autoHeight={false}
        filters={
          enableFilters ? (
            <ErrorBoundary fallbackRender={() => <></>}>
              <div className={styles.filters}>
                <CreatedOnFilter />
                <ErrorBoundary fallbackRender={() => <></>}>
                  <CreatedByFilter />
                </ErrorBoundary>
                {isWtw && (
                  <ErrorBoundary fallbackRender={() => <></>}>
                    <PrimaryAssuredFilter context="quotes_filter" />
                  </ErrorBoundary>
                )}
                <ErrorBoundary fallbackRender={() => <></>}>
                  <CargoOwnersFilter />
                </ErrorBoundary>
                {isWtw && <StatusFilter type={StatusFilterTypeEnum.QUOTE} />}
                <Button
                  onPress={clearFilters}
                  variant="ghost"
                  width="fixed"
                  size="small"
                  isDisabled={!filters.length}
                  label={t('tables.filters.ClearFilters', {
                    ns: 'common',
                    context: platform,
                  })}
                />
              </div>
            </ErrorBoundary>
          ) : undefined
        }
      />
    </div>
  );
};
