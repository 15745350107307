import { type ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@breeze-ai/ui-library';
import { sortBy } from 'lodash';

import { usePlatform } from '../../context/PlatformContext';
import { type TitledOption } from '../../model/TitledOption';

type IncotermSelectorProps = {
  options: TitledOption[];
  selectedIncoterm?: string;
  onChange: (incoterm: string) => void;
  inputProps?: ComponentProps<typeof Autocomplete>['inputProps'];
};

export const IncotermSelector = ({
  options,
  selectedIncoterm,
  onChange,
  inputProps,
}: IncotermSelectorProps) => {
  const { t } = useTranslation();
  const { platform } = usePlatform();
  const incoterms = sortBy(options, 'title').map(({ title }) => title);

  return (
    <Autocomplete<string>
      value={selectedIncoterm}
      getOptionValue={(option) => option}
      options={incoterms}
      onChange={(incoterm) => onChange(incoterm ?? '')}
      inputProps={{
        label: t('Incoterm', {
          ns: 'common',
          context: platform,
        }),
        placeholder: 'Select...',
        testId: 'incoterm-selector',
        ...inputProps,
      }}
    />
  );
};
