import { FaEllipsisVertical } from 'react-icons/fa6';
import {
  Button,
  LinkButton,
  ListBox,
  ListBoxItem,
  Popover,
  Typography,
} from '@breezeai-frontend/cargo-ui';

export type ActionMenuItem = {
  type: 'button' | 'link';
  label: string;
  href?: string;
  leftIcon: React.ReactNode;
  isDisabled?: boolean;
  isDownload: boolean;
  isHidden?: boolean;
  onPress?: () => void;
  loadingText?: string;
  isLoading?: boolean;
};

export function ActionMenu({
  items,
  id,
}: {
  items: ActionMenuItem[];
  id: string;
}) {
  const visibleItems = items.filter((item) => !item.isHidden);
  // TODO: popover lingers when the user clicks a link and navigates away from the page - it only closes once the next page renders
  // See https://breeze.fibery.io/Breeze/Tech-Debt-Form-360#Task/Popover-hangs-when-navigating-from-table-using-link-in-action-menu-3306
  if (!visibleItems.length) return null;
  const itemStyle = 'justify-start rounded-lg text-text-primary';
  return (
    <div className="w-full flex justify-center">
      <Popover
        offset={-7}
        placement="left top"
        anchorElement={
          <Button
            leftIcon={<FaEllipsisVertical />}
            variant="ghost"
            data-testid={`action-menu-button-${id}`}
          />
        }
      >
        <ListBox className="space-y-1" aria-label="Actions">
          {visibleItems.map((item) => (
            <ListBoxItem
              key={item.label}
              className="p-0"
              textValue={item.label}
            >
              {item.type === 'link' ? (
                <LinkButton
                  isDisabled={item.isDisabled}
                  customStyles={itemStyle}
                  width="full"
                  href={item.href}
                  leftIcon={
                    <span className="text-icons-primary">{item.leftIcon}</span>
                  }
                  variant="ghost"
                  target={item.isDownload ? '_blank' : undefined}
                  onPress={item.onPress}
                >
                  <Typography>{item.label}</Typography>
                </LinkButton>
              ) : (
                <Button
                  variant="ghost"
                  onPress={item.onPress}
                  isDisabled={item.isDisabled}
                  customStyles={itemStyle}
                  width="full"
                  isLoading={item.isLoading}
                  loadingText={item.loadingText}
                  leftIcon={
                    <span className="text-icons-primary">{item.leftIcon}</span>
                  }
                >
                  <Typography>{item.label}</Typography>
                </Button>
              )}
            </ListBoxItem>
          ))}
        </ListBox>
      </Popover>
    </div>
  );
}
