import React from 'react';
import {
  composeRenderProps,
  ListBox,
  type ListBoxItemProps,
  Select as AriaSelect,
  type SelectProps as AriaSelectProps,
  SelectValue,
  type ValidationResult,
} from 'react-aria-components';
import { FaSpinner } from 'react-icons/fa';
import { FaChevronDown } from 'react-icons/fa6';

import { clsxMerge } from '../common/utils/classNameUtils';
import { Description, FieldError, fieldStyles, Label } from '../Field/Field';
import {
  DropdownItem,
  DropdownSection,
  type DropdownSectionProps,
} from '../ListBox/ListBox';
import { Popover } from '../Popover/Popover';
import { SelectButton } from './SelectButton';

export interface SelectProps<T extends object>
  extends Omit<AriaSelectProps<T>, 'children'> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  offsetErrorMessage?: boolean;
  items?: Iterable<T>;
  absoluteErrorMessage?: boolean;
  children: React.ReactNode | ((item: T) => React.ReactNode);
  selectedValueTestId?: string;
  isLoading?: boolean;
}

export function Select<T extends object>({
  label,
  description,
  isInvalid,
  errorMessage,
  offsetErrorMessage,
  children,
  items,
  isLoading = false,
  absoluteErrorMessage = false,
  selectedValueTestId,
  ...props
}: SelectProps<T>) {
  return (
    // TODO HACK: relative div is needed for the selected not overflow the page container
    <div className="relative">
      <AriaSelect
        {...props}
        isInvalid={isInvalid}
        className={composeRenderProps(
          props.className,
          (className, renderProps) =>
            clsxMerge(fieldStyles({ ...renderProps }), className),
        )}
      >
        {label && <Label isRequired={props.isRequired}>{label}</Label>}
        <SelectButton isInvalid={isInvalid}>
          <SelectValue
            // TODO HACK: max-w-96 is needed to prevent the selected value from overflowing the page container if selected value is too long
            className="flex items-center gap-1 data-[placeholder]:text-field-text-input overflow-scroll no-scrollbar max-w-96"
            data-testid={selectedValueTestId}
          />
          {isLoading && <FaSpinner className="w-3 h-3 animate-spin" />}
          {!isLoading && (
            <FaChevronDown aria-hidden className="w-3 h-3 fill-gray-700" />
          )}
        </SelectButton>
        {description && !isInvalid && <Description>{description}</Description>}
        <FieldError
          absolute={absoluteErrorMessage}
          className={clsxMerge(offsetErrorMessage && 'left-2.5')}
        >
          {errorMessage}
        </FieldError>
        <Popover className="min-w-[--trigger-width] overflow-hidden border-none">
          <ListBox
            items={items}
            className="outline-none p-2 max-h-[inherit] overflow-auto [clip-path:inset(0_0_0_0_round_.75rem)]"
          >
            {children}
          </ListBox>
        </Popover>
      </AriaSelect>
    </div>
  );
}

export function SelectItem<T extends object>(props: ListBoxItemProps<T>) {
  return <DropdownItem {...props} />;
}

export function SelectSection<T extends object>(
  props: DropdownSectionProps<T>,
) {
  return <DropdownSection {...props} />;
}
