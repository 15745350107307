import { TextField } from '@breezeai-frontend/cargo-ui';

import { useClaimCreationStateAction } from '../context/hooks';

const FormRow = ({ children }: { children: React.ReactNode }) => (
  <div className="w-full flex gap-5 flex-row">{children}</div>
);

export const ShipmentDetailsForm = () => {
  const { setFormData } = useClaimCreationStateAction();

  return (
    <div className="w-full flex flex-wrap gap-6">
      <FormRow>
        <TextField
          label="Conveyance"
          placeholder="Enter conveyance"
          onChange={(conveyance) => setFormData({ conveyance })}
        />
        <TextField
          label="Origin"
          placeholder="Enter origin"
          onChange={(origin) => setFormData({ origin })}
        />
        <TextField
          label="Destination"
          placeholder="Enter destination"
          onChange={(destination) => setFormData({ destination })}
        />
      </FormRow>

      <FormRow>
        <TextField
          label="Bill of Lading Number"
          placeholder="Enter bill of lading number"
          onChange={(billOfLadingNumber) => setFormData({ billOfLadingNumber })}
        />
        <TextField
          label="Carrier Name"
          placeholder="Enter carrier name"
          onChange={(carrierName) => setFormData({ carrierName })}
        />
      </FormRow>

      <FormRow>
        <TextField
          label="Description of Cargo"
          placeholder="Enter description of cargo"
          variant="area"
          customStyles="min-h-28"
          onChange={(descriptionOfCargo) => setFormData({ descriptionOfCargo })}
        />
      </FormRow>
    </div>
  );
};
