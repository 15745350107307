import { useContext, useEffect, useMemo } from 'react';

import { useUserSetting } from '../../../../context/auth/auth-hooks';
import { type Claim } from '../../../../model/Claim';
import { type SupportedCurrencies } from '../../../../model/CurrencyValue';
import { type ValidationResult } from '../../../../utils/validators';
import { type ClaimCreationFlowSteps } from '../../constants';
import { ClaimCreationContext } from './ClaimCreationContext';
import { type ClaimFormsData, type FileErrors } from './types';

export const useInitClaimForms = () => {
  const { setFormData } = useClaimCreationStateAction();
  const defaultCurrency =
    useUserSetting<SupportedCurrencies>('default_currency');

  useEffect(() => {
    setFormData({
      reportedAmount: 0,
      reportedAmountCurrency: defaultCurrency,
      raiseAgainstOpenCover: false,
    });
  }, [defaultCurrency, setFormData]);
};

export const useClaimCreationFlowContext = () => {
  const { state, dispatch } = useContext(ClaimCreationContext);

  return { ...state, dispatch };
};

export const useClaimCreationStateAction = () => {
  const { dispatch } = useClaimCreationFlowContext();

  return useMemo(
    () => ({
      setStep: (payload: ClaimCreationFlowSteps) =>
        dispatch({ type: 'set-claim-creation-flow-step', payload }),
      setFormData: (payload: Partial<ClaimFormsData>) =>
        dispatch({ type: 'set-forms-data', payload }),
      setFormError: (payload: ValidationResult | FileErrors) =>
        dispatch({ type: 'set-form-error', payload }),
      setClaim: (payload: Claim) => dispatch({ type: 'set-claim', payload }),
    }),
    [dispatch],
  );
};
