import axios from 'axios';

import { Configuration, QuotesApi } from '../generated/api-client';
import { fetchIdToken } from './apis/auth/auth';
import { createRequestConfig } from './apis/utils';
import { bffServiceUrl } from './netconfig';

export async function getBffApiConfiguration() {
  const token = await fetchIdToken();
  return new Configuration({
    accessToken: token ?? undefined,
    basePath: bffServiceUrl,
  });
}

export async function getApiClient() {
  const apiConfig = await getBffApiConfiguration();
  // TODO HACK: This is needleslly creating a new instance of QuotesApi. Token needs to be refreshed on logout and login.
  return new QuotesApi(apiConfig);
}

export type NetworkRequestOptions = {
  auth?: boolean;
};

export interface NetworkResponse<T> {
  data: T;
  status: number;
  statusText: string;
}

export const post = async <T>(
  url: string,
  payload?: unknown,
  options?: NetworkRequestOptions,
): Promise<NetworkResponse<T>> => {
  const config = await createRequestConfig(options);
  const res = await axios.post<T>(url, payload, config);
  return res;
};

export const put = async <T>(
  url: string,
  payload?: unknown,
  options?: NetworkRequestOptions,
): Promise<NetworkResponse<T>> => {
  const config = await createRequestConfig(options);

  return await axios.put<T>(url, payload, config);
};

export const get = async <T>(
  url: string,
  payload?: unknown,
  options?: NetworkRequestOptions,
): Promise<NetworkResponse<T>> => {
  const config = await createRequestConfig(options);

  return await axios.get<T>(url, {
    ...config,
    params: payload,
  });
};
