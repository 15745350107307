import { FaDownload, FaPlus, FaTrash } from 'react-icons/fa';
import { FaTriangleExclamation } from 'react-icons/fa6';
import { PiClockCountdown } from 'react-icons/pi';
import { Button, LinkButton, Typography } from '@breezeai-frontend/cargo-ui';
import moment from 'moment';

import { queryClient } from '../../../providers/ReactQueryProvider';
import {
  defaultFileIcon,
  fileTypeIcons,
} from '../../components/FileList/file-icons';
import { useUpdateStorageLocationFile } from '../locations/hooks/useUpdateStorageLocationFile';
import {
  type StorageLocationFileModel,
  StorageLocationFileStatusEnum,
  StorageLocationFileTypeEnum,
  type StorageLocationModel,
} from '../locations/types';
import { StorageLocationDetailsSectionLayout } from './StorageLocationDetailsSectionLayout';

const FilePreview = ({
  title,
  url,
  type,
  createdDate,
  expiryDate,
  onDelete,
  isDeleting,
}: {
  title: string;
  url: string;
  type: string;
  createdDate: string;
  expiryDate?: string;
  onDelete: () => void;
  isDeleting?: boolean;
}) => {
  const expiringSoon = expiryDate
    ? moment(expiryDate).diff(moment(), 'days') < 15
    : false;
  const expired = expiryDate
    ? moment(expiryDate).diff(moment(), 'days') < 0
    : false;

  return (
    <div className="w-full border-2 border-solid border-system-grey-200 rounded-md p-2 flex flex-row items-center justify-between">
      <div className="flex flex-row items-center gap-x-3">
        <div className="size-6">
          {fileTypeIcons[type.toLowerCase()] ?? defaultFileIcon}
        </div>
        <div className="flex flex-col">
          <Typography level="base">{title}</Typography>
          <div className="flex flex-row gap-2 text-text-tertiary">
            <Typography level="subtext">{type.toUpperCase()}</Typography>
            <Typography level="subtext">
              {moment(createdDate).format('DD/MM/YYYY').toString()}
            </Typography>
          </div>
        </div>
      </div>

      <div className="flex flex-row gap-x-2 items-center">
        {expiryDate && expiringSoon && (
          <div className="flex gap-1 bg-system-orange-100 rounded-3xl py-2 px-4 mr-2 items-center">
            <PiClockCountdown size={14} className="text-system-orange-500" />
            <Typography level="subtext" customStyles="text-primary">
              Expiring Soon
            </Typography>
          </div>
        )}
        {expiryDate && expired && (
          <div className="flex gap-1 bg-system-red-100 rounded-3xl py-2 px-4 mr-2">
            <FaTriangleExclamation size={14} className="text-system-red-500" />
            <Typography level="subtext" customStyles="text-primary">
              Expired
            </Typography>
          </div>
        )}
        <Typography level="base" customStyles="text-text-tertiary">
          {expiryDate
            ? `Expiry: ${moment(expiryDate).format('DD/MM/YYYY').toString()}`
            : null}
        </Typography>
        <LinkButton
          variant="ghost"
          leftIcon={<FaDownload />}
          href={url}
          target="_blank"
          isDisabled={isDeleting}
        />
        <Button
          loadingText="Deleting"
          isLoading={isDeleting}
          variant="ghost"
          leftIcon={<FaTrash className="fill-system-red-500" />}
          onPress={onDelete}
        />
      </div>
    </div>
  );
};

export function Documents({
  data,
  type,
}: {
  data: StorageLocationModel;
  type: StorageLocationFileTypeEnum;
}) {
  let title: string;
  switch (type) {
    case StorageLocationFileTypeEnum.SURVEY_REPORT:
      title = 'Survey Report';
      break;
    default:
      title = 'Other Documents';
      break;
  }
  let files: StorageLocationFileModel[];
  switch (type) {
    case StorageLocationFileTypeEnum.SURVEY_REPORT:
      files = data?.active_storage_survey_report_file
        ? [data.active_storage_survey_report_file]
        : [];
      break;
    case StorageLocationFileTypeEnum.OTHER:
      files =
        data?.storage_location_files.filter((file) => {
          return (
            file.storage_file_type !== StorageLocationFileTypeEnum.SURVEY_REPORT
          );
        }) ?? [];
      break;
    default:
      files = [];
      break;
  }

  const { mutate, isPending } = useUpdateStorageLocationFile({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['storageLocation', String(data.id)],
        });
      },
    },
  });

  const editButtonLabel =
    type === StorageLocationFileTypeEnum.SURVEY_REPORT
      ? 'Add Report'
      : 'Upload Documents';

  const isDisplayEditButton =
    (files.length === 0 &&
      type === StorageLocationFileTypeEnum.SURVEY_REPORT) ||
    type === StorageLocationFileTypeEnum.OTHER;

  const isDeletingFile =
    isPending ||
    queryClient.getQueryState(['storageLocation', String(data.id)])
      ?.isInvalidated;
  return (
    <StorageLocationDetailsSectionLayout
      title={
        <div className="w-full flex flex-row justify-between items-center">
          <Typography level="h4" customStyles="text-text-secondary">
            {title}
          </Typography>
          {isDisplayEditButton && (
            <LinkButton
              variant="secondary"
              width="auto"
              leftIcon={<FaPlus />}
              label={editButtonLabel}
              href={`/storage/update/files/${data.id}?type=${type}`}
            />
          )}
        </div>
      }
      isCollapsed={files.length === 0}
    >
      <div className="flex flex-col gap-y-2">
        {files.map(
          (file) =>
            file &&
            file.status === StorageLocationFileStatusEnum.ACTIVE && (
              <FilePreview
                key={file.id}
                title={file.file_name}
                url={file.signed_url}
                type={file.file_type}
                createdDate={file.created_time}
                expiryDate={file.expiry_date}
                isDeleting={isDeletingFile}
                onDelete={() =>
                  mutate({
                    storageLocationFileId: file.id.toString(),
                    locationId: data.id.toString(),
                  })
                }
              />
            ),
        )}
      </div>
    </StorageLocationDetailsSectionLayout>
  );
}
