import { Dialog, Typography } from '@breeze-ai/ui-library';
import { LinkButton } from '@breezeai-frontend/cargo-ui';

import { usePlatform } from '../../../../context/PlatformContext';
import { type Claim } from '../../../../model/Claim';

type ClaimSuccessDialogProps = {
  claim: Claim;
  visible?: boolean;
};

export const ClaimSuccessDialog = ({
  visible,
  claim,
}: ClaimSuccessDialogProps) => {
  const { isWtw } = usePlatform();
  return (
    <Dialog
      role="success-dialog"
      visible={visible}
      width={450}
      closable={false}
      maskClosable={false}
    >
      <Dialog.Icon icon="check" slot="icon" variant="success" />
      <Dialog.Title slot="title">
        <Typography level="h3">We've received your claim</Typography>
      </Dialog.Title>
      <Dialog.Content slot="content">
        <Typography block variant="secondary">
          Your claim number is {claim?.claim_number}.
        </Typography>
        {!isWtw && (
          <Typography block variant="secondary">
            Bear with us. We usually respond to claims within hours.
          </Typography>
        )}
      </Dialog.Content>
      <Dialog.Footer slot="footer">
        <LinkButton
          variant="secondary"
          width="fixed"
          href="/"
          label="Back to Home Page"
        />
        <LinkButton
          aria-details="view-claim-button"
          data-testid="view-claim-button"
          width="fixed"
          variant="primary"
          // TODO THEMING: Replace href with the enum
          href={`/claims/${claim.id}`}
          label="View Claim"
        />
      </Dialog.Footer>
    </Dialog>
  );
};
