import { type ErrorResponse } from 'react-router-dom';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

import { type NetworkResponse, post } from '../../../../network/apis/utils';
import { bffServiceUrl } from '../../../../network/netconfig';
import {
  type CreateStorageDeclarationPayload,
  type CreateStorageDeclarationResponse,
} from '../types';

export const useCreateStorageDeclaration = ({
  options,
}: {
  options?: UseMutationOptions<
    NetworkResponse<CreateStorageDeclarationResponse>,
    ErrorResponse,
    CreateStorageDeclarationPayload & { location_id: string }
  >;
}) => {
  return useMutation({
    mutationFn: (payload) => {
      const body = { ...payload, location_id: undefined };
      return post(
        `${bffServiceUrl}/bff-storage-declarations/storage-locations/${payload.location_id}/storage-declaration`,
        body,
      );
    },
    ...options,
  });
};
