import { type ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { type Typography } from '@breeze-ai/ui-library';
import {
  toPercentage,
  Typography as CargoTypography,
} from '@breezeai-frontend/cargo-ui';

import { usePlatform } from '../../../context/PlatformContext';
import { type SupportedCurrencies } from '../../../model/CurrencyValue';
import { type QuotePriceProperties } from '../../types/quote';
import { PremiumTotal } from './PremiumTotal/PremiumTotal';
import { PriceComponent } from './PriceComponent/PriceComponent';

export type PriceComponentTypographyProps = {
  title?: ComponentProps<typeof Typography>;
  currency?: ComponentProps<typeof Typography>;
};

export interface PremiumBreakdownProps extends QuotePriceProperties {
  premium_value: number;
  premium_currency: SupportedCurrencies;
  componentProps?: {
    components?: PriceComponentTypographyProps;
    total?: PriceComponentTypographyProps;
  };
}

export const PremiumBreakdown = ({
  componentProps,
  ...props
}: PremiumBreakdownProps) => {
  const {
    premium_value,
    premium_currency,
    premium_rate,
    premium_war_rate,
    tax,
    hide_tax_info,
  } = props;
  const { isWtw, platform } = usePlatform();
  const { t } = useTranslation();

  const premiumValue = hide_tax_info
    ? premium_value + (tax?.value ?? 0)
    : premium_value;

  return (
    <div className="flex flex-col gap-2 h-full" role="premium-breakdown">
      <div className="flex flex-col gap-2 mb-auto">
        <PriceComponent
          title={t('Premium', {
            ns: 'common',
            context: platform,
          })}
          name="premium"
          value={premiumValue}
          currency={premium_currency}
          typographyProps={componentProps?.components}
        />
        {isWtw && premium_rate && (
          <CargoTypography level="subtext" customStyles="flex justify-between">
            <span className="text-text-tertiary">
              {t('PremiumRate', {
                ns: 'common',
                context: platform,
              })}
            </span>
            <span>{toPercentage(premium_rate, 5)}</span>
          </CargoTypography>
        )}
        {!!premium_war_rate && (
          <CargoTypography level="subtext" customStyles="flex justify-between">
            <span className="text-text-tertiary">War Rate (%)</span>
            <span>{toPercentage(premium_war_rate, 6)}</span>
          </CargoTypography>
        )}
        {tax && tax?.value > 0 && !hide_tax_info && (
          <PriceComponent
            title="Taxes"
            name="tax"
            value={tax.value}
            currency={tax.currency_code}
            typographyProps={componentProps?.components}
          />
        )}
      </div>
      <PremiumTotal {...props} typographyProps={componentProps?.total} />
    </div>
  );
};
