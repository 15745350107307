import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';
import { FaDownload } from 'react-icons/fa6';
import {
  clsxMerge,
  ErrorFlag,
  LinkButton,
  Modal,
  type ModalProps,
  Typography,
} from '@breezeai-frontend/cargo-ui';

import { usePlatform } from '../../../../context/PlatformContext';
import { ExclusionReasons } from '../../../components/ExclusionReasons/ExclusionReasons';
import { type CreateStorageDeclarationResponse } from '../types';

const SuccessHeading = ({
  title,
  description,
}: {
  title: string;
  description: ReactNode;
}) => {
  return (
    <div className="flex items-center flex-col py-3 gap-3">
      <FaCheckCircle size={42} className="fill-system-green-500" />
      <Typography level="h2" customStyles="text-center">
        {title}
      </Typography>
      {typeof description === 'string' ? (
        <Typography color="secondary" customStyles="text-center w-[308px]">
          {description}
        </Typography>
      ) : (
        description
      )}
    </div>
  );
};

const Heading = ({
  status,
  certificate_url,
  location_name,
}: CreateStorageDeclarationResponse) => {
  const { t } = useTranslation();
  const { platform } = usePlatform();
  // TODO use https://github.com/gvergnaud/ts-pattern
  switch (status) {
    case 'issued':
      if (certificate_url) {
        return (
          <SuccessHeading
            title={t(
              'declarations.form.outcome.issued_with_certificate.Title',
              { ns: 'storage', context: platform },
            )}
            description={t(
              'declarations.form.outcome.issued_with_certificate.Description',
              { ns: 'storage', context: platform, locationName: location_name },
            )}
          />
        );
      }
      return (
        <SuccessHeading
          title={t('declarations.form.outcome.issued.Title', {
            ns: 'storage',
            context: platform,
          })}
          description={
            <Typography color="secondary" customStyles="text-center w-[308px]">
              {t('declarations.form.outcome.issued.Description', {
                ns: 'storage',
                context: platform,
                locationName: location_name,
              })}
            </Typography>
          }
        />
      );
    case 'pending_review':
      return (
        <div className="flex items-center flex-col py-3 border-b border-field-border-default space-y-4">
          <ErrorFlag className="size-36" />
          <Typography level="h3" customStyles="text-center">
            {t('declarations.form.outcome.pending_review.Title', {
              ns: 'storage',
              context: platform,
            })}
          </Typography>
          <Typography color="secondary" customStyles="text-center">
            {t('declarations.form.outcome.pending_review.Description', {
              ns: 'storage',
              context: platform,
            })}
          </Typography>
        </div>
      );
    default:
      return null;
  }
};

const Body = ({
  status,
  exclusions,
  certificate_url,
  showViewLocationDetailsButton,
  location_id,
}: CreateStorageDeclarationResponse & {
  showViewLocationDetailsButton?: boolean;
}) => {
  const { t } = useTranslation();
  const { platform } = usePlatform();
  switch (status) {
    case 'issued':
      if (certificate_url) {
        return (
          <div className="pt-8 w-full flex gap-4 flex-col items-center">
            <div className="flex flex-1 gap-4 w-full">
              <LinkButton
                label={t('declarations.form.outcome.Download', {
                  ns: 'storage',
                  context: platform,
                })}
                variant="secondary"
                href={certificate_url}
                target="_blank"
                download
                width="full"
                leftIcon={<FaDownload />}
              />
              <LinkButton
                label={t('declarations.form.outcome.CreateNewDeclaration', {
                  ns: 'storage',
                  context: platform,
                })}
                variant="primary"
                href={`/storage/declarations/create/${location_id}`}
                width="full"
              />
            </div>
            {showViewLocationDetailsButton && (
              <LinkButton
                label={t('declarations.form.outcome.ViewLocationDetails', {
                  ns: 'storage',
                  context: platform,
                })}
                variant="ghost"
                href={`/storage/details/${location_id}`}
                width="full"
              />
            )}
          </div>
        );
      }
      return (
        <div className="flex gap-4 w-full pt-8">
          {/* TODO remove reversed condition */}
          {showViewLocationDetailsButton && (
            <LinkButton
              label={t('declarations.form.outcome.ViewLocationDetails', {
                ns: 'storage',
                context: platform,
              })}
              variant="secondary"
              href={`/storage/details/${location_id}`}
              width="full"
            />
          )}
          <LinkButton
            label={t('declarations.form.outcome.CreateNewDeclaration', {
              ns: 'storage',
              context: platform,
            })}
            variant="primary"
            href={`/storage/declarations/create/${location_id}`}
            width="full"
          />
        </div>
      );
    case 'pending_review':
      return (
        <ExclusionReasons
          reasons={exclusions ?? []}
          title={
            <Typography customStyles="text-center pt-4">
              {t('declarations.form.outcome.pending_review.Exclusions', {
                ns: 'storage',
                context: platform,
              })}
            </Typography>
          }
        />
      );
    default:
      return null;
  }
};

export function DeclarationCreationOutcomeModal({
  id,
  exclusions,
  certificate_url,
  status,
  location_name,
  location_id,
  showViewLocationDetailsButton,
  ...props
}: Omit<ModalProps, 'children'> &
  CreateStorageDeclarationResponse & {
    showViewLocationDetailsButton?: boolean;
  }) {
  return (
    <Modal
      {...props}
      // min h to prevent modal from shrinking when closed and route state is cleared
      className={clsxMerge('max-w-[480px]', !status && 'min-h-64')}
      showExitButton
      isDismissable
      heading={
        <Heading
          {...{
            id,
            status,
            exclusions,
            certificate_url,
            location_id,
            location_name,
          }}
        />
      }
    >
      <Body
        {...{
          id,
          status,
          exclusions,
          certificate_url,
          location_id,
          location_name,
          showViewLocationDetailsButton,
        }}
      />
    </Modal>
  );
}
