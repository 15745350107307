import { useTranslation } from 'react-i18next';

import { useFeatureToggle, useUser } from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import { FlowSteps } from '../../constants';

// TODO(https://breeze.fibery.io/Breeze/Task/Implement-Sealink-copy-using-internationalization-solution-2899)
// Remove this once labels are moved to internationalization solution.
/**
 *
 * @param step the current step in the Policy creation flow
 * @returns the CTA text for policy creation, or empty string otherwise
 */
export const usePolicyCtaText = (step: FlowSteps) => {
  const user = useUser();
  const enableDraftCertificate = useFeatureToggle('enable_draft_certificate');
  const { t } = useTranslation();
  const { platform } = usePlatform();

  const enableReferPolicyFlow = user?.settings.find(
    (s) => s.name === 'enable_refer_a_policy_flow',
  )?.value;

  const create = t('form.summary.CreatePolicy', {
    ns: 'quotes',
    context: platform,
  });
  const issue = t('form.summary.IssuePolicy', {
    ns: 'quotes',
    context: platform,
  });
  const refer = t('form.summary.ReferPolicy', {
    ns: 'quotes',
    context: platform,
  });
  const referA = t('form.summary.ReferAPolicy', {
    ns: 'quotes',
    context: platform,
  });

  switch (step) {
    case FlowSteps.QUOTE_SUMMARY: {
      if (enableDraftCertificate) {
        return 'Issue Draft & Policy';
      }

      return enableReferPolicyFlow ? referA : issue;
    }
    case FlowSteps.SHIPMENT_INFORMATION: {
      if (enableDraftCertificate) {
        return 'Create Draft & Policy';
      }

      return enableReferPolicyFlow ? refer : create;
    }
    default: {
      return '';
    }
  }
};
