import { get, post } from '../../AuthRequests';
import { bffServiceUrl } from '../../netconfig';
import {
  type FilterableFields,
  type PaginationBaseQueryParams,
} from '../types';
import { normalizeQuoteResponse } from './normalizers';
import {
  type CoverageDescription,
  type GetQuotesNormalizedResponse,
  type GetQuotesResponse,
  type QuotesSortableFields as QuotesSortableFields,
} from './types';

export const getQuotes = async (
  params?: PaginationBaseQueryParams<QuotesSortableFields, FilterableFields>,
): Promise<GetQuotesNormalizedResponse> => {
  const { data } = await post<GetQuotesResponse>(
    `${bffServiceUrl}/bff_quotes/get`,
    params,
  );

  return {
    ...data,
    quotes: data.quotes.map(normalizeQuoteResponse),
  };
};

export const getQuoteConditions = async (
  quoteId: number | string,
): Promise<CoverageDescription[]> => {
  const { data } = await get<CoverageDescription[]>(
    `${bffServiceUrl}/bff_quotes/${quoteId}/conditions`,
  );

  return data;
};
