import { useTranslation } from 'react-i18next';
import { FaCircleInfo } from 'react-icons/fa6';
import { Button, Tooltip, Typography } from '@breezeai-frontend/cargo-ui';
import { useQuery } from '@tanstack/react-query';

import { useAuthenticatedUser } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import { distributorQueries } from '../../../network/apis/distributors/distributors';

function Trigger() {
  const { t } = useTranslation();
  const { platform } = usePlatform();

  const user = useAuthenticatedUser();

  const { isLoading } = useQuery(
    distributorQueries.activeOpenCover({
      distributorId: user.distributor.id,
    }),
  );

  return (
    <div className="flex items-center gap-1 text-system-blue-600">
      <Typography
        customStyles="whitespace-nowrap"
        id="active-open-cover-tooltip-trigger"
      >
        {t('openCoverTooltip.Title', { ns: 'common', context: platform })}
      </Typography>
      <Button
        aria-labelledby="active-open-cover-tooltip-trigger"
        data-testid="title-active-open-cover-tooltip-btn"
        leftIcon={<FaCircleInfo />}
        variant="icon"
        isLoading={isLoading}
      />
    </div>
  );
}

function Content() {
  const { platform } = usePlatform();
  const { t } = useTranslation();

  const user = useAuthenticatedUser();
  const { data, error } = useQuery(
    distributorQueries.activeOpenCover({
      distributorId: user.distributor.id,
    }),
  );

  return (
    <Tooltip
      placement="bottom"
      className="bg-system-grey-900 p-4 rounded-md min-w-[300px] flex flex-col gap-2"
    >
      <div className="flex justify-between items-center text-white gap-3">
        <Typography text="bold">{user.distributor.legal_name}</Typography>
        {data?.underwriting_year && (
          <Typography customStyles="bg-white text-black px-2 py-1 rounded-md text-center text-sm">
            {data.underwriting_year}
          </Typography>
        )}
      </div>
      {error && (
        <Typography customStyles="text-white">
          {t('NoActiveOpenCoverDescription', {
            ns: 'common',
            context: platform,
          })}
        </Typography>
      )}
      {!error && data && (
        <div className="grid gap-3 grid-cols-[70px_1fr] text-white">
          <Typography customStyles="text-system-grey-200">Policy ID</Typography>
          <Typography text="bold">{data.open_cover_policy_number}</Typography>

          <Typography customStyles="text-system-grey-200">
            Valid from
          </Typography>
          <div className="flex gap-3">
            <Typography text="bold">{data.inception_date}</Typography>
            <Typography customStyles="text-system-grey-200">to</Typography>
            <Typography text="bold">{data.termination_date}</Typography>
          </div>
        </div>
      )}
    </Tooltip>
  );
}

export const ActiveOpenCoverTooltip = {
  Trigger,
  Content,
};
