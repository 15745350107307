import { useMemo } from 'react';
import { FaWarehouse } from 'react-icons/fa';
import { FaHouseFloodWater } from 'react-icons/fa6';
import { PiFarm } from 'react-icons/pi';

import {
  FilterableFields,
  FilterOperators,
} from '../../../../../../network/apis/types';
import { useFilterStore } from '../../../../../components/TableFilters';
import { MultiSelectRadioDropdown } from '../../../../../components/TableFilters/components/MultiSelectRadioDropdown';
import { StorageLocationTypeEnum } from '../../../types';

const storageTypeOptions = [
  {
    label: 'Warehouse',
    value: StorageLocationTypeEnum.WAREHOUSE,
    prefix: <FaWarehouse className="size-4 fill-icons-default" />,
  },
  {
    label: 'Floating',
    value: StorageLocationTypeEnum.FLOATING,
    prefix: <FaHouseFloodWater className="size-4 fill-icons-default" />,
  },
  {
    label: 'Open Yard',
    value: StorageLocationTypeEnum.OPEN_YARD,
    prefix: <PiFarm className="size-4 fill-icons-default" />,
  },
];

export function StorageTypeFilter() {
  const {
    setSelectedLocationTypes,
    selectedLocationTypes,
    updateFilter,
    removeFilter,
    filters,
  } = useFilterStore((state) => ({
    setSelectedLocationTypes: state.setSelectedLocationTypes,
    selectedLocationTypes: state.selectedLocationTypes,
    updateFilter: state.updateFilter,
    removeFilter: state.removeFilter,
    filters: state.filters.find(
      (filter) => filter.field === FilterableFields.LOCATION_TYPE,
    )?.values,
  }));

  const onSubmit = () =>
    updateFilter({
      field: FilterableFields.LOCATION_TYPE,
      operator: FilterOperators.EQUALS,
      values: selectedLocationTypes as string[],
    });

  const onReset = () => removeFilter(FilterableFields.LOCATION_TYPE);

  const appliedOptions = useMemo(
    () =>
      storageTypeOptions?.filter((option) => filters?.includes(option.value)) ??
      [],
    [filters],
  );

  const nonAppliedOptions = useMemo(
    () =>
      storageTypeOptions?.filter(
        (option) => !filters?.includes(option.value),
      ) ?? [],
    [filters],
  );

  return (
    <MultiSelectRadioDropdown
      appliedOptions={appliedOptions}
      optionsCount={filters?.length}
      selected={selectedLocationTypes as string[]}
      setSelected={setSelectedLocationTypes as (selected: string[]) => void}
      name="storageType"
      label="Type"
      dropdownIcon="location-dot"
      nonAppliedOptions={nonAppliedOptions}
      onSubmit={onSubmit}
      onReset={onReset}
      triggerTestId="storage-location-type-filter-trigger"
      contentTestId="storage-location-type-filter-content"
    />
  );
}
