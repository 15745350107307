import { splitFileName } from '@breeze-ai/ui-library';

import { post } from '../../network/AuthRequests';
import { bffServiceUrl } from '../../network/netconfig';
import {
  type StorageLocationFilePayload,
  type StorageLocationFileTypeEnum,
  SupportedFileTypes,
} from './locations/types';

export const isSupportedFileType = (
  fileType: string,
): fileType is SupportedFileTypes =>
  Object.values(SupportedFileTypes).includes(fileType as SupportedFileTypes);

const fileContentToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve((reader.result as string).split('base64,')[1]);
    reader.onerror = reject;
  });

export const uploadStorageLocationFile = async ({
  locationId,
  file,
  documentType,
  surveyReportExpiryDate,
}: {
  locationId: string;
  file: File;
  documentType: StorageLocationFileTypeEnum;
  surveyReportExpiryDate?: ISODate;
}) => {
  const fileType = splitFileName(file)[1];
  if (!isSupportedFileType(fileType)) {
    throw new Error(`Unsupported file type: ${fileType}`);
  }
  const base64Content = await fileContentToBase64(file);
  const payload: StorageLocationFilePayload = {
    name: file.name,
    type: documentType,
    size_in_bytes: file.size,
    content: base64Content,
    file_type: fileType,
    data_type: file.type,
    expiry_date: surveyReportExpiryDate,
  };
  const { data } = await post(
    `${bffServiceUrl}/bff-storage-locations/${locationId}/files`,
    payload,
  );
  return data;
};
