import { type IconType } from 'react-icons';
import { BsClockHistory } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';
import { IoIosWarning } from 'react-icons/io';
import { PiArchiveFill } from 'react-icons/pi';
import { type Badge } from '@breezeai-frontend/cargo-ui';

import { type StorageDeclarationStatus } from '../../types';

export type DeclarationStatusToBadge = Record<
  StorageDeclarationStatus | 'unknown',
  {
    label: string;
    variant?: React.ComponentProps<typeof Badge>['variant'];
    Icon?: IconType;
  }
>;

export const statusToBadge: DeclarationStatusToBadge = {
  issued: { label: 'Issued', variant: 'success', Icon: FaCheck },
  pending_review: {
    label: 'In Review',
    variant: 'warning',
    Icon: BsClockHistory,
  },
  declined: {
    label: 'Declined',
    variant: 'error',
    Icon: IoIosWarning,
  },
  unknown: { label: 'Unknown' },
  archived: { label: 'Archived', variant: 'default', Icon: PiArchiveFill },
};
