import {
  BffGetConfigurationsResponseCoveragePackage,
  type BffQuoteModel,
  BffQuoteModelDivisionEnum,
  type BffQuoteModelWithPermissions,
} from '../generated/api-client';
import {
  type QuoteStatusEnum,
  type QuoteSubmissionSource,
} from '../network/apis/quotes/types';
import { type AccountUser } from '../network/apis/users/types';
import { type AdditionalClause } from './AdditionalClause';
import {
  type CurrencyDefinition,
  type CurrencyExchangeRate,
  type CurrencyValue,
  type SupportedCurrencies,
} from './CurrencyValue';
import { type Customer } from './Customer';
import { type Distributor } from './Distributor';
import { type OpenCover } from './OpenCover';
import { type Place, type PlaceModel } from './Place';
import { type Port } from './Port';
import {
  type ContainerModeEnum,
  type ConveyanceType,
  type ShipmentSpecialCondition,
} from './Shipment';
import { type UserPermissions } from './User';

export interface PremiumTax extends CurrencyValue {
  description: string;
}

export type ExclusionReason = {
  name: string;
  value: string;
};

// Export aliases for value and type - original type is too long
export const CoveragePackage = BffGetConfigurationsResponseCoveragePackage;
export type CoveragePackageType = BffGetConfigurationsResponseCoveragePackage;

export const Division = BffQuoteModelDivisionEnum;
export type DivisionType = BffQuoteModelDivisionEnum;

export const isDivision = (value: string): value is DivisionType => {
  return Boolean(
    Object.values(Division).find((division) => division === value),
  );
};

// TODO: This type is used in normalizers. Remove normalizers and type to use
// the generated types.
type QuoteOverrides = {
  id: number;
  premium_value?: number;
  premium_currency?: SupportedCurrencies;
  premium_rate?: number;
  premium_war_rate?: BffQuoteModel['premium_war_rate'];
  tax?: PremiumTax;
  converted_customer_premium_value?: number;
  converted_tax_amount?: number;
  exchange_rate?: CurrencyExchangeRate;
  distributor_id?: number;
  primary_transport_mode_code?: ConveyanceType;
  transport_mode_display_name?: string;
  primary_transport_mode_code_display_name?: string;
  created_time: ISODateTZ;
  distributor?: Distributor;
  customer?: Customer;
  status?: QuoteStatusEnum;
  active_policy_id?: number;
  submission_source?: QuoteSubmissionSource;
  coverage_package?: CoveragePackageType;
  secondary_transport_mode_code?: ConveyanceType;
  loading_place?: PlaceModel;
  loading_transport_mode_code?: ConveyanceType;
  origin_place?: Place;
  origin_port?: Port;
  destination_place?: Place;
  destination_port?: Port;
  origin_port_code?: string;
  destination_port_code?: string;
  delivery_place?: PlaceModel;
  delivery_transport_mode_code?: ConveyanceType;
  insured_value?: number;
  insured_value_currency?: SupportedCurrencies;
  insurer_premium_value?: number;
  insurer_premium_currency?: SupportedCurrencies;
  distributor_net_cost_value?: BffQuoteModel['distributor_net_cost_value'];
  distributor_net_cost_currency?: CurrencyDefinition;
  deductible_value?: number;
  deductible_currency?: SupportedCurrencies;
  commodity_value?: number;
  commodity_currency?: SupportedCurrencies;
  commodity_category_id?: number;
  commodity_category?: string;
  commodity_external_description?: string;
  eta?: ISODate;
  etd?: ISODate;
  issue_date?: ISODate;
  external_reference?: string;
  vessel_name?: string;
  incoterm_code?: string;
  container_ids?: string[];
  special_conditions?: ShipmentSpecialCondition[];
  container_mode?: ContainerModeEnum;
  freight_cost?: number;
  freight_cost_currency?: SupportedCurrencies;
  duty_cost?: number;
  duty_cost_currency?: SupportedCurrencies;
  created_by_user?: AccountUser;
  expiring_on?: ISODate;
  goods_marks?: string;
  exclusion_reasons?: ExclusionReason[];
  quote_additional_clauses?: AdditionalClause[];
  letter_of_credit?: string;
  open_cover?: OpenCover;
  uplift_percentage: number;
  division?: DivisionType;
  // TODO in reality this is not optional - once openapi client is generated upon permissions release, this should be removed
  permissions?: UserPermissions['quotes'];
  policy_permissions?: UserPermissions['policies'];
  vessel?: BffQuoteModel['vessel'];
  profit_center?: BffQuoteModel['profit_center'];
};

export type Quote = Omit<BffQuoteModelWithPermissions, keyof QuoteOverrides> &
  QuoteOverrides;
