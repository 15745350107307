import { clsxMerge } from '../../components/common/utils/classNameUtils';
import { Typography } from '../../components/Typography/Typography';

type Item = {
  label: string;
  value?: string;
};
interface TotalPriceBannerProps extends React.HTMLAttributes<HTMLDivElement> {
  price: Item;
  priceBreakdownTooltip?: React.ReactNode;
  premiumRate?: Item;
  premiumWarRate?: number;
  netCost?: string;
}

export function TotalPriceBanner({
  price,
  priceBreakdownTooltip,
  netCost,
  premiumRate,
  premiumWarRate,
  className,
  ...props
}: TotalPriceBannerProps) {
  return (
    <div
      className={clsxMerge(
        'w-full flex space-between bg-system-grey-50 rounded-3xl py-9 px-6 justify-between items-center min-w-64',
        className,
      )}
      {...props}
    >
      <div className="flex flex-col gap-2 items-start">
        <div className="flex gap-3 items-center">
          <Typography customStyles="text-[22px] font-bold leading-5 text-system-grey-850 whitespace-nowrap">
            {price.label}
          </Typography>
          {priceBreakdownTooltip}
        </div>
        {!!premiumRate?.value && (
          <Typography level="subtext" color="secondary" text="italic">
            {premiumRate.label} {premiumRate.value}
          </Typography>
        )}
        {!!premiumWarRate && (
          <Typography level="subtext" color="secondary" text="italic">
            War rate(%) {premiumWarRate}
          </Typography>
        )}
        {!!netCost && (
          <Typography level="subtext" color="secondary" text="italic">
            Net Cost: {netCost}
          </Typography>
        )}
      </div>
      <Typography customStyles="text-[41px] font-bold leading-8 text-quote-total-price-text">
        {price.value}
      </Typography>
    </div>
  );
}
