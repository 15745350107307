import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BsClockHistory } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa6';
import { IoIosWarning } from 'react-icons/io';
import { PiArchiveFill } from 'react-icons/pi';

import { usePlatform } from '../../../../../context/PlatformContext';
import {
  FilterableFields,
  FilterOperators,
} from '../../../../../network/apis/types';
import { StatusBadge } from '../../../../components/StatusBadge/StatusBadge';
import { useFilterStore } from '../../../../components/TableFilters';
import { MultiSelectRadioDropdown } from '../../../../components/TableFilters/components/MultiSelectRadioDropdown';
import { StatusEnum } from '../../../types';

export enum StatusFilterTypeEnum {
  CERTIFICATE = 'certificate',
  QUOTE = 'quote',
}

interface StatusFilterProps {
  type: StatusFilterTypeEnum;
}

export function StatusFilter({ type }: StatusFilterProps) {
  const { t } = useTranslation();
  const { platform } = usePlatform();
  const statusOptions = [
    {
      label: (
        <StatusBadge
          variant="success"
          Icon={FaCheck}
          label={t('tables.filters.status.Ready', {
            ns: 'common',
            context: platform,
          })}
        />
      ),
      value: StatusEnum.READY,
      labelHtmlFor: StatusEnum.READY,
    },
    {
      label: (
        <StatusBadge
          variant="success"
          Icon={FaCheck}
          label={t('tables.filters.status.Issued', {
            ns: 'common',
            context: platform,
          })}
        />
      ),
      value: StatusEnum.ISSUED,
      labelHtmlFor: StatusEnum.ISSUED,
    },
    {
      label: (
        <StatusBadge
          variant="warning"
          Icon={BsClockHistory}
          label={t('tables.filters.status.InReview', {
            ns: 'common',
            context: platform,
          })}
        />
      ),
      value: StatusEnum.IN_REVIEW,
      labelHtmlFor: StatusEnum.IN_REVIEW,
    },
    {
      label: (
        <StatusBadge
          variant="error"
          Icon={IoIosWarning}
          label={t('tables.filters.status.Declined', {
            ns: 'common',
            context: platform,
          })}
        />
      ),
      value: StatusEnum.DECLINED,
      labelHtmlFor: StatusEnum.DECLINED,
    },
    {
      label: (
        <StatusBadge
          variant="default"
          Icon={PiArchiveFill}
          label={t('tables.filters.status.Archived', {
            ns: 'common',
            context: platform,
          })}
        />
      ),
      value: StatusEnum.ARCHIVED,
      labelHtmlFor: StatusEnum.ARCHIVED,
    },
  ];

  const {
    setSelectedCertificateStatuses,
    selectedCertificateStatuses,
    updateFilter,
    removeFilter,
    filters,
  } = useFilterStore((state) => ({
    setSelectedCertificateStatuses: state.setSelectedStatuses,
    selectedCertificateStatuses: state.selectedStatuses,
    updateFilter: state.updateFilter,
    removeFilter: state.removeFilter,
    filters: state.filters.find(
      (filter) => filter.field === FilterableFields.STATUS,
    )?.values,
  }));

  const adjustedFilterOptions = statusOptions.filter((option) => {
    if (type === StatusFilterTypeEnum.CERTIFICATE) {
      return option.value !== StatusEnum.READY;
    } else {
      return option.value !== StatusEnum.ISSUED;
    }
  });

  const onSubmit = () =>
    updateFilter({
      field: FilterableFields.STATUS,
      operator: FilterOperators.EQUALS,
      values: selectedCertificateStatuses as string[],
    });

  const onReset = () => removeFilter(FilterableFields.STATUS);

  const appliedOptions = useMemo(
    () =>
      adjustedFilterOptions?.filter((option) =>
        filters?.includes(option.value),
      ) ?? [],
    [filters, adjustedFilterOptions],
  );

  const nonAppliedOptions = useMemo(
    () =>
      adjustedFilterOptions?.filter(
        (option) => !filters?.includes(option.value),
      ) ?? [],
    [filters, adjustedFilterOptions],
  );

  return (
    <MultiSelectRadioDropdown
      appliedOptions={appliedOptions}
      optionsCount={filters?.length}
      selected={selectedCertificateStatuses as string[]}
      setSelected={
        setSelectedCertificateStatuses as (selected: string[]) => void
      }
      name="certificateStatus"
      label={t('Status', {
        ns: 'common',
        context: platform,
      })}
      dropdownIcon="circle-half-stroke"
      nonAppliedOptions={nonAppliedOptions}
      onSubmit={onSubmit}
      onReset={onReset}
      triggerTestId="certificate-status-filter-trigger"
      contentTestId="certificate-status-filter-content"
    />
  );
}
