/* tslint:disable */
/* eslint-disable */
/**
 * Service API Document
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { BffCertificateModelWithPermissions } from '../models';
// @ts-ignore
import type { BffCreateCertificateOnlyBody } from '../models';
// @ts-ignore
import type { BffGetCertificateOnlyResponse } from '../models';
// @ts-ignore
import type { BffGetCertificatesOnlyRequest } from '../models';
// @ts-ignore
import type { BffUpdateCertificateOnlyRequest } from '../models';
// @ts-ignore
import type { BffValidationErrorValidationErrorElement } from '../models';
/**
 * CertificateOnlyApi - axios parameter creator
 * @export
 */
export const CertificateOnlyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create_certificate_only <POST>
         * @param {BffCreateCertificateOnlyBody} [bffCreateCertificateOnlyBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCertificateOnly: async (bffCreateCertificateOnlyBody?: BffCreateCertificateOnlyBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bff-certificate-only/certificate-only`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bffCreateCertificateOnlyBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get_certificate <GET>
         * @param {number} certificateOnlyId 
         * @param {BffGetCertificatesOnlyRequest} [bffGetCertificatesOnlyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificate: async (certificateOnlyId: number, bffGetCertificatesOnlyRequest?: BffGetCertificatesOnlyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'certificateOnlyId' is not null or undefined
            assertParamExists('getCertificate', 'certificateOnlyId', certificateOnlyId)
            const localVarPath = `/bff-certificate-only/{certificate_only_id}`
                .replace(`{${"certificate_only_id"}}`, encodeURIComponent(String(certificateOnlyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bffGetCertificatesOnlyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get_certificates_only <POST>
         * @param {BffGetCertificatesOnlyRequest} [bffGetCertificatesOnlyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificatesOnly: async (bffGetCertificatesOnlyRequest?: BffGetCertificatesOnlyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bff-certificate-only/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bffGetCertificatesOnlyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update_certificate_only_policy <PUT>
         * @param {BffUpdateCertificateOnlyRequest} [bffUpdateCertificateOnlyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCertificateOnlyPolicy: async (bffUpdateCertificateOnlyRequest?: BffUpdateCertificateOnlyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bff-certificate-only/certificate-only`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bffUpdateCertificateOnlyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CertificateOnlyApi - functional programming interface
 * @export
 */
export const CertificateOnlyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CertificateOnlyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create_certificate_only <POST>
         * @param {BffCreateCertificateOnlyBody} [bffCreateCertificateOnlyBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCertificateOnly(bffCreateCertificateOnlyBody?: BffCreateCertificateOnlyBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BffCertificateModelWithPermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCertificateOnly(bffCreateCertificateOnlyBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CertificateOnlyApi.createCertificateOnly']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get_certificate <GET>
         * @param {number} certificateOnlyId 
         * @param {BffGetCertificatesOnlyRequest} [bffGetCertificatesOnlyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCertificate(certificateOnlyId: number, bffGetCertificatesOnlyRequest?: BffGetCertificatesOnlyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BffCertificateModelWithPermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCertificate(certificateOnlyId, bffGetCertificatesOnlyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CertificateOnlyApi.getCertificate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get_certificates_only <POST>
         * @param {BffGetCertificatesOnlyRequest} [bffGetCertificatesOnlyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCertificatesOnly(bffGetCertificatesOnlyRequest?: BffGetCertificatesOnlyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BffGetCertificateOnlyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCertificatesOnly(bffGetCertificatesOnlyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CertificateOnlyApi.getCertificatesOnly']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update_certificate_only_policy <PUT>
         * @param {BffUpdateCertificateOnlyRequest} [bffUpdateCertificateOnlyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCertificateOnlyPolicy(bffUpdateCertificateOnlyRequest?: BffUpdateCertificateOnlyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BffCertificateModelWithPermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCertificateOnlyPolicy(bffUpdateCertificateOnlyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CertificateOnlyApi.updateCertificateOnlyPolicy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CertificateOnlyApi - factory interface
 * @export
 */
export const CertificateOnlyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CertificateOnlyApiFp(configuration)
    return {
        /**
         * 
         * @summary create_certificate_only <POST>
         * @param {CertificateOnlyApiCreateCertificateOnlyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCertificateOnly(requestParameters: CertificateOnlyApiCreateCertificateOnlyRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BffCertificateModelWithPermissions> {
            return localVarFp.createCertificateOnly(requestParameters.bffCreateCertificateOnlyBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get_certificate <GET>
         * @param {CertificateOnlyApiGetCertificateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificate(requestParameters: CertificateOnlyApiGetCertificateRequest, options?: RawAxiosRequestConfig): AxiosPromise<BffCertificateModelWithPermissions> {
            return localVarFp.getCertificate(requestParameters.certificateOnlyId, requestParameters.bffGetCertificatesOnlyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get_certificates_only <POST>
         * @param {CertificateOnlyApiGetCertificatesOnlyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificatesOnly(requestParameters: CertificateOnlyApiGetCertificatesOnlyRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BffGetCertificateOnlyResponse> {
            return localVarFp.getCertificatesOnly(requestParameters.bffGetCertificatesOnlyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update_certificate_only_policy <PUT>
         * @param {CertificateOnlyApiUpdateCertificateOnlyPolicyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCertificateOnlyPolicy(requestParameters: CertificateOnlyApiUpdateCertificateOnlyPolicyRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BffCertificateModelWithPermissions> {
            return localVarFp.updateCertificateOnlyPolicy(requestParameters.bffUpdateCertificateOnlyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCertificateOnly operation in CertificateOnlyApi.
 * @export
 * @interface CertificateOnlyApiCreateCertificateOnlyRequest
 */
export interface CertificateOnlyApiCreateCertificateOnlyRequest {
    /**
     * 
     * @type {BffCreateCertificateOnlyBody}
     * @memberof CertificateOnlyApiCreateCertificateOnly
     */
    readonly bffCreateCertificateOnlyBody?: BffCreateCertificateOnlyBody
}

/**
 * Request parameters for getCertificate operation in CertificateOnlyApi.
 * @export
 * @interface CertificateOnlyApiGetCertificateRequest
 */
export interface CertificateOnlyApiGetCertificateRequest {
    /**
     * 
     * @type {number}
     * @memberof CertificateOnlyApiGetCertificate
     */
    readonly certificateOnlyId: number

    /**
     * 
     * @type {BffGetCertificatesOnlyRequest}
     * @memberof CertificateOnlyApiGetCertificate
     */
    readonly bffGetCertificatesOnlyRequest?: BffGetCertificatesOnlyRequest
}

/**
 * Request parameters for getCertificatesOnly operation in CertificateOnlyApi.
 * @export
 * @interface CertificateOnlyApiGetCertificatesOnlyRequest
 */
export interface CertificateOnlyApiGetCertificatesOnlyRequest {
    /**
     * 
     * @type {BffGetCertificatesOnlyRequest}
     * @memberof CertificateOnlyApiGetCertificatesOnly
     */
    readonly bffGetCertificatesOnlyRequest?: BffGetCertificatesOnlyRequest
}

/**
 * Request parameters for updateCertificateOnlyPolicy operation in CertificateOnlyApi.
 * @export
 * @interface CertificateOnlyApiUpdateCertificateOnlyPolicyRequest
 */
export interface CertificateOnlyApiUpdateCertificateOnlyPolicyRequest {
    /**
     * 
     * @type {BffUpdateCertificateOnlyRequest}
     * @memberof CertificateOnlyApiUpdateCertificateOnlyPolicy
     */
    readonly bffUpdateCertificateOnlyRequest?: BffUpdateCertificateOnlyRequest
}

/**
 * CertificateOnlyApi - object-oriented interface
 * @export
 * @class CertificateOnlyApi
 * @extends {BaseAPI}
 */
export class CertificateOnlyApi extends BaseAPI {
    /**
     * 
     * @summary create_certificate_only <POST>
     * @param {CertificateOnlyApiCreateCertificateOnlyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateOnlyApi
     */
    public createCertificateOnly(requestParameters: CertificateOnlyApiCreateCertificateOnlyRequest = {}, options?: RawAxiosRequestConfig) {
        return CertificateOnlyApiFp(this.configuration).createCertificateOnly(requestParameters.bffCreateCertificateOnlyBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get_certificate <GET>
     * @param {CertificateOnlyApiGetCertificateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateOnlyApi
     */
    public getCertificate(requestParameters: CertificateOnlyApiGetCertificateRequest, options?: RawAxiosRequestConfig) {
        return CertificateOnlyApiFp(this.configuration).getCertificate(requestParameters.certificateOnlyId, requestParameters.bffGetCertificatesOnlyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get_certificates_only <POST>
     * @param {CertificateOnlyApiGetCertificatesOnlyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateOnlyApi
     */
    public getCertificatesOnly(requestParameters: CertificateOnlyApiGetCertificatesOnlyRequest = {}, options?: RawAxiosRequestConfig) {
        return CertificateOnlyApiFp(this.configuration).getCertificatesOnly(requestParameters.bffGetCertificatesOnlyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update_certificate_only_policy <PUT>
     * @param {CertificateOnlyApiUpdateCertificateOnlyPolicyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateOnlyApi
     */
    public updateCertificateOnlyPolicy(requestParameters: CertificateOnlyApiUpdateCertificateOnlyPolicyRequest = {}, options?: RawAxiosRequestConfig) {
        return CertificateOnlyApiFp(this.configuration).updateCertificateOnlyPolicy(requestParameters.bffUpdateCertificateOnlyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

