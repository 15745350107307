import { useTranslation } from 'react-i18next';
import { FaInfoCircle, FaShieldAlt, FaUser } from 'react-icons/fa';
import { FaMoneyBills, FaShirt } from 'react-icons/fa6';
import {
  CurrencyCell,
  Typography as BreezeUITypography,
} from '@breeze-ai/ui-library';
import {
  Button,
  Tooltip,
  TooltipTriggerWrapper,
  toPercentage,
} from '@breezeai-frontend/cargo-ui';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useUserSetting } from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import { useRouteParams } from '../../../../router/router-hooks';
import { getTheme } from '../../../../utils/getTheme';
import { AttributesGrid } from '../../../components/AttributesGrid/AttributesGrid';
import { InfoItem } from '../../../components/InfoItem/InfoItem';
import { PageSection } from '../../../components/PageSection/PageSection';
import { COVERAGE_PACKAGES } from '../../../constants';
import { useTranslationContext } from '../../../hooks/useTranslationContext';
import { policiesQueries } from '../../network/queries';
import { type PolicyRouteParams } from '../PolicyDetailsPage';

type PolicyInsuranceDetailsProps = {
  className?: string;
};

const isWtw = getTheme() === 'wtw';

export const PolicyInsuranceDetails = ({
  className,
}: PolicyInsuranceDetailsProps) => {
  const showDutyCost = useUserSetting<boolean>('enable_duty_cost') ?? false;
  const showFreightCostField =
    useUserSetting<boolean>('enable_freight_cost') ?? false;
  const { platform } = usePlatform();
  const { t } = useTranslation();
  const tContext = useTranslationContext();
  const { params } = useRouteParams<PolicyRouteParams>();
  const { policyId } = params;
  const { data: policy } = useSuspenseQuery(
    policiesQueries.details({ policyId }),
  );
  const {
    data: { coverage_options },
  } = useSuspenseQuery(policiesQueries.policyFormConfiguration());

  const {
    commodity_value,
    commodity_currency,
    insured_value,
    insured_value_currency,
    freight_cost,
    freight_cost_currency,
    duty_cost,
    duty_cost_currency,
    commodity_external_description,
    coverage_package,
    uplift_percentage,
  } = policy?.quote ?? {};

  const insuredValueTooltipContent = `Insured Value is ${
    isWtw ? 'Commercial Invoice Value' : 'Cargo Value & Freight Cost'
  } + ${toPercentage(uplift_percentage ?? 0.1)}%`;

  return (
    <PageSection
      title={t('details.InsuranceDetails', {
        ns: 'policies',
        context: platform,
      })}
      className={className}
      data-testid="insurance-details"
    >
      <AttributesGrid>
        <InfoItem
          title={t('Distributor', {
            ns: 'common',
            context: tContext,
          })}
          value={policy?.quote?.distributor?.legal_name}
          testId="shipment-distributor"
          Icon={FaUser}
        />
        <InfoItem
          title={t('CargoOwner', {
            ns: 'common',
            context: tContext,
          })}
          value={policy?.customer?.company_name}
          testId="shipment-cargo-owner"
          Icon={FaUser}
        />
        {coverage_options.length > 1 && coverage_package && (
          <InfoItem
            title={t('CoverageType', {
              ns: 'common',
              context: platform,
            })}
            value={COVERAGE_PACKAGES[coverage_package].label}
            testId="coverage-package"
            Icon={FaShieldAlt}
          />
        )}
        <InfoItem
          title={t('Commodity', {
            ns: 'common',
            context: platform,
          })}
          value={commodity_external_description}
          testId="shipment-commodity-description"
          Icon={FaShirt}
        />
        <InfoItem
          title={t('CommodityValue', {
            ns: 'common',
            context: platform,
          })}
          value={
            <CurrencyCell
              value={commodity_value}
              currency={commodity_currency}
              typographyProps={{ bold: true, level: 'h5' }}
            />
          }
          testId="shipment-commodity-value"
          Icon={FaMoneyBills}
        />
        {showFreightCostField && (
          <InfoItem
            title={t('FreightCost', {
              ns: 'common',
              context: platform,
            })}
            value={
              <CurrencyCell
                value={freight_cost}
                currency={freight_cost_currency}
                typographyProps={{ bold: true, level: 'h5' }}
              />
            }
            testId="shipment-freight-cost"
            Icon={FaMoneyBills}
          />
        )}
        {showDutyCost && (
          <InfoItem
            title={t('DutyCost', {
              ns: 'common',
              context: platform,
            })}
            value={
              <CurrencyCell
                value={duty_cost}
                currency={duty_cost_currency}
                typographyProps={{ bold: true, level: 'h5' }}
              />
            }
            testId="shipment-duty-cost"
            Icon={FaMoneyBills}
          />
        )}
        <InfoItem
          title={
            <div className="flex flex-row items-center pt-1">
              <BreezeUITypography
                variant="input"
                level="subtext"
                capitalize={true}
                ellipsis={true}
              >
                {t('InsuredValue', {
                  ns: 'common',
                  context: tContext,
                })}
              </BreezeUITypography>
              <TooltipTriggerWrapper delay={0}>
                <Button
                  variant="icon"
                  customStyles="p-0 ml-1 h-min text-icons-default"
                >
                  <FaInfoCircle />
                </Button>
                <Tooltip placement="top">{insuredValueTooltipContent}</Tooltip>
              </TooltipTriggerWrapper>
            </div>
          }
          value={
            <CurrencyCell
              value={insured_value}
              currency={insured_value_currency}
              typographyProps={{ bold: true, level: 'h5' }}
            />
          }
          testId="shipment-insured-value"
          Icon={FaMoneyBills}
        />
      </AttributesGrid>
    </PageSection>
  );
};
