import {
  useQuery,
  type UseQueryOptions,
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query';
import { stringify } from 'query-string';

import {
  type Distributor,
  DistributorStatusEnum,
  type GetDistributorContext,
} from '../../../model/Distributor';
import { bffServiceUrl } from '../../netconfig';
import {
  type ErrorResponse,
  type PaginationBaseQueryParams,
  type PaginationBaseResponse,
} from '../types';
import { get } from '../utils';

export type GetDistributorsResponse = PaginationBaseResponse & {
  distributors: Distributor[];
};

export const useDistributors = ({
  options,
  params,
}: {
  params?: PaginationBaseQueryParams<{ id: string }> & {
    include_archived?: boolean;
    context: (typeof GetDistributorContext)[number];
  };
  options?: UseQueryOptions<
    GetDistributorsResponse,
    ErrorResponse,
    Distributor[],
    Array<string>
  >;
}) => {
  const queryParams = params ? `?${stringify(params)}` : '';

  const key = ['distributors', stringify(params || {})];

  return useQuery({
    queryFn: () =>
      get<GetDistributorsResponse>(
        `${bffServiceUrl}/bff-distributors/distributors${queryParams}`,
      ),
    queryKey: key,
    select: ({ distributors }) =>
      (distributors ?? []).map((distributor) => ({
        ...distributor,
        legal_name:
          distributor.status === DistributorStatusEnum.ARCHIVED
            ? `${distributor.legal_name} (Archived)`
            : distributor.legal_name,
      })),
    ...(options ?? {}),
  });
};
export const useSuspenseDistributors = ({
  options,
  params,
}: {
  params?: PaginationBaseQueryParams<{ id: string }> & {
    include_archived?: boolean;
    context: (typeof GetDistributorContext)[number];
  };
  options?: UseSuspenseQueryOptions<
    GetDistributorsResponse,
    ErrorResponse,
    Distributor[],
    Array<string>
  >;
}) => {
  const queryParams = params ? `?${stringify(params)}` : '';
  const key = ['distributors', stringify(params || {})];

  return useSuspenseQuery({
    queryFn: () =>
      get<GetDistributorsResponse>(
        `${bffServiceUrl}/bff-distributors/distributors${queryParams}`,
      ),
    queryKey: key,
    select: ({ distributors }) =>
      (distributors ?? []).map((distributor) => ({
        ...distributor,
        legal_name:
          distributor.status === DistributorStatusEnum.ARCHIVED
            ? `${distributor.legal_name} (Archived)`
            : distributor.legal_name,
      })),
    ...(options ?? {}),
  });
};
