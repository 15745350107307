import { type SupportedCurrencies } from './CurrencyValue';
import { type Customer } from './Customer';
import { type Distributor } from './Distributor';
import { type Place } from './Place';
import { type Port } from './Port';

export type ShipmentLocation = 'port' | 'place';
export type ConveyanceType = 'sea' | 'air' | 'road' | 'rail';

export enum ContainerModeEnum {
  FCL = 'FCL',
  LCL = 'LCL',
  FTL = 'FTL',
  LTL = 'LTL',
  CONTAINER = 'CONTAINER',
  PALLET = 'PALLET',
  PARCELS = 'PARCELS',
  CARTONS = 'CARTONS',
  SCN = 'SCN',
}
export const isContainerModeEnum = (
  payload: string,
): payload is ContainerModeEnum => {
  return Object.values(ContainerModeEnum).includes(
    payload as ContainerModeEnum,
  );
};

export type ShipmentSpecialCondition =
  | 'hazardous'
  | 'temperature_controlled'
  | 'fragile'
  | 'livestock'
  | 'bulk_cargo'
  | 'bagged_goods'
  | 'used_goods';

export type ShipmentLocationType = Port | Place;
export type SpecialConditionOptionType = {
  id: number;
  label: string;
  value: ShipmentSpecialCondition;
};

export type Shipment = {
  id: number;
  external_shipment_id: string;
  created_time: ISODateTZ;
  primary_transport_mode_code: ConveyanceType;
  transport_mode_code_display_name: string | undefined;
  primary_transport_mode_code_display_name?: string | undefined;
  distributor?: Distributor;
  customer?: Customer;
  origin_port?: Port;
  origin_port_code?: string;
  origin_place?: Place;
  destination_port?: Port;
  destination_port_code?: string;
  destination_place?: Place;
  commodity_value?: number;
  commodity_currency?: SupportedCurrencies;
  commodity_type?: string;
  commodity_description?: string;
  freight_cost?: number;
  freight_cost_currency?: SupportedCurrencies;
  duty_cost?: number;
  duty_cost_currency?: SupportedCurrencies;
  incoterm_code?: string;
  vessel_name?: string;
  container_mode?: ContainerModeEnum;
  container_ids?: string[];
  eta?: ISODate;
  etd?: ISODate;
  secondary_mode_of_transport?: ConveyanceType;
  special_conditions?: ShipmentSpecialCondition[];
};
