import { type ReactNode } from 'react';
import {
  Checkbox as AriaCheckbox,
  type CheckboxGroupProps as AriaCheckboxGroupProps,
  type CheckboxProps,
  composeRenderProps,
  type ValidationResult,
} from 'react-aria-components';
import { FaCheck, FaMinus } from 'react-icons/fa';

import { tv } from '../../utils/customTv';
import { focusRing } from '../common/utils/classNameUtils';

export interface CheckboxGroupProps
  extends Omit<AriaCheckboxGroupProps, 'children'> {
  label?: string;
  children?: ReactNode;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
}

const checkboxStyles = tv({
  base: 'flex gap-2 items-center group text-sm transition',
  variants: {
    isDisabled: {
      false: 'text-gray-800',
      true: 'text-gray-300',
    },
  },
});

const boxStyles = tv({
  extend: focusRing,
  base: 'w-5 h-5 flex-shrink-0 rounded flex items-center justify-center border-2 transition',
  variants: {
    isSelected: {
      false: `bg-white border-field-border-default`,
      true: `bg-buttons-primary-bg-default border-none group-pressed:bg-buttons-primary-bg-default`,
    },

    isDisabled: {
      true: `bg-system-grey-200 border-system-grey-400 `,
    },
  },
});

const iconStyles = 'w-3 h-3 text-white group-disabled:text-gray-400 ';

export function Checkbox(props: CheckboxProps) {
  return (
    <AriaCheckbox
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        checkboxStyles({ ...renderProps, className }),
      )}
    >
      {({ isSelected, isIndeterminate, ...renderProps }) => (
        <>
          <div
            className={boxStyles({
              isSelected: isSelected || isIndeterminate,
              ...renderProps,
            })}
          >
            {isIndeterminate ? (
              <FaMinus aria-hidden className={iconStyles} />
            ) : isSelected ? (
              <FaCheck aria-hidden className={iconStyles} />
            ) : null}
          </div>
          {props.children}
        </>
      )}
    </AriaCheckbox>
  );
}
