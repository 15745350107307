import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@breeze-ai/ui-library';
import { useQuery } from '@tanstack/react-query';

import { usePlatform } from '../../../../context/PlatformContext';
import { PDFStatus } from '../../../../network/apis/policies/types';
import { useRouteParams } from '../../../../router/router-hooks';
import {
  type Asset,
  AssetsList,
} from '../../../components/FileList/AssetsList/AssetsList';
import { PageSection } from '../../../components/PageSection/PageSection';
import { policiesQueries } from '../../network/queries';
import { type PolicyRouteParams } from '../PolicyDetailsPage';

const MAX_WAIT_TIME = 20000;

export function PollingFileList() {
  const { params } = useRouteParams<PolicyRouteParams>();
  const { policyId } = params;
  const [isTimeoutError, setIsTimeoutError] = useState(false);
  const { platform } = usePlatform();
  const { t } = useTranslation();
  const {
    data: policy,
    isFetching,
    refetch,
  } = useQuery({
    ...policiesQueries.details({
      policyId,
    }),
    refetchInterval: ({ state }) => {
      setTimeout(() => {
        setIsTimeoutError(true);
      }, MAX_WAIT_TIME);

      const docsInProgress =
        state.data?.proof_of_cover_status !== PDFStatus.InProgress &&
        state.data?.certificate_status !== PDFStatus.InProgress;

      if (isTimeoutError || docsInProgress) {
        return 0;
      }

      return 4000;
    },
  });

  const {
    open_cover,
    certificate_url,
    certificate_status,
    draft_certificate_status,
    draft_certificate_url,
    draft_certificate_created_time,
    proof_of_cover_url,
    proof_of_cover_status,
    issue_date,
    proof_of_cover_created_time,
    permissions,
  } = policy ?? {};

  const isProofOfCoverLoading =
    (proof_of_cover_status === PDFStatus.InProgress && !isTimeoutError) ||
    isFetching;
  const isCertificateLoading =
    (certificate_status === PDFStatus.InProgress && !isTimeoutError) ||
    isFetching;
  const isProofOfCoverTimeOutError =
    proof_of_cover_status === PDFStatus.InProgress &&
    isTimeoutError &&
    !isProofOfCoverLoading;
  const isCertificateTimeOutError =
    certificate_status === PDFStatus.InProgress &&
    isTimeoutError &&
    !isCertificateLoading;
  const isDraftCertificateLoading =
    (draft_certificate_status === PDFStatus.InProgress && !isTimeoutError) ||
    isFetching;
  const isDraftCertificateTimeOutError =
    draft_certificate_status === PDFStatus.InProgress &&
    isTimeoutError &&
    !isDraftCertificateLoading;

  const isAuthorizedToViewCertificate = Boolean(permissions?.show_cert_pdf);
  const policyFiles: Asset[] = [
    {
      path: draft_certificate_url,
      name: t('Draft', {
        ns: 'common',
        context: platform,
      }),
      issueDate: draft_certificate_created_time,
      testId: 'draft-certificate',
      isLoading: isDraftCertificateLoading,
      isError: isDraftCertificateTimeOutError,
      isHidden: !draft_certificate_status || !draft_certificate_url,
      onTryAgain: refetch,
    },
    {
      path: certificate_url,
      name: t('Certificate', {
        ns: 'common',
        context: platform,
      }),
      issueDate: issue_date,
      testId: 'policy-certificate',
      isLoading: isCertificateLoading,
      isError: isCertificateTimeOutError,
      isHidden: !certificate_status || !isAuthorizedToViewCertificate,
      onTryAgain: refetch,
    },
    {
      path: proof_of_cover_url,
      name: t('ProofOfCover', {
        ns: 'common',
        context: platform,
      }),
      creationDate: proof_of_cover_created_time,
      testId: 'policy-poc',
      isLoading: isProofOfCoverLoading,
      isError: isProofOfCoverTimeOutError,
      isHidden: !proof_of_cover_url,
      onTryAgain: refetch,
    },
    {
      // This doc is not produced async like other docs
      path: open_cover?.terms_and_conditions_url,
      name: t('PolicyFullTermsAndConditions', {
        ns: 'common',
        context: platform,
      }),
      testId: 'policy-terms-and-conditions',
      isHidden: !open_cover?.terms_and_conditions_url,
    },
  ];

  return proof_of_cover_status || certificate_status ? (
    <PageSection>
      <AssetsList
        assets={policyFiles}
        title={<Typography level="h4">Documents</Typography>}
      />
    </PageSection>
  ) : null;
}
