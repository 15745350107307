import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCopy, FaPen } from 'react-icons/fa6';
import {
  ColumnHeader,
  CurrencyCell,
  DateCell,
  getBaseColumnDefinition,
  type TableColumn,
  TextCell,
  Tooltip,
} from '@breeze-ai/ui-library';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useFeatureToggle, useUser } from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import { type Quote } from '../../../../model/Quote';
import {
  ActionMenu,
  type ActionMenuItem,
} from '../../../components/ActionMenu/ActionMenu';
import { QuotePriceBreakdownTooltip } from '../../../components/QuotePriceBreakdownTooltip/QuotePriceBreakdownTooltip';
import { StatusBadge } from '../../../components/StatusBadge/StatusBadge';
import { COVERAGE_PACKAGES } from '../../../constants';
import { useTranslationContext } from '../../../hooks/useTranslationContext';
import labels from '../../../labels';
import { policiesQueries } from '../../../policies/network/queries';
import { statusToBadge } from '../../utils/statusToBadge';

export const useQuotesTableColumns = (): TableColumn<Quote>[] => {
  const sortingEnabled = useFeatureToggle('enable_column_sorting');
  const { t } = useTranslation();
  const tContext = useTranslationContext();

  const { isWtw, platform } = usePlatform();
  const user = useUser();
  // TODO once we can use the edit permission, change the below to     user?.permissions?.quotes?.edit || user?.permissions?.quotes?.create,
  const displayActionMenuColumn = Boolean(user?.permissions?.quotes?.create);

  const {
    data: { coverage_options },
  } = useSuspenseQuery(policiesQueries.policyFormConfiguration());

  const insuredValueTranslation = t('InsuredValue', {
    ns: 'common',
    context: tContext,
  });

  return useMemo(
    () =>
      [
        {
          ...getBaseColumnDefinition('id', labels.quotesTable.columns.id.title),
          sortable: sortingEnabled,
          valueGetter: ({ row }: { row: Quote }) => row.id,
          renderCell: ({ value }: { value: string }) => (
            <TextCell>{value as string}</TextCell>
          ),
        },
        {
          ...getBaseColumnDefinition(
            'created_at',
            t('CreatedOn', {
              ns: 'common',
              context: platform,
            }),
          ),
          minWidth: 140,
          type: 'date',
          sortable: sortingEnabled,
          valueGetter: ({ row }: { row: Quote }) => row.created_time,
          renderCell: ({ row }: { row: Quote }) => (
            <DateCell
              value={row.created_time}
              createdBy={
                row.created_by_user && {
                  avatarColor: row.created_by_user.avatar_color,
                  name: `${row.created_by_user.first_name} ${row.created_by_user.last_name}`,
                }
              }
              format="SHORT_MONTH_NAME_DATE"
            />
          ),
        },
        ...(coverage_options.length > 1
          ? ([
              {
                ...getBaseColumnDefinition('coverage_package', 'Coverage Type'),
                minWidth: 160,
                valueGetter: ({ row }: { row: Quote }) => row.coverage_package,
                renderCell: ({ row: { coverage_package } }) => (
                  <TextCell>
                    {coverage_package
                      ? COVERAGE_PACKAGES[coverage_package].label
                      : undefined}
                  </TextCell>
                ),
                sortable: true,
              },
            ] as TableColumn<Quote>[])
          : []),
        {
          ...getBaseColumnDefinition(
            'external_reference',
            t('BookingReference', {
              ns: 'common',
              context: platform,
            }),
          ),
          minWidth: isWtw ? 110 : 180,
          valueGetter: ({ row }: { row: Quote }) => row.external_reference,
          sortable: sortingEnabled,
          renderCell: ({ value }: { value: string }) => (
            <TextCell>{value as string}</TextCell>
          ),
        },
        isWtw
          ? {
              ...getBaseColumnDefinition(
                'primary_assured',
                t('PrimaryAssured', {
                  ns: 'common',
                  context: tContext,
                }),
              ),
              valueGetter: ({ row }: { row: Quote }) =>
                row.distributor?.legal_name,
              renderCell: ({ value }: { value: string }) => (
                <Tooltip content={String(value)}>
                  <div className="flex flex-row items-center gap-2 truncate w-full">
                    <TextCell>{value as string}</TextCell>
                  </div>
                </Tooltip>
              ),
              sortable: false,
              minWidth: 160,
            }
          : null,
        {
          ...getBaseColumnDefinition(
            'customer',
            t('CargoOwner', {
              ns: 'common',
              context: tContext,
            }),
          ),
          valueGetter: ({ row }: { row: Quote }) => row.customer?.company_name,
          renderCell: ({ value }: { value: string }) => (
            <TextCell>{value as string}</TextCell>
          ),
          sortable: false,
          minWidth: 140,
        },
        {
          ...getBaseColumnDefinition('insured_value', insuredValueTranslation),
          renderHeader: () => {
            const content = `${insuredValueTranslation} is ${
              isWtw ? 'Commercial Invoice Value' : 'Cargo Value & Freight Cost'
            } + 10%`;
            return (
              <ColumnHeader
                tooltip={{
                  content,
                  icon: 'info',
                }}
              >
                {insuredValueTranslation}
              </ColumnHeader>
            );
          },
          minWidth: 190,
          type: 'number',
          valueGetter: ({ row }: { row: Quote }) => row.insured_value,
          renderCell: ({ row }: { row: Quote }) => (
            <CurrencyCell
              value={row.insured_value}
              currency={row.insured_value_currency}
            />
          ),
          sortable: sortingEnabled,
        },
        {
          ...getBaseColumnDefinition(
            'commodity_type',
            t('Commodity', {
              ns: 'common',
              context: platform,
            }),
          ),
          minWidth: 200,
          valueGetter: ({ row }: { row: Quote }) =>
            row.commodity_external_description,
          renderCell: ({ value }: { value: string }) => (
            <TextCell>{value as string}</TextCell>
          ),
          sortable: sortingEnabled,
        },
        {
          ...getBaseColumnDefinition(
            'etd',
            t('Departure', {
              ns: 'common',
              context: platform,
            }),
          ),
          minWidth: 140,
          type: 'date',
          valueGetter: ({ row }: { row: Quote }) => row.etd,
          renderCell: ({ value, row }: { value: string; row: Quote }) => {
            const title =
              row.origin_port_code || row.origin_place?.display_name;
            return (
              <DateCell
                value={value as ISODate}
                title={value ? title : undefined}
                invalidComponent={<TextCell>{title}</TextCell>}
              />
            );
          },
          sortable: sortingEnabled,
        },
        {
          ...getBaseColumnDefinition(
            'eta',
            t('Arrival', {
              ns: 'common',
              context: platform,
            }),
          ),
          type: 'date',
          valueGetter: ({ row }: { row: Quote }) => row.eta,
          renderCell: ({ value, row }: { value: string; row: Quote }) => {
            const title =
              row.destination_port_code || row.destination_place?.display_name;

            return (
              <DateCell
                value={value as ISODate}
                title={value ? title : undefined}
                invalidComponent={<TextCell>{title}</TextCell>}
              />
            );
          },
          sortable: sortingEnabled,
        },
        user?.permissions.quotes?.show_premiums && {
          ...getBaseColumnDefinition(
            'customer_premium_value',
            t('Total', { ns: 'common', context: platform }),
          ),
          type: 'number',
          valueGetter: ({
            row: { tax, premium_value = 0 },
          }: {
            row: Quote;
          }) => {
            return (tax?.value ?? 0) + premium_value;
          },
          renderCell: ({ row, value }: { value: number; row: Quote }) => {
            const {
              premium_value,
              premium_currency,
              tax,
              exchange_rate,
              converted_customer_premium_value,
              converted_tax_amount,
              status,
              distributor,
            } = row;

            if (status === 'pending_for_breeze_manual_review') {
              return <TextCell>—</TextCell>;
            }

            if (!premium_value || !premium_currency) {
              return <TextCell>—</TextCell>;
            }

            const shouldShowTooltip = Boolean(tax?.value || exchange_rate);

            return (
              <CurrencyCell
                value={value}
                currency={premium_currency}
                suffix={
                  shouldShowTooltip && (
                    <QuotePriceBreakdownTooltip
                      className="w-3.5 h-3.5"
                      premium_value={premium_value}
                      premium_currency={premium_currency}
                      tax={tax}
                      converted_customer_premium_value={
                        converted_customer_premium_value
                      }
                      converted_tax_amount={converted_tax_amount}
                      exchange_rate={exchange_rate}
                      hide_tax_info={distributor?.hide_tax_info}
                    />
                  )
                }
              />
            );
          },
          sortable: sortingEnabled,
        },
        {
          ...getBaseColumnDefinition(
            'status',
            t('Status', { ns: 'common', context: platform }),
          ),
          minWidth: 140,
          sortable: sortingEnabled,
          valueGetter: ({ row }: { row: Quote }) => row.status,
          renderCell: ({ row }: { row: Quote }) => {
            const { label, variant, Icon } =
              statusToBadge[
                (row.status as keyof typeof statusToBadge) ?? 'unknown'
              ] ?? statusToBadge.unknown;

            return <StatusBadge variant={variant} label={label} Icon={Icon} />;
          },
        },
        displayActionMenuColumn && {
          ...getBaseColumnDefinition('action', ''),
          minWidth: 40,
          maxWidth: 60,
          renderCell: ({ row }: { row: Quote }) => {
            const items: ActionMenuItem[] = [
              {
                type: 'link',
                label: t('table.actions.EditQuote', {
                  ns: 'quotes',
                  context: platform,
                }),
                href: `/quotes/${row.id}`,
                leftIcon: <FaPen />,
                isDownload: false,
                // TODO once we can use the edit permission, change the below to row.permissions?.edit
                isHidden: !row.permissions?.create,
              },
              {
                type: 'link',
                label: t('table.actions.DuplicateQuote', {
                  ns: 'quotes',
                  context: platform,
                }),
                href: `/quotes/duplicate/${row.id}`,
                leftIcon: <FaCopy />,
                isHidden: !row.permissions?.create,
                isDownload: false,
              },
            ];
            return <ActionMenu items={items} id={String(row.id)} />;
          },
        },
      ].filter(Boolean) as TableColumn<Quote>[],
    [
      coverage_options,
      sortingEnabled,
      user,
      displayActionMenuColumn,
      isWtw,
      platform,
      t,
      tContext,
      insuredValueTranslation,
    ],
  );
};
