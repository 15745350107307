import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { stringify } from 'query-string';

import {
  type BffGetCustomersResponse,
  CustomersApi,
} from '../../../generated/api-client';
import { type Customer } from '../../../model/Customer';
import {
  getBffApiConfiguration,
  type NetworkResponse,
} from '../../AuthRequests';
import { normalizePlaceResponse } from '../places/normalizers';
import { type ErrorResponse, type PaginationBaseQueryParams } from '../types';
import { type CustomersSortOption } from './types';

export const useCustomers = ({
  options,
  params,
}: {
  params?: PaginationBaseQueryParams<CustomersSortOption>;
  options?: UseQueryOptions<
    NetworkResponse<BffGetCustomersResponse>,
    ErrorResponse,
    Customer[],
    Array<string>
  >;
}) => {
  const key = ['customers', stringify(params || {})];

  return useQuery({
    queryKey: key,
    queryFn: async () => {
      const config = await getBffApiConfiguration();
      return new CustomersApi(config).getCustomers({
        ...params,
        ids: params?.ids?.map((id) => id.toString()).join(','),
      });
    },
    select: ({ data }) =>
      data.customers.map((customer) => ({
        id: customer.id,
        company_name: customer.company_name!, // TODO HACK: type should be fixed in the API,
        distributor_id: customer.distributor_id!, // TODO HACK: type should be fixed in the API,
        address: normalizePlaceResponse({
          provider_place_uuid: customer?.address_provider_uuid ?? '',
          provider: customer?.address_provider ?? '',
          name: customer?.address_name ?? '',
          full_address: customer?.address_line1 ?? '',
          country_name: customer?.country_name,
          country_code: customer?.country_code,
          state_code: customer?.state_code,
          state_name: customer?.state_name,
          city: customer?.city,
          geolocation: {
            lat: Number(customer?.address_lat),
            lng: Number(customer?.address_lon),
          },
        }),
      })),

    ...options,
  });
};
