import { useState } from 'react';
import {
  ComboBox,
  ComboBoxItem,
  type ComboBoxProps,
} from '@breezeai-frontend/cargo-ui';
import { keepPreviousData } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { snakeCase } from 'lodash-es';

import { useCustomers } from '../../../network/apis/customers/hooks';
import { type CustomerOption } from './types';

const getEmptyCollectionMessage = (inputValue: string) => {
  if (!inputValue) {
    return 'Please type and select an option';
  }
  return 'Searching...';
};

export function CustomerComboBox({
  ...props
}: Omit<ComboBoxProps<CustomerOption>, 'children'>) {
  const [query, setQuery] = useState<string | undefined>();
  const debouncedQuery = useDebounce(query, 500);

  const { data } = useCustomers({
    options: {
      queryKey: ['customers', debouncedQuery ?? ''],
      throwOnError: true,
      refetchOnWindowFocus: false,
      placeholderData: keepPreviousData,
      gcTime: 0,
    },
    params: {
      limit: 15,
      order: 'asc',
      query: debouncedQuery,
    },
  });

  const onInputChange = (value: string) => {
    setQuery(value);
    props.onInputChange?.(value);
  };

  const createCustomOption = (inputValue: string): CustomerOption => {
    return {
      company_name: inputValue,
    };
  };

  // Create a copy of data and append custom items with type info
  const allItems = data ?? [];
  const displayItems = [...allItems];

  return (
    <ComboBox
      {...props}
      items={displayItems}
      onInputChange={onInputChange}
      allowsCustomValue
      allowsEmptyCollection
      emptyCollectionMessage={getEmptyCollectionMessage(query ?? '')}
      showCustomOptionWhenEmpty={true}
      createCustomOption={createCustomOption}
      inputValue={query}
    >
      {(item) => {
        const { company_name, id, address } = item;
        // If this is a custom option (no id), we display "Add new "[value]"" instead
        const displayName = !id ? `Add new "${company_name}"` : company_name;

        return (
          <ComboBoxItem
            data-testid={`customer-option-${snakeCase(company_name)}`}
            key={JSON.stringify({ id, company_name, address })}
            id={JSON.stringify({ id, company_name, address })}
            textValue={company_name}
            className={'flex flex-col items-start'}
          >
            <span>{displayName}</span>
            {address && (
              <span className="text-sm text-gray-500">
                {address.full_address}
              </span>
            )}
          </ComboBoxItem>
        );
      }}
    </ComboBox>
  );
}
