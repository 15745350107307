import {
  DatePicker as AriaDatePicker,
  type DatePickerProps as AriaDatePickerProps,
} from 'react-aria-components';
import { FaCalendar } from 'react-icons/fa';
import { type DateValue, parseDate } from '@internationalized/date';
import { CalendarDate } from '@internationalized/date';
import type moment from 'moment';

import { Button } from '../Button/Button';
import { Calendar } from '../Calendar/Calendar';
import { clsxMerge } from '../common/utils/classNameUtils';
import { DateInput } from '../DateField/DateField';
import { Dialog } from '../Dialog/Dialog';
import {
  Description,
  FieldError,
  FieldGroup,
  fieldStyles,
  Label,
} from '../Field/Field';
import { Popover } from '../Popover/Popover';

export interface DatePickerProps<T extends DateValue>
  extends AriaDatePickerProps<T> {
  label?: string;
  description?: string;
  errorMessage?: string;
}

export function momentToCalendarDate(momentDate: moment.Moment) {
  return new CalendarDate(
    momentDate.year(),
    momentDate.month() + 1, // Moment months are 0-based, CalendarDate expects 1-based
    momentDate.date(),
  );
}

export const getDate = (dateString?: string): DateValue | undefined => {
  return dateString ? parseDate(dateString) : undefined;
};

export function DatePicker<T extends DateValue>({
  label,
  description,
  isInvalid,
  errorMessage,
  isRequired,
  ...props
}: DatePickerProps<T>) {
  return (
    <AriaDatePicker
      {...props}
      isInvalid={isInvalid}
      isRequired={isRequired}
      className={clsxMerge(
        fieldStyles({ isInvalid }),
        props.className,
        'group flex flex-col',
      )}
      validationBehavior="aria"
    >
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <FieldGroup className="w-auto flex justify-between">
        <DateInput data-testid="date-picker-input" className="flex-1" />

        <Button
          variant="ghost"
          leftIcon={<FaCalendar className="fill-icons-default" />}
          customStyles="w-6 mr-1 rounded outline-offset-0 hover:bg-transparent"
        />
      </FieldGroup>
      {description && !isInvalid && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
      <Popover>
        <Dialog>
          <Calendar data-testid="calendar" />
        </Dialog>
      </Popover>
    </AriaDatePicker>
  );
}
