import { useCallback, useState } from 'react';
import { Autocomplete } from '@breeze-ai/ui-library';
import { capitalize } from 'lodash-es';
import invariant from 'tiny-invariant';

import {
  getPolicyCertificateIdList,
  type PolicyIdWithCertificateId,
} from '../../../network/apis/policies/policies';
import {
  useClaimCreationFlowContext,
  useClaimCreationStateAction,
} from '../../claims/ClaimCreationFlow/context/hooks';
import labels from '../../labels';

export const CertificateIdAutocomplete = () => {
  const { formsData, formErrors } = useClaimCreationFlowContext();
  const { setFormData } = useClaimCreationStateAction();

  // TODO: this will not be needed once we move away from ui-library component
  const [policyResults, setPolicyResults] = useState<
    PolicyIdWithCertificateId[]
  >([]);
  const getCertificateIdsOptions = useCallback(getPolicyCertificateIdList, []);

  return (
    <Autocomplete<string>
      required={true}
      value={formsData.policy?.certificate_id}
      onChange={(certificateId) => {
        const policy = policyResults.find(
          (policy) => policy.certificate_id === certificateId,
        );

        invariant(
          policy,
          `Policy not found for certificate id: ${certificateId}`,
        );

        setFormData({ policy });
      }}
      getOptionValue={(option) => option}
      fetchOptions={(query) =>
        getCertificateIdsOptions({ query }).then((data) => {
          setPolicyResults(data);
          return data.map(({ certificate_id }) => certificate_id);
        })
      }
      isOptionEqualToValue={(option, value) => option === value}
      inputProps={{
        placeholder: `Select ${labels.terms.policy.singular} number...`,
        label: `${capitalize(labels.terms.policy.singular)} number`,
        error: !!formErrors.policy,
        errorHelperText: formErrors.policy?.reason,
        testId: 'certificate-id-input',
      }}
    />
  );
};
