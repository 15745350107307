import { useCallback } from 'react';

import {
  isNonEmpty,
  isPositive,
  isValidEmail,
  type ValidationOptions,
  type ValidationResult,
  type Validator,
} from '../../../utils/validators';
import {
  useClaimCreationFlowContext,
  useClaimCreationStateAction,
} from './context/hooks';
import { type ClaimFormsData } from './context/types';

const getFieldValidators: {
  [K in keyof Partial<ClaimFormsData>]: (
    options?: ValidationOptions,
  ) => Validator[];
} = {
  policy: () => [isNonEmpty],
  eventDate: () => [isNonEmpty],
  eventDescription: () => [isNonEmpty],
  contactName: () => [isNonEmpty],
  contactEmail: () => [isValidEmail, isNonEmpty],
  contactPhone: () => [isNonEmpty],
  reportedAmount: () => [isPositive],

  typeOfClaim: () => [isNonEmpty],
  commonReasonClaim: () => [isNonEmpty],
  countryManagerOpsDirector: () => [isNonEmpty],
  managingDirector: () => [isNonEmpty],
  dateDamagedReported: () => [isNonEmpty],
  transportationOfLossDamage: () => [isNonEmpty],
  noticeOfIncidentDate: () => [isNonEmpty],
};

export const useClaimFormInputValidation = () => {
  const { setFormError } = useClaimCreationStateAction();

  return useCallback(
    (
      field: keyof ClaimFormsData,
      value: string,
      options?: ValidationOptions,
    ) => {
      const checks = getFieldValidators?.[field]?.(options) ?? [];
      const validationResult = checks.reduce<ValidationResult>(
        (result, check) => {
          const { valid, reason } = check(value);

          if (!valid && reason) {
            result.error = true;
            result.reason = reason;
          }

          return result;
        },
        { field, error: false },
      );

      setFormError(validationResult);
      return !validationResult.error;
    },
    [setFormError],
  );
};

export const useClaimCreationFormValidations = () => {
  const { formsData, formErrors } = useClaimCreationFlowContext();

  return useCallback(
    (requiredFields: (keyof ClaimFormsData)[]) => {
      const formContainsErrors = Object.values(formErrors).some((e) => e);
      const requiredFieldsFilled = requiredFields.every(
        (field) => !!formsData?.[field as keyof ClaimFormsData],
      );

      return requiredFieldsFilled && !formContainsErrors;
    },
    [formErrors, formsData],
  );
};
