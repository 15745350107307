import { useLocation } from 'react-router-dom';
import { type FormApi } from '@tanstack/react-form';
import { type zodValidator } from '@tanstack/zod-form-adapter';
import invariant from 'tiny-invariant';

import { useUser } from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import {
  type BffDuplicateQuoteParamsPayload,
  type BffPostQuoteFormBody,
  type BffUpdateQuoteParamsPayload,
} from '../../../../generated/api-client';
import { type Location } from '../../../../network/apis/locations/types';
import { useRouteParams } from '../../../../router/router-hooks';
import { type MapsApiPayload } from '../../../certificates/types';
import { getJourneyUpdatePayload } from '../../../components/RouteDetails/utils';
import {
  getVesselsUpdatePayload,
  VESSEL_NOT_FOUND_ID,
} from '../../../components/VesselFields/utils';
import { getUpdatedValue } from '../../../utils/forms';
import { type PolicyFlowUrlParams } from '../../context/types';
import { type QuoteFormData } from './QuoteForm';
import { isAnyFieldDirty } from './utils';

export const useQuoteFormLogic = () => {
  const {
    params: { quoteId },
  } = useRouteParams<PolicyFlowUrlParams>();
  const { pathname } = useLocation();

  const { isWtw } = usePlatform();

  const user = useUser();

  const isDuplicate = pathname.includes('/duplicate/');
  // TODO set route for quote update to /update/:quoteId and check pathname if isUpdate
  const isUpdate = !!quoteId && !isDuplicate;

  // TODO: copied from useFormLogic under CertificateFlowPage;
  // move to shared place
  const getPlacePayload = (
    location?: Location | null,
  ): MapsApiPayload | null | undefined => {
    if (location === undefined) {
      return undefined;
    }
    if (location === null) {
      return null;
    }
    if (location.type === 'place') {
      return {
        place_id: location.place.place_id,
        session_token: location.place.session_token,
      };
    }

    return undefined;
  };

  const getCreationPayload = (
    form: FormApi<QuoteFormData, typeof zodValidator>,
  ): BffPostQuoteFormBody => {
    const formData = form.state.values;
    if (
      !formData.commodityCategory ||
      !formData.cargoOwner ||
      (isWtw ? false : !formData.containerModeId) ||
      !formData.coveragePackage
    ) {
      throw new Error('Please fill in all required fields');
    }

    const loadingPlace = getPlacePayload(formData.placeOfLoading);
    const deliveryPlace = getPlacePayload(formData.placeOfDelivery);
    const originPlace = getPlacePayload(formData.origin);
    const destinationPlace = getPlacePayload(formData.destination);
    const originPortCode =
      formData.origin?.type === 'port' ? formData.origin.port.code : undefined;
    const destinationPortCode =
      formData.destination?.type === 'port'
        ? formData.destination.port.code
        : undefined;

    return {
      distributor_id: formData.distributorId,

      division: formData.division || undefined,

      transport_mode_code: formData.primaryMot.name,
      transport_mode_code_display_name: formData.primaryMot.displayName,

      origin_port_code: originPortCode,
      destination_port_code: destinationPortCode,

      origin_place_provider_details: originPlace ?? undefined,
      destination_place_provider_details: destinationPlace ?? undefined,

      loading_place_provider_details: loadingPlace ?? undefined,
      loading_transport_mode_code: formData.placeOfLoading
        ? formData.placeOfLoadingMot ?? undefined
        : undefined,

      delivery_place_provider_details: deliveryPlace ?? undefined,
      delivery_transport_mode_code: formData.placeOfDelivery
        ? formData.placeOfDeliveryMot ?? undefined
        : undefined,

      commodity: {
        type_id: formData.commodityCategory.typeId.toString(),
        name: formData.commodityCategory.name,
        description: formData.commodityDescription!,
        currency_code: formData.commodityCurrency!,
        value: formData.commodityValue!,
      },

      cargo_owner: {
        id: formData.cargoOwner.id
          ? formData.cargoOwner.id.toString()
          : undefined,
        name: formData.cargoOwner.company_name,
      },

      container_mode_id: formData.containerModeId,

      freight_cost: formData.freightCost ? formData.freightCost : undefined,
      freight_cost_currency: formData.freightCost
        ? formData.freightCostCurrency
        : undefined,

      duty_cost: formData.dutyCost ? formData.dutyCost : undefined,
      duty_cost_currency: formData.dutyCost
        ? formData.dutyCostCurrency
        : undefined,

      external_reference: formData.externalReference,
      special_conditions: formData.specialConditions?.map((c) => ({
        value: c,
      })),
      coverage_package: formData.coveragePackage,
      letter_of_credit: formData.letterOfCredit,

      vessel_name:
        formData.vessel?.name && !formData.vessel.external_id
          ? formData.vessel.name
          : undefined,
      vessel_external_id:
        formData.vessel?.external_id &&
        formData.vessel.external_id !== VESSEL_NOT_FOUND_ID
          ? formData.vessel.external_id
          : undefined,
      profit_center: formData.profitCenter,
      // Not allowing null here so field won't be sent if it's empty (=== deleted)
      uplift_percentage: formData.upliftPercentage ?? undefined,
      purpose_type: formData.purposeType,
      standard_liability_offering: formData.standardLiaibilityOffering,
      claims_handler_location: formData.claimsHandlerLocation,
    };
  };

  const getUpdatePayload = (
    form: FormApi<QuoteFormData, typeof zodValidator>,
  ): BffUpdateQuoteParamsPayload => {
    const formData = form.state.values;
    const formMeta = form.state.fieldMeta;
    invariant(quoteId, 'Quote ID is required for update');
    invariant(user?.distributor.id, 'Distributor ID is required for update');

    return {
      ...getJourneyUpdatePayload(form),
      ...getVesselsUpdatePayload(formData, formMeta),

      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrade to pydantic v2
      distributor_id: getUpdatedValue(
        formData.distributorId,
        formMeta,
        'distributorId',
      ),
      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrade to pydantic v2
      division: getUpdatedValue(formData.division, formMeta, 'division'),

      commodity: isAnyFieldDirty(form, [
        'commodityCategory',
        'commodityDescription',
        'commodityCurrency',
        'commodityValue',
      ])
        ? {
            type_id: getUpdatedValue(
              formData.commodityCategory?.typeId,
              formMeta,
              'commodityCategory',
            ),
            // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrade to pydantic v2
            name: getUpdatedValue(
              formData.commodityCategory?.name,
              formMeta,
              'commodityCategory',
            ),

            description: getUpdatedValue(
              formData.commodityDescription,
              formMeta,
              'commodityDescription',
            ),

            currency_code: getUpdatedValue(
              formData.commodityCurrency,
              formMeta,
              'commodityCurrency',
            ),

            value: getUpdatedValue(
              formData.commodityValue,
              formMeta,
              'commodityValue',
            ),
          }
        : undefined,

      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrade to pydantic v2
      cargo_owner: isAnyFieldDirty(form, 'cargoOwner')
        ? {
            id: getUpdatedValue(
              formData.cargoOwner?.id,
              formMeta,
              'cargoOwner',
            ),

            name: getUpdatedValue(
              formData.cargoOwner?.company_name,
              formMeta,
              'cargoOwner',
            ),
          }
        : undefined,

      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrade to pydantic v2
      container_mode_id: getUpdatedValue(
        formData.containerModeId,
        formMeta,
        'containerModeId',
      ),

      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrade to pydantic v2
      freight_cost: getUpdatedValue(
        formData.freightCost,
        formMeta,
        'freightCost',
      ),

      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrade to pydantic v2
      freight_cost_currency: getUpdatedValue(
        formData.freightCostCurrency,
        formMeta,
        'freightCostCurrency',
      ),

      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrade to pydantic v2
      duty_cost: getUpdatedValue(formData.dutyCost, formMeta, 'dutyCost'),

      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrade to pydantic v2
      duty_cost_currency: getUpdatedValue(
        formData.dutyCostCurrency,
        formMeta,
        'dutyCostCurrency',
      ),

      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrade to pydantic v2
      external_reference: getUpdatedValue(
        formData.externalReference,
        formMeta,
        'externalReference',
      ),

      special_conditions:
        formData.specialConditions &&
        form.state.fieldMeta['specialConditions'].isDirty
          ? formData.specialConditions.map((c) => ({
              value: c,
            }))
          : undefined,

      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrade to pydantic v2
      coverage_package: getUpdatedValue(
        formData.coveragePackage,
        formMeta,
        'coveragePackage',
      ),

      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrade to pydantic v2
      letter_of_credit: getUpdatedValue(
        formData.letterOfCredit,
        formMeta,
        'letterOfCredit',
      ),

      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrade to pydantic v2
      profit_center: getUpdatedValue(
        formData.profitCenter,
        formMeta,
        'profitCenter',
      ),
      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrade to pydantic v2
      uplift_percentage: getUpdatedValue(
        formData.upliftPercentage,
        formMeta,
        'upliftPercentage',
      ),
      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrade to pydantic v2
      purpose_type: getUpdatedValue(
        formData.purposeType,
        formMeta,
        'purposeType',
      ),
      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrade to pydantic v2
      standard_liability_offering: getUpdatedValue(
        formData.standardLiaibilityOffering,
        formMeta,
        'standardLiaibilityOffering',
      ),
      // @ts-expect-error - TODO HACK: generated api doesnt support null values until we upgrade to pydantic v2
      claims_handler_location: getUpdatedValue(
        formData.claimsHandlerLocation,
        formMeta,
        'claimsHandlerLocation',
      ),
    };
  };

  const getDuplicationPayload = (
    form: FormApi<QuoteFormData, typeof zodValidator>,
  ): BffDuplicateQuoteParamsPayload => {
    invariant(quoteId, 'Original Quote ID is required for duplication');
    return {
      ...getCreationPayload(form),
      original_quote_id: parseInt(quoteId),
    };
  };

  return {
    isDuplicate,
    isUpdate,
    getCreationPayload,
    getDuplicationPayload,
    getUpdatePayload,
  };
};
