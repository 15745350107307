import { type BffCreateClaimPayloadParams } from '../../../generated/api-client';
import { type Claim } from '../../../model/Claim';
import { type ClaimFormsData } from '../../../ui/claims/ClaimCreationFlow/context/types';
import { toISODate } from '../../../utils/dates';
import { normalizePlaceResponse } from '../places/normalizers';
import { normalizePolicyResponse } from '../policies/normalizers';
import { type ClaimModel } from './types';

export const createClaimPayload = (
  data: Required<ClaimFormsData>,
): BffCreateClaimPayloadParams => ({
  status: 'unassigned',
  distributor_id: data.distributorId,
  policy_id: data.policy?.id,
  description: data.eventDescription,
  event_start_time: toISODate(data.eventDate, { time: true }),
  event_place: data.eventAddress,
  event_location_type: data.eventLocationType,
  current_cargo_place: data.currentCargoPlace,
  claim_contact_person_name: data.contactName,
  claim_contact_person_email: data.contactEmail,
  claim_contact_person_mobile: data.contactPhone,
  reported_amount: data.reportedAmount,
  reported_amount_currency_code: data.reportedAmountCurrency,
  raised_against_open_cover: data.raiseAgainstOpenCover,
  type_of_claim: data.typeOfClaim,
  common_reason_claim: data.commonReasonClaim,
  shippers_interest_claim: data.shippersInterestClaim,
  country_manager_ops_director: data.countryManagerOpsDirector,
  managing_director: data.managingDirector,
  date_damaged_reported: data.dateDamagedReported
    ? toISODate(data.dateDamagedReported, { time: true })
    : undefined,
  transportation_of_loss_damage: data.transportationOfLossDamage,
  notice_of_incident_date: data.noticeOfIncidentDate
    ? toISODate(data.noticeOfIncidentDate, { time: true })
    : undefined,
  bl_date: data.blDate ? toISODate(data.blDate, { time: true }) : undefined,
  surveyor_appointed_by: data.surveyorAppointedBy,
  named_assured: data.customer,
  named_assured_address_country: data.customerAddress,
  client_reference_number: data.clientReferenceNumber,
  underwriter_reference_number: data.underwriterReferenceNumber,
  broker_reference_number: data.brokerReferenceNumber,
  // Shipment details
  conveyance: data.conveyance,
  origin: data.origin,
  destination: data.destination,
  bill_of_lading_no: data.billOfLadingNumber,
  carrier_name: data.carrierName,
  description_of_cargo: data.descriptionOfCargo,
});

export const normalizeClaimResponse = (response: ClaimModel): Claim => ({
  id: response.id,
  policy_id: response.policy_id,
  claim_number: response.claim_number,
  status: response.status,
  paying_company: response.paying_company,
  reported_time: response.reported_time,
  description: response.description,
  currency: response.reported_amount_currency?.code,
  paid_amount: response.paid_amount,
  reported_amount: response.reported_amount,
  reserved_amount: response.reserved_amount,
  applied_deductible_amount: response.applied_deductible_amount,
  recovered_amount: response.recovered_amount,
  total_amount: response?.total_amount,
  payment_time: response.payment_time,
  policy: response.policy
    ? normalizePolicyResponse(response.policy)
    : undefined,
  event: {
    start_time: response.event_start_time,
    end_time: response.event_end_time,
    location_type: response.event_location_type,
    location: normalizePlaceResponse(response.event_place),
    current_cargo_place: normalizePlaceResponse(response.current_cargo_place),
  },
  contact_person: {
    name: response.claim_contact_person_name,
    email: response.claim_contact_person_email,
    mobile: response.claim_contact_person_mobile,
  },
  opening_user: {
    email: response.opening_user_email,
    name: response.opening_user_name,
  },
  claim_adjuster_name: response.claim_adjuster_name,
  created_by_user: response.created_by_user,
  distributor: response.distributor,
});
