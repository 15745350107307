import { type HTMLAttributes, type ReactNode, Suspense } from 'react';

import { clsxMerge, UnauthorizedError } from '../..';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { Spinner } from '../../components/Spinner/Spinner';
import {
  type Tooltip,
  TooltipTriggerWrapper,
} from '../../components/Tooltip/Tooltip';

interface SectionLayoutProps
  extends Omit<
    HTMLAttributes<HTMLDivElement>,
    'title' | 'actions' | 'children'
  > {
  title: ReactNode;
  isAuthorized: boolean;
  statusBadge?: ReactNode;
  tooltip?: {
    trigger: React.ReactElement<'button'>;
    content: React.ReactElement<typeof Tooltip>;
    props?: React.ComponentProps<typeof TooltipTriggerWrapper>;
  };
  actions?: ReactNode;
  children?: ReactNode;
  backLink?: string;
}

// TODO group title props into single object
export function SectionLayout({
  title,
  actions,
  statusBadge,
  children,
  backLink,
  tooltip,
  isAuthorized,
  ...props
}: SectionLayoutProps) {
  const isDisplayTitle = title || actions;
  if (!isAuthorized) throw new UnauthorizedError();
  return (
    <Suspense
      fallback={
        <Spinner className="self-center" data-testid="section-layout-spinner" />
      }
    >
      {isDisplayTitle && (
        <div className="sticky py-10 top-0 flex justify-between z-20 bg-gradient-to-b from-surfaces-secondary from-60% to-transparent gap-x-3 flex-wrap">
          <div className="flex items-center gap-2 flex-wrap">
            <PageTitle
              title={title}
              backLink={backLink}
              statusBadge={statusBadge}
            />
            {tooltip && (
              <TooltipTriggerWrapper delay={0} {...tooltip.props}>
                {tooltip.trigger}
                {tooltip.content}
              </TooltipTriggerWrapper>
            )}
          </div>
          <div className="flex items-center gap-4 justify-self-end">
            {actions}
          </div>
        </div>
      )}
      <div
        {...props}
        className={clsxMerge('flex w-full h-full', props.className)}
      >
        {children}
      </div>
    </Suspense>
  );
}
