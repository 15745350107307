import { FaShip } from 'react-icons/fa';
import { FaLocationDot, FaPlaneUp, FaTrain, FaTruck } from 'react-icons/fa6';
import { type DeepNullable } from '@breezeai-frontend/cargo-ui';
import i18n from '@breezeai-frontend/i18n-config';
import { type FormApi } from '@tanstack/react-form';
import { type zodValidator } from '@tanstack/zod-form-adapter';

import { PlatformEnum } from '../../../context/PlatformContext';
import { type BffUpdateQuoteParamsPayload } from '../../../generated/api-client';
import { type ConveyanceType } from '../../../model/Shipment';
import {
  type Location,
  type PlaceLocation,
  type PortLocation,
} from '../../../network/apis/locations/types';
import { getPortSearchDisplayValue } from '../../utils/ports';
import { type RouteDetailsFormData } from './types';

export const getSelectionIcon = (mot?: ConveyanceType | null) => {
  switch (mot) {
    case 'sea':
      return FaShip;
    case 'air':
      return FaPlaneUp;
    case 'rail':
      return FaTrain;
    case 'road':
      return FaTruck;
    default:
      return FaLocationDot;
  }
};

export const isLocation = (location: unknown): location is Location => {
  if (typeof location !== 'object' || location === null) return false;
  return 'port' in location || 'place' in location;
};

export const isPortLocation = (location?: Location): location is PortLocation =>
  location?.type === 'port';
export const isPlaceLocation = (
  location?: Location,
): location is PlaceLocation => location?.type === 'place';

export const getDefaultInputValue = (location?: Location | null): string => {
  if (!location) return '';

  switch (location.type) {
    case 'port': {
      return getPortSearchDisplayValue(location.port);
    }
    case 'place': {
      return location.place.description;
    }
  }
};

export const getPlaceholderText = (mot: ConveyanceType) => {
  switch (mot) {
    case 'sea': {
      return i18n.t('routeDetails.SeaPortSearchPlaceholder', {
        ns: 'common',
        context: PlatformEnum.BREEZE,
      });
    }
    case 'air': {
      return i18n.t('routeDetails.AirPortSearchPlaceholder', {
        ns: 'common',
        context: PlatformEnum.BREEZE,
      });
    }
    default: {
      return i18n.t('routeDetails.LocationSearchPlaceholder', {
        ns: 'common',
        context: PlatformEnum.BREEZE,
      });
    }
  }
};

type JourneyUpdatePayload = DeepNullable<
  Pick<
    BffUpdateQuoteParamsPayload,
    | 'origin_port_code'
    | 'origin_place_provider_details'
    | 'destination_port_code'
    | 'destination_place_provider_details'
    | 'transport_mode_code'
    | 'loading_place_provider_details'
    | 'loading_transport_mode_code'
    | 'delivery_place_provider_details'
    | 'delivery_transport_mode_code'
    | 'transport_mode_code_display_name'
  >
>;

export const getJourneyUpdatePayload = (
  form: FormApi<RouteDetailsFormData, typeof zodValidator>,
): JourneyUpdatePayload => {
  const updatePayload: JourneyUpdatePayload = {};

  const {
    origin,
    destination,
    primaryMot,
    placeOfLoading,
    placeOfLoadingMot,
    placeOfDelivery,
    placeOfDeliveryMot,
  } = form.state.values;

  const fieldMeta = form.state.fieldMeta;

  // Primary route

  if (primaryMot && fieldMeta.primaryMot?.isDirty) {
    updatePayload.transport_mode_code = primaryMot.name;
    updatePayload.transport_mode_code_display_name = primaryMot.displayName;
    updatePayload.transport_mode_code = primaryMot.name;
  }
  if (origin && fieldMeta.origin?.isDirty) {
    if (isPortLocation(origin)) {
      updatePayload.origin_port_code = origin.port.code;
      updatePayload.origin_place_provider_details = null;
    } else {
      updatePayload.origin_place_provider_details = {
        place_id: origin.place.place_id,
        session_token: origin.place.session_token,
      };
      updatePayload.origin_port_code = null;
    }
  }
  if (destination && fieldMeta.destination?.isDirty) {
    if (isPortLocation(destination)) {
      updatePayload.destination_port_code = destination.port.code;
      updatePayload.destination_place_provider_details = null;
    } else {
      updatePayload.destination_place_provider_details = {
        place_id: destination.place.place_id,
        session_token: destination.place.session_token,
      };
      updatePayload.destination_port_code = null;
    }
  }

  // Place of loading

  if (fieldMeta.placeOfLoadingMot?.isDirty) {
    if (placeOfLoadingMot) {
      updatePayload.loading_transport_mode_code = placeOfLoadingMot;
    } else {
      updatePayload.loading_transport_mode_code = null;
    }
  }

  if (fieldMeta.placeOfLoading?.isDirty) {
    if (placeOfLoading) {
      updatePayload.loading_place_provider_details = {
        place_id: placeOfLoading.place.place_id,
        session_token: placeOfLoading.place.session_token,
      };
    } else {
      updatePayload.loading_place_provider_details = null;
    }
  }

  // Place of delivery

  if (fieldMeta.placeOfDeliveryMot?.isDirty) {
    if (placeOfDeliveryMot) {
      updatePayload.delivery_transport_mode_code = placeOfDeliveryMot;
    } else {
      updatePayload.delivery_transport_mode_code = null;
    }
  }

  if (fieldMeta.placeOfDelivery?.isDirty) {
    if (placeOfDelivery) {
      updatePayload.delivery_place_provider_details = {
        place_id: placeOfDelivery.place.place_id,
        session_token: placeOfDelivery.place.session_token,
      };
    } else {
      updatePayload.delivery_place_provider_details = null;
    }
  }

  return updatePayload;
};
