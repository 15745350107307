import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

import { removeInlineStyles } from '../../../../../../packages/cargo-ui/src/utils/tools';
import { usePlatform } from '../../../context/PlatformContext';
import { type AdditionalClause } from '../../../model/AdditionalClause';
import { DeductibleMessage } from '../DeductibleMessage/DeductibleMessage';
import { PageSection } from '../PageSection/PageSection';

interface Props {
  additionalClause: AdditionalClause;
}

export function AdditionalClauses({ additionalClause }: Props) {
  const { clause_text, ...deductibleProps } = additionalClause;
  const cleanTextMarkup = DOMPurify.sanitize(clause_text);
  const { platform } = usePlatform();
  const { t } = useTranslation();
  const title = t('AdditionalClauses', {
    ns: 'common',
    context: platform,
  });

  return (
    <PageSection title={title}>
      <div className="flex flex-col gap-1.5">
        <DeductibleMessage {...deductibleProps} />
        <div
          dangerouslySetInnerHTML={{
            __html: removeInlineStyles(cleanTextMarkup),
          }}
          className="text-text-secondary text-base leading-3 text-left"
        />
      </div>
    </PageSection>
  );
}
