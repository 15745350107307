import { useEffect, useState } from 'react';
import {
  ComboBox,
  ComboBoxItem,
  type ComboBoxProps,
  Typography,
} from '@breezeai-frontend/cargo-ui';
import { useDebounce } from '@uidotdev/usehooks';

import {
  type BffGetVesselSuggestionsResponse,
  type BffGetVesselSuggestionsResponseExternalVessel,
} from '../../../generated/api-client';
import { type RouteDetailsFormData } from '../RouteDetails/types';
import { useVesselSuggestions } from './useVesselSuggestions';
import { getEmptyOptionName, VESSEL_NOT_FOUND_ID } from './utils';

export type VesselSuggestion =
  BffGetVesselSuggestionsResponse['vessels'][number];

interface VesselsComboBoxProps
  extends Omit<ComboBoxProps<VesselSuggestion>, 'children'> {
  primaryMot: RouteDetailsFormData['primaryMot'];
  defaultPrimaryMot?: RouteDetailsFormData['primaryMot'];
  onInputChangeWithItems?: ({
    value,
    vessels,
  }: {
    value: string;
    vessels: BffGetVesselSuggestionsResponseExternalVessel[] | undefined;
  }) => void;
}

type SelectionKey = ComboBoxProps<VesselSuggestion>['selectedKey'];

const MINIMUM_QUERY_LENGTH = 3;

const getEmptyCollectionMessage = (inputValue: string) => {
  if (!inputValue) {
    return 'Please type and select an option';
  }

  // Narrowing search for better UI performance. Better solution would be a
  // virtualized list, but this is simpler until we get some user feedback.
  if (inputValue.length < MINIMUM_QUERY_LENGTH) {
    return 'Continue typing to search';
  }

  return 'Searching...';
};

// TODO: We have a few comboboxes that use debouncing.
// Maybe it's worth implementing a debounced combobox in cargo-ui.
export function VesselsComboBox({
  defaultPrimaryMot,
  primaryMot,
  isRequired = false,
  defaultInputValue = '',
  onInputChange,
  onInputChangeWithItems,
  onSelectionChange,
  ...props
}: VesselsComboBoxProps) {
  const [inputValue, setInputValue] = useState(defaultInputValue);
  const [selectedKey, setSelectedKey] = useState<SelectionKey>(null);

  const debouncedQuery = useDebounce(inputValue, 500);

  const emptyOptionName = getEmptyOptionName(isRequired, debouncedQuery);

  const { data: vessels, isFetching } = useVesselSuggestions({
    params: { query_text: debouncedQuery },
    options: {
      enabled:
        !!debouncedQuery &&
        debouncedQuery.length >= MINIMUM_QUERY_LENGTH &&
        debouncedQuery !== emptyOptionName,
    },
  });

  const emptyOption: VesselSuggestion = {
    name: emptyOptionName,
    external_id: VESSEL_NOT_FOUND_ID,
    date_of_build: '',
    imo_number: isRequired ? "Can't find vessel name or IMO" : '',
    under_sanctions: false,
  };

  const vesselsWithNotFoundOption =
    vessels && debouncedQuery.length >= MINIMUM_QUERY_LENGTH
      ? [...vessels, emptyOption]
      : vessels;

  useEffect(() => {
    // TODO HACK: Reset the vessel name when the primaryMot changes
    if (defaultPrimaryMot?.name !== primaryMot.name) {
      setInputValue('');
      setSelectedKey(null);
      onSelectionChange?.(JSON.stringify({ name: '', external_id: '' }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryMot]);

  const handleInputChange = (value: string) => {
    if (!value) {
      setSelectedKey(null);
    }
    setInputValue(value);
    onInputChange?.(value);
    onInputChangeWithItems?.({ value, vessels: vesselsWithNotFoundOption });
  };

  const handleSelectionChange = (key: SelectionKey) => {
    if (!key || typeof key !== 'string') {
      return;
    }

    setSelectedKey(key);

    const { name, external_id }: VesselSuggestion = JSON.parse(key);

    // If vessel lookup is mandatory, we always want to save the value the user selected,
    // even if it's the "Can't find vessel" option
    if (isRequired) {
      setInputValue(name);
      onSelectionChange?.(JSON.stringify({ name, external_id }));

      return;
    }

    // Vessel lookup not mandatory, so we want to save the value the user typed in the input
    if (external_id === VESSEL_NOT_FOUND_ID) {
      setInputValue(debouncedQuery);
      onSelectionChange?.(
        JSON.stringify({ name: debouncedQuery, external_id: undefined }),
      );

      return;
    }

    setInputValue(name);
    onSelectionChange?.(JSON.stringify({ name, external_id }));
  };

  return (
    <ComboBox
      data-testid="vessel-search-field"
      isRequired={isRequired}
      label="Vessel name"
      placeholder="Type to search vessel name or IMO"
      items={vesselsWithNotFoundOption}
      isFetchingItems={isFetching}
      inputValue={inputValue}
      selectedKey={selectedKey}
      onInputChange={handleInputChange}
      onSelectionChange={handleSelectionChange}
      allowsEmptyCollection
      emptyCollectionMessage={getEmptyCollectionMessage(inputValue)}
      {...props}
    >
      {vesselsWithNotFoundOption?.map(({ name, external_id, imo_number }) => {
        const id = JSON.stringify({ name, external_id });

        return (
          <ComboBoxItem
            key={id}
            id={id}
            // make typeahead text = query so "Can't find vessel" is always displayed,
            textValue={
              external_id === VESSEL_NOT_FOUND_ID
                ? debouncedQuery
                : `${name}${imo_number}`
            }
            className="flex flex-col items-start"
          >
            <Typography>{name}</Typography>
            {!!imo_number && (
              <Typography level="subtext" color="secondary">
                {imo_number}
              </Typography>
            )}
          </ComboBoxItem>
        );
      })}
    </ComboBox>
  );
}
