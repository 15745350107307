import capitalize from 'lodash/capitalize';

export const getModeOfTransportDisplayString = (
  primaryTransportMode: string,
  hasSecondaries: boolean,
) => {
  const capitalizedTransportMode = capitalize(primaryTransportMode);

  return hasSecondaries
    ? `${capitalizedTransportMode}, Road`
    : capitalizedTransportMode;
};
