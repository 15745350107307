import { FaPlus } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { LinkButton, SectionLayout } from '@breezeai-frontend/cargo-ui';

import { useUser } from '../../../context/auth/auth-hooks';
import { useTracking } from '../../../utils/snowplow/hooks';
import { DeclarationCreationModal } from '../declarations/DeclarationCreationModal/DeclarationCreationModal';
import { DeclarationCreationOutcomeModal } from '../declarations/DeclarationCreationOutcomeModal/DeclarationCreationOutcomeModal';
import { StorageCreationModal } from '../locations/StorageCreationModal/StorageCreationModal';
import { StorageTable } from '../locations/StorageTable/StorageTable';

export const StoragePage = ({
  declarationCreationModalOpen,
}: {
  declarationCreationModalOpen?: boolean;
}) => {
  useTracking();
  const user = useUser();
  const isAuthorized = Boolean(user?.permissions.storage_locations?.view);
  const isAuthorizedToCreateLocations =
    user?.permissions.storage_locations?.create;
  const isAuthorizedToCreateDeclarations =
    user?.permissions.storage_locations?.create_declaration;
  const navigate = useNavigate();
  // TODO make strictly typed using zod
  const { state } = useLocation();
  const { declarationCreationResponse } = state ?? {};

  return (
    <SectionLayout
      isAuthorized={isAuthorized}
      title="Locations"
      data-testid="storage-page"
      actions={
        isAuthorizedToCreateLocations && (
          <div className="flex flex-row gap-2">
            <LinkButton
              size="small"
              data-testid="add-location-button"
              href="/storage/create/details"
              width="auto"
              label="Add New Location"
              variant="secondary"
              leftIcon={<FaPlus />}
            />
          </div>
        )
      }
    >
      {/* Open state dependant on path */}
      <StorageCreationModal />

      {/* Open state dependant on path */}
      {isAuthorizedToCreateDeclarations && (
        <DeclarationCreationModal
          isOpen={Boolean(declarationCreationModalOpen)}
          isDismissable
          showExitButton
          onExit={() => {
            navigate('/storage');
          }}
          onCreation={(declarationCreationResponse) => {
            navigate('/storage', {
              state: { declarationCreationResponse },
            });
          }}
          navigateToOnCancel="/storage"
        />
      )}

      {/* Open state dependant on route state */}
      <DeclarationCreationOutcomeModal
        showViewLocationDetailsButton
        isOpen={!!declarationCreationResponse}
        onExit={() =>
          navigate('/storage', {
            state: {},
          })
        }
        {...declarationCreationResponse}
      />
      <StorageTable />
    </SectionLayout>
  );
};
