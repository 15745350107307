import { queryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { type OpenCover as GetDistributorActiveOpenCoverResponse } from '../../../model/OpenCover';
import { bffServiceUrl } from '../../netconfig';
import { get } from '../utils';

export const distributorQueries = {
  activeOpenCover: ({ distributorId }: { distributorId: number }) =>
    queryOptions({
      queryKey: ['distributorActiveOpenCover', distributorId],
      queryFn: () =>
        get<GetDistributorActiveOpenCoverResponse>(
          `${bffServiceUrl}/bff-distributors/${distributorId}/active-open-cover`,
        ),
      throwOnError: (err) => {
        // Throw only if the error is not a 404, we want to handle that in the component
        if (!(err instanceof AxiosError) || err.response?.status !== 404) {
          return true;
        }

        return false;
      },
    }),
};
