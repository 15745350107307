/* tslint:disable */
/* eslint-disable */
/**
 * Service API Document
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { BffCustomerModel } from '../models';
// @ts-ignore
import type { BffGetCustomersQueryParamsCustomerSortByColumnsOptions } from '../models';
// @ts-ignore
import type { BffGetCustomersQueryParamsSortByDirectionOptions } from '../models';
// @ts-ignore
import type { BffGetCustomersResponse } from '../models';
// @ts-ignore
import type { BffUpdateCustomerPayload } from '../models';
// @ts-ignore
import type { BffUpdateCustomersBatchPayload } from '../models';
// @ts-ignore
import type { BffUpdateCustomersBatchResponse } from '../models';
// @ts-ignore
import type { BffValidationErrorValidationErrorElement } from '../models';
/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get_customers <GET>
         * @param {number} [limit] 
         * @param {number} [pagePointer] 
         * @param {BffGetCustomersQueryParamsCustomerSortByColumnsOptions} [sortBy] 
         * @param {BffGetCustomersQueryParamsSortByDirectionOptions} [order] 
         * @param {boolean} [paginate] 
         * @param {string} [ids] 
         * @param {string} [query] 
         * @param {boolean} [automaticallyInsured] 
         * @param {number} [distributorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers: async (limit?: number, pagePointer?: number, sortBy?: BffGetCustomersQueryParamsCustomerSortByColumnsOptions, order?: BffGetCustomersQueryParamsSortByDirectionOptions, paginate?: boolean, ids?: string, query?: string, automaticallyInsured?: boolean, distributorId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pagePointer !== undefined) {
                localVarQueryParameter['page_pointer'] = pagePointer;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (paginate !== undefined) {
                localVarQueryParameter['paginate'] = paginate;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (automaticallyInsured !== undefined) {
                localVarQueryParameter['automatically_insured'] = automaticallyInsured;
            }

            if (distributorId !== undefined) {
                localVarQueryParameter['distributor_id'] = distributorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update_customer <PUT>
         * @param {number} customerId 
         * @param {BffUpdateCustomerPayload} [bffUpdateCustomerPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomer: async (customerId: number, bffUpdateCustomerPayload?: BffUpdateCustomerPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('updateCustomer', 'customerId', customerId)
            const localVarPath = `/customers/{customer_id}`
                .replace(`{${"customer_id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bffUpdateCustomerPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update_customer_batch <PUT>
         * @param {BffUpdateCustomersBatchPayload} [bffUpdateCustomersBatchPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerBatch: async (bffUpdateCustomersBatchPayload?: BffUpdateCustomersBatchPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bffUpdateCustomersBatchPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get_customers <GET>
         * @param {number} [limit] 
         * @param {number} [pagePointer] 
         * @param {BffGetCustomersQueryParamsCustomerSortByColumnsOptions} [sortBy] 
         * @param {BffGetCustomersQueryParamsSortByDirectionOptions} [order] 
         * @param {boolean} [paginate] 
         * @param {string} [ids] 
         * @param {string} [query] 
         * @param {boolean} [automaticallyInsured] 
         * @param {number} [distributorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomers(limit?: number, pagePointer?: number, sortBy?: BffGetCustomersQueryParamsCustomerSortByColumnsOptions, order?: BffGetCustomersQueryParamsSortByDirectionOptions, paginate?: boolean, ids?: string, query?: string, automaticallyInsured?: boolean, distributorId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BffGetCustomersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomers(limit, pagePointer, sortBy, order, paginate, ids, query, automaticallyInsured, distributorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersApi.getCustomers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update_customer <PUT>
         * @param {number} customerId 
         * @param {BffUpdateCustomerPayload} [bffUpdateCustomerPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomer(customerId: number, bffUpdateCustomerPayload?: BffUpdateCustomerPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BffCustomerModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomer(customerId, bffUpdateCustomerPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersApi.updateCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update_customer_batch <PUT>
         * @param {BffUpdateCustomersBatchPayload} [bffUpdateCustomersBatchPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerBatch(bffUpdateCustomersBatchPayload?: BffUpdateCustomersBatchPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BffUpdateCustomersBatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerBatch(bffUpdateCustomersBatchPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersApi.updateCustomerBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersApiFp(configuration)
    return {
        /**
         * 
         * @summary get_customers <GET>
         * @param {CustomersApiGetCustomersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers(requestParameters: CustomersApiGetCustomersRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BffGetCustomersResponse> {
            return localVarFp.getCustomers(requestParameters.limit, requestParameters.pagePointer, requestParameters.sortBy, requestParameters.order, requestParameters.paginate, requestParameters.ids, requestParameters.query, requestParameters.automaticallyInsured, requestParameters.distributorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update_customer <PUT>
         * @param {CustomersApiUpdateCustomerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomer(requestParameters: CustomersApiUpdateCustomerRequest, options?: RawAxiosRequestConfig): AxiosPromise<BffCustomerModel> {
            return localVarFp.updateCustomer(requestParameters.customerId, requestParameters.bffUpdateCustomerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update_customer_batch <PUT>
         * @param {CustomersApiUpdateCustomerBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerBatch(requestParameters: CustomersApiUpdateCustomerBatchRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BffUpdateCustomersBatchResponse> {
            return localVarFp.updateCustomerBatch(requestParameters.bffUpdateCustomersBatchPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCustomers operation in CustomersApi.
 * @export
 * @interface CustomersApiGetCustomersRequest
 */
export interface CustomersApiGetCustomersRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomersApiGetCustomers
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof CustomersApiGetCustomers
     */
    readonly pagePointer?: number

    /**
     * 
     * @type {BffGetCustomersQueryParamsCustomerSortByColumnsOptions}
     * @memberof CustomersApiGetCustomers
     */
    readonly sortBy?: BffGetCustomersQueryParamsCustomerSortByColumnsOptions

    /**
     * 
     * @type {BffGetCustomersQueryParamsSortByDirectionOptions}
     * @memberof CustomersApiGetCustomers
     */
    readonly order?: BffGetCustomersQueryParamsSortByDirectionOptions

    /**
     * 
     * @type {boolean}
     * @memberof CustomersApiGetCustomers
     */
    readonly paginate?: boolean

    /**
     * 
     * @type {string}
     * @memberof CustomersApiGetCustomers
     */
    readonly ids?: string

    /**
     * 
     * @type {string}
     * @memberof CustomersApiGetCustomers
     */
    readonly query?: string

    /**
     * 
     * @type {boolean}
     * @memberof CustomersApiGetCustomers
     */
    readonly automaticallyInsured?: boolean

    /**
     * 
     * @type {number}
     * @memberof CustomersApiGetCustomers
     */
    readonly distributorId?: number
}

/**
 * Request parameters for updateCustomer operation in CustomersApi.
 * @export
 * @interface CustomersApiUpdateCustomerRequest
 */
export interface CustomersApiUpdateCustomerRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomersApiUpdateCustomer
     */
    readonly customerId: number

    /**
     * 
     * @type {BffUpdateCustomerPayload}
     * @memberof CustomersApiUpdateCustomer
     */
    readonly bffUpdateCustomerPayload?: BffUpdateCustomerPayload
}

/**
 * Request parameters for updateCustomerBatch operation in CustomersApi.
 * @export
 * @interface CustomersApiUpdateCustomerBatchRequest
 */
export interface CustomersApiUpdateCustomerBatchRequest {
    /**
     * 
     * @type {BffUpdateCustomersBatchPayload}
     * @memberof CustomersApiUpdateCustomerBatch
     */
    readonly bffUpdateCustomersBatchPayload?: BffUpdateCustomersBatchPayload
}

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
    /**
     * 
     * @summary get_customers <GET>
     * @param {CustomersApiGetCustomersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getCustomers(requestParameters: CustomersApiGetCustomersRequest = {}, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).getCustomers(requestParameters.limit, requestParameters.pagePointer, requestParameters.sortBy, requestParameters.order, requestParameters.paginate, requestParameters.ids, requestParameters.query, requestParameters.automaticallyInsured, requestParameters.distributorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update_customer <PUT>
     * @param {CustomersApiUpdateCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public updateCustomer(requestParameters: CustomersApiUpdateCustomerRequest, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).updateCustomer(requestParameters.customerId, requestParameters.bffUpdateCustomerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update_customer_batch <PUT>
     * @param {CustomersApiUpdateCustomerBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public updateCustomerBatch(requestParameters: CustomersApiUpdateCustomerBatchRequest = {}, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).updateCustomerBatch(requestParameters.bffUpdateCustomersBatchPayload, options).then((request) => request(this.axios, this.basePath));
    }
}

