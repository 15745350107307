import { useTranslation } from 'react-i18next';
import { DatePicker, Typography } from '@breezeai-frontend/cargo-ui';
import { Button, clsxMerge } from '@breezeai-frontend/cargo-ui';
import { type IconName } from '@fortawesome/fontawesome-svg-core';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classnames from 'classnames';
import moment, { type Moment } from 'moment';

import { usePlatform } from '../../../../../context/PlatformContext';
import { TriggerButton } from '../TriggerButton/TriggerButton';
import styles from './RadioDropdown.module.scss';

type Option = {
  label: string;
  value: string;
};

export type HandleCustomDateRangeChangeProps = {
  type: 'start' | 'end';
  value: Moment;
};

type CustomDateRangeProps = {
  minEndDate?: string;
  maxStartDate?: string;
  onCustomDateChange: ({
    type,
    value,
  }: HandleCustomDateRangeChangeProps) => void;
};

const CustomDateRange = ({
  minEndDate,
  maxStartDate,
  onCustomDateChange,
}: CustomDateRangeProps) => {
  const dateRangeMinEndDate = moment(minEndDate, 'DD/MM/YYYY');
  const dateRangeMaxStartDate = moment(maxStartDate, 'DD/MM/YYYY');
  return (
    <div
      className={styles.customDateRangeContainer}
      data-testid="custom-date-range-container"
    >
      <DatePicker
        data-testid="custom-date-range-start"
        label="Start"
        onChange={(e) =>
          onCustomDateChange({
            type: 'start',
            value: moment(e.toString()),
          })
        }
        isDateUnavailable={(date) =>
          moment(date.toString()).isAfter(dateRangeMaxStartDate)
        }
      />
      <DatePicker
        data-testid="custom-date-range-end"
        label="End"
        onChange={(e) =>
          onCustomDateChange({
            type: 'end',
            value: moment(e.toString()),
          })
        }
        isDateUnavailable={(date) =>
          moment(date.toString()).isBefore(dateRangeMinEndDate)
        }
      />
    </div>
  );
};

export const RadioDropdown = ({
  options,
  onSelect,
  selected,
  setSelected,
  name,
  label,
  dropdownIcon,
  customDateRange,
  triggerTestId,
  contentTestId,
  onReset,
}: {
  options: Option[];
  onSelect: (option: string) => void;
  selected: string | undefined;
  setSelected: (selected: string) => void;
  name: string;
  label: string;
  dropdownIcon?: IconName;
  customDateRange?: CustomDateRangeProps;
  triggerTestId?: string;
  contentTestId?: string;
  onReset: () => void;
}) => {
  const { platform } = usePlatform();
  const { t } = useTranslation();
  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild data-testid={triggerTestId}>
        <TriggerButton
          selectedCount={selected ? 1 : undefined}
          name={name}
          label={label}
          prefixIcon={dropdownIcon}
          suffixIcon="chevron-down"
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          data-testid={contentTestId}
          sideOffset={5}
          align="start"
          className={styles.dropdownMenuContent}
        >
          <DropdownMenu.RadioGroup
            value={selected}
            onValueChange={setSelected}
            className={styles.radioGroupRoot}
            aria-label="Select Option"
            data-testid="radio-group"
          >
            {options.map(({ value, label }) => {
              return (
                <button
                  key={value}
                  className={classnames(
                    styles.radioGroupItemContainer,
                    'hover:bg-dropdown-row-bg-hover',
                    {
                      ['bg-dropdown-row-bg-selected hover:bg-dropdown-row-bg-selected']:
                        selected === value,
                    },
                  )}
                  onClick={() => {
                    // Clear custom date range upon clicking teh option. This causes an unnecessary api call for all dates.
                    value === 'custom_dates' && onReset();

                    setSelected(value);
                    onSelect(value);
                  }}
                >
                  <DropdownMenu.RadioItem
                    onSelect={(e) => {
                      e.preventDefault();
                      onSelect(value);
                    }}
                    className={styles.RadioGroupItem}
                    value={value}
                    id={value}
                  >
                    <DropdownMenu.ItemIndicator
                      className={clsxMerge(
                        styles.radioGroupIndicator,
                        'after:bg-buttons-primary-bg-default',
                      )}
                    />
                  </DropdownMenu.RadioItem>
                  <label className={styles.label} htmlFor={value}>
                    <Typography>{label}</Typography>
                  </label>
                </button>
              );
            })}
          </DropdownMenu.RadioGroup>
          {customDateRange && <CustomDateRange {...customDateRange} />}

          <div className="flex justify-end w-full border-t-[1px] border-t-gray-200 py-1 mt-1">
            <Button
              data-testid="reset-radio-button"
              variant="ghost"
              width="auto"
              label={t('tables.filters.Reset', {
                ns: 'common',
                context: platform,
              })}
              onPress={onReset}
            />
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
