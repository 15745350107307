import { type FormApi } from '@tanstack/react-form';
import { type zodValidator } from '@tanstack/zod-form-adapter';

import { type BffGetConfigurationsResponseContainerModesByConveyance } from '../../../../generated/api-client';
import { type Quote } from '../../../../model/Quote';
import { type ConveyanceType, type Shipment } from '../../../../model/Shipment';
import { type QuoteFormData } from './QuoteForm';

export const getDefaultContainerModeId = (
  containerModes: BffGetConfigurationsResponseContainerModesByConveyance,
  preferredModeOfTransportCode: ConveyanceType,
  existingData: Quote | Shipment | null,
  isWtw: boolean,
) => {
  if (isWtw) {
    if (containerModes.any.length === 1) return containerModes.any[0].id;
    // It is possible that there are no "any" container modes for wtw users
    return containerModes.any.find(
      ({ title }) => existingData?.container_mode === title,
    )?.id;
  }

  // If there is no container mode or primary transport mode, return the first container mode for the primary MOT
  if (!existingData || !existingData.primary_transport_mode_code)
    return containerModes[preferredModeOfTransportCode][0].id;

  const { container_mode, primary_transport_mode_code } = existingData;

  return containerModes[primary_transport_mode_code].find(
    ({ title }) => title === container_mode,
  )?.id;
};

/**
 * Takes a form and a list of fields (or a single field) and
 * checks if any of the specified fields are dirty.
 * @param form
 * @param fields single field or list of fields
 * @returns true if any of the fields is dirty, false otherwise
 */
export const isAnyFieldDirty = (
  form: FormApi<QuoteFormData, typeof zodValidator>,
  fields: keyof QuoteFormData | (keyof QuoteFormData)[],
) => {
  if (!Array.isArray(fields)) return form.state.fieldMeta[fields].isDirty;

  return fields.some((field) => form.state.fieldMeta[field].isDirty);
};
