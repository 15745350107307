import { type CurrencyDefinition } from '../../../model/CurrencyValue';
import { type Distributor } from '../../../model/Distributor';
import { FilterableFields } from '../../../network/apis/types';
import { type AccountUser } from '../../../network/apis/users/types';
import { type UNITS } from './hooks/useQuantityUnits';

// TODO to be replaced by generated types
export type StorageDeclarationStatus =
  | 'issued'
  | 'declined'
  | 'pending_review'
  | 'archived';

export interface CreateStorageDeclarationPayload {
  date_of_issuance: string | undefined;
  start_date: string;
  end_date: string;
  distributor_id: string;
  customer_id: string | undefined;
  customer_name: string | undefined;
  customer_address:
    | {
        place_id: string | undefined;
        session_token: string | undefined;
      }
    | undefined;
  commodity_id: string;
  description: string;
  insured_value_currency_code: string;
  insured_value_amount: string;
  quantity_amount: string | undefined;
  quantity_unit: string | undefined;
  create_certificate: boolean;
}

export interface CreateStorageDeclarationResponse {
  id: string;
  status: StorageDeclarationStatus;
  exclusions?: {
    name: string;
    value: string;
  }[];
  certificate_url?: string;
  location_id: string;
  location_name: string;
}

// TODO to be replaced by generated types
export interface StorageDeclaration extends Record<string, unknown> {
  id: number;
  declaration_number: string;
  date_of_issuance?: string;
  start_date: string;
  end_date: string;
  distributor: Distributor;
  commodity?: { commodity_type: string; hs_codes: string[]; id: string };
  insured_value_currency: CurrencyDefinition;
  insured_value_amount: string;
  premium_amount: string;
  premium_currency: CurrencyDefinition;
  certificate_url?: string;
  status: StorageDeclarationStatus;
  created_time: string;
  created_by_user?: AccountUser;
  quantity_unit?: (typeof UNITS)[number];
  quantity_amount?: number;
  create_certificate: boolean;
}

export type DeclarationsSortableFields =
  | 'id'
  | 'distributor_id'
  | 'date_of_issuance'
  | 'start_date'
  | 'end_date'
  | 'commodity_id'
  | 'insured_value'
  | 'premium'
  | 'status'
  | 'created_at'
  | 'quantity';

export const storageTableFilters = [FilterableFields.DISTRIBUTOR_ID];
