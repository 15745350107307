import { Suspense } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  clsxMerge,
  Modal,
  type ModalProps,
  Spinner,
  Typography,
} from '@breezeai-frontend/cargo-ui';

import { type StorageRouteParams } from '../../types';
import { type StorageCreationModalRouteParams } from '../types';
import { StepperProgressBar } from './components/StepperProgressBar';
import { StorageFileUploadForm } from './components/StorageFileUploadForm';
import { StorageInputsForm } from './components/StorageInputsForm';

const Fallback = () => (
  <div className="flex justify-center items-center h-96">
    <Spinner />
  </div>
);

export function StorageCreationModal({
  ...props
}: Omit<ModalProps, 'children'>) {
  const { step } = useParams<StorageCreationModalRouteParams>();
  let currentStep: number;
  switch (step) {
    case 'inputs':
      currentStep = 1;
      break;
    case 'files':
      currentStep = 2;
      break;
    default:
      currentStep = 1;
      break;
  }

  const { pathname } = useLocation();
  const isUpdate = pathname.includes('update');
  const navigate = useNavigate();

  const title = isUpdate
    ? 'Update Storage Location'
    : 'Create New Storage Location';
  const { locationId } = useParams<StorageRouteParams>();
  const navigateToOnExit = isUpdate
    ? `/storage/details/${locationId}`
    : '/storage';

  return (
    <Modal
      {...props}
      data-testid="storage-location-modal"
      showExitButton
      onExit={() => {
        if (currentStep === 2) {
          window.location.replace(navigateToOnExit);
        } else {
          navigate(navigateToOnExit, { replace: true });
        }
      }}
      // TODO use prop to determine open state for better clarity
      isOpen={!!step}
      heading={
        <div className={clsxMerge(isUpdate && 'mb-6', 'w-full')}>
          <Typography level="h4">{title}</Typography>
        </div>
      }
      className="w-[762px]"
    >
      <div className="w-full h-full space-y-4 flex flex-col max-h-[85vh] overflow-y-auto">
        {!isUpdate && <StepperProgressBar currentStep={currentStep} />}
        {currentStep === 1 ? (
          <Suspense fallback={<Fallback />}>
            <StorageInputsForm isUpdate={isUpdate} />
          </Suspense>
        ) : (
          <Suspense fallback={<Fallback />}>
            <StorageFileUploadForm isUpdate={isUpdate} />
          </Suspense>
        )}
      </div>
    </Modal>
  );
}
