import { useTranslation } from 'react-i18next';

import { usePlatform } from '../../../../context/PlatformContext';

export const UNITS = ['kg', 'mt', 'lb'] as const;

export const useQuantityUnits = () => {
  const { t } = useTranslation();
  const { platform } = usePlatform();
  const quantityUnits: {
    label: string;
    value: (typeof UNITS)[number];
  }[] = [
    {
      label: t('declarations.form.quantity.Kg', {
        ns: 'storage',
        context: platform,
      }),
      value: 'kg',
    },
    {
      label: t('declarations.form.quantity.MetricTons', {
        ns: 'storage',
        context: platform,
      }),
      value: 'mt',
    },
    {
      label: t('declarations.form.quantity.Pounds', {
        ns: 'storage',
        context: platform,
      }),
      value: 'lb',
    },
  ];

  const getQuantityUnitLabel = (unit: (typeof UNITS)[number]) => {
    const option = quantityUnits.find((option) => option.value === unit);
    if (!option) {
      return unit;
    }
    return option.label;
  };
  return { quantityUnits, getQuantityUnitLabel };
};
