import { type ErrorResponse } from 'react-router-dom';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

import {
  type BffQuoteModelWithPermissions,
  QuotesApi,
  type QuotesApiDuplicateQuoteRequest,
  type QuotesApiPostQuoteFormRequest,
  type QuotesApiUpdateQuoteRequest,
} from '../../../generated/api-client';
import {
  getBffApiConfiguration,
  type NetworkResponse,
} from '../../AuthRequests';

export const useCreateQuote = (
  options: UseMutationOptions<
    NetworkResponse<BffQuoteModelWithPermissions>,
    ErrorResponse,
    QuotesApiPostQuoteFormRequest
  >,
) => {
  return useMutation({
    mutationFn: async (payload) => {
      const config = await getBffApiConfiguration();
      return new QuotesApi(config).postQuoteForm(payload);
    },
    ...options,
  });
};

export const useUpdateQuote = (
  options: UseMutationOptions<
    NetworkResponse<BffQuoteModelWithPermissions>,
    ErrorResponse,
    QuotesApiUpdateQuoteRequest
  >,
) => {
  return useMutation({
    mutationFn: async (payload) => {
      const config = await getBffApiConfiguration();
      return new QuotesApi(config).updateQuote(payload);
    },
    ...options,
  });
};

export const useDuplicateQuote = (
  options: UseMutationOptions<
    NetworkResponse<BffQuoteModelWithPermissions>,
    ErrorResponse,
    QuotesApiDuplicateQuoteRequest
  >,
) => {
  return useMutation({
    mutationFn: async (payload) => {
      const config = await getBffApiConfiguration();
      return new QuotesApi(config).duplicateQuote(payload);
    },
    ...options,
  });
};
