import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  type ModalProps,
  Typography,
} from '@breezeai-frontend/cargo-ui';

import { usePlatform } from '../../../context/PlatformContext';

export function NoActiveOpenCoverModal({
  onOpenChange,
  ...props
}: Omit<ModalProps, 'children'>) {
  const { platform } = usePlatform();
  const { t } = useTranslation();

  return (
    <Modal
      {...props}
      onOpenChange={onOpenChange}
      heading={
        <div className="space-y-2 w-full pb-10 pt-4">
          <Typography level="h3">
            {t('NoActiveOpenCoverTitle', {
              ns: 'common',
              context: platform,
            })}
          </Typography>
          <Typography color="secondary">
            {t('NoActiveOpenCoverDescription', {
              ns: 'common',
              context: platform,
            })}
          </Typography>
        </div>
      }
      className="max-w-xl"
    >
      <div className="flex flex-row gap-3 w-full justify-end">
        <Button
          width="auto"
          onPress={() => onOpenChange?.(false)}
          label="OK"
          size="large"
        />
      </div>
    </Modal>
  );
}
