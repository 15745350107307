import { useQuery } from '@tanstack/react-query';

import { useRouteParams } from '../../../../router/router-hooks';
import { AdditionalClauses } from '../../../components/AdditionalClauses/AdditionalClauses.tsx';
import { certificateQueries } from '../../network/queries';
import { type CertificatesRouteParams } from '../../types';

export function CertificateAdditionalClauses() {
  const { params } = useRouteParams<CertificatesRouteParams>();
  const { policyId } = params;

  const { data: certificate } = useQuery(
    certificateQueries.details({ policyId }),
  );

  const additionalClause = certificate?.quote?.quote_additional_clauses?.[0];

  if (!additionalClause) {
    return null;
  }

  return <AdditionalClauses additionalClause={additionalClause} />;
}
