import { useTranslation } from 'react-i18next';
import { RadioGroup, RadioOption } from '@breeze-ai/ui-library';
import { ComboBox, ComboBoxItem } from '@breezeai-frontend/cargo-ui';
import { type FieldApi } from '@tanstack/react-form';
import { type zodValidator } from '@tanstack/zod-form-adapter';

import { useAppConfiguration } from '../../../context/app-configuration/useAppConfiguration';
import { usePlatform } from '../../../context/PlatformContext';
import { COVERAGE_PACKAGES } from '../../constants';
import { type QuoteFormData } from '../../policy-flow/steps/QuoteForm/QuoteForm';

type CoverageTypeField = FieldApi<
  QuoteFormData,
  'coveragePackage',
  undefined,
  typeof zodValidator
>;

export function CoverageTypeRadio({ field }: { field: CoverageTypeField }) {
  const { available_coverage_package: coverage_options } =
    useAppConfiguration();

  return (
    <RadioGroup
      selected={field.state.value ?? coverage_options[0]}
      onChange={(value) => {
        const option = coverage_options.find((option) => option === value);
        field.handleChange(option);
      }}
      name="coverage-type-radio-group"
      outlined
      className="flex items-center w-full gap-4"
      data-testid="coverage-type-radio-selector"
    >
      {coverage_options.map((coverageType, i) => {
        const { label, description } = COVERAGE_PACKAGES[coverageType];

        return (
          <RadioOption
            key={i}
            value={coverageType}
            label={label}
            description={description}
            data-testid={`select-option-${coverageType}`}
          />
        );
      })}
    </RadioGroup>
  );
}

export function CoverageTypeComboBox({ field }: { field: CoverageTypeField }) {
  const { available_coverage_package: coverage_options } =
    useAppConfiguration();

  const { platform } = usePlatform();
  const { t } = useTranslation();

  return (
    <ComboBox
      isRequired
      aria-label={t('CoverageTypePlaceholder', {
        ns: 'common',
        context: platform,
      })}
      data-testid="coverage-type-combobox"
      label={t('CoverageType', {
        ns: 'common',
        context: platform,
      })}
      placeholder={t('CoverageTypePlaceholder', {
        ns: 'common',
        context: platform,
      })}
      id={field.name}
      name={field.name}
      onBlur={field.handleBlur}
      menuTrigger="focus"
      defaultSelectedKey={field.getValue()}
      onSelectionChange={(key) => {
        const selection = String(key);
        const selectedCoveragePackage = coverage_options.find(
          (option) => option === selection,
        );
        field.handleChange(selectedCoveragePackage);
      }}
      isInvalid={field.state.meta.errors.length > 0}
      errorMessage={field.state.meta.errors.join(', ')}
    >
      {coverage_options.map((option) => {
        const label = COVERAGE_PACKAGES[option].label;
        return (
          <ComboBoxItem key={option} id={option} textValue={label}>
            {label}
          </ComboBoxItem>
        );
      })}
    </ComboBox>
  );
}

interface Props {
  field: CoverageTypeField;
  type: 'radio' | 'select';
}

export const CoverageTypeSelector = ({ field, type }: Props) => {
  return type === 'radio' ? (
    <CoverageTypeRadio field={field} />
  ) : (
    <CoverageTypeComboBox field={field} />
  );
};
