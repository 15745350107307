import { FormLayout, FormRow, Typography } from '@breeze-ai/ui-library';
import {
  Checkbox,
  ComboBox,
  ComboBoxItem,
  DatePicker,
} from '@breezeai-frontend/cargo-ui';
import { momentToCalendarDate } from '@breezeai-frontend/cargo-ui';
import moment from 'moment';

import { type BffGetConfigurationsResponseClaimsAdditionalFieldsOptions } from '../../../../generated/api-client';
import {
  useClaimCreationFlowContext,
  useClaimCreationStateAction,
} from '../context/hooks';
import { useClaimFormInputValidation } from '../form-validations';

export const AdditionalDetailsForm = ({
  claims_additional_fields_options,
}: {
  claims_additional_fields_options: BffGetConfigurationsResponseClaimsAdditionalFieldsOptions;
}) => {
  const { formsData, formErrors } = useClaimCreationFlowContext();
  const { setFormData } = useClaimCreationStateAction();
  const validateInput = useClaimFormInputValidation();

  return (
    <FormLayout>
      <FormRow gutter={20}>
        <Checkbox
          isSelected={formsData.shippersInterestClaim}
          onChange={() => {
            setFormData({
              shippersInterestClaim: !formsData.shippersInterestClaim,
            });
          }}
        >
          <Typography>Shipper’s interest claim</Typography>
        </Checkbox>
      </FormRow>
      <FormRow gutter={20}>
        <ComboBox
          label="Type of claim"
          placeholder="Select type"
          isRequired={true}
          errorMessage={formErrors.typeOfClaim?.reason}
          data-testid="type-of-claim-selector"
          defaultInputValue={formsData.typeOfClaim}
          onSelectionChange={(key) => {
            const value = key?.toString() || '';
            validateInput('typeOfClaim', value);
            setFormData({ typeOfClaim: value });
          }}
          isInvalid={!!formErrors.typeOfClaim?.reason}
        >
          {claims_additional_fields_options?.type_of_claim?.map((option) => (
            <ComboBoxItem key={option} id={option}>
              {option}
            </ComboBoxItem>
          ))}
        </ComboBox>
        <ComboBox
          label="Common reason claim"
          placeholder="Select reason"
          isRequired={true}
          data-testid="common-reason-claim-selector"
          defaultInputValue={formsData.commonReasonClaim}
          onSelectionChange={(key) => {
            validateInput('commonReasonClaim', key?.toString() || '');
            setFormData({ commonReasonClaim: key?.toString() });
          }}
          errorMessage={formErrors.commonReasonClaim?.reason}
          isInvalid={!!formErrors.commonReasonClaim?.reason}
        >
          {claims_additional_fields_options?.common_reason_claim?.map(
            (option) => (
              <ComboBoxItem key={option} id={option}>
                {option}
              </ComboBoxItem>
            ),
          )}
        </ComboBox>
        <ComboBox
          label="Country manager/Ops director"
          placeholder="Select director"
          isRequired={true}
          data-testid="country-manager-ops-director-selector"
          defaultInputValue={formsData.countryManagerOpsDirector}
          onSelectionChange={(key) => {
            const value = key?.toString() || '';
            validateInput('countryManagerOpsDirector', value);
            setFormData({ countryManagerOpsDirector: value });
          }}
          errorMessage={formErrors.countryManagerOpsDirector?.reason}
          isInvalid={!!formErrors.countryManagerOpsDirector?.reason}
        >
          {claims_additional_fields_options?.country_manager_ops_director?.map(
            (option) => (
              <ComboBoxItem key={option} id={option}>
                {option}
              </ComboBoxItem>
            ),
          )}
        </ComboBox>
      </FormRow>
      <FormRow gutter={20}>
        <ComboBox
          label="Managing director"
          placeholder="Select managing director"
          isRequired={true}
          data-testid="managing-director-selector"
          defaultInputValue={formsData.managingDirector}
          onSelectionChange={(key) => {
            const value = key?.toString() || '';
            validateInput('managingDirector', value);
            setFormData({ managingDirector: value });
          }}
          errorMessage={formErrors.managingDirector?.reason}
          isInvalid={!!formErrors.managingDirector?.reason}
        >
          {claims_additional_fields_options?.managing_director?.map(
            (option) => (
              <ComboBoxItem key={option} id={option}>
                {option}
              </ComboBoxItem>
            ),
          )}
        </ComboBox>
        <ComboBox
          label="Transportation of loss/damage"
          placeholder="Select transportation"
          isRequired={true}
          data-testid="transportation-selector"
          defaultInputValue={formsData.transportationOfLossDamage}
          onSelectionChange={(key) => {
            const value = key?.toString() || '';
            validateInput('transportationOfLossDamage', value);
            setFormData({ transportationOfLossDamage: value });
          }}
          errorMessage={formErrors.transportationOfLossDamage?.reason}
          isInvalid={!!formErrors.transportationOfLossDamage?.reason}
        >
          {claims_additional_fields_options?.transportation_of_loss?.map(
            (option) => (
              <ComboBoxItem key={option} id={option}>
                {option}
              </ComboBoxItem>
            ),
          )}
        </ComboBox>
        <DatePicker
          label="Date damage reported to BDP"
          isRequired={true}
          data-testid="date-damaged-reported-selector"
          onChange={(date) => {
            validateInput(
              'dateDamagedReported',
              date !== null ? date.toString() : '',
            );
            setFormData({ dateDamagedReported: moment(date.toString()) });
          }}
          defaultValue={
            formsData.dateDamagedReported
              ? momentToCalendarDate(formsData.dateDamagedReported)
              : undefined
          }
          errorMessage={formErrors.dateDamagedReported?.reason}
          isInvalid={!!formErrors.dateDamagedReported?.reason}
        />
      </FormRow>
      <FormRow gutter={20}>
        <DatePicker
          label="Notice of intent date"
          isRequired={true}
          data-testid="notice-of-intent-date-selector"
          onChange={(date) => {
            validateInput(
              'noticeOfIncidentDate',
              date !== null ? date.toString() : '',
            );
            setFormData({ noticeOfIncidentDate: moment(date.toString()) });
          }}
          defaultValue={
            formsData.noticeOfIncidentDate
              ? momentToCalendarDate(formsData.noticeOfIncidentDate)
              : undefined
          }
          errorMessage={formErrors.noticeOfIncidentDate?.reason}
          isInvalid={!!formErrors.noticeOfIncidentDate?.reason}
        />
        <DatePicker
          label="BL Date"
          data-testid="bl-date-selector"
          onChange={(date) => {
            validateInput('blDate', date !== null ? date.toString() : '');
            setFormData({ blDate: moment(date.toString()) });
          }}
          isInvalid={!!formErrors.blDate?.reason}
          errorMessage={formErrors.blDate?.reason}
          defaultValue={
            formsData.blDate
              ? momentToCalendarDate(formsData.blDate)
              : undefined
          }
        />
      </FormRow>
      <FormRow gutter={20}>
        <ComboBox
          label="Surveyor appointed by"
          placeholder="Select surveyor"
          data-testid="surveyor-appointed-by-selector"
          defaultInputValue={formsData.surveyorAppointedBy}
          onSelectionChange={(key) =>
            setFormData({ surveyorAppointedBy: key?.toString() })
          }
        >
          {claims_additional_fields_options?.surveyor_appointed_by?.map(
            (option) => (
              <ComboBoxItem key={option} id={option}>
                {option}
              </ComboBoxItem>
            ),
          )}
        </ComboBox>
      </FormRow>
    </FormLayout>
  );
};
