import type { IconType } from 'react-icons';
import { FaShip, FaTrain, FaTruck } from 'react-icons/fa';
import { FaPlaneUp } from 'react-icons/fa6';

import { type CoveragePackageType } from '../model/Quote';
import { type ConveyanceType, type ShipmentLocation } from '../model/Shipment';

// This is not that great. We need a holistic approach to from
// configuration, including date limitations like the constants below.
export const ETD_MIN_DAYS_OFFSET_BREEZE = 20;
export const ETD_MIN_DAYS_OFFSET_WTW = 30;

export const CONVEYANCE_TYPE_ICONS: Record<ConveyanceType, IconType> = {
  sea: FaShip,
  air: FaPlaneUp,
  road: FaTruck,
  rail: FaTrain,
};

export const LOCATION_TYPES_BY_CONVEYANCE: Record<
  ConveyanceType,
  ShipmentLocation
> = {
  sea: 'port',
  air: 'port',
  road: 'place',
  rail: 'place',
};

// TODO: should probably be refactored and moved to the BE
export const COVERAGE_PACKAGES: Record<
  CoveragePackageType,
  { label: string; badgeLabel: string; description?: string }
> = {
  all_risks: {
    label: 'All risks',
    badgeLabel: 'All risk coverage',
    description:
      'Covers losses or damages due to any cause, except for those excluded',
  },
  fpa: {
    label: 'FPA',
    badgeLabel: 'FPA',
    description:
      '"Free of Particular Average" - limited coverage for partial cargo loss or damage',
  },
  iccc: {
    label: 'ICC (C)',
    badgeLabel: 'ICC (C)',
    description: 'Limited coverage for partial cargo loss or damage',
  },
  total_loss: {
    label: 'Total loss',
    badgeLabel: 'Total loss',
  },
  with_deductible: {
    label: 'With deductible',
    badgeLabel: 'With deductible',
  },
  without_deductible: {
    label: 'Without deductible',
    badgeLabel: 'Without deductible',
  },
  conditions_for_stainless_steel_containers: {
    label: 'Conditions for stainless steel containers',
    badgeLabel: 'Conditions for stainless steel containers',
  },
  all_risks_1_000_deductible: {
    label: 'All risks - $1,000 deductible',
    badgeLabel: 'All risks - $1,000 deductible',
  },
  all_risks_100_000_deductible: {
    label: 'All risks - $100,000 deductible',
    badgeLabel: 'All risks - $100,000 deductible',
  },
  all_risks_500_deductible: {
    label: 'All risks - $500 deductible',
    badgeLabel: 'All risks - $500 deductible',
  },
  all_risks_nil_deductible: {
    label: 'All risks - no deductible',
    badgeLabel: 'All risks - no deductible',
  },
  all_risks_russian_shipments: {
    label: 'All risks - Russian shipments',
    badgeLabel: 'All risks - Russian shipments',
  },
  Any: {
    label: 'Any',
    badgeLabel: 'Any',
  },
  other: {
    label: 'Other',
    badgeLabel: 'Other',
  },
  national: {
    label: 'National',
    badgeLabel: 'National',
  },
  contingent: {
    label: 'Contingent',
    badgeLabel: 'Contingent',
  },
  contingent_russian_shipments: {
    label: 'Contingent - Russian shipments',
    badgeLabel: 'Contingent - Russian shipments',
  },
  difference_in_conditions_cover: {
    label: 'Difference in conditions cover',
    badgeLabel: 'Difference in conditions cover',
  },
  difference_in_conditions_cover_russian_shipments: {
    label: 'Difference in conditions cover - Russian shipments',
    badgeLabel: 'Difference in conditions cover - Russian shipments',
  },
  mechanical_malfunction: {
    label: 'Mechanical malfunction',
    badgeLabel: 'Mechanical malfunction',
  },
  mold_mildew: {
    label: 'Mold, mildew',
    badgeLabel: 'Mold, mildew',
  },
  mold_mildew_and_mechanical_malfunction: {
    label: 'Mold, mildew and mechanical malfunction',
    badgeLabel: 'Mold, mildew and mechanical malfunction',
  },
  none: {
    label: 'None',
    badgeLabel: 'None',
  },
  pairs_sets: {
    label: 'Pairs, sets',
    badgeLabel: 'Pairs, sets',
  },
  pairs_sets_and_mold_mildew: {
    label: 'Pairs, sets and mold, mildew',
    badgeLabel: 'Pairs, sets and mold, mildew',
  },
  pairs_sets_and_mechanical_malfunction: {
    label: 'Pairs, sets and mechanical malfunction',
    badgeLabel: 'Pairs, sets and mechanical malfunction',
  },
  pairs_sets_and_mold_mildew_and_mechanical_malfunction: {
    label: 'Pairs, sets and mold, mildew and mechanical malfunction',
    badgeLabel: 'Pairs, sets and mold, mildew and mechanical malfunction',
  },
  packing_unpacking_only: {
    label: 'Packing/unpacking only',
    badgeLabel: 'Packing/unpacking only',
  },
  including_hoisting: {
    label: 'Including hoisting',
    badgeLabel: 'Including hoisting',
  },
  excluding_hoisting: {
    label: 'Excluding hoisting',
    badgeLabel: 'Excluding hoisting',
  },
  all_risks_refrigerated: {
    label: 'All risks - refrigerated',
    badgeLabel: 'All risks - refrigerated',
  },
  all_interests_excluding_sugar_all_risks_excluding_war_in_black_sea: {
    label:
      'All interests excluding sugar - all risks excluding war in Black Sea',
    badgeLabel:
      'All interests excluding sugar - all risks excluding war in Black Sea',
  },
  sugar_only_all_risks_excluding_war_in_black_sea: {
    label: 'Sugar only - all risks excluding war in Black Sea',
    badgeLabel: 'Sugar only - all risks excluding war in Black Sea',
  },
  all_interests_including_sugar_war_and_strikes_risks_only: {
    label: 'All interests including sugar - war and strikes risks only',
    badgeLabel: 'All interests including sugar - war and strikes risks only',
  },
  all_interests_excluding_sugar_all_risks_including_war_in_black_sea: {
    label:
      'All interests excluding sugar - all risks including war in Black Sea',
    badgeLabel:
      'All interests excluding sugar - all risks including war in Black Sea',
  },
  sugar_only_all_risks_including_war_in_black_sea: {
    label: 'Sugar only - all risks including war in Black Sea',
    badgeLabel: 'Sugar only - all risks including war in Black Sea',
  },
  section_1_all_risk_inc_got_wsrcc_inc_black_sea_ex_red_sea: {
    label: 'SECTION 1 - All risk INC GOT + WSRCC INC Black Sea EX Red Sea',
    badgeLabel: 'SECTION 1 - All risk INC GOT + WSRCC INC Black Sea EX Red Sea',
  },
  section_1_all_risk_inc_got_wsrcc_inc_black_sea_inc_red_sea: {
    label: 'SECTION 1 - All risk INC GOT + WSRCC INC Black Sea INC Red Sea',
    badgeLabel:
      'SECTION 1 - All risk INC GOT + WSRCC INC Black Sea INC Red Sea',
  },
  section_1_all_risks_ex_got_wsrcc_after_bosphorus_strait_ex_red_sea: {
    label:
      'SECTION 1 - All risks EX GOT + WSRCC after Bosphorus Strait EX Red Sea',
    badgeLabel:
      'SECTION 1 - All risks EX GOT + WSRCC after Bosphorus Strait EX Red Sea',
  },
  section_1_all_risks_ex_got_wsrcc_after_bosphorus_strait_inc_red_sea: {
    label:
      'SECTION 1 - All risks EX GOT + WSRCC after Bosphorus Strait INC Red Sea',
    badgeLabel:
      'SECTION 1 - All risks EX GOT + WSRCC after Bosphorus Strait INC Red Sea',
  },
  section_1_all_risks_ex_got_wsrcc_inc_black_sea_ex_red_sea: {
    label: 'SECTION 1 - All risks EX GOT + WSRCC INC Black Sea EX Red Sea',
    badgeLabel: 'SECTION 1 - All risks EX GOT + WSRCC INC Black Sea EX Red Sea',
  },
  section_1_all_risks_ex_got_wsrcc_inc_black_sea_inc_red_sea: {
    label: 'SECTION 1 - All risks EX GOT + WSRCC INC Black Sea INC Red Sea',
    badgeLabel:
      'SECTION 1 - All risks EX GOT + WSRCC INC Black Sea INC Red Sea',
  },
  section_1_all_risks_inc_got_wsrcc_after_bosphorus_strait_ex_red_sea: {
    label:
      'SECTION 1 - All risks INC GOT + WSRCC after Bosphorus Strait EX Red sea',
    badgeLabel:
      'SECTION 1 - All risks INC GOT + WSRCC after Bosphorus Strait EX Red sea',
  },
  section_1_all_risks_inc_got_wsrcc_after_bosphorus_strait_inc_red_sea: {
    label:
      'SECTION 1 - All risks INC GOT + WSRCC after Bosphorus Strait INC Red sea',
    badgeLabel:
      'SECTION 1 - All risks INC GOT + WSRCC after Bosphorus Strait INC Red sea',
  },
  section_2_all_risk_inc_got_wsrcc_inc_black_sea_ex_red_sea: {
    label: 'SECTION 2 - All risk INC GOT + WSRCC INC Black Sea EX Red Sea',
    badgeLabel: 'SECTION 2 - All risk INC GOT + WSRCC INC Black Sea EX Red Sea',
  },
  section_2_all_risk_inc_got_wsrcc_inc_black_sea_inc_red_sea: {
    label: 'SECTION 2 - All risk INC GOT + WSRCC INC Black Sea INC Red Sea',
    badgeLabel:
      'SECTION 2 - All risk INC GOT + WSRCC INC Black Sea INC Red Sea',
  },
  section_2_all_risks_ex_got_wsrcc_after_bosphorus_strait_ex_red_sea: {
    label:
      'SECTION 2 - All risks EX GOT + WSRCC after Bosphorus Strait EX Red Sea',
    badgeLabel:
      'SECTION 2 - All risks EX GOT + WSRCC after Bosphorus Strait EX Red Sea',
  },
  section_2_all_risks_ex_got_wsrcc_after_bosphorus_strait_inc_red_sea: {
    label:
      'SECTION 2 - All risks EX GOT + WSRCC after Bosphorus Strait INC Red Sea',
    badgeLabel:
      'SECTION 2 - All risks EX GOT + WSRCC after Bosphorus Strait INC Red Sea',
  },
  section_2_all_risks_ex_got_wsrcc_inc_black_sea_ex_red_sea: {
    label: 'SECTION 2 - All risks EX GOT + WSRCC INC Black Sea EX Red Sea',
    badgeLabel: 'SECTION 2 - All risks EX GOT + WSRCC INC Black Sea EX Red Sea',
  },
  section_2_all_risks_ex_got_wsrcc_inc_black_sea_inc_red_sea: {
    label: 'SECTION 2 - All risks EX GOT + WSRCC INC Black Sea INC Red Sea',
    badgeLabel:
      'SECTION 2 - All risks EX GOT + WSRCC INC Black Sea INC Red Sea',
  },
  section_2_all_risks_inc_got_wsrcc_after_bosphorus_strait_ex_red_sea: {
    label:
      'SECTION 2 - All risks INC GOT + WSRCC after Bosphorus Strait EX Red sea',
    badgeLabel:
      'SECTION 2 - All risks INC GOT + WSRCC after Bosphorus Strait EX Red sea',
  },
  section_2_all_risks_inc_got_wsrcc_after_bosphorus_strait_inc_red_sea: {
    label:
      'SECTION 2 - All risks INC GOT + WSRCC after Bosphorus Strait INC Red sea',
    badgeLabel:
      'SECTION 2 - All risks INC GOT + WSRCC after Bosphorus Strait INC Red sea',
  },
  storage: {
    label: 'Storage',
    badgeLabel: 'Storage',
    description: 'Storage coverage',
  },
};
