import { useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  type BaseQueryParams,
  type PageState,
  ServerSideTableV2,
} from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';
import { type GridRowParams } from '@mui/x-data-grid-pro';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { type Shipment } from '../../../model/Shipment';
import { useAppNavigation } from '../../../router/use-app-navigation';
import { reportException } from '../../../utils/error-reporting/error-reporting';
import { useSectionError } from '../../components/Section/SectionContext';
import {
  CargoOwnersFilter,
  CreatedOnFilter,
  useFilterStore,
} from '../../components/TableFilters';
import { getShipments } from '../network/shipments';
import { type ShipmentsSortableFields } from '../network/types';
import styles from './ShipmentsTable.module.scss';
import { useShipmentsTableColumns } from './use-shipments-table-columns';

const DEFAULT_QUERY_PARAMS = {
  limit: 10,
};

export const ShipmentsTable = () => {
  const enableFilters = useFeatureToggle('enable_column_filters');

  const { filters, clearFilters } = useFilterStore((state) => ({
    filters: state.filters,
    clearFilters: state.clearFilters,
  }));
  const { navigateToPolicyFlow } = useAppNavigation();
  const { setError } = useSectionError();
  const columns = useShipmentsTableColumns();

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [queryParams, setQueryParams] =
    useState<BaseQueryParams>(DEFAULT_QUERY_PARAMS);

  // clear filter when navigating away from page
  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, [clearFilters]);

  const fetchShipmentsPage = useCallback(
    async (params: BaseQueryParams): Promise<PageState<Shipment>> => {
      return getShipments({
        limit: params?.limit,
        query: params?.query,
        order: params?.order,
        sort_by: params?.sortBy as ShipmentsSortableFields,
        page_pointer: params?.pagePointer,
        filters,
        paginate: true,
      })
        .then((page) => {
          return {
            records: page.shipments,
            nextPagePointer: page.next_page_pointer,
            prevPagePointer: page.prev_page_pointer,
            totalRows: page.total_num_records,
          };
        })
        .catch((e) => {
          reportException(e);
          setError(e);
          return { records: [] };
        });
    },
    [filters, setError],
  );

  return (
    <div className="h-full w-full">
      <ServerSideTableV2<Shipment>
        testId="shipments-table"
        fetchPage={fetchShipmentsPage}
        columns={columns}
        getRowId={(row) => row.id}
        getRowClassName={() => styles.quoteRow}
        onRowClick={({ row }: GridRowParams<Shipment>) =>
          navigateToPolicyFlow({ shipmentId: String(row.id) })
        }
        actions={{ search: true, pageSize: true, filters: true }}
        searchProps={{ placeholder: 'Search Shipment ID...' }}
        setCurrentPageNumber={setCurrentPageNumber}
        currentPageNumber={currentPageNumber}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        autoHeight={false}
        filters={
          enableFilters ? (
            <ErrorBoundary fallbackRender={() => <></>}>
              <div className={styles.filters}>
                <CreatedOnFilter />
                <ErrorBoundary fallbackRender={() => <></>}>
                  <CargoOwnersFilter />
                </ErrorBoundary>
                <Button
                  onPress={clearFilters}
                  width="fixed"
                  variant="ghost"
                  size="small"
                  isDisabled={!filters.length}
                  label="Clear filters"
                />
              </div>
            </ErrorBoundary>
          ) : undefined
        }
      />
    </div>
  );
};
