import {
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  type DateValue,
  RangeCalendar as AriaRangeCalendar,
  type RangeCalendarProps as AriaRangeCalendarProps,
  Text,
} from 'react-aria-components';
import { getLocalTimeZone, today } from '@internationalized/date';
import { tv } from 'tailwind-variants';

import { CalendarGridHeader, CalendarHeader } from '../Calendar/Calendar';
import { clsxMerge, focusRing } from '../common/utils/classNameUtils';

export interface RangeCalendarProps<T extends DateValue>
  extends Omit<AriaRangeCalendarProps<T>, 'visibleDuration'> {
  errorMessage?: string;
}

const cell = tv({
  extend: focusRing,
  base: 'w-9 h-9 text-sm rounded-full flex items-center justify-center forced-color-adjust-none group outline outline-0 [td:first-child_&]:rounded-s-full selection-start:rounded-s-full [td:last-child_&]:rounded-e-full selection-end:rounded-e-full',
  variants: {
    selectionState: {
      isSelected: 'bg-dropdown-row-bg-selected forced-colors:bg-[Highlight]',
      none: 'group-hover:bg-gray-100 group-pressed:bg-gray-200',
      middle: [
        'group-hover:bg-dropdown-row-bg-hover forced-colors:group-hover:bg-[Highlight]',
        'group-invalid:group-hover:bg-red-200 forced-colors:group-invalid:group-hover:bg-[Mark]',
        'group-pressed:bg-dropdown-row-bg-hover forced-colors:group-pressed:bg-[Highlight] forced-colors:text-[HighlightText]',
        'group-invalid:group-pressed:bg-red-300 forced-colors:group-invalid:group-pressed:bg-[Mark]',
      ],
      cap: 'bg-blue-600 group-invalid:bg-red-600 forced-colors:bg-[Highlight] forced-colors:group-invalid:bg-[Mark] text-white forced-colors:text-[HighlightText]',
    },
    isOutsideMonth: {
      true: 'hidden',
    },
    isDisabled: {
      true: 'text-gray-300 forced-colors:text-[GrayText]',
    },
    isUnavailable: {
      true: 'text-gray-300 forced-colors:text-[GrayText] opacity-50',
    },
    isInvalid: {
      true: 'text-red-300 forced-colors:text-[Mark]',
    },
  },
});

export function RangeCalendar<T extends DateValue>({
  errorMessage,
  ...props
}: RangeCalendarProps<T>) {
  return (
    <AriaRangeCalendar {...props}>
      <CalendarHeader />
      <CalendarGrid className="[&_td]:px-0">
        <CalendarGridHeader />
        <CalendarGridBody data-testid="range-calendar-body">
          {(date) => {
            const isToday =
              date.toString() == today(getLocalTimeZone()).toString();
            return (
              <CalendarCell
                data-testid={`range-calendar-cell-${date.day}-${date.month}-${date.year}`}
                date={date}
                className={clsxMerge(
                  isToday && 'font-bold',
                  'group size-9 text-sm outline outline-0 cursor-default selected:bg-dropdown-row-bg-selected forced-colors:selected:bg-[Highlight] invalid:selected:bg-red-100  forced-colors:invalid:selected:bg-[Mark] [td:first-child_&]:rounded-s-full selection-start:rounded-s-full [td:last-child_&]:rounded-e-full selection-end:rounded-e-full',
                )}
              >
                {({
                  formattedDate,
                  isSelected,
                  isSelectionStart,
                  isSelectionEnd,
                  ...props
                }) => (
                  <span
                    className={cell({
                      selectionState:
                        isSelected && (isSelectionStart || isSelectionEnd)
                          ? 'cap'
                          : isSelected
                            ? 'middle'
                            : 'none',
                      ...props,
                    })}
                  >
                    {formattedDate}
                  </span>
                )}
              </CalendarCell>
            );
          }}
        </CalendarGridBody>
      </CalendarGrid>
      {errorMessage && (
        <Text slot="errorMessage" className="text-sm text-red-600">
          {errorMessage}
        </Text>
      )}
    </AriaRangeCalendar>
  );
}
