import { useTranslation } from 'react-i18next';

import { usePlatform } from '../../../../context/PlatformContext';
import { type ConveyanceType } from '../../../../model/Shipment';

type LabelsByConveyance = {
  containerIds: string;
  vehicleName: string;
};
export const useShipmentFormLabels = (primaryMot?: ConveyanceType) => {
  const { t } = useTranslation();
  const { platform } = usePlatform();
  const labels: Record<ConveyanceType, LabelsByConveyance> = {
    sea: {
      containerIds: t('ContainerNumbers', {
        ns: 'common',
        context: platform,
      }),
      vehicleName: t('VesselName', {
        ns: 'common',
        context: platform,
      }),
    },
    air: {
      containerIds: 'AWB',
      vehicleName: t('FlightNumber', {
        ns: 'common',
        context: platform,
      }),
    },
    road: {
      containerIds: t('TrailerNumbers', {
        ns: 'common',
        context: platform,
      }),
      vehicleName: t('CarrierName', {
        ns: 'common',
        context: platform,
      }),
    },
    rail: {
      containerIds: t('ContainerNumbers', {
        ns: 'common',
        context: platform,
      }),
      vehicleName: t('CarrierName', {
        ns: 'common',
        context: platform,
      }),
    },
  };
  if (!primaryMot) {
    return;
  }

  return labels[primaryMot];
};
