import { useEffect } from 'react';
import { TextField, type TextFieldProps } from '@breezeai-frontend/cargo-ui';

import { useShipmentFormLabels } from '../../policy-flow/steps/ShipmentInformationForm/useShipmentFormLabels';
import { type RouteDetailsFormData } from '../RouteDetails/types';

interface VesselTextFieldProps extends TextFieldProps {
  primaryMot: RouteDetailsFormData['primaryMot'];
  defaultPrimaryMot?: RouteDetailsFormData['primaryMot'];
}

export function VesselTextField({
  defaultPrimaryMot,
  primaryMot,
  onChange,
  ...props
}: VesselTextFieldProps) {
  const labels = useShipmentFormLabels(primaryMot.name);

  useEffect(() => {
    // TODO HACK: Reset the vessel name when the primaryMot changes
    if (defaultPrimaryMot?.name !== primaryMot.name) {
      onChange?.('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryMot]);

  return (
    <TextField
      data-testid="vehicle-name-input"
      placeholder="Enter name"
      label={labels?.vehicleName}
      onChange={onChange}
      {...props}
    />
  );
}
