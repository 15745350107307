import { useCallback, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  type BaseQueryParams,
  type PageState,
  ServerSideTableV2,
} from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';
import invariant from 'tiny-invariant';

import { useFeatureToggle } from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import {
  FilterableFields,
  type FilterBaseParam,
  type PaginationBaseQueryParams,
  type PaginationBaseResponse,
} from '../../../../network/apis/types';
import { post } from '../../../../network/apis/utils';
import { bffServiceUrl } from '../../../../network/netconfig';
import { reportException } from '../../../../utils/error-reporting/error-reporting';
import { useSectionError } from '../../../components/Section/SectionContext';
import { useTableFilters } from '../../../components/TableFilters/hooks/useTableFilters';
import { useTableQueryParamsV2 } from '../../../components/TableFilters/hooks/useTableQueryParamsV2';
import { getFilterPayload } from '../../../components/TableFilters/utils';
import { PrimaryAssuredFilterV2 } from '../../../components/TableFilters/V2/PrimaryAssuredFilterV2';
import { StorageLocationDetailsSectionLayout } from '../../StorageDetailsPage/StorageLocationDetailsSectionLayout';
import { type StorageRouteParams } from '../../types';
import {
  type DeclarationsSortableFields,
  type StorageDeclaration,
  storageTableFilters,
} from '../types';
import { useDeclarationsTableColumns } from './useDeclarationsTableColumns';

interface DeclarationsResponse extends PaginationBaseResponse {
  declarations: StorageDeclaration[];
}
// The table component is non compatible with react-query
export const getDeclarations = async (
  locationId: string,
  params?: PaginationBaseQueryParams<
    DeclarationsSortableFields,
    FilterableFields
  >,
) => {
  const { data } = await post<DeclarationsResponse>(
    `${bffServiceUrl}/bff-storage-declarations/storage-locations/${locationId}/storage-declarations/get`,
    params,
  );

  return data;
};

export const DeclarationsTable = () => {
  const { platform } = usePlatform();
  const { t } = useTranslation();
  const [filtersComponentKey, setFiltersComponentKey] = useState<number>(0);
  const refreshFilterState = () => {
    clearAllFilters(storageTableFilters);
    setFiltersComponentKey((prev) => prev + 1);
  };
  const { locationId } = useParams<StorageRouteParams>();
  invariant(locationId, 'Location ID is required');

  const enableFilters = useFeatureToggle('enable_column_filters');
  const [searchParams, setSearchParams] = useSearchParams();
  const { clearAllFilters } = useTableFilters({
    setSearchParams,
  });

  // TODO remove coercion
  const filterPayload = storageTableFilters
    .map((filter) => getFilterPayload(filter, searchParams))
    .filter((item) => !!item) as FilterBaseParam<FilterableFields>[];

  const {
    getQuerySearchURLParam,
    getSortOrderFromURLParam,
    getSortByFromURLParam,
    upsertTableQuery,
  } = useTableQueryParamsV2(setSearchParams);

  const queryParams = {
    limit: 10,
    query: getQuerySearchURLParam(searchParams),
    sortBy: getSortByFromURLParam(searchParams),
    order: getSortOrderFromURLParam(searchParams),
  };

  const columns = useDeclarationsTableColumns();
  const { setError } = useSectionError();

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);

  const fetchDeclarationsPage = useCallback(
    async (params: BaseQueryParams): Promise<PageState<StorageDeclaration>> => {
      return getDeclarations(locationId, {
        limit: params?.limit,
        query: params?.query,
        order: params?.order,
        sort_by: params?.sortBy as DeclarationsSortableFields,
        page_pointer: params?.pagePointer,
        filters: filterPayload,
        paginate: true,
      })
        .then((page) => {
          return {
            records: page.declarations,
            nextPagePointer: page.next_page_pointer,
            prevPagePointer: page.prev_page_pointer,
            totalRows: page.total_num_records,
          };
        })
        .catch((e) => {
          reportException(e);
          setError(e);
          return { records: [] };
        });
    },
    [setError, filterPayload, locationId],
  );

  return (
    <StorageLocationDetailsSectionLayout title="Declarations" divider={false}>
      <div className="h-[673px] w-full">
        <ServerSideTableV2<StorageDeclaration>
          testId="declarations-table"
          fetchPage={fetchDeclarationsPage}
          columns={columns}
          getRowId={(row) => row.id}
          actions={{ search: true, pageSize: true, filters: true }}
          searchProps={{
            placeholder: t('declarations.table.SearchPlaceholder', {
              ns: 'storage',
              context: platform,
            }),
            defaultValue: getQuerySearchURLParam(searchParams),
          }}
          setCurrentPageNumber={setCurrentPageNumber}
          currentPageNumber={currentPageNumber}
          queryParams={queryParams}
          setQueryParams={upsertTableQuery}
          sortModel={[
            {
              field: queryParams.sortBy ?? 'id',
              sort: queryParams.order,
            },
          ]}
          pinnedColumns={{ right: ['action'] }}
          sx={{
            '.MuiDataGrid-pinnedColumns--right': {
              boxShadow: 'none',
            },
            '.MuiDataGrid-pinnedColumnHeaders--right': {
              display: 'none',
            },
          }}
          autoHeight={false}
          filters={
            enableFilters ? (
              <ErrorBoundary
                fallbackRender={() => <></>}
                key={filtersComponentKey}
              >
                <div className="flex flex-wrap gap-2 items-center justify-start">
                  <ErrorBoundary fallbackRender={() => <></>}>
                    <PrimaryAssuredFilterV2
                      filterName={FilterableFields.DISTRIBUTOR_ID}
                      setSearchParams={setSearchParams}
                      searchParams={searchParams}
                      context="storage_locations_filter"
                    />
                  </ErrorBoundary>
                  <Button
                    onPress={refreshFilterState}
                    variant="ghost"
                    size="small"
                    isDisabled={!filterPayload.length}
                    width="fixed"
                    label={t('tables.filters.ClearFilters', {
                      ns: 'common',
                      context: platform,
                    })}
                  />
                </div>
              </ErrorBoundary>
            ) : undefined
          }
        />
      </div>
    </StorageLocationDetailsSectionLayout>
  );
};
