import {
  Dialog,
  Heading,
  Modal as RACModal,
  ModalOverlay,
  type ModalOverlayProps,
} from 'react-aria-components';
import { LiaTimesSolid } from 'react-icons/lia';
import { tv, type VariantProps } from 'tailwind-variants';

import { Button } from '../../components/Button/Button';
import { clsxMerge } from '../../components/common/utils/classNameUtils';

const overlayStyles = tv({
  base: `fixed top-0 left-0 w-full h-[--visual-viewport-height] isolate z-20 bg-black/[15%] flex items-center justify-center p-4 text-center backdrop-brightness-50`,
  variants: {
    isEntering: {
      true: 'animate-in fade-in duration-200 ease-out',
    },
    isExiting: {
      true: 'animate-out fade-out duration-200 ease-in',
    },
  },
});

const modalStyles = tv({
  base: `focus-visible:ring-0 w-full max-h-full rounded-2xl bg-slate-50 forced-colors:bg-[Canvas] text-left align-middle text-slate-700  shadow-2xl bg-clip-padding border border-black/10`,
  variants: {
    isEntering: {
      true: 'animate-in zoom-in-105 ease-out duration-200',
    },
    isExiting: {
      true: 'animate-out zoom-out-95 ease-in duration-200',
    },
  },
});

export interface ModalProps
  extends VariantProps<typeof modalStyles>,
    ModalOverlayProps {
  children: React.ReactNode;
  heading?: React.ReactNode;
  showExitButton?: boolean;
  onExit?: () => void;
}

export function Modal({
  children,
  heading,
  showExitButton,
  onExit,
  ...props
}: ModalProps) {
  return (
    <ModalOverlay {...props} className={overlayStyles}>
      <RACModal
        {...props}
        className={clsxMerge(modalStyles(), props.className)}
      >
        <Dialog className="flex justify-center items-center flex-col p-4 relative outline-none">
          {({ close }) => (
            <>
              {showExitButton && (
                <Button
                  variant="ghost"
                  leftIcon={<LiaTimesSolid size={18} />}
                  onPress={() => {
                    onExit && onExit();
                    // This will close the modal when it is not controlled
                    close();
                  }}
                  data-testid="close-modal-button"
                  customStyles="absolute top-4 right-4 text-system-grey-750"
                />
              )}
              {heading && (
                <Heading
                  slot="title"
                  className="w-full flex flex-col justify-center items-center"
                >
                  {heading}
                </Heading>
              )}
              {children}
            </>
          )}
        </Dialog>
      </RACModal>
    </ModalOverlay>
  );
}
