/**
 * Temporary list of GCIDs that are blocked from accessing the app.
 *
 * These distributors are being set up.
 *
 * TODO: remove constant and logic once distributors are set up.
 * https://breeze.fibery.io/Breeze/Task/Remove-hardcoded-list-of-GCIDs-3183
 */

import { usePlatform } from '../context/PlatformContext';
import { GCID } from '../network/apis/auth/auth';
import { getCookie } from '../network/apis/auth/cookies';

const blockedGcIds = [
  '2591668',
  '1943009',
  '2016869',
  '2016869',
  '1396239',
  '1740973',
  '2651745',
  '2651745',
  '1322510',
  '2395240',
  '1139604',
  '2725894',
  '1154320',
  '1284406',
  '2255637',
  '2255637',
  '1285858',
  '2836160',
  '2836160',
  '2836160',
  '1090614',
  '1078470',
  '1078470',
  '1338597',
  '1022295',
  '2024855',
  '1131845',
  '1033750',
  '1152600',
  '1146983',
  '1146983',
  '1146287',
  '2783101',
  '1133303',
  '1154560',
  '1023556',
  '1017280',
  '1774861',
  '1081743',
  '1134484',
  '1757804',
  '1082118',
  '1695156',
  '1467878',
  '1098602',
  '1015177',
  '1397425',
  '2664765',
  '1691731',
  '1574850',
  '1046474',
  '1058141',
  '2312856',
  '2312856',
  '1017469',
  '1006398',
  '2302923',
  '1077756',
  '1363647',
  '1108392',
  '2915382',
  '1117888',
  '1555211',
  '2776889',
  '2494591',
  '2657319',
  '1764395',
  '2764281',
  '2764281',
  '2293813',
  '2284442',
  '1439397',
  '1015436',
  '1423750',
  '1445192',
  '1332292',
  '1152325',
  '1376340',
  '1656912',
  '1150730',
  '2894366',
  '1882011',
  '1763521',
  '1067407',
  '1630826',
  '1630826',
  '1630826',
  '1736626',
  '1646339',
  '1646339',
  '2169305',
  '2451646',
  '2451646',
  '1047995',
  '1879578',
  '1879578',
  '2680388',
  '1353201',
  '1964092',
  '1082128',
  '2931278',
  '1446371',
  '1149750',
  '1149750',
  '1766752',
  '1004893',
  '1417267',
  '2920205',
  '2611362',
  '1914448',
  '1042955',
  '1347857',
  '1346316',
  '2584218',
  '1453849',
  '2731027',
  '2014981',
  '2398995',
  '1430641',
  '1322621',
  '1322621',
  '1112299',
  '2614534',
  '1006390',
  '1006390',
  '2054844',
  '1772897',
  '2010942',
  '2010942',
  '1113069',
  '1142976',
  '1345037',
  '1896937',
  '1395264',
  '1134898',
  '1036073',
  '1122652',
  '1084177',
  '2606570',
  '1007855',
  '1926521',
  '2929296',
  '1128392',
  '2484280',
  '1074035',
  '1066283',
  '1131752',
  '1035154',
  '1773150',
  '1099990',
  '1302966',
  '1000013',
  '1119997',
  '1040904',
  '1032432',
  '1065736',
  '1378743',
  '1077795',
  '1100028',
  '1569585',
  '1341000',
  '1070905',
  '2126473',
  '2126473',
  '1120694',
  '2954295',
  '2083937',
  '1714238',
  '2600296',
  '2589852',
  '1567965',
  '1441459',
  '1741587',
  '1408932',
  '1129017',
];

/**
 * TODO: remove once the distributors setup is complete.
 * https://breeze.fibery.io/Breeze/Task/Remove-hardcoded-list-of-GCIDs-3183
 */
export const useShouldBlockUser = () => {
  const { isWtw } = usePlatform();

  if (!isWtw) {
    return false;
  }

  const gcId = getCookie(GCID);
  const isProd = import.meta.env.VITE_APP_ENV === 'production';
  const today = new Date(); // Unblock on April 1st
  const march28 = new Date(today.getFullYear(), 2, 28); // Unblock on March 28
  const shouldBlockUser =
    isProd && gcId && blockedGcIds.includes(gcId) && today < march28; // Unblock on March 28

  return shouldBlockUser;
};
