import {
  DatePicker,
  FormLayout,
  FormRow,
  Input,
  Select,
  type SelectOptionType,
} from '@breeze-ai/ui-library';
import { Checkbox, Typography } from '@breezeai-frontend/cargo-ui';

import { useAppConfiguration } from '../../../../context/app-configuration/useAppConfiguration';
import { useUser } from '../../../../context/auth/auth-hooks';
import { type ClaimEventLocationType } from '../../../../model/Claim';
import { useDistributors } from '../../../../network/apis/distributors/use-distributors';
import { CertificateIdAutocomplete } from '../../../components/CertificateIdAutocomplete/CertificateIdAutocomplete';
import { CurrencyValueInput } from '../../../components/CurrencyValueInput/CurrencyValueInput';
import { PlaceAutocomplete } from '../../../components/PlaceAutocomplete/PlaceAutocomplete';
import labels from '../../../labels';
import {
  useClaimCreationFlowContext,
  useClaimCreationStateAction,
} from '../context/hooks';
import { useClaimFormInputValidation } from '../form-validations';

export const ClaimDetailsForm = () => {
  const { currencies, claim_location_types } = useAppConfiguration();
  const { formsData, formErrors } = useClaimCreationFlowContext();
  const { setFormData, setFormError } = useClaimCreationStateAction();
  const validateInput = useClaimFormInputValidation();
  const user = useUser();

  const { data: distributors, isLoading: isFetchingDistributors } =
    useDistributors({
      params: {
        paginate: false,
        order: 'asc',
        include_archived: true,
        context: 'claims_creation',
      },
    });

  const canCreateClaimsWithoutCertificate = Boolean(
    user?.permissions.claims?.create_with_no_policy,
  );

  const {
    distributorId,
    eventDate,
    eventAddress,
    eventDescription,
    currentCargoPlace,
    eventLocationType,
    reportedAmount,
    reportedAmountCurrency,
    raiseAgainstOpenCover,
  } = formsData;
  const { claims_additional_fields_options } = useAppConfiguration();

  const claimLocationOptions = claim_location_types.map<
    SelectOptionType<ClaimEventLocationType>
  >(({ label, value }) => ({
    label,
    value,
    payload: value,
  }));

  const distributorOptions =
    distributors?.map(({ legal_name, id }) => ({
      label: legal_name,
      value: id,
      payload: id,
    })) ?? [];

  return (
    <FormLayout>
      {canCreateClaimsWithoutCertificate && (
        <FormRow>
          <Checkbox
            isSelected={raiseAgainstOpenCover}
            onChange={() => {
              setFormData({
                raiseAgainstOpenCover: !raiseAgainstOpenCover,
              });

              // reset fields
              setFormData({
                policy: undefined,
                distributorId: undefined,
              });
            }}
          >
            <Typography>
              This claim applies to a shipment without a certificate
            </Typography>
          </Checkbox>
        </FormRow>
      )}
      <FormRow gutter={20}>
        {raiseAgainstOpenCover ? (
          <Select
            required={true}
            value={distributorId}
            onChange={({ payload }) => setFormData({ distributorId: payload })}
            options={distributorOptions}
            label={labels.fields.distributor}
            placeholder={`Select ${labels.fields.distributor}...`}
            testId="distributor-select"
            loading={isFetchingDistributors}
          />
        ) : (
          <CertificateIdAutocomplete />
        )}
        <DatePicker
          value={eventDate}
          onChange={(date) => setFormData({ eventDate: date ?? undefined })}
          position="bottom-end"
          required={true}
          disableFuture={true}
          onValidation={(error, reason) =>
            setFormError({ field: 'eventDate', error, reason })
          }
          inputProps={{
            label: 'Event date',
            error: !!formErrors?.eventDate,
            errorHelperText: formErrors?.eventDate?.reason,
            testId: 'event-date-picker',
          }}
        />
        {!claims_additional_fields_options && (
          <Select<ClaimEventLocationType>
            value={eventLocationType}
            onChange={({ payload }) =>
              setFormData({ eventLocationType: payload })
            }
            options={claimLocationOptions}
            label="Location type"
            placeholder="Select..."
            testId="event-location-type-selector"
          />
        )}
      </FormRow>
      <FormRow gutter={20}>
        <PlaceAutocomplete
          value={eventAddress}
          onChange={(eventAddress) => setFormData({ eventAddress })}
          inputProps={{
            label: 'Event address',
            placeholder: 'Where did it happen?',
            prefixIcon: 'map-marker-alt',
            required: false,
            testId: 'event-address-input',
          }}
        />
        <PlaceAutocomplete
          value={currentCargoPlace}
          onChange={(currentCargoPlace) => setFormData({ currentCargoPlace })}
          inputProps={{
            label: 'Current location of goods',
            placeholder: 'Where are the goods currently?',
            prefixIcon: 'map-marker-alt',
            required: false,
            testId: 'cargo-location-input',
          }}
        />
        <CurrencyValueInput
          label="Loss amount"
          value={reportedAmount}
          currency={reportedAmountCurrency}
          currencyOptions={currencies}
          onChange={(reportedAmount) => setFormData({ reportedAmount })}
          onCurrencyChange={(reportedAmountCurrency) =>
            setFormData({ reportedAmountCurrency })
          }
          error={!!formErrors.reportedAmount}
          errorHelperText={formErrors.reportedAmount?.reason}
          validator={(value) => validateInput('reportedAmount', value)}
          testId="reported-amount-input"
          inputDropdownProps={{ testId: 'reported-amount-currency-input' }}
        />
      </FormRow>
      <FormRow gutter={20}>
        <Input
          label="Client Reference Number"
          placeholder="Enter number"
          onChange={({ target }) =>
            setFormData({ clientReferenceNumber: target.value })
          }
        />
        <Input
          label="Underwriter Reference Number"
          placeholder="Enter number"
          onChange={({ target }) =>
            setFormData({ underwriterReferenceNumber: target.value })
          }
        />
        <Input
          label="Broker Reference Number"
          placeholder="Enter number"
          onChange={({ target }) =>
            setFormData({ brokerReferenceNumber: target.value })
          }
        />
      </FormRow>
      <FormRow gutter={20}>
        <Input
          label={
            claims_additional_fields_options ? 'Customer Name' : 'Named Assured'
          }
          placeholder={
            claims_additional_fields_options
              ? 'Enter customer name'
              : 'Enter named assured'
          }
          onChange={({ target }) => setFormData({ customer: target.value })}
        />
        <Input
          onChange={({ target }) =>
            setFormData({ customerAddress: target.value })
          }
          label={
            claims_additional_fields_options
              ? 'Customer Address'
              : 'Named Assured Address'
          }
          placeholder={
            claims_additional_fields_options
              ? 'Enter customer address'
              : 'Enter named assured address'
          }
        />
        {/* <CustomerComboBox
          label="Named Assured"
          placeholder="Enter named assured"
          onSelectionChange={(key) => {
            if (key) {
              const customer: CustomerOption = JSON.parse(String(key));
              setFormData({ customer });
            }
          }}
        />
        <PlaceAutocomplete
          onChange={(currentCargoPlace) => setFormData({ currentCargoPlace })}
          inputProps={{
            label: 'Named Assured Address',
            placeholder: 'Enter named assured address',
          }}
        /> */}
      </FormRow>

      <FormRow gutter={20}>
        <Input
          label="Event description"
          type="textarea"
          placeholder="Tell us what happened"
          value={eventDescription}
          required={true}
          validator={(value) => validateInput('eventDescription', value)}
          error={!!formErrors.eventDescription}
          errorHelperText={formErrors.eventDescription?.reason}
          onChange={({ target: { value: eventDescription } }) =>
            setFormData({ eventDescription })
          }
          testId="event-description-input"
        />
      </FormRow>
    </FormLayout>
  );
};
