import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  clsxMerge,
  DatePicker,
  getDate,
  TextField,
  Typography,
} from '@breezeai-frontend/cargo-ui';
import { momentToCalendarDate } from '@breezeai-frontend/cargo-ui';
import { ComboBox, ComboBoxItem } from '@breezeai-frontend/cargo-ui';
import { type FormApi } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { type zodValidator } from '@tanstack/zod-form-adapter';
import moment from 'moment';
import { z } from 'zod';

import { useAppConfiguration } from '../../../../context/app-configuration/useAppConfiguration';
import {
  useAuthenticatedUser,
  useUserSetting,
} from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import { type SupportedCurrencies } from '../../../../model/CurrencyValue';
import { DistributorStatusEnum } from '../../../../model/Distributor';
import { type PlaceSuggestion } from '../../../../model/Place';
import { distributorQueries } from '../../../../network/apis/distributors/distributors';
import { useDistributors } from '../../../../network/apis/distributors/use-distributors';
import { CoverageTypeSelector } from '../../../components/CoverageTypeSelector/CoverageTypeSelector';
import { CurrencyValueInput } from '../../../components/CurrencyValueInput/CurrencyValueInput';
import { CustomerComboBox } from '../../../components/CustomerComboBox/CustomerComboBox';
import { type CustomerOption } from '../../../components/CustomerComboBox/types';
import { DistributorSelect } from '../../../components/DistributorSelect/DistributorSelect';
import { PlacesComboBox } from '../../../components/PlacesComboBox/PlacesComboBox';
import { VesselFields } from '../../../components/VesselFields/VesselFields';
import { LetterOfCreditSelector } from '../../../policy-flow/components/LetterOfCreditSelector/LetterOfCreditSelector';
import {
  getArrivalDateLimits,
  getDepartureDateLimits,
} from '../../../utils/eta-etd';
import { type CertificateModel } from '../../types';
import { type CertificateFormProps } from './types';

export function ShipmentDetailsForm({
  form,
  isUpdate,
  certificate,
}: {
  form: FormApi<CertificateFormProps, typeof zodValidator>;
  isUpdate: boolean;
  certificate: CertificateModel | undefined;
}) {
  const enableLetterOfCredit = useUserSetting<boolean>(
    'enable_letter_of_credit',
  );

  const showFreightCostField =
    useUserSetting<boolean>('enable_freight_cost') ?? false;

  const showDutyCostField =
    useUserSetting<boolean>('enable_duty_cost') ?? false;

  const { isWtw, platform } = usePlatform();
  const { t } = useTranslation();

  const {
    currencies,
    commodities,
    container_modes,
    available_coverage_package,
  } = useAppConfiguration();
  const [isAssuredAddressInputDisabled, setIsAssuredAddressInputDisabled] =
    useState(form.getFieldValue('assuredName')?.company_name.length === 0);
  const assuredNameRef = useRef<HTMLInputElement>(null);

  const { data: unfilteredDistributors, isLoading } = useDistributors({
    params: {
      paginate: false,
      order: 'asc',
      include_archived: isUpdate,
      context: 'certificates_creation',
    },
  });
  const distributors = unfilteredDistributors?.filter(
    (distributor) => distributor.can_issue_policy,
  );

  const user = useAuthenticatedUser();
  const { data: openCover } = useQuery(
    distributorQueries.activeOpenCover({ distributorId: user.distributor.id }),
  );

  const etdLimits = getDepartureDateLimits({
    eta:
      form.getFieldValue('eta') !== undefined &&
      form.getFieldValue('eta') !== ''
        ? moment(form.getFieldValue('eta'))
        : undefined,
    terminationDate:
      certificate?.open_cover?.termination_date !== undefined
        ? moment(certificate?.open_cover?.termination_date)
        : undefined,
    platform: isWtw ? 'wtw' : 'breeze',
  });

  const etaLimits = getArrivalDateLimits({
    etd:
      form.getFieldValue('etd') !== undefined &&
      form.getFieldValue('etd') !== ''
        ? moment(form.getFieldValue('etd'))
        : undefined,
    terminationDate:
      certificate?.open_cover?.termination_date !== undefined
        ? moment(certificate?.open_cover?.termination_date)
        : undefined,
    platform: isWtw ? 'wtw' : 'breeze',
  });

  return (
    /* TODO: When page is smaller than lg viewport, large, undesired whitespace is created at the bottom of the page due to the space-y- classes */
    <div
      data-testid="certificate-flow-form-shipment-details"
      className="lg:grid lg:grid-cols-2 lg:gap-y-5 lg:gap-x-4 max-lg:space-y-6 mb-4"
    >
      {available_coverage_package?.length > 1 &&
        available_coverage_package.length < 4 && (
          <div className="lg:col-span-2">
            <Typography
              level="h3"
              customStyles="pt-5 pb-4 sticky top-0 bg-gradient-to-b from-white from-80% to-transparent z-10"
            >
              {t('CoverageType', {
                ns: 'common',
                context: platform,
              })}
            </Typography>
            <form.Field
              name="coveragePackage"
              validators={{
                onSubmit: z.string().min(1, 'Please select a coverage package'),
                onChange: z.string().min(1, 'Please select a coverage package'),
              }}
              children={(field) => (
                <CoverageTypeSelector type="radio" field={field} />
              )}
            />
          </div>
        )}
      <Typography level="h3" customStyles="py-4 lg:col-span-2">
        Shipment Details
      </Typography>
      <form.Field
        name="issueDate"
        validators={{
          onSubmit: z.string().min(1, 'Please enter a valid date value'),
        }}
        children={(field) => (
          <DatePicker
            isRequired
            data-testid="issue-date-picker"
            label="Issue date"
            defaultValue={getDate(field.state.value)}
            validate={() => {
              if (field.state.meta.errors.length > 0) {
                return field.state.meta.errors.join(', ');
              }
              return true;
            }}
            minValue={
              openCover
                ? momentToCalendarDate(moment(openCover.inception_date))
                : undefined
            }
            maxValue={
              openCover
                ? momentToCalendarDate(moment(openCover.termination_date))
                : undefined
            }
            id={field.name}
            name={field.name}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.toString())}
            isDateUnavailable={(date) => {
              // All dates available if there is no open cover because
              // users will not be able to create a certificate in the end.
              if (!openCover) {
                return false;
              }

              const issueDate = moment(date.toString());

              return !issueDate.isBetween(
                moment(openCover.inception_date),
                moment(openCover.termination_date),
              );
            }}
          />
        )}
      />
      <form.Field
        name="etd"
        validators={{
          onSubmit: z.string().min(1, 'Please enter a valid date value'),
        }}
        children={(field) => (
          <DatePicker
            isRequired
            minValue={momentToCalendarDate(etdLimits.min)}
            maxValue={momentToCalendarDate(etdLimits.max)}
            validate={() => {
              if (field.state.meta.errors.length > 0) {
                return field.state.meta.errors.join(', ');
              }
              return true;
            }}
            data-testid="etd-date-picker"
            label="Date of Departure"
            defaultValue={getDate(field.state.value)}
            id={field.name}
            name={field.name}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.toString())}
            isDateUnavailable={(date) => {
              const etdMoment = moment(date.toString());
              const etaMoment = moment(field.form.getFieldValue('eta'));
              return (
                etaMoment.isBefore(etdMoment) ||
                (isWtw && etdMoment.isBefore(moment().subtract(30, 'days')))
              );
            }}
          />
        )}
      />
      <form.Field
        name="eta"
        children={(field) => {
          return (
            <DatePicker
              data-testid="eta-date-picker"
              minValue={momentToCalendarDate(etaLimits.min)}
              maxValue={momentToCalendarDate(etaLimits.max)}
              label="Date of Arrival"
              defaultValue={getDate(field.state.value)}
              id={field.name}
              name={field.name}
              onBlur={field.handleBlur}
              isDateUnavailable={(date) => {
                const etaMoment = moment(date.toString());
                const etdMoment = moment(field.form.getFieldValue('etd'));
                return etaMoment.isBefore(etdMoment);
              }}
              onChange={(e) => e && field.handleChange(e.toString())}
            />
          );
        }}
      />
      <form.Field
        name="commodity"
        validators={
          commodities.length > 1
            ? {
                onSubmit: z.object({
                  title: z.string().min(1, 'Please select a commodity'),
                }),
              }
            : undefined
        }
        children={(field) => {
          return (
            <ComboBox
              className={clsxMerge(commodities.length <= 1 && 'hidden')}
              isRequired={commodities.length > 1}
              data-testid="commodities-selector"
              label="Commodity"
              placeholder="Type to search commodity"
              menuTrigger="focus"
              id={field.name}
              name={field.name}
              onBlur={field.handleBlur}
              onInputChange={(value) => {
                if (!value) {
                  return;
                }
              }}
              defaultInputValue={field.state.value?.title ?? ''}
              selectedKey={field.state.value?.id ?? ''}
              onSelectionChange={(key) => {
                const matchingCommodity = commodities.find(
                  (commodity) => commodity.id === Number(key),
                );
                if (matchingCommodity !== undefined) {
                  field.handleChange(matchingCommodity);
                }
              }}
              isInvalid={field.state.meta.errors.length > 0}
              errorMessage={field.state.meta.errors.join(', ')}
            >
              {commodities.map(({ id, title }) => (
                <ComboBoxItem key={id} id={id}>
                  {title}
                </ComboBoxItem>
              ))}
            </ComboBox>
          );
        }}
      />

      {available_coverage_package?.length > 1 &&
        available_coverage_package.length > 3 && (
          <form.Field
            name="coveragePackage"
            validators={{
              onSubmit: z.string().min(1, 'Please select a coverage package'),
              onChange: z.string().min(1, 'Please select a coverage package'),
            }}
            children={(field) => (
              <CoverageTypeSelector type="select" field={field} />
            )}
          />
        )}
      <form.Field
        name="containerMode"
        validators={
          container_modes.any.length > 1
            ? {
                onSubmit: z.string().min(1, 'Please select a container mode'),
                onChange: z.string().min(1, 'Please select a container mode'),
              }
            : undefined
        }
        children={(field) => {
          // If there is only one container mode, we should set it as the default selected key
          return (
            <ComboBox
              className={clsxMerge(container_modes.any.length <= 1 && 'hidden')}
              isRequired={container_modes.any.length > 1}
              data-testid="container-mode-selector"
              label="Packing Category"
              placeholder="Type to search a container mode"
              menuTrigger="focus"
              id={field.name}
              name={field.name}
              onBlur={field.handleBlur}
              selectedKey={field.state.value ?? ''}
              inputValue={field.state.value ?? ''}
              onInputChange={field.handleChange}
              onSelectionChange={(key) => {
                const matchingContainerMode = container_modes.any.find(
                  (containerMode) => containerMode.id === Number(key),
                );
                if (matchingContainerMode !== undefined) {
                  field.handleChange(matchingContainerMode.title);
                }
              }}
              isInvalid={field.state.meta.errors.length > 0}
              errorMessage={field.state.meta.errors.join(', ')}
            >
              {container_modes.any.map(({ id, title }) => (
                <ComboBoxItem key={id} id={id}>
                  {title}
                </ComboBoxItem>
              ))}
            </ComboBox>
          );
        }}
      />

      <form.Field
        name="cargoValue"
        validators={{
          onSubmit: ({ value }) => {
            if (!value || Number(value) <= 0) {
              return 'Please enter a valid amount';
            }
            return;
          },
        }}
        children={(field) => {
          return (
            <CurrencyValueInput
              type="number"
              step="any"
              aria-required
              data-testid="cargo-value-input"
              // TODO The way this is implemented is different to the rest of the fields. It will be replaced so the evil type coersion can be ignored
              value={field.state.value ? Number(field.state.value) : undefined}
              currencyOptions={currencies}
              name={field.name}
              id={field.name}
              onChange={(value) => {
                field.handleChange(value);
              }}
              onBlur={field.handleBlur}
              currency={
                field.form.state.values.cargoCurrency as SupportedCurrencies
              }
              onCurrencyChange={(currency) => {
                field.handleChange(field.form.state.values.cargoValue);
                field.form.setFieldValue('cargoCurrency', currency, {
                  touch: true,
                });
              }}
              label="Commercial Invoice Value *"
              placeholder="Enter amount"
              testId="commodity-value-input"
              error={field.state.meta.errors?.length > 0}
              errorHelperText={field.state.meta.errors.join(', ')}
            />
          );
        }}
      />
      <form.Field
        name="bookingReference"
        children={(field) => (
          <TextField
            data-testid="booking-reference-input"
            placeholder="Enter booking reference"
            label="Reference"
            id={field.name}
            name={field.name}
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={field.handleChange}
            isInvalid={field.state.meta.errors?.length > 0}
            errorMessage={field.state.meta.errors.join(', ')}
          />
        )}
      />
      <form.Field
        name="cargoDescription"
        validators={{
          onSubmit: z.string().min(1, 'Please enter a valid description'),
          onChange: z
            .string()
            .max(600, 'Input exceeds maximum character limit of 600'),
        }}
        children={(field) => (
          <TextField
            isRequired
            data-testid="goods-description-text-area"
            variant="area"
            placeholder="Enter description"
            label="Description of Cargo"
            inputStyles="min-h-24 resize-none "
            id={field.name}
            name={field.name}
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={field.handleChange}
            isInvalid={field.state.meta.errors.length > 0}
            errorMessage={field.state.meta.errors.join(', ')}
          />
        )}
      />
      <VesselFields form={form} />
      <form.Field
        name="marksAndNumbers"
        validators={{
          onChange: z
            .string()
            .max(600, 'Input exceeds maximum character limit of 600'),
        }}
        children={(field) => (
          <TextField
            data-testid="marks-and-numbers-text-area"
            inputStyles="min-h-[100px] resize-none"
            variant="area"
            placeholder="Enter marks & numbers"
            label="Marks & Numbers"
            id={field.name}
            name={field.name}
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={field.handleChange}
            isInvalid={field.state.meta.errors?.length > 0}
            errorMessage={field.state.meta.errors.join(', ')}
          />
        )}
      />
      {showFreightCostField && (
        <form.Subscribe
          selector={(state) => [state.values.freightCostCurrency]}
          children={([freightCostCurrency]) => (
            <>
              <form.Field name="freightCostCurrency" children={() => null} />
              <form.Field
                name="freightCost"
                validators={{
                  onSubmit: ({ value }) => {
                    if (!value) {
                      return;
                    }

                    const numberValue = Number(value);
                    if (numberValue <= 0) {
                      return 'Freight cost cannot be negative or zero';
                    }

                    return;
                  },
                }}
                children={(field) => (
                  <CurrencyValueInput
                    testId="freight-cost-input"
                    label={t('FreightCost', {
                      ns: 'common',
                      context: platform,
                    })}
                    placeholder={t(
                      'form.shipmentDetails.FreightCostPlaceholder',
                      {
                        ns: 'quotes',
                        context: platform,
                      },
                    )}
                    value={field.state.value ?? undefined}
                    name={field.name}
                    onBlur={field.handleBlur}
                    currency={freightCostCurrency}
                    currencyOptions={currencies}
                    onChange={(value) => {
                      if (!value) {
                        field.handleChange(null);
                        return;
                      }

                      field.handleChange(value);
                    }}
                    onCurrencyChange={(currency) => {
                      field.form.setFieldValue('freightCostCurrency', currency);

                      // Need to do this since freightCostCurrency doesn't have it's own component
                      const meta = field.getMeta();
                      field.form.setFieldMeta('freightCostCurrency', {
                        ...meta,
                        isTouched: true,
                        isDirty: true,
                      });
                    }}
                    error={field.state.meta.errors?.length > 0}
                    errorHelperText={field.state.meta.errors.join(', ')}
                    inputDropdownProps={{
                      testId: 'freight-cost-currency-selector',
                    }}
                  />
                )}
              />
            </>
          )}
        />
      )}
      {showDutyCostField && (
        <form.Subscribe
          selector={(state) => [state.values.dutyCostCurrency]}
          children={([dutyCostCurrency]) => (
            <>
              <form.Field name="dutyCostCurrency" children={() => null} />
              <form.Field
                name="dutyCost"
                validators={{
                  onSubmit: ({ value }) => {
                    if (!value) {
                      return;
                    }

                    const numberValue = Number(value);
                    if (numberValue <= 0) {
                      return 'Duty cost cannot be negative or zero';
                    }

                    return;
                  },
                }}
                children={(field) => (
                  <CurrencyValueInput
                    testId="duty-cost-input"
                    label={t('DutyCost', {
                      ns: 'common',
                      context: platform,
                    })}
                    placeholder="Enter amount"
                    value={field.state.value ?? undefined}
                    name={field.name}
                    onBlur={field.handleBlur}
                    currency={dutyCostCurrency}
                    currencyOptions={currencies}
                    onChange={(value) => {
                      if (!value) {
                        field.handleChange(null);
                        return;
                      }

                      field.handleChange(value);
                    }}
                    onCurrencyChange={(currency) => {
                      field.form.setFieldValue('dutyCostCurrency', currency);

                      // Need to do this since dutyCostCurrency doesn't have it's own component
                      const meta = field.getMeta();
                      field.form.setFieldMeta('dutyCostCurrency', {
                        ...meta,
                        isTouched: true,
                        isDirty: true,
                      });
                    }}
                    error={field.state.meta.errors?.length > 0}
                    errorHelperText={field.state.meta.errors.join(', ')}
                    inputDropdownProps={{
                      testId: 'duty-cost-currency-selector',
                    }}
                  />
                )}
              />
            </>
          )}
        />
      )}
      {enableLetterOfCredit && (
        <div className="col-span-2">
          <LetterOfCreditSelector form={form} />
        </div>
      )}
      <div className="lg:grid lg:grid-cols-2 lg:col-span-full lg:gap-x-4 space-y-6">
        <Typography level="h4" customStyles="lg:col-span-full mt-6">
          Assured Details
        </Typography>

        <form.Field
          name="distributorId"
          validators={{
            onSubmit: ({ value }) => {
              if (!value) {
                return 'Please select a primary assured';
              }
              const distributor = distributors?.find(
                (distributor) => distributor.id === Number(value),
              );
              const distributorIsArchived =
                distributor?.status === DistributorStatusEnum.ARCHIVED;

              if (distributorIsArchived) {
                return 'Please select a new primary assured in order to update the certificate';
              }
              return;
            },
          }}
          children={(field) => (
            <DistributorSelect
              id={field.name}
              name={field.name}
              onBlur={field.handleBlur}
              defaultSelectedKey={form.state.values.distributorId}
              onSelectionChange={(key) => {
                if (key) {
                  field.handleChange(Number(key));
                }
              }}
              isRequired
              distributors={distributors ?? []}
              isLoading={isLoading}
              isDisabled={isUpdate}
              isInvalid={field.state.meta.errors?.length > 0}
              errorMessage={field.state.meta.errors.join(', ')}
            />
          )}
        />
        <form.Field
          name="assuredName"
          validators={{
            onSubmit: () => {
              if (
                !assuredNameRef.current?.value &&
                !isAssuredAddressInputDisabled
              ) {
                return 'Please enter an assured name';
              }
              return;
            },
          }}
          children={(field) => (
            <CustomerComboBox
              data-testid="assured-name-combobox"
              inputRef={assuredNameRef}
              label="Named Assured"
              menuTrigger="focus"
              placeholder="Enter named assured name"
              popoverProps={{
                className: 'max-h-28',
              }}
              id={field.name}
              name={field.name}
              onBlur={(e) => {
                // This is to handle the case where the user only types the company name and does not select from the dropdown
                const isCreatingNewCustomer =
                  field.state.value?.company_name !== e.target.value;
                if (isCreatingNewCustomer) {
                  field.setValue({
                    company_name: e.target.value.trim(),
                  });
                  field.setMeta({
                    ...field.state.meta,
                    isDirty: true,
                  });
                }

                // If the user clears the input, we need to clear the assured address as well.
                if (e.target.value.length === 0) {
                  field.form.setFieldValue('assuredAddress', {
                    description: '',
                    place_id: '',
                  });
                  setIsAssuredAddressInputDisabled(true);
                  field.setMeta({
                    ...field.state.meta,
                    isDirty: true,
                  });
                }

                setIsAssuredAddressInputDisabled(
                  e.target.value.trim().length === 0,
                );
                field.handleBlur();
              }}
              onSelectionChange={(option) => {
                if (option) {
                  const customer: CustomerOption = JSON.parse(String(option));
                  field.handleChange(customer);
                  customer.address?.address &&
                    field.form.setFieldValue(
                      'assuredAddress',
                      {
                        description: customer.address?.address?.full_address,
                        place_id: customer.address?.provider_place_uuid,
                      },
                      { touch: false },
                    );
                  setIsAssuredAddressInputDisabled(false);
                }
              }}
              aria-label="Assured Name"
              defaultInputValue={field.state.value?.company_name}
              isInvalid={field.state.meta.errors?.length > 0}
              errorMessage={field.state.meta.errors.join(', ')}
            />
          )}
        />
        <form.Field
          name="assuredAddress"
          children={(field) => (
            <PlacesComboBox
              data-testid="assured-address-combobox"
              isDisabled={isAssuredAddressInputDisabled}
              aria-label="Named Assured Address"
              label="Named Assured Address"
              placeholder="Enter named assured address"
              id={field.name}
              name={field.name}
              onBlur={() => {
                if (field.state.value?.place_id.length === 0) {
                  field.handleChange({
                    ...field.state.value,
                    place_id: '',
                    description: '',
                  });
                }
                field.handleBlur();
              }}
              selectedKey={JSON.stringify(field.state.value)}
              isInvalid={field.state.meta.errors?.length > 0}
              errorMessage={field.state.meta.errors.join(', ')}
              inputValue={field.state.value?.description}
              onInputChange={(value) => {
                field.handleChange({
                  ...field.state.value,
                  place_id: '',
                  description: value,
                });
              }}
              onSelectionChange={(option) => {
                const parsedOption: PlaceSuggestion = JSON.parse(
                  String(option),
                );
                const isFieldUpdated =
                  parsedOption.place_id !== field.prevState.value?.place_id;
                isFieldUpdated && field.handleChange(parsedOption);
              }}
            />
          )}
        />
      </div>
    </div>
  );
}
