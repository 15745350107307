import { type FormApi } from '@tanstack/react-form';
import { type zodValidator } from '@tanstack/zod-form-adapter';

import { useUserSetting } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import { type RouteDetailsFormData } from '../RouteDetails/types';
import { VesselsComboBox } from './VesselsComboBox';
import { VesselTextField } from './VesselTextField';

export interface VesselFieldsFormData extends RouteDetailsFormData {
  vessel?: { name?: string; external_id?: string };
}

type SelectorReturnType = [
  RouteDetailsFormData['primaryMot'],
  VesselFieldsFormData['vessel'],
];

const validateVesselField = (
  value: VesselFieldsFormData['vessel'],
  isRequired: boolean,
) => {
  if (isRequired && !value?.external_id) {
    return 'Please select a vessel';
  }
  return;
};

export function VesselFields({
  form,
}: {
  form: FormApi<VesselFieldsFormData, typeof zodValidator>;
}) {
  const { isWtw } = usePlatform();

  const requireVesselInformation = useUserSetting<boolean>(
    'require_vessel_information',
  );

  return (
    <form.Subscribe
      selector={(state): SelectorReturnType => [
        state.values.primaryMot,
        state.values.vessel,
      ]}
      children={([primaryMot, vessel]) => {
        const shouldShowVesselSearchField = isWtw && primaryMot.name === 'sea';

        return (
          <>
            {shouldShowVesselSearchField && (
              <form.Field
                name="vessel"
                validators={{
                  onBlur: ({ value }) =>
                    validateVesselField(value, requireVesselInformation),
                  onSubmit: ({ value }) =>
                    validateVesselField(value, requireVesselInformation),
                }}
                children={(field) => (
                  <VesselsComboBox
                    defaultPrimaryMot={form.options.defaultValues?.primaryMot}
                    primaryMot={primaryMot}
                    id={field.name}
                    isRequired={requireVesselInformation}
                    name={field.name}
                    onBlur={field.handleBlur}
                    defaultInputValue={vessel?.name}
                    onInputChangeWithItems={({ value, vessels }) => {
                      if (!value) {
                        field.handleChange({
                          name: '',
                          external_id: '',
                        });
                        return;
                      }
                      if (vessels === undefined) {
                        field.handleChange({
                          name: value,
                          external_id: undefined,
                        });
                        return;
                      }
                      // TODO HACK: returning the first vessel for now - maybe need to fine-tune this
                      const vessel_external_id = vessels?.find(
                        (v) => v.name === value,
                      )?.external_id;

                      field.handleChange({
                        name: value,
                        external_id: vessel_external_id,
                      });
                    }}
                    onSelectionChange={(key) => {
                      if (typeof key === 'string') {
                        const vessel: VesselFieldsFormData['vessel'] =
                          JSON.parse(key);
                        field.handleChange(vessel);
                      }
                    }}
                    isInvalid={field.state.meta.errors.length > 0}
                    errorMessage={field.state.meta.errors[0]?.toString()}
                  />
                )}
              />
            )}
            {!shouldShowVesselSearchField && (
              <form.Field
                name="vessel"
                children={(field) => (
                  <VesselTextField
                    defaultPrimaryMot={form.options.defaultValues?.primaryMot}
                    primaryMot={primaryMot}
                    id={field.name}
                    name={field.name}
                    value={field.state.value?.name}
                    onBlur={field.handleBlur}
                    onChange={(value) => {
                      field.handleChange({
                        name: value,
                        external_id: undefined,
                      });
                    }}
                    isInvalid={field.state.meta.errors?.length > 0}
                    errorMessage={field.state.meta.errors.join(', ')}
                  />
                )}
              />
            )}
          </>
        );
      }}
    />
  );
}
