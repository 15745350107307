import { useAppConfiguration } from '../../context/app-configuration/useAppConfiguration';
import { useUserSetting } from '../../context/auth/auth-hooks';
import { type ConveyanceType } from '../../model/Shipment';
export const useDefaultPrimaryMot = (): {
  name: ConveyanceType;
  displayName: string;
} => {
  const preferred_mode_of_transport_code = useUserSetting(
    'preferred_mode_of_transport_code',
  );

  const parsedPreferredModeOfTransportCode =
    preferred_mode_of_transport_code &&
    ['sea', 'air', 'road', 'rail'].includes(
      preferred_mode_of_transport_code.toString(),
    )
      ? preferred_mode_of_transport_code
      : undefined;

  const { modes_of_transport } = useAppConfiguration();

  const mot = modes_of_transport?.find(
    (mot) => mot.name === (parsedPreferredModeOfTransportCode || 'sea'),
  );

  return {
    name: (parsedPreferredModeOfTransportCode || 'sea') as ConveyanceType,
    displayName: mot?.display_name || 'Sea',
  };
};
