import {
  ComboBox,
  ComboBoxItem,
  type ComboBoxProps,
} from '@breezeai-frontend/cargo-ui';

import { type TitledOption } from '../../../model/TitledOption';

export function CommodityComboBox({
  commodities,
  ...props
}: {
  commodities: TitledOption[];
} & Omit<ComboBoxProps<TitledOption>, 'children'>) {
  return (
    <ComboBox {...props} defaultItems={commodities} allowsCustomValue>
      {commodities.map((commodity) => {
        const { id, title } = commodity;
        return (
          <ComboBoxItem
            key={id}
            id={JSON.stringify(commodity)}
            textValue={title}
          >
            {title}
          </ComboBoxItem>
        );
      })}
    </ComboBox>
  );
}
