import { useTranslation } from 'react-i18next';
import {
  Select,
  SelectItem,
  type SelectProps,
} from '@breezeai-frontend/cargo-ui';
import { kebabCase } from 'lodash-es';

import { type Distributor } from '../../../model/Distributor';
import { useTranslationContext } from '../../hooks/useTranslationContext';

interface DistributorSelectProps
  extends Omit<SelectProps<Distributor>, 'children'> {
  distributors: Distributor[];
  isLoading?: boolean;
}

export function DistributorSelect({
  distributors,
  isLoading,
  ...props
}: DistributorSelectProps) {
  const { t } = useTranslation();
  const tContext = useTranslationContext();

  return (
    <Select
      label={t('Distributor', {
        ns: 'common',
        context: tContext,
      })}
      placeholder={t('DistributorPlaceholder', {
        ns: 'common',
        context: tContext,
      })}
      isLoading={isLoading}
      data-testid="distributor-selector"
      validationBehavior="aria"
      selectedValueTestId="distributor-selected-value"
      {...props}
    >
      {distributors.map(({ legal_name, id }) => (
        <SelectItem
          key={id}
          id={id}
          textValue={legal_name}
          data-testid={`select-option-${kebabCase(legal_name)}`}
        >
          {legal_name}
        </SelectItem>
      ))}
    </Select>
  );
}
