import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker, useNavigate } from 'react-router-dom';
import { CancelChangesModal, Typography } from '@breezeai-frontend/cargo-ui';
import { type FormApi, useForm } from '@tanstack/react-form';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { zodValidator } from '@tanstack/zod-form-adapter';

import { useAppConfiguration } from '../../../../context/app-configuration/useAppConfiguration';
import {
  useAuthenticatedUser,
  useUserSetting,
} from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import {
  type BffGetConfigurationsResponseProfitCenterEnum,
  type BffQuoteModelWithPermissions,
  type BffUpdateQuoteParamsPayload,
} from '../../../../generated/api-client';
import { type SupportedCurrencies } from '../../../../model/CurrencyValue';
import {
  type CoveragePackageType,
  type DivisionType,
} from '../../../../model/Quote';
import { type ShipmentSpecialCondition } from '../../../../model/Shipment';
import { useSuspenseDistributors } from '../../../../network/apis/distributors/use-distributors';
import {
  useCreateQuote,
  useDuplicateQuote,
  useUpdateQuote,
} from '../../../../network/apis/quotes/hooks';
import { normalizeQuoteResponse } from '../../../../network/apis/quotes/normalizers';
import { useRouteParams } from '../../../../router/router-hooks';
import { trackPageView } from '../../../../utils/snowplow/utils';
import { getDefaultPortOrPlace } from '../../../certificates/CertificateFlowPage/utils';
import { CoverageTypeSelector } from '../../../components/CoverageTypeSelector/CoverageTypeSelector';
import { type CustomerOption } from '../../../components/CustomerComboBox/types';
import { NoActiveOpenCoverModal } from '../../../components/NoActiveOpenCoverModal/NoActiveOpenCoverModal';
import { RouteDetails } from '../../../components/RouteDetails/RouteDetails';
import { RouteDetailsInputsProvider } from '../../../components/RouteDetails/RouteDetailsInputProvider';
import { getDefaultInputValue } from '../../../components/RouteDetails/utils';
import { ShipmentSpecialConditionsSelector } from '../../../components/ShipmentSpecialConditionsSelector/ShipmentSpecialConditionsSelector';
import { getVesselFieldDefaultValue } from '../../../components/VesselFields/utils';
import { type VesselFieldsFormData } from '../../../components/VesselFields/VesselFields';
import { useDefaultPrimaryMot } from '../../../hooks/useDefaultPrimaryMot';
import { policiesQueries } from '../../../policies/network/queries';
import {
  bffQuoteToQuoteModel,
  quotesQueries,
} from '../../../quotes/network/queries';
import { shipmentsQueries } from '../../../shipments/network/queries';
import { LetterOfCreditSelector } from '../../components/LetterOfCreditSelector/LetterOfCreditSelector';
import { FLOW_ERROR_MESSAGES, FlowSteps } from '../../constants';
import {
  type PolicyFlowUrlParams,
  type PolicyFlowUrlSearchParams,
} from '../../context/types';
import {
  usePolicyFlowContext,
  useSetFlowError,
  useSetFlowStep,
  useSetQuoteData,
} from '../../hooks/context-hooks';
import { CargoDetails } from './CargoDetails';
import { FormContext } from './FormContext';
import { QuoteFormActions } from './QuoteFormActions';
import { useQuoteFormLogic } from './useQuoteFormLogic';
import { getDefaultContainerModeId } from './utils';

export interface QuoteFormData extends VesselFieldsFormData {
  distributorId?: number;
  coveragePackage?: CoveragePackageType;
  commodityValue?: number | null;
  commodityCategory?: {
    name: string;
    typeId: number;
  } | null;
  commodityDescription?: string;
  commodityCurrency?: SupportedCurrencies;
  cargoOwner?: CustomerOption | null;
  containerModeId?: number;
  freightCost?: number | null;
  freightCostCurrency?: SupportedCurrencies;
  dutyCost?: number | null;
  dutyCostCurrency?: SupportedCurrencies;
  externalReference?: string;
  specialConditions?: ShipmentSpecialCondition[];
  letterOfCredit?: string;
  division?: DivisionType | null;
  profitCenter?: BffGetConfigurationsResponseProfitCenterEnum;
  upliftPercentage?: number;

  purposeType?: BffUpdateQuoteParamsPayload['purpose_type'];
  standardLiaibilityOffering?: BffUpdateQuoteParamsPayload['standard_liability_offering'];
  claimsHandlerLocation?: BffUpdateQuoteParamsPayload['claims_handler_location'];
}

export const QuoteFormContent = ({
  form,
}: {
  form: FormApi<QuoteFormData, typeof zodValidator>;
}) => {
  const enableLetterOfCredit = useUserSetting<boolean>(
    'enable_letter_of_credit',
  );

  const { isBreeze, isWtw, platform } = usePlatform();
  const { t } = useTranslation();

  const { special_conditions, container_modes } = useAppConfiguration();

  const {
    data: { coverage_options },
  } = useSuspenseQuery(policiesQueries.policyFormConfiguration());

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      form.state.isDirty &&
      currentLocation.pathname !== nextLocation.pathname &&
      // prevent the blocker from from blocking the route navigation to /quotes/:quoteId
      !nextLocation.pathname.includes('/quotes/'),
  );

  const { isDuplicate, isUpdate } = useQuoteFormLogic();

  return (
    <form
      noValidate
      data-testid="quote-submission-form"
      className="flex flex-row p-3 bg-white h-full w-full min-w-[600px] overflow-x-auto overflow-y-auto min-h-max gap-4 rounded-3xl"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        void form.handleSubmit();
      }}
    >
      <CancelChangesModal
        title="Are you sure you want to cancel this quote?"
        subtitle="Your changes will be lost."
        isOpen={blocker.state === 'blocked'}
        onOpenChange={() => blocker.reset && blocker.reset()}
        onDiscardChanges={() => blocker.proceed && blocker.proceed()}
      />

      <div className="flex flex-grow bg-surfaces-secondary rounded-2xl p-5 overflow-y-auto  min-w-fit">
        <RouteDetails
          isUpdate={isUpdate}
          isDuplicate={isDuplicate}
          form={form}
          listeners={{
            primaryMot: {
              onChange: (value) => {
                if (isBreeze) {
                  form.setFieldValue(
                    'containerModeId',
                    container_modes[value.name][0]?.id,
                  );

                  const meta = form.getFieldMeta('containerModeId');
                  if (!meta) return;

                  form.setFieldMeta('containerModeId', {
                    ...meta,
                    isDirty: true,
                  });
                }
              },
            },
          }}
        />
      </div>

      <div className="flex flex-col h-full overflow-y-auto w-full min-w-96 ">
        {coverage_options.length > 1 && coverage_options.length < 4 && (
          <div className="flex flex-col w-full min-h-fit">
            <Typography
              level="h3"
              customStyles="pt-5 pb-4 sticky top-0 bg-gradient-to-b from-white from-80% to-transparent z-10"
            >
              {t('CoverageType', {
                ns: 'common',
                context: platform,
              })}
            </Typography>
            <form.Field
              name="coveragePackage"
              children={(field) => (
                <CoverageTypeSelector type="radio" field={field} />
              )}
            />
          </div>
        )}
        <CargoDetails form={form} isUpdate={isUpdate} />

        {special_conditions.length > 0 && (
          <div>
            {/* TODO: fix layout not to use margins */}
            <Typography level="h4" customStyles="xl:col-span-full mt-6 mb-4">
              {t('form.SpecialConditions', {
                ns: 'quotes',
                context: platform,
              })}
            </Typography>
            <form.Field
              name="specialConditions"
              children={(field) => (
                <ShipmentSpecialConditionsSelector
                  options={special_conditions}
                  selected={form.getFieldValue('specialConditions')}
                  onChange={field.handleChange}
                />
              )}
            />
          </div>
        )}

        {isWtw && enableLetterOfCredit && (
          <div className="mb-4">
            <LetterOfCreditSelector form={form} />
          </div>
        )}

        <div className="w-full xl:w-auto flex flex-row gap-2 self-end mt-auto justify-end">
          <QuoteFormActions form={form} />
        </div>
      </div>
    </form>
  );
};

export function QuoteForm() {
  const { isWtw, isBreeze } = usePlatform();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { container_modes, commodities } = useAppConfiguration();
  const user = useAuthenticatedUser();

  const {
    isDuplicate,
    isUpdate,
    getUpdatePayload,
    getCreationPayload,
    getDuplicationPayload,
  } = useQuoteFormLogic();

  const { resetForm } = useContext(FormContext);

  const defaultCurrency =
    useUserSetting<SupportedCurrencies>('default_currency');

  const [isOpenCoverModalOpen, setIsOpenCoverModalOpen] = useState(false);

  // TODO: remove these hook calls when the old context is deprecated
  const setFlowStep = useSetFlowStep();
  const setQuoteData = useSetQuoteData();
  const setFlowError = useSetFlowError();
  const { step } = usePolicyFlowContext();

  useEffect(() => {
    step === FlowSteps.QUOTE_FORM &&
      trackPageView(
        {
          eventFeature: 'quote',
          eventSubfeature: 'form',
          eventAction: 'page_view',
        },
        user,
      );
  }, [user, step]);

  const {
    params: { quoteId },
  } = useRouteParams<PolicyFlowUrlParams>();

  const handleSuccess = async ({
    data,
  }: {
    data: BffQuoteModelWithPermissions;
  }) => {
    const quote = bffQuoteToQuoteModel(data);
    // TODO: deprecate old form context and hooks
    setQuoteData(normalizeQuoteResponse(quote));
    setFlowStep(FlowSteps.QUOTE_SUMMARY);

    await queryClient.invalidateQueries({
      queryKey: ['quotes', String(quote.id)],
      refetchType: 'active',
    });

    // Reset form so touched and dirty states are false again
    resetForm();

    navigate(`/quotes/${quote.id}`);
  };

  const handleError = () => {
    setFlowError({
      fallbackStep: FlowSteps.QUOTE_FORM,
      message: isUpdate
        ? FLOW_ERROR_MESSAGES.updateQuote
        : FLOW_ERROR_MESSAGES.createQuote,
      confirmText: 'Try again',
      retry: () => form.handleSubmit(),
    });
  };

  // Use the right hook based on the action
  const { mutate: createMutate } = useCreateQuote({
    onMutate: () => {
      setFlowStep(FlowSteps.LOADING);
    },
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const { mutate: updateMutate } = useUpdateQuote({
    onMutate: () => {
      setFlowStep(FlowSteps.LOADING);
    },
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const { mutate: duplicateMutate } = useDuplicateQuote({
    onMutate: () => {
      setFlowStep(FlowSteps.LOADING);
    },
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const {
    searchParams: { shipment_id },
  } = useRouteParams<never, PolicyFlowUrlSearchParams>();

  const { data: quote } = useSuspenseQuery(quotesQueries.details({ quoteId }));
  const { data: shipment } = useSuspenseQuery(
    shipmentsQueries.details({ shipmentId: shipment_id }),
  );
  const {
    data: { coverage_options },
  } = useSuspenseQuery(policiesQueries.policyFormConfiguration());

  // TODO: WET code - called again in CargoDetails.tsx
  const { data: unfilteredDistributors } = useSuspenseDistributors({
    params: {
      paginate: false,
      order: 'asc',
      include_archived: isUpdate,
      context: 'quotes_creation',
    },
  });
  const distributors = unfilteredDistributors.filter(
    (distributor) => distributor.can_issue_policy,
  );

  const defaultPrimaryMot = useDefaultPrimaryMot();

  const initialData = quote || shipment;

  const isUpdateOrBreeze = isUpdate || isBreeze;

  const requireVesselInformation = useUserSetting<boolean>(
    'require_vessel_information',
  );

  // Default to first option, or no default if >= 4 options
  const defaultCoveragePackage =
    coverage_options.length < 4 ? coverage_options[0] : undefined;

  const defaultDistributorForEmptyForm =
    distributors && distributors.length === 1 ? distributors[0].id : undefined;

  const form = useForm<QuoteFormData, typeof zodValidator>({
    defaultValues: {
      distributorId: quote?.distributor_id ?? defaultDistributorForEmptyForm,

      division: quote?.division,

      coveragePackage: quote?.coverage_package || defaultCoveragePackage,

      origin: initialData
        ? getDefaultPortOrPlace(initialData, 'origin')
        : undefined,
      destination: initialData
        ? getDefaultPortOrPlace(initialData, 'destination')
        : undefined,
      primaryMot:
        initialData?.primary_transport_mode_code &&
        initialData?.primary_transport_mode_code_display_name
          ? {
              name: initialData!.primary_transport_mode_code,
              displayName:
                initialData.primary_transport_mode_code_display_name as string,
            }
          : defaultPrimaryMot,

      placeOfLoading:
        quote && quote.loading_place
          ? {
              type: 'place',
              place: {
                place_id: quote.loading_place.provider_place_uuid,
                description: quote.loading_place.full_address,
              },
              port: null,
            }
          : undefined,
      placeOfLoadingMot: quote?.loading_transport_mode_code || 'road',

      placeOfDelivery:
        quote && quote.delivery_place
          ? {
              type: 'place',
              place: {
                place_id: quote.delivery_place.provider_place_uuid,
                description: quote.delivery_place.full_address,
              },
              port: null,
            }
          : undefined,
      placeOfDeliveryMot: quote?.delivery_transport_mode_code || 'road',

      commodityValue:
        isUpdateOrBreeze && initialData?.commodity_value
          ? initialData.commodity_value
          : undefined,
      commodityCurrency: initialData?.commodity_currency || defaultCurrency,
      commodityDescription:
        quote?.commodity_external_description ||
        shipment?.commodity_description ||
        '',
      // The user should always have at at least one commodity available to them. In that case, when there is a single option, it will be selected by default and the field wil be hidden.
      commodityCategory:
        (quote?.commodity_category && quote?.commodity_category_id) ||
        commodities.length === 1
          ? {
              name: quote?.commodity_category ?? commodities[0]?.title,
              typeId: quote?.commodity_category_id ?? commodities[0]?.id,
            }
          : undefined,

      cargoOwner: initialData?.customer
        ? {
            id: initialData.customer.id,
            address: initialData.customer.address,
            company_name: initialData.customer.company_name,
          }
        : undefined,

      containerModeId: getDefaultContainerModeId(
        container_modes,
        defaultPrimaryMot.name,
        initialData,
        isWtw,
      ),

      externalReference: isDuplicate
        ? ''
        : quote?.external_reference || shipment?.external_shipment_id || '',

      freightCost:
        isUpdateOrBreeze && initialData?.freight_cost
          ? initialData.freight_cost
          : undefined,
      freightCostCurrency:
        initialData?.freight_cost_currency || defaultCurrency,

      dutyCost: initialData?.duty_cost ? initialData.duty_cost : undefined,
      dutyCostCurrency: initialData?.duty_cost_currency || defaultCurrency,

      specialConditions: initialData?.special_conditions || undefined,
      letterOfCredit: quote?.letter_of_credit || '',

      vessel: getVesselFieldDefaultValue(
        quote?.vessel,
        initialData?.vessel_name,
        initialData?.primary_transport_mode_code,
        requireVesselInformation,
      ),
      profitCenter: quote?.profit_center,
      upliftPercentage: quote?.uplift_percentage,

      purposeType: quote?.purpose_type,
      standardLiaibilityOffering: quote?.standard_liability_offering,
      claimsHandlerLocation: quote?.claims_handler_location,
    },
    onSubmit: ({ formApi }) => {
      if (!formApi.state.values.coveragePackage) {
        setIsOpenCoverModalOpen(true);
        return;
      }

      if (isUpdate) {
        const payload = getUpdatePayload(formApi);
        const quoteIdToUpdate = quoteId ? parseInt(quoteId) : undefined;
        if (!quoteIdToUpdate) {
          throw new Error('Quote ID is required for update');
        }
        return updateMutate({
          id: quoteIdToUpdate,
          bffUpdateQuoteParamsPayload: payload,
        });
      }
      if (isDuplicate) {
        const payload = getDuplicationPayload(formApi);
        return duplicateMutate({
          bffDuplicateQuoteParamsPayload: payload,
        });
      }
      if (!isDuplicate && !isUpdate) {
        const payload = getCreationPayload(formApi);

        return createMutate({
          bffPostQuoteFormBody: payload,
        });
      }
    },
    validatorAdapter: zodValidator,
  });

  return (
    <RouteDetailsInputsProvider
      // These are default values for the route details inputs
      originInputValue={getDefaultInputValue(form.getFieldValue('origin'))}
      destinationInputValue={getDefaultInputValue(
        form.getFieldValue('destination'),
      )}
      placeOfLoadingInputValue={
        form.getFieldValue('placeOfLoading')?.place?.description
      }
      placeOfDeliveryInputValue={
        form.getFieldValue('placeOfDelivery')?.place?.description
      }
    >
      <QuoteFormContent form={form} />
      <NoActiveOpenCoverModal
        isOpen={isOpenCoverModalOpen}
        onOpenChange={setIsOpenCoverModalOpen}
      />
    </RouteDetailsInputsProvider>
  );
}
