import {
  composeRenderProps,
  NumberField as AriaNumberField,
  type NumberFieldProps as AriaNumberFieldProps,
} from 'react-aria-components';

import { clsxMerge } from '../common/utils/classNameUtils';
import {
  Description,
  FieldError,
  fieldStyles,
  Input,
  Label,
} from '../Field/Field';

export interface PercentageFieldProps
  extends Omit<AriaNumberFieldProps, 'children' | 'className'> {
  label?: string;
  description?: string;
  errorMessage?: string;
  customStyles?: string;
  placeholder?: string;
  inputStyles?: string;
}

export function PercentageField({
  label,
  description,
  isInvalid,
  errorMessage,
  isRequired,
  placeholder,
  inputStyles,
  minValue = 0,
  // TODO HACK: max value is enforced in the input field
  // maxValue = 1,
  step = 1,
  ...props
}: PercentageFieldProps) {
  return (
    <AriaNumberField
      {...props}
      minValue={minValue * 100}
      // TODO HACK: max value is enforced in the input field
      maxValue={undefined}
      value={props.value ? props.value * 100 : props.value}
      step={step}
      isInvalid={isInvalid}
      validationBehavior="aria"
      isRequired={isRequired}
      className={composeRenderProps(
        props.customStyles,
        (customStyles, renderProps) =>
          clsxMerge(fieldStyles({ ...renderProps }), customStyles),
      )}
      onChange={(value) => {
        if (props.onChange) {
          props.onChange(value / 100);
        }
      }}
    >
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <div className="grid grid-cols-[auto_1fr]">
        <div className="flex items-center justify-center px-3 border text-text-disabled border-field-border-default border-1 border-r-0 bg-white">
          %
        </div>
        <Input
          className={clsxMerge(inputStyles, 'rounded-l-none')}
          placeholder={placeholder}
        />
      </div>
      {description && !isInvalid && <Description>{description}</Description>}
      <FieldError absolute>{errorMessage}</FieldError>
    </AriaNumberField>
  );
}
