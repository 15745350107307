import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import {
  type BffGetConfigurationsResponse,
  ConfigurationsApi,
} from '../../generated/api-client';
import { type ClaimEventLocationType } from '../../model/Claim';
import { type CurrencyDefinition } from '../../model/CurrencyValue';
import { type SpecialConditionOptionType } from '../../model/Shipment';
import {
  type LabeledOption,
  type TitledOption,
} from '../../model/TitledOption';
import { getBffApiConfiguration } from '../../network/AuthRequests';

export type AppConfiguration = Omit<
  BffGetConfigurationsResponse,
  keyof WebAppConfigurationOverrides
> &
  WebAppConfigurationOverrides;

export type WebAppConfigurationOverrides = {
  currencies: CurrencyDefinition[];
  incoterms: TitledOption[];
  special_conditions: SpecialConditionOptionType[];
  claim_location_types: LabeledOption<ClaimEventLocationType>[];
};

const normalizeAppConfiguration = (
  data: BffGetConfigurationsResponse,
): AppConfiguration => {
  return {
    ...data,
    currencies: data.currencies satisfies CurrencyDefinition[],
    incoterms: data.incoterms satisfies TitledOption[],
    // TODO HACK: type needs to be narrowed down in backend - use enum and mandatory id and label fields
    special_conditions: data.special_conditions as SpecialConditionOptionType[],
    // TODO HACK: type needs to be narrowed down in backend - use enum and mandatory id and label fields
    claim_location_types:
      data.claim_location_types as LabeledOption<ClaimEventLocationType>[],
    divisions: data.divisions,
    modes_of_transport: data.modes_of_transport,
  };
};

// TODO: Convert custom hook to use query factory structure instead of hook (https://tkdodo.eu/blog/the-query-options-api#query-factories)
export const configurationQueries = {
  application: queryOptions({
    queryKey: ['app-configuration'],
    queryFn: async () => {
      const apiConfig = await getBffApiConfiguration();
      return new ConfigurationsApi(apiConfig).getConfigurations();
    },
  }),
};

export const useAppConfiguration = () => {
  const { data } = useSuspenseQuery(configurationQueries.application);
  return {
    ...normalizeAppConfiguration(data.data),
  };
};
