import { useState } from 'react';
import { ErrorDialog } from '@breeze-ai/ui-library';

import { useAppConfiguration } from '../../../../context/app-configuration/useAppConfiguration';
import {
  createClaim,
  updateClaim,
} from '../../../../network/apis/claims/claims';
import { createClaimPayload } from '../../../../network/apis/claims/normalizers';
import { useAppNavigation } from '../../../../router/use-app-navigation';
import { reportException } from '../../../../utils/error-reporting/error-reporting';
import { NavigationFooter } from '../../../components/NavigationFooter/NavigationFooter';
import { PageSection } from '../../../components/PageSection/PageSection';
import { ClaimCreationFlowSteps } from '../../constants';
import {
  useClaimCreationFlowContext,
  useClaimCreationStateAction,
} from '../context/hooks';
import { type ClaimFormsData } from '../context/types';
import { useClaimCreationFormValidations } from '../form-validations';
import { AdditionalDetailsForm } from './AdditionalDetailsForm';
import styles from './ClaimDetails.module.scss';
import { ClaimDetailsForm } from './ClaimDetailsForm';
import { ContactDetailsForm } from './ContactDetailsForm';
import { ShipmentDetailsForm } from './ShipmentDetailsForm';

export const ClaimDetails = () => {
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();
  const { navigateToSection } = useAppNavigation();
  const { formsData, claim } = useClaimCreationFlowContext();
  const { setStep, setClaim } = useClaimCreationStateAction();
  const { claims_additional_fields_options } = useAppConfiguration();

  const validateForms = useClaimCreationFormValidations();
  const formsValid = validateForms([
    // Different form fields are required based on the type of claim
    formsData.raiseAgainstOpenCover ? 'distributorId' : 'policy',
    'eventDate',
    'eventDescription',
    'contactName',
    'contactEmail',
    'contactPhone',
    ...(claims_additional_fields_options
      ? ([
          'typeOfClaim',
          'commonReasonClaim',
          // 'shippersInterestClaim',
          'countryManagerOpsDirector',
          'managingDirector',
          'dateDamagedReported',
          'transportationOfLossDamage',
          'noticeOfIncidentDate',
        ] satisfies Array<keyof ClaimFormsData>)
      : []),
  ]);

  const cancelClaimCreation = () => {
    navigateToSection('claims');
  };

  const onNext = async () => {
    if (formsValid) {
      const payload = createClaimPayload(formsData as Required<ClaimFormsData>);

      try {
        setLoading(true);
        const response = claim
          ? await updateClaim(claim.id, payload)
          : await createClaim(payload);

        setClaim(response);
        setStep(ClaimCreationFlowSteps.UPLOAD_FILES);
      } catch (e) {
        reportException(e);
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <PageSection title="Claim details">
        <ClaimDetailsForm />
      </PageSection>
      {claims_additional_fields_options && (
        <PageSection title="Additional details">
          <AdditionalDetailsForm
            claims_additional_fields_options={claims_additional_fields_options}
          />
        </PageSection>
      )}
      {formsData.raiseAgainstOpenCover && (
        <PageSection title="Shipment details">
          <ShipmentDetailsForm />
        </PageSection>
      )}
      {/* TODO remove distributor specific customization - https://breeze.fibery.io/Breeze/Tech-Debt-Form-360#Task/Remove-distributor-specific-customizations-3533 */}
      <PageSection
        title={
          claims_additional_fields_options
            ? 'PSA BDP Contact Details'
            : 'Contact details'
        }
      >
        <ContactDetailsForm />
      </PageSection>
      <NavigationFooter
        onBack={cancelClaimCreation}
        backText="Cancel"
        onNext={onNext}
        nextText="Next"
        backProps={{ leftIcon: undefined, isDisabled: loading }}
        nextProps={{ isDisabled: !formsValid, isLoading: loading }}
      />
      <ErrorDialog visible={error} onConfirm={() => setError(false)} />
    </div>
  );
};
