import { FormLayout, FormRow, Input } from '@breeze-ai/ui-library';

import {
  useClaimCreationFlowContext,
  useClaimCreationStateAction,
} from '../context/hooks';
import { useClaimFormInputValidation } from '../form-validations';

export const ContactDetailsForm = () => {
  const { formsData, formErrors } = useClaimCreationFlowContext();
  const { setFormData } = useClaimCreationStateAction();
  const validateInput = useClaimFormInputValidation();

  const { contactName, contactEmail, contactPhone } = formsData;

  return (
    <FormLayout>
      <FormRow gutter={20}>
        <Input
          label="Contact name"
          placeholder="Who can we talk to?"
          required={true}
          value={contactName}
          onChange={({ target: { value: contactName } }) =>
            setFormData({ contactName })
          }
          error={!!formErrors.contactName}
          errorHelperText={formErrors.contactName?.reason}
          validator={(value) => validateInput('contactName', value)}
          testId="contact-name-input"
        />
        <Input
          label="Contact email"
          placeholder="Who can we email?"
          type="email"
          required={true}
          value={contactEmail}
          onChange={({ target: { value: contactEmail } }) =>
            setFormData({ contactEmail })
          }
          error={!!formErrors.contactEmail}
          errorHelperText={formErrors.contactEmail?.reason}
          validator={(value) => validateInput('contactEmail', value)}
          testId="contact-email-input"
        />
        <Input
          label="Contact phone"
          placeholder="+1 (111) 111-111"
          type="tel"
          value={contactPhone}
          onChange={({ target: { value: contactPhone } }) =>
            setFormData({ contactPhone })
          }
          error={!!formErrors.contactPhone}
          errorHelperText={formErrors.contactPhone?.reason}
          validator={(value) => validateInput('contactPhone', value)}
          testId="contact-phone-input"
        />
      </FormRow>
    </FormLayout>
  );
};
