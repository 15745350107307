import { type ErrorResponse } from 'react-router';
import {
  keepPreviousData,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { stringify } from 'query-string';

import {
  type BffGetVesselSuggestionsQuery,
  type BffGetVesselSuggestionsResponse,
} from '../../../generated/api-client';
import { get } from '../../../network/apis/utils';
import { bffServiceUrl } from '../../../network/netconfig';

export const useVesselSuggestions = ({
  params,
  options,
}: {
  params: BffGetVesselSuggestionsQuery;
  options?: Partial<
    UseQueryOptions<
      BffGetVesselSuggestionsResponse,
      ErrorResponse,
      BffGetVesselSuggestionsResponse['vessels'],
      Array<string>
    >
  >;
}) => {
  const queryParams = stringify(params);

  return useQuery({
    queryKey: ['vesselSuggestions', queryParams],
    queryFn: () =>
      get<BffGetVesselSuggestionsResponse>(
        `${bffServiceUrl}/vessels/suggestions?${queryParams}`,
      ),
    select: ({ vessels }) => vessels,
    enabled: !!params.query_text,
    refetchOnWindowFocus: false,
    // vessel suggestions will be fetched from an external API,
    // so we don't want to cache results
    gcTime: 0,
    placeholderData: keepPreviousData,
    ...options,
  });
};
