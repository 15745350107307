import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

import {
  type BffCertificateModelWithPermissions,
  CertificateOnlyApi,
  type CertificateOnlyApiCreateCertificateOnlyRequest,
  type CertificateOnlyApiUpdateCertificateOnlyPolicyRequest,
} from '../../generated/api-client';
import { type ErrorResponse } from '../../network/apis/types';
import { getBffApiConfiguration } from '../../network/AuthRequests';

export const useCreateCertificate = ({
  options,
}: {
  options?: UseMutationOptions<
    BffCertificateModelWithPermissions,
    ErrorResponse,
    CertificateOnlyApiCreateCertificateOnlyRequest
  >;
}) => {
  return useMutation({
    mutationFn: async (payload) => {
      const apiConfig = await getBffApiConfiguration();
      return (
        await new CertificateOnlyApi(apiConfig).createCertificateOnly(payload)
      ).data;
    },
    ...options,
  });
};

export const useUpdateCertificate = ({
  options,
}: {
  options?: UseMutationOptions<
    BffCertificateModelWithPermissions,
    ErrorResponse,
    CertificateOnlyApiUpdateCertificateOnlyPolicyRequest
  >;
}) => {
  return useMutation({
    mutationFn: async (payload) => {
      const apiConfig = await getBffApiConfiguration();
      return (
        await new CertificateOnlyApi(apiConfig).updateCertificateOnlyPolicy(
          payload,
        )
      ).data;
    },
    ...options,
  });
};
