import { type HTMLAttributes } from 'react';

export function BouncingDots(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props} data-testid="bouncing-dots-loader">
      <div className="flex gap-2 justify-center items-center mb-6">
        <span className="sr-only">Loading...</span>
        <div className="size-2 bg-loader-bg rounded-full animate-bounce [animation-delay:-0.6s]"></div>
        <div className="size-2 bg-loader-bg rounded-full animate-bounce [animation-delay:-0.3s]"></div>
        <div className="size-2 bg-loader-bg rounded-full animate-bounce [animation-delay:-0.15s]"></div>
        <div className="size-2 bg-loader-bg rounded-full animate-bounce"></div>
      </div>
    </div>
  );
}
