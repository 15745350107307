import { useState } from 'react';
import { FaPlus, FaRedo } from 'react-icons/fa';
import { PiArchiveFill } from 'react-icons/pi';
import { useParams } from 'react-router-dom';
import {
  ActionConfirmationModal,
  Button,
  LinkButton,
} from '@breezeai-frontend/cargo-ui';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { useUser } from '../../../context/auth/auth-hooks';
import { useUpdateStorageLocation } from '../locations/hooks/useCreateStorageLocation';
import {
  type StorageLocationModel,
  StorageLocationStatusEnum,
} from '../locations/types';
import { storageLocationQueries } from '../network/queries';
import { type StorageRouteParams } from '../types';

export function StorageDetailsActions({
  location_name,
  status,
}: {
  location_name: StorageLocationModel['location_name'];
  status: StorageLocationModel['status'];
}) {
  const queryClient = useQueryClient();
  const user = useUser();
  const isAuthorizedToCreateDeclarations = Boolean(
    user?.permissions.storage_locations?.create_declaration,
  );
  const [showAlertModal, setShowAlertModal] = useState(false);
  const { locationId } = useParams<StorageRouteParams>();
  invariant(locationId, 'Location ID is required');
  const { mutate, isPending } = useUpdateStorageLocation({
    locationId,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['storage-location', locationId],
        });
      },
    },
  });
  const { isRefetching: isFetchingUpdatedData } = useQuery({
    ...storageLocationQueries.details({
      locationId,
    }),
    staleTime: Infinity,
  });
  const actionLoadingText =
    status === StorageLocationStatusEnum.ARCHIVED ? 'Restoring' : 'Archiving';
  return (
    <div className="flex flex-row sm:items-center sm:justify-between gap-2">
      <div className="flex sm:flex-row flex-col items-center gap-2">
        <LinkButton
          variant="ghost"
          label="Edit"
          width="auto"
          customStyles="border border-buttons-primary-bg-default"
          href={`/storage/update/details/${locationId}`}
          isDisabled={
            isPending ||
            isFetchingUpdatedData ||
            status === StorageLocationStatusEnum.ARCHIVED
          }
        />
        <Button
          variant="ghost"
          label={
            status === StorageLocationStatusEnum.ARCHIVED
              ? 'Restore'
              : 'Archive'
          }
          isLoading={isPending || isFetchingUpdatedData}
          data-testid={
            status === StorageLocationStatusEnum.ARCHIVED
              ? 'restore-button'
              : 'archive-button'
          }
          loadingText={isFetchingUpdatedData ? 'Loading' : actionLoadingText}
          leftIcon={
            status === StorageLocationStatusEnum.ARCHIVED ? (
              <FaRedo />
            ) : (
              <PiArchiveFill size={16} />
            )
          }
          width="auto"
          customStyles="border border-buttons-primary-bg-default"
          onPress={() => setShowAlertModal(true)}
        />
        {isAuthorizedToCreateDeclarations && (
          <LinkButton
            label="Add Declaration"
            leftIcon={<FaPlus />}
            width="auto"
            href={`/storage/details/${locationId}/declarations/create`}
          />
        )}
      </div>
      <ActionConfirmationModal
        title={`Are you sure you would like to ${
          status === StorageLocationStatusEnum.ARCHIVED ? 'restore' : 'archive'
        } ${location_name}?`}
        description={`You will still be able to ${
          status === StorageLocationStatusEnum.ARCHIVED ? 'archive' : 'restore'
        } this location at any time`}
        heading={
          <div className="mb-3 flex p-2.5 items-center justify-center rounded-full border border-field-border-default self-start">
            {status === StorageLocationStatusEnum.ARCHIVED ? (
              <FaRedo />
            ) : (
              <PiArchiveFill size={16} />
            )}
          </div>
        }
        onOpenChange={(value: boolean) => setShowAlertModal(value)}
        isOpen={showAlertModal}
        onConfirmChanges={() =>
          mutate({
            status:
              status === StorageLocationStatusEnum.ARCHIVED
                ? StorageLocationStatusEnum.ACTIVE
                : StorageLocationStatusEnum.ARCHIVED,
          })
        }
      />
    </div>
  );
}
