import { type ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  type BarItem,
  colors,
  formatDate,
  normalizeDate,
  Typography,
} from '@breeze-ai/ui-library';
import moment from 'moment';

import { useAppConfiguration } from '../../../../context/app-configuration/useAppConfiguration';
import { usePlatform } from '../../../../context/PlatformContext';
import { type PoliciesMonthlyInsights } from '../../../../model/Insights';
import { formatDateRange, staticDateFormats } from '../../../../utils/dates';
import {
  DashboardWidget,
  type DashboardWidgetProps,
} from '../../../components/DashboardWidget/DashboardWidget';
import { usePoliciesInsights } from '../../hooks';
import { useInsightsPageContext } from '../../InsightsPageContext';
import styles from './GraphWidget.module.scss';
import { GraphWidgetTooltip } from './GraphWidgetTooltip/GraphWidgetTooltip';

const chartXAxisProps: ComponentProps<typeof BarChart>['xAxisProps'] = {
  dataKey: 'month',
  axisLine: false,
  padding: { left: 24 },
  tickLine: false,
  tickFormatter: (value: number) =>
    formatDate(normalizeDate(moment().month(value - 1)), {
      customFormat: staticDateFormats.SHORT_MONTH,
    }),
  tick: { fill: colors.inputText },
};

const chartYAxisProps: ComponentProps<typeof BarChart>['yAxisProps'] = {
  dataKey: 'total',
  tickMargin: 10,
  padding: { bottom: 12 },
  tick: { type: 'number' },
  axisLine: false,
  tickLine: false,
};

const chartTooltipProps: ComponentProps<typeof BarChart>['tooltipProps'] = {
  offset: 0,
  cursor: { opacity: 0.1 },
};

export const GraphWidget = ({ ...props }: Partial<DashboardWidgetProps>) => {
  const { currencies } = useAppConfiguration();
  const { startDate, endDate, currencyCode } = useInsightsPageContext();
  const { platform } = usePlatform();
  const { t } = useTranslation();

  const formattedDateRange = formatDateRange({ startDate, endDate });
  const currency = currencies.find(({ code }) => code === currencyCode)?.symbol;

  const { data } = usePoliciesInsights({
    params: {
      period_start: startDate,
      period_end: endDate,
      currency_code: currencyCode,
    },
  });

  const { policies } = data ?? {};

  const bars: BarItem<PoliciesMonthlyInsights>[] = [
    {
      dataKey: 'total',
      minPointSize: 8,
      calculateProps: ({ total }) => ({
        fill: total === 0 ? 'var(--system-grey-100)' : 'var(--graph-bar-fill)',
      }),
    },
  ];

  return (
    <DashboardWidget
      {...props}
      title={t('PoliciesIssued', {
        ns: 'insights',
        context: platform,
        // TODO there is no type safety for string interpolation
        currency,
      })}
      data-testid="graph-widget"
      className={styles.wrapper}
      loading={!policies}
    >
      {policies && (
        <div className={styles.content}>
          <Typography variant="input" data-testid="date-range">
            {formattedDateRange}
          </Typography>
          <div className={styles.chart}>
            <div
              className={styles.chartWrapper}
              data-testid="by-month-bar-chart"
            >
              <BarChart<PoliciesMonthlyInsights>
                data={policies.months}
                bars={bars}
                yAxisProps={chartYAxisProps}
                xAxisProps={chartXAxisProps}
                tooltip={(data) => <GraphWidgetTooltip data={data} />}
                tooltipProps={chartTooltipProps}
              />
            </div>
          </div>
        </div>
      )}
    </DashboardWidget>
  );
};
