import { type ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaDownload } from 'react-icons/fa6';
import {
  CurrencyCell,
  DateCell,
  getBaseColumnDefinition,
  type TableColumn,
  TextCell,
  Tooltip,
} from '@breeze-ai/ui-library';
import { Typography } from '@breezeai-frontend/cargo-ui';

import { useFeatureToggle } from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import {
  ActionMenu,
  type ActionMenuItem,
} from '../../../components/ActionMenu/ActionMenu';
import { StatusBadge } from '../../../components/StatusBadge/StatusBadge';
import { useQuantityUnits } from '../hooks/useQuantityUnits';
import { type StorageDeclaration } from '../types';
import { statusToBadge } from './utils/statusToBadge';

const TooltipWrapper = ({
  children,
  content,
}: {
  children: ReactNode;
  content: string;
}) => (
  <Tooltip content={content}>
    <div className="flex flex-row items-center gap-2 truncate w-full">
      {children}
    </div>
  </Tooltip>
);
const NoValueCell = () => <TextCell>—</TextCell>;

const QuantityCell = ({
  quantity,
  unit,
}: {
  quantity: StorageDeclaration['quantity_amount'];
  unit: StorageDeclaration['quantity_unit'];
}) => {
  const { getQuantityUnitLabel } = useQuantityUnits();
  if (!quantity || !unit) {
    return <NoValueCell />;
  }

  return (
    <TextCell>
      <div className="flex flex-col">
        <Typography>{quantity.toLocaleString()}</Typography>
        <Typography level="subtext" color="tertiary">
          {getQuantityUnitLabel(unit)}
        </Typography>
      </div>
    </TextCell>
  );
};

export const useDeclarationsTableColumns =
  (): TableColumn<StorageDeclaration>[] => {
    const { platform } = usePlatform();
    const { t } = useTranslation();
    const sortingEnabled = useFeatureToggle('enable_column_sorting');

    return useMemo(
      () =>
        [
          {
            ...getBaseColumnDefinition<StorageDeclaration>('id', 'ID'),
            valueGetter: ({ row }: { row: StorageDeclaration }) =>
              row.declaration_number,
            renderCell: ({ value }: { value: string }) => (
              <TextCell>{value}</TextCell>
            ),
            sortable: sortingEnabled,
            minWidth: 160,
          },
          {
            ...getBaseColumnDefinition<StorageDeclaration>(
              'distributor_id',
              t('Distributor', { ns: 'common', context: platform }),
            ),
            valueGetter: ({ row }: { row: StorageDeclaration }) =>
              row.distributor.legal_name,
            renderCell: ({ value }: { value: string }) => (
              <TooltipWrapper content={String(value)}>
                <TextCell>{value as string}</TextCell>
              </TooltipWrapper>
            ),
            sortable: sortingEnabled,
            minWidth: 200,
          },
          {
            ...getBaseColumnDefinition<StorageDeclaration>(
              'date_of_issuance',
              'Issued On',
            ),
            type: 'date',
            valueGetter: ({ row }: { row: StorageDeclaration }) =>
              row.date_of_issuance,
            renderCell: ({ row }: { row: StorageDeclaration }) =>
              row.date_of_issuance ? (
                <DateCell
                  format="FULL_DATE_NUMERIC"
                  value={row.date_of_issuance}
                />
              ) : (
                <NoValueCell />
              ),
            sortable: sortingEnabled,
            minWidth: 200,
          },
          {
            ...getBaseColumnDefinition<StorageDeclaration>(
              'start_date',
              t('StartDate', { ns: 'common', context: platform }),
            ),
            type: 'date',
            valueGetter: ({ row }: { row: StorageDeclaration }) =>
              row.start_date,
            renderCell: ({ row }: { row: StorageDeclaration }) => (
              <DateCell format="FULL_DATE_NUMERIC" value={row.start_date} />
            ),
            sortable: sortingEnabled,
            minWidth: 200,
          },
          {
            ...getBaseColumnDefinition<StorageDeclaration>(
              'end_date',
              t('EndDate', { ns: 'common', context: platform }),
            ),
            type: 'date',
            valueGetter: ({ row }: { row: StorageDeclaration }) => row.end_date,
            renderCell: ({ row }: { row: StorageDeclaration }) => (
              <DateCell format="FULL_DATE_NUMERIC" value={row.end_date} />
            ),
            sortable: sortingEnabled,
          },
          {
            ...getBaseColumnDefinition(
              'commodity_type',
              t('Commodity', {
                ns: 'common',
                context: platform,
              }),
            ),
            minWidth: 200,
            valueGetter: ({ row }: { row: StorageDeclaration }) =>
              row.commodity?.commodity_type,
            renderCell: ({ value }: { value: string }) => (
              <TooltipWrapper content={String(value)}>
                <TextCell>{value as string}</TextCell>
              </TooltipWrapper>
            ),
            sortable: sortingEnabled,
          },
          {
            ...getBaseColumnDefinition(
              'insured_value',
              t('InsuredValue', {
                ns: 'common',
                context: platform,
              }),
            ),
            type: 'number',
            valueGetter: ({ row }: { row: StorageDeclaration }) =>
              row?.insured_value_amount,
            renderCell: ({ row }: { row: StorageDeclaration }) => (
              <CurrencyCell
                value={Number(row?.insured_value_amount)}
                currency={row.insured_value_currency.code}
              />
            ),
            minWidth: 150,
            sortable: sortingEnabled,
          },
          {
            ...getBaseColumnDefinition(
              'premium_value',
              t('Premium', {
                ns: 'common',
                context: platform,
              }),
            ),
            type: 'number',
            valueGetter: ({ row }: { row: StorageDeclaration }) =>
              row?.premium_amount,
            renderCell: ({ row }: { row: StorageDeclaration }) => (
              <CurrencyCell
                value={Number(row.premium_amount)}
                currency={row.premium_currency.code}
              />
            ),
            minWidth: 150,
            sortable: sortingEnabled,
          },
          {
            ...getBaseColumnDefinition(
              'status',
              t('Status', { ns: 'common', context: platform }),
            ),
            minWidth: 150,
            sortable: sortingEnabled,
            valueGetter: ({ row }: { row: StorageDeclaration }) => row.status,
            renderCell: ({ row }: { row: StorageDeclaration }) => {
              const { label, variant, Icon } =
                statusToBadge[
                  (row.status as keyof typeof statusToBadge) ?? 'unknown'
                ] ?? statusToBadge.unknown;

              return (
                <StatusBadge variant={variant} label={label} Icon={Icon} />
              );
            },
          },
          {
            ...getBaseColumnDefinition(
              'created_at',
              t('CreatedOn', { ns: 'common', context: platform }),
            ),
            type: 'date',
            minWidth: 150,
            valueGetter: ({ row }: { row: StorageDeclaration }) =>
              row.created_time,
            renderCell: ({ row }: { row: StorageDeclaration }) => (
              <DateCell
                format="SHORT_MONTH_NAME_DATE"
                value={row.created_time}
                createdBy={
                  row.created_by_user && {
                    avatarColor: row.created_by_user.avatar_color,
                    name: `${row.created_by_user.first_name} ${row.created_by_user.last_name}`,
                  }
                }
              />
            ),
            sortable: sortingEnabled,
          },
          {
            ...getBaseColumnDefinition<StorageDeclaration>(
              'quantity',
              t('Quantity', { ns: 'common', context: platform }),
            ),
            type: 'number',
            renderCell: ({ row }: { row: StorageDeclaration }) => {
              return (
                <QuantityCell
                  quantity={row.quantity_amount}
                  unit={row.quantity_unit}
                />
              );
            },
          },
          {
            ...getBaseColumnDefinition('action', ''),
            minWidth: 40,
            maxWidth: 60,
            renderCell: ({ row }: { row: StorageDeclaration }) => {
              const items: ActionMenuItem[] = [
                {
                  type: 'link',
                  label: 'Download certificate',
                  href: row.certificate_url,
                  leftIcon: <FaDownload />,
                  isDisabled: !row.certificate_url,
                  isDownload: true,
                },
              ];
              return <ActionMenu items={items} id={String(row.id)} />;
            },
          },
        ].filter(Boolean) as TableColumn<StorageDeclaration>[],
      [platform, t, sortingEnabled],
    );
  };
