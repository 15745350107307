/* tslint:disable */
/* eslint-disable */
/**
 * Service API Document
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { BffCoverageDescriptionListCoverageDescription } from '../models';
// @ts-ignore
import type { BffDuplicateQuoteParamsPayload } from '../models';
// @ts-ignore
import type { BffGetQuoteFormResponse } from '../models';
// @ts-ignore
import type { BffGetQuotesRequest } from '../models';
// @ts-ignore
import type { BffGetQuotesResponse } from '../models';
// @ts-ignore
import type { BffPostQuoteFormBody } from '../models';
// @ts-ignore
import type { BffQuoteModel } from '../models';
// @ts-ignore
import type { BffQuoteModelWithPermissions } from '../models';
// @ts-ignore
import type { BffUpdateQuoteParamsPayload } from '../models';
// @ts-ignore
import type { BffValidationErrorValidationErrorElement } from '../models';
/**
 * QuotesApi - axios parameter creator
 * @export
 */
export const QuotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary duplicate_quote <POST>
         * @param {BffDuplicateQuoteParamsPayload} [bffDuplicateQuoteParamsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateQuote: async (bffDuplicateQuoteParamsPayload?: BffDuplicateQuoteParamsPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bff_quotes/duplicate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bffDuplicateQuoteParamsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get_quote_coverage_conditions <GET>
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuoteCoverageConditions: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getQuoteCoverageConditions', 'id', id)
            const localVarPath = `/bff_quotes/{id}/conditions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get_quote_details <GET>
         * @param {string} quoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuoteDetails: async (quoteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quoteId' is not null or undefined
            assertParamExists('getQuoteDetails', 'quoteId', quoteId)
            const localVarPath = `/bff_quotes/{quote_id}`
                .replace(`{${"quote_id"}}`, encodeURIComponent(String(quoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get_quote_form <GET>
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuoteForm: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/form/quote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get_quotes <POST>
         * @param {BffGetQuotesRequest} [bffGetQuotesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuotes: async (bffGetQuotesRequest?: BffGetQuotesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bff_quotes/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bffGetQuotesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary post_quote_form <POST>
         * @param {BffPostQuoteFormBody} [bffPostQuoteFormBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postQuoteForm: async (bffPostQuoteFormBody?: BffPostQuoteFormBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/form/quote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bffPostQuoteFormBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary restore_expired_quote <POST>
         * @param {number} quoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreExpiredQuote: async (quoteId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quoteId' is not null or undefined
            assertParamExists('restoreExpiredQuote', 'quoteId', quoteId)
            const localVarPath = `/bff_quotes/{quote_id}/restore-expired`
                .replace(`{${"quote_id"}}`, encodeURIComponent(String(quoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update_quote <PUT>
         * @param {number} id 
         * @param {BffUpdateQuoteParamsPayload} [bffUpdateQuoteParamsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuote: async (id: number, bffUpdateQuoteParamsPayload?: BffUpdateQuoteParamsPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateQuote', 'id', id)
            const localVarPath = `/bff_quotes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bffUpdateQuoteParamsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuotesApi - functional programming interface
 * @export
 */
export const QuotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuotesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary duplicate_quote <POST>
         * @param {BffDuplicateQuoteParamsPayload} [bffDuplicateQuoteParamsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateQuote(bffDuplicateQuoteParamsPayload?: BffDuplicateQuoteParamsPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BffQuoteModelWithPermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateQuote(bffDuplicateQuoteParamsPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.duplicateQuote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get_quote_coverage_conditions <GET>
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuoteCoverageConditions(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BffCoverageDescriptionListCoverageDescription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuoteCoverageConditions(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.getQuoteCoverageConditions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get_quote_details <GET>
         * @param {string} quoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuoteDetails(quoteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BffQuoteModelWithPermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuoteDetails(quoteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.getQuoteDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get_quote_form <GET>
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuoteForm(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BffGetQuoteFormResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuoteForm(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.getQuoteForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get_quotes <POST>
         * @param {BffGetQuotesRequest} [bffGetQuotesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuotes(bffGetQuotesRequest?: BffGetQuotesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BffGetQuotesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuotes(bffGetQuotesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.getQuotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary post_quote_form <POST>
         * @param {BffPostQuoteFormBody} [bffPostQuoteFormBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postQuoteForm(bffPostQuoteFormBody?: BffPostQuoteFormBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BffQuoteModelWithPermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postQuoteForm(bffPostQuoteFormBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.postQuoteForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary restore_expired_quote <POST>
         * @param {number} quoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreExpiredQuote(quoteId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BffQuoteModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreExpiredQuote(quoteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.restoreExpiredQuote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update_quote <PUT>
         * @param {number} id 
         * @param {BffUpdateQuoteParamsPayload} [bffUpdateQuoteParamsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQuote(id: number, bffUpdateQuoteParamsPayload?: BffUpdateQuoteParamsPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BffQuoteModelWithPermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateQuote(id, bffUpdateQuoteParamsPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.updateQuote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * QuotesApi - factory interface
 * @export
 */
export const QuotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuotesApiFp(configuration)
    return {
        /**
         * 
         * @summary duplicate_quote <POST>
         * @param {QuotesApiDuplicateQuoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateQuote(requestParameters: QuotesApiDuplicateQuoteRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BffQuoteModelWithPermissions> {
            return localVarFp.duplicateQuote(requestParameters.bffDuplicateQuoteParamsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get_quote_coverage_conditions <GET>
         * @param {QuotesApiGetQuoteCoverageConditionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuoteCoverageConditions(requestParameters: QuotesApiGetQuoteCoverageConditionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<BffCoverageDescriptionListCoverageDescription>> {
            return localVarFp.getQuoteCoverageConditions(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get_quote_details <GET>
         * @param {QuotesApiGetQuoteDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuoteDetails(requestParameters: QuotesApiGetQuoteDetailsRequest, options?: RawAxiosRequestConfig): AxiosPromise<BffQuoteModelWithPermissions> {
            return localVarFp.getQuoteDetails(requestParameters.quoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get_quote_form <GET>
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuoteForm(options?: RawAxiosRequestConfig): AxiosPromise<BffGetQuoteFormResponse> {
            return localVarFp.getQuoteForm(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get_quotes <POST>
         * @param {QuotesApiGetQuotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuotes(requestParameters: QuotesApiGetQuotesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BffGetQuotesResponse> {
            return localVarFp.getQuotes(requestParameters.bffGetQuotesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary post_quote_form <POST>
         * @param {QuotesApiPostQuoteFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postQuoteForm(requestParameters: QuotesApiPostQuoteFormRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BffQuoteModelWithPermissions> {
            return localVarFp.postQuoteForm(requestParameters.bffPostQuoteFormBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary restore_expired_quote <POST>
         * @param {QuotesApiRestoreExpiredQuoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreExpiredQuote(requestParameters: QuotesApiRestoreExpiredQuoteRequest, options?: RawAxiosRequestConfig): AxiosPromise<BffQuoteModel> {
            return localVarFp.restoreExpiredQuote(requestParameters.quoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update_quote <PUT>
         * @param {QuotesApiUpdateQuoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuote(requestParameters: QuotesApiUpdateQuoteRequest, options?: RawAxiosRequestConfig): AxiosPromise<BffQuoteModelWithPermissions> {
            return localVarFp.updateQuote(requestParameters.id, requestParameters.bffUpdateQuoteParamsPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for duplicateQuote operation in QuotesApi.
 * @export
 * @interface QuotesApiDuplicateQuoteRequest
 */
export interface QuotesApiDuplicateQuoteRequest {
    /**
     * 
     * @type {BffDuplicateQuoteParamsPayload}
     * @memberof QuotesApiDuplicateQuote
     */
    readonly bffDuplicateQuoteParamsPayload?: BffDuplicateQuoteParamsPayload
}

/**
 * Request parameters for getQuoteCoverageConditions operation in QuotesApi.
 * @export
 * @interface QuotesApiGetQuoteCoverageConditionsRequest
 */
export interface QuotesApiGetQuoteCoverageConditionsRequest {
    /**
     * 
     * @type {number}
     * @memberof QuotesApiGetQuoteCoverageConditions
     */
    readonly id: number
}

/**
 * Request parameters for getQuoteDetails operation in QuotesApi.
 * @export
 * @interface QuotesApiGetQuoteDetailsRequest
 */
export interface QuotesApiGetQuoteDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof QuotesApiGetQuoteDetails
     */
    readonly quoteId: string
}

/**
 * Request parameters for getQuotes operation in QuotesApi.
 * @export
 * @interface QuotesApiGetQuotesRequest
 */
export interface QuotesApiGetQuotesRequest {
    /**
     * 
     * @type {BffGetQuotesRequest}
     * @memberof QuotesApiGetQuotes
     */
    readonly bffGetQuotesRequest?: BffGetQuotesRequest
}

/**
 * Request parameters for postQuoteForm operation in QuotesApi.
 * @export
 * @interface QuotesApiPostQuoteFormRequest
 */
export interface QuotesApiPostQuoteFormRequest {
    /**
     * 
     * @type {BffPostQuoteFormBody}
     * @memberof QuotesApiPostQuoteForm
     */
    readonly bffPostQuoteFormBody?: BffPostQuoteFormBody
}

/**
 * Request parameters for restoreExpiredQuote operation in QuotesApi.
 * @export
 * @interface QuotesApiRestoreExpiredQuoteRequest
 */
export interface QuotesApiRestoreExpiredQuoteRequest {
    /**
     * 
     * @type {number}
     * @memberof QuotesApiRestoreExpiredQuote
     */
    readonly quoteId: number
}

/**
 * Request parameters for updateQuote operation in QuotesApi.
 * @export
 * @interface QuotesApiUpdateQuoteRequest
 */
export interface QuotesApiUpdateQuoteRequest {
    /**
     * 
     * @type {number}
     * @memberof QuotesApiUpdateQuote
     */
    readonly id: number

    /**
     * 
     * @type {BffUpdateQuoteParamsPayload}
     * @memberof QuotesApiUpdateQuote
     */
    readonly bffUpdateQuoteParamsPayload?: BffUpdateQuoteParamsPayload
}

/**
 * QuotesApi - object-oriented interface
 * @export
 * @class QuotesApi
 * @extends {BaseAPI}
 */
export class QuotesApi extends BaseAPI {
    /**
     * 
     * @summary duplicate_quote <POST>
     * @param {QuotesApiDuplicateQuoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public duplicateQuote(requestParameters: QuotesApiDuplicateQuoteRequest = {}, options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).duplicateQuote(requestParameters.bffDuplicateQuoteParamsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get_quote_coverage_conditions <GET>
     * @param {QuotesApiGetQuoteCoverageConditionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public getQuoteCoverageConditions(requestParameters: QuotesApiGetQuoteCoverageConditionsRequest, options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).getQuoteCoverageConditions(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get_quote_details <GET>
     * @param {QuotesApiGetQuoteDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public getQuoteDetails(requestParameters: QuotesApiGetQuoteDetailsRequest, options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).getQuoteDetails(requestParameters.quoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get_quote_form <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public getQuoteForm(options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).getQuoteForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get_quotes <POST>
     * @param {QuotesApiGetQuotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public getQuotes(requestParameters: QuotesApiGetQuotesRequest = {}, options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).getQuotes(requestParameters.bffGetQuotesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary post_quote_form <POST>
     * @param {QuotesApiPostQuoteFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public postQuoteForm(requestParameters: QuotesApiPostQuoteFormRequest = {}, options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).postQuoteForm(requestParameters.bffPostQuoteFormBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary restore_expired_quote <POST>
     * @param {QuotesApiRestoreExpiredQuoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public restoreExpiredQuote(requestParameters: QuotesApiRestoreExpiredQuoteRequest, options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).restoreExpiredQuote(requestParameters.quoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update_quote <PUT>
     * @param {QuotesApiUpdateQuoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public updateQuote(requestParameters: QuotesApiUpdateQuoteRequest, options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).updateQuote(requestParameters.id, requestParameters.bffUpdateQuoteParamsPayload, options).then((request) => request(this.axios, this.basePath));
    }
}

