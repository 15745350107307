import { type FieldMeta } from '@tanstack/react-form';

import { type Quote } from '../../../model/Quote';
import { getUpdatedValue } from '../../utils/forms';
import { type VesselFieldsFormData } from './VesselFields';

export const VESSEL_NOT_FOUND_ID = 'vessel-not-found-id';
export const VESSEL_NOT_FOUND_TEXT = "Can't find vessel";

export const getEmptyOptionName = (
  isVesselIdRequired: boolean,
  currentInputValue: string,
) =>
  isVesselIdRequired ? VESSEL_NOT_FOUND_TEXT : `Use "${currentInputValue}"`;

export const getVesselFieldDefaultValue = (
  vessel: Quote['vessel'],
  vesselName: Quote['vessel_name'],
  primaryMot: Quote['primary_transport_mode_code'],
  requireVesselInformation: boolean,
): VesselFieldsFormData['vessel'] => {
  if (vessel) {
    return { name: vessel.name, external_id: vessel.external_id };
  }

  // No vessel & primaryMot is "sea" means quote was created with "Can't find vessel" option
  if (requireVesselInformation && primaryMot === 'sea') {
    return { name: VESSEL_NOT_FOUND_TEXT, external_id: VESSEL_NOT_FOUND_ID };
  }

  return { name: vesselName || '', external_id: '' };
};

export const getVesselsUpdatePayload = (
  formData: VesselFieldsFormData,
  formMeta: Record<string, FieldMeta>,
): {
  vessel_external_id?: ReturnType<typeof getUpdatedValue<string>>;
  vessel_name?: ReturnType<typeof getUpdatedValue<string>>;
} => {
  const updatedVesselExternalId = getUpdatedValue(
    formData.vessel?.external_id,
    formMeta,
    'vessel',
  );
  const updateVesselName = getUpdatedValue(
    formData.vessel?.name,
    formMeta,
    'vessel',
  );

  if (
    updatedVesselExternalId &&
    updatedVesselExternalId === VESSEL_NOT_FOUND_ID
  ) {
    return {
      vessel_external_id: null,
    };
  }

  if (updatedVesselExternalId) {
    return {
      vessel_external_id: updatedVesselExternalId,
    };
  }

  return {
    vessel_name: updateVesselName,
  };
};
