import { useMemo } from 'react';
import { FaCheck } from 'react-icons/fa6';
import { PiArchiveFill } from 'react-icons/pi';
import { Typography } from '@breezeai-frontend/cargo-ui';

import {
  FilterableFields,
  FilterOperators,
} from '../../../../../../network/apis/types';
import { useFilterStore } from '../../../../../components/TableFilters';
import { MultiSelectRadioDropdown } from '../../../../../components/TableFilters/components/MultiSelectRadioDropdown';
import { StorageLocationStatusEnum } from '../../../types';

const storageLocationStatusOptions = [
  {
    label: (
      <div
        className={
          'bg-system-green-100 flex gap-1 items-center px-2 py-1 rounded-full'
        }
      >
        <FaCheck size={14} className="text-system-green-500" />

        <Typography level="subtext" color="primary" text="capitalize">
          Active
        </Typography>
      </div>
    ),
    value: StorageLocationStatusEnum.ACTIVE,
    labelHtmlFor: StorageLocationStatusEnum.ACTIVE,
  },
  {
    label: (
      <div
        className={
          'bg-system-grey-300 flex gap-1 items-center px-2 py-1 rounded-full'
        }
      >
        <PiArchiveFill size={14} className="text-system-grey-700" />

        <Typography level="subtext" color="primary" text="capitalize">
          Archived
        </Typography>
      </div>
    ),
    value: StorageLocationStatusEnum.ARCHIVED,
    labelHtmlFor: StorageLocationStatusEnum.ARCHIVED,
  },
];

export function StorageLocationStatusFilter() {
  const {
    selectedLocationStatuses,
    setSelectedLocationStatuses,
    updateFilter,
    removeFilter,
    filters,
  } = useFilterStore((state) => ({
    setSelectedLocationStatuses: state.setSelectedLocationStatuses,
    selectedLocationStatuses: state.selectedLocationStatuses,
    updateFilter: state.updateFilter,
    removeFilter: state.removeFilter,
    filters: state.filters.find(
      (filter) => filter.field === FilterableFields.LOCATION_STATUS,
    )?.values,
  }));

  const onSubmit = () =>
    updateFilter({
      field: FilterableFields.LOCATION_STATUS,
      operator: FilterOperators.EQUALS,
      values: selectedLocationStatuses as string[],
    });

  const onReset = () => removeFilter(FilterableFields.LOCATION_STATUS);

  const appliedOptions = useMemo(
    () =>
      storageLocationStatusOptions?.filter((option) =>
        filters?.includes(option.value),
      ) ?? [],
    [filters],
  );

  const nonAppliedOptions = useMemo(
    () =>
      storageLocationStatusOptions?.filter(
        (option) => !filters?.includes(option.value),
      ) ?? [],
    [filters],
  );

  return (
    <MultiSelectRadioDropdown
      appliedOptions={appliedOptions}
      optionsCount={filters?.length}
      selected={selectedLocationStatuses as string[]}
      setSelected={setSelectedLocationStatuses as (selected: string[]) => void}
      name="storageLocationStatus"
      label="Location Status"
      dropdownIcon="circle-half-stroke"
      nonAppliedOptions={nonAppliedOptions}
      onSubmit={onSubmit}
      onReset={onReset}
      triggerTestId="storage-location-status-filter-trigger"
      contentTestId="storage-location-status-filter-content"
    />
  );
}
