import { useEffect } from 'react';
import { SectionLayout } from '@breezeai-frontend/cargo-ui';

import { useUser } from '../../../context/auth/auth-hooks';
import { useRouteParams } from '../../../router/router-hooks';
import { useTracking } from '../../../utils/snowplow/hooks';
import { ClaimCreationFlowSteps } from '../constants';
import { ClaimCreationStepsIndicator } from './ClaimCreationStepsIndicator/ClaimCreationStepsIndicator';
import { ClaimDetails } from './ClaimDetails/ClaimDetails';
import { ClaimFilesUpload } from './ClaimFilesUpload/ClaimFilesUpload';
import { ClaimReview } from './ClaimReview/ClaimReview';
import { ClaimCreationContextProvider } from './context/ClaimCreationContext';
import {
  useClaimCreationFlowContext,
  useClaimCreationStateAction,
  useInitClaimForms,
} from './context/hooks';

type RouteSearchParams = {
  policy_id?: string;
  certificate_id?: string;
};

export const ClaimCreationFlowContent = () => {
  useInitClaimForms();
  const { step } = useClaimCreationFlowContext();
  const { setFormData } = useClaimCreationStateAction();

  const params = useRouteParams<never, RouteSearchParams>();

  useEffect(() => {
    if (!params) return;

    const { policy_id, certificate_id } = params.searchParams;

    if (policy_id && certificate_id) {
      setFormData({
        policy: {
          id: parseInt(policy_id),
          certificate_id,
        },
      });
    }
  }, [params, setFormData]);

  const user = useUser();
  const isAuthorized = Boolean(user?.permissions.claims?.create);

  return (
    <SectionLayout
      data-testid="claim-creation-flow"
      title=""
      isAuthorized={isAuthorized}
    >
      <div className="pt-10 w-full flex flex-col gap-10">
        <div className="flex justify-center">
          <ClaimCreationStepsIndicator />
        </div>
        <div className="flex justify-center">
          <div className="w-full max-w-6xl min-w-fit">
            {step === ClaimCreationFlowSteps.CLAIM_DETAILS && <ClaimDetails />}
            {step === ClaimCreationFlowSteps.UPLOAD_FILES && (
              <ClaimFilesUpload />
            )}
            {step === ClaimCreationFlowSteps.REVIEW && <ClaimReview />}
          </div>
        </div>
      </div>
    </SectionLayout>
  );
};

export const ClaimCreationFlow = () => {
  useTracking();

  return (
    <ClaimCreationContextProvider>
      <ClaimCreationFlowContent />
    </ClaimCreationContextProvider>
  );
};
